import { Collapse, Col } from 'antd';
import PropTypes from 'prop-types';
import { DoctorsLetterForm } from './DoctorsLetterForm';

const { Panel } = Collapse;

/**
 * Renders a collapsible component for the doctor's letter.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.header - The header text for the collapsible panel.
 * @param {Array} props.filesList - The list of files associated with the doctor's letter.
 * @param {Function} props.setFilesList - The function to update the files list.
 * @returns {JSX.Element} The rendered component.
 */
export const CollapseDoctorsLetter = ({ header, filesList, setFilesList }) => (
  <Col span={24}>
    <Collapse expandIconPosition="end" className="patient-form-collapse">
      <Panel header={header} key="1">
        <DoctorsLetterForm filesList={filesList} setFilesList={setFilesList} />
      </Panel>
    </Collapse>
  </Col>
);

CollapseDoctorsLetter.propTypes = {
  header: PropTypes.string.isRequired,
  filesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFilesList: PropTypes.func.isRequired
};
