import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

/**
 * Custom hook to display error messages for the vitale card.
 *
 * @hook
 * @returns {JSX.Element} The error message component.
 */
export const useCustomErrorMessage = () => {
  const { t } = useTranslation();
  const keysMessages = ['error', 'error_instruction', 'error_contact_support'];

  return keysMessages.map((key) => (
    <Row key={key}>{t(`stellair.vitale_card.${key}`)}</Row>
  ));
};
