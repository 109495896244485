import { Card, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components';
import { usePrescriptionAuthColumns } from './prescriptionAuthColumns';

const { Panel } = Collapse;

/**
 * Component for managing authorization settings for users, including allowed prescriptions for nurses.
 * @component
 * @example
 * // Usage in a parent component:
 * <Authorization />
 * @returns {JSX.Element} Returns the JSX element for the authorization settings component.
 */
const Authorization = () => {
  const { t } = useTranslation();
  const { columns } = usePrescriptionAuthColumns();

  return (
    <Card style={{ marginBottom: 24 }}>
      <Collapse expandIconPosition="end" defaultActiveKey={['1']} ghost>
        <Panel header={t('settings.collapses.allowed_prescription')} key="1">
          <Datatable
            resourceName="users"
            columns={columns}
            deleteAction={false}
            showAction={false}
            editAction={false}
            archiveAction={false}
            extraQuery="position=NURSE"
          />
        </Panel>
      </Collapse>
    </Card>
  );
};

export default Authorization;
