import { useTranslation } from 'react-i18next';
import { StayTable } from '../dataTables/StayTable';
import { ExitTable } from '../dataTables/ExitTable';
import { RadiologyTable } from '../dataTables/RadiologyTable';
import { ScorTable } from '../dataTables/ScorTable';
import { PhotosTable } from '../dataTables/PhotosTable';

/**
 * Custom hook that returns an array of modal care collapse items.
 * @hook
 *
 * @param {Object} options - The options object.
 * @param {string} options.resource - The resource string.
 * @param {Array} options.prescriptions - The prescriptions array.
 * @param {Function} options.onTableChange - The table change event handler.
 * @param {Array} options.consultationsWithPhotos - The consultations with photos array.
 * @returns {Object} - The modal care collapse items.
 */
export const useModalCareCollapseItems = ({
  resource,
  prescriptions,
  onTableChange,
  consultationsWithPhotos
}) => {
  const { t } = useTranslation();

  const modalCareCollapseItems = [
    {
      key: '1',
      label: t(`${resource}.documents.stay`),
      children: (
        <StayTable
          data={prescriptions?.stayPrescriptionsArray || []}
          onChange={onTableChange}
          className="ant-table-wrapper"
        />
      )
    },
    {
      key: '2',
      label: t(`${resource}.documents.exit`),
      children: (
        <ExitTable
          data={prescriptions?.exitPrescriptions || []}
          onChange={onTableChange}
          className="ant-table-wrapper"
        />
      )
    },
    {
      key: '3',
      label: t(`${resource}.documents.radiology`),
      children: (
        <RadiologyTable
          data={prescriptions?.radiologyPrescriptions || []}
          onChange={onTableChange}
          className="ant-table-wrapper"
        />
      )
    },
    {
      key: '4',
      label: t(`${resource}.documents.scor`),
      children: (
        <ScorTable
          data={prescriptions?.scorPrescriptions || []}
          onChange={onTableChange}
          className="ant-table-wrapper"
        />
      )
    },
    {
      key: '5',
      label: t(`${resource}.documents.photos`),
      children: (
        <PhotosTable
          data={consultationsWithPhotos}
          onChange={onTableChange}
          className="ant-table-wrapper"
        />
      )
    }
  ];
  return { modalCareCollapseItems };
};
