import React from 'react';
import { Collapse, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import useFields from '../fields';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useGenerateFormItem } from '../../../utils/generateFormItem';

const { Panel } = Collapse;

/**
 * Component for managing default center settings for users.
 * @component
 * @example
 * // Usage in a parent component:
 * <DefaultCenter />
 * @returns {JSX.Element} Returns the JSX element for the default center settings component.
 */
const DefaultCenter = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { defaultCenterFields } = useFields(user);
  const generateFields = useGenerateFormItem();

  return (
    <Card style={{ marginBottom: 24 }}>
      <Collapse expandIconPosition="end" defaultActiveKey={['1']} ghost>
        <Panel header={t('settings.collapses.default_center')} key="1">
          {defaultCenterFields.map((field) => generateFields('users', field))}
        </Panel>
      </Collapse>
    </Card>
  );
};

export default DefaultCenter;
