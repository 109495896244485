import { Tag } from 'antd';

/**
 * Returns an array of columns for the centers table.
 *
 * @hook
 * @param {Object} t - i18n translate function.
 * @param {Array} typeArr - An array of center types.
 * @returns {Array} Array of column objects.
 */
export const useCentersColumns = (t, typeArr) => [
  {
    title: t('centers.form.name').toUpperCase(),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.length - b.name.length
  },
  {
    title: t('centers.form.type').toUpperCase(),
    dataIndex: 'type',
    key: 'type',
    sorter: (a, b) => b.type.length - a.type.length,
    filters: typeArr.map((type) => ({ text: type, value: type })),
    onFilter: (value, record) => record.type.indexOf(value) === 0
  },
  {
    title: t('centers.form.number_of_users').toUpperCase(),
    dataIndex: 'user_count',
    key: 'user_count',
    sorter: (a, b) => a.user_count - b.user_count
  },
  {
    dataIndex: 'archived',
    key: 'archived',
    render: (archived) =>
      archived && <Tag color="red">{t('centers.tags.archived')}</Tag>
  }
];
