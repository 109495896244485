import dayjs from 'dayjs';

/**
 * Fills form data from a Vitale card.
 *
 * @function
 * @param {Object} beneficiary - The beneficiary object containing the data from the Vitale card.
 * @param {string} patientStellairId - The ID of the patient in the Stellair system.
 * @param {Object} form - The form object used to set the field values.
 * @param {function} setPatientStellairId - The function to set the patientStellairId state.
 * @param {function} setIsModalVisible - The function to set the isModalVisible state.
 * @returns {void}
 */
export const fillFormDatasFromVitaleCard = (
  beneficiary,
  patientStellairId,
  form,
  setPatientStellairId,
  setIsModalVisible
) => {
  const {
    adresse,
    nom,
    prenom,
    dateNaissanceStr,
    nirAssure,
    medecinTraitantDeclare
  } = beneficiary;
  // These are keys from Stellair and yes, it's in french.

  form.setFieldsValue({
    last_name: nom || '-',
    first_name: prenom || '-',
    birth_date: dayjs(dateNaissanceStr, 'DD/MM/YYYY').toISOString(),
    address: adresse || '-',
    ins: nirAssure || null,
    general_practitioner: medecinTraitantDeclare || '-',
    gender: nirAssure.startsWith('1') ? 'MAN' : 'WOMAN'
  });
  setPatientStellairId(patientStellairId);

  setIsModalVisible((prevState) => ({ ...prevState, success: false }));
};
