import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Checkbox, Form } from 'antd';
import { PatientContext } from '../../../../contexts/PatientContext/PatientContext';

export const NoCardRadioButton = ({ dbKey }) => {
  const {
    noHealthInsuranceCard,
    setNoHealthInsuranceCard,
    noIdCard,
    setNoIdCard
  } = useContext(PatientContext);
  const { Item } = Form;

  const noCard = () => {
    if (dbKey === 'no_identity_card') {
      setNoIdCard(!noIdCard);
    } else {
      setNoHealthInsuranceCard(!noHealthInsuranceCard);
    }
  };
  return (
    <Item name={['card', `${dbKey}`]} valuePropName="checked">
      <Checkbox
        onChange={noCard}
        checked={
          dbKey === 'no_identity_card' ? noIdCard : noHealthInsuranceCard
        }
      />
    </Item>
  );
};
NoCardRadioButton.propTypes = {
  dbKey: PropTypes.string.isRequired
};
