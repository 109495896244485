import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CardUpload } from '../../../../components/CardUpload/CardUpload';
import { useDocumentsTypes } from './utils/useDocumentsTypes';

/**
 * Hook to manage document types and wording.
 *
 * @hook
 *
 * @returns {Object} The document type, setDocumentType function, document wording, setDocumentWording function, and photoFields array.
 * @property {string} documentType - The current document type.
 * @property {Function} setDocumentType - Function to set the document type.
 * @property {string} documentWording - The current document wording.
 * @property {Function} setDocumentWording - Function to set the document wording.
 * @property {Array} photoFields - Array of fields for handling photo upload.
 */
export const useFields = () => {
  const { t } = useTranslation();
  const documentsTypesList = useDocumentsTypes();

  const [documentType, setDocumentType] = useState('');
  const [documentWording, setDocumentWording] = useState('');

  const photoFields = [
    {
      noLabel: true,
      name: ['photos'],
      rules: [{ required: false }, { type: 'file' }],
      input: <CardUpload dbKey="photos" resource="consultations" />
    }
  ];

  /**
   * Array of objects representing the fields for printing documents.
   * Each object contains a label, input, and optional name property.
   * The label represents the field title, the input represents the input component,
   * and the name represents the name of the field.
   *
   * @type {Array<{ label: string, input: JSX.Element, name?: string }>}
   */
  const printFields = [
    {
      name: 'type',
      label: 'type.title',
      input: (
        <Select
          // map through the keys of the documentsTypesList object
          options={Object.keys(documentsTypesList)?.map((value) => ({
            label: t(`consultations.documents.form.type.${value}`),
            value
          }))}
          onChange={(value) => setDocumentType(value)}
          allowClear
        />
      )
    },
    {
      name: `${documentType}_wording`,
      label: 'document_wording',
      hidden: documentsTypesList[documentType]?.length === 0 || !documentType,
      input: (
        <Select
          // map through the values of the documentsTypesList object using the documentType as the key
          options={documentsTypesList[documentType]?.map((wording) => ({
            label: t(`consultations.documents.form.wording.${wording}`),
            value: wording
          }))}
          onChange={(value) => setDocumentWording(value)}
          allowClear
        />
      )
    }
  ];

  return {
    photoFields,
    printFields,
    documentType,
    documentWording
  };
};
