import { SelectGender } from './components/Fields/SelectGender';
import { FormatedDatePicker } from './components/Fields/FormatedDatePicker';
import { ReasonsSelect } from './components/SearchPatientModal/ReasonsSelect';
import { SelectDiagnostic } from './components/SearchPatientModal/SelectDiagnostic';

const useSearchFields = ({ enums }) => {
  const searchPatientFields = [
    {
      noLabel: true,
      name: ['patient', 'gender'],
      input: <SelectGender enums={enums} />
    },
    {
      label: 'patient_last_name',
      name: ['patient', 'last_name']
    },
    {
      label: 'patient_birth_name',
      name: ['patient', 'birth_name']
    },
    {
      label: 'patient_first_name',
      name: ['patient', 'first_name']
    },
    {
      label: 'birthdate',
      name: ['patient', 'birth_date'],
      input: <FormatedDatePicker antdKey="" dbKey="birth_date" />
    },
    {
      label: 'appointment_purpose',
      name: ['patient', 'visit_reason'],
      input: <ReasonsSelect enums={enums} />
    },
    {
      label: 'diagnostic',
      name: ['patient', 'diagnostic'],
      input: <SelectDiagnostic enums={enums} />
    },
    {
      label: 'consultation_date',
      name: ['patient', 'date'],
      input: <FormatedDatePicker antdKey="" dbKey="date" />
    }
  ];

  return {
    searchPatientFields
  };
};
export default useSearchFields;
