/**
 * Custom hook that returns an array of fields.
 * Each field object contains a name and a label.
 *
 * @hook
 * @returns {Object} An object with a fields property that contains an array of field objects.
 */
export const useFields = () => {
  const fields = [
    {
      name: 'first_visit_waiting_room',
      label: 'centers.form.default_first_visit_room'
    },
    {
      name: 'return_visit_waiting_room',
      label: 'centers.form.default_return_visit_room'
    }
  ];

  return { fields };
};
