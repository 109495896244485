import { Routes, Route } from 'react-router-dom';
import { PrescriptionContextProvider } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { PrescriptionChoises } from './PrescriptionChoises';
import { CreateUpdateMedicationsPrescription } from './MedicationPrescription/CreateUpdateMedicationsPrescription';
import { CreateUpdateRadiologyPrescription } from './RadiologyPrescription/CreateUpdateRadiologyPrescription';
import { CreateUpdateFreeTextPrescription } from './FreeTextPrescription.jsx/CreateUpdateFreeTextPrescription';
import { Exception } from '../../components';

export const PrescriptionsRouter = () => (
  <PrescriptionContextProvider>
    <Routes>
      <Route
        path="/prescription-choice/:type/:id"
        element={<PrescriptionChoises />}
      />
      <Route
        path="/create/medications/:type/:id"
        element={<CreateUpdateMedicationsPrescription purpose="create" />}
      />
      <Route
        path="/create/free-text/:type/:id"
        element={<CreateUpdateFreeTextPrescription purpose="create" />}
      />
      <Route
        path="/create/radiology/:id"
        element={<CreateUpdateRadiologyPrescription purpose="create" />}
      />
      <Route index element={<Exception />} />
    </Routes>
  </PrescriptionContextProvider>
);
