import { Row } from 'antd';
import PropTypes from 'prop-types';
import { useHandleResize } from '../../utils/handleResize';
import { ConfidentialButton } from '../ConfidentialButton/ConfidentialButton';

/**
 * React component for rendering admin header buttons.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {Function} props.setCollapsed - A function to set the collapsed state.
 * @returns {JSX.Element} The rendered React element.
 */
export const AdminHeaderButtons = ({ setCollapsed }) => {
  const { width } = useHandleResize();

  return (
    <Row
      style={{
        gap: 12,
        display: 'flex',
        alignItems: 'center'
      }}
      mode="horizontal"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
    >
      <ConfidentialButton />
    </Row>
  );
};

AdminHeaderButtons.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
