import { Card, Checkbox, Col, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { renderTitle } from '../../utils/renderTitle';

/**
 * Renders a card component for displaying effects data in a radiology prescription.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.effectsData - The array of effects data.
 * @returns {JSX.Element} The rendered card component.
 */
export const CardEffects = ({ effectsData }) => {
  const { t } = useTranslation();

  return (
    <Col xs={24} md={24} lg={6} style={{ display: 'flex' }}>
      <Card
        title={renderTitle(
          t('prescription.radiology.form.card_titles.effects')
        )}
        style={{
          border: 'none',
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {effectsData?.length > 0 && (
          <Form.Item name="effects" style={{ flex: '1 1 auto' }}>
            <Checkbox.Group
              options={effectsData}
              style={{
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column'
              }}
            />
          </Form.Item>
        )}
      </Card>
    </Col>
  );
};

CardEffects.propTypes = {
  effectsData: PropTypes.arrayOf(PropTypes.shape({}))
};

CardEffects.defaultProps = {
  effectsData: []
};
