import { useNavigate } from 'react-router-dom';
import { ListResource } from '../../../components';
import { CustomAction } from '../components/CustomAction';
import { useColumns } from './columns';

/**
 * React component for rendering a list of protocols with custom actions.
 *
 * @returns {JSX.Element} - The rendered React element.
 */
export const ListProtocol = () => {
  const { columns } = useColumns();
  const navigate = useNavigate();

  return (
    <>
      <CustomAction resource="protocols" />
      <ListResource
        isPageHeaderCustom={false}
        isOriginalContentCustom={false}
        showAction={false}
        populate="visit_reasons"
        resourceName="protocols"
        path="protocols"
        columns={columns}
        archiveAction
        onDoubleClickAction={{
          action: (record) =>
            navigate(`/data-base/protocols/edit/${record._id}`)
        }}
      />
    </>
  );
};
