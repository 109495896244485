import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { Prescription } from '../../../../models/Prescription';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useDownloadDocument } from '../../../../utils/downloadDoc';

/**
 * Custom hook for validating choices in a prescription stay.
 * @hook
 *
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setLoading - The function to set the loading state.
 * @param {Array} options.imagingTreeKeys - The array of imaging tree keys.
 * @param {Array} options.srnCareTreeKeys - The array of srn care tree keys.
 * @param {Array} options.biologyTreeKeys - The array of biology tree keys.
 * @param {Object} options.patientInfos - The patient information object.
 * @param {Object} options.form - The form object.
 * @returns {Object} - The object containing the handleValidateChoices function.
 */
export const useValidateChoices = ({ setLoading, patientInfos, form }) => {
  const { user } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const { type } = useParams();
  const { printMultipleDocuments } = useDownloadDocument();

  const {
    IMAGING = [],
    SRN_CARE_WITHOUT_COTATIONS = [],
    SRN_CARE_WITH_COTATIONS = [],
    BIOLOGY = []
  } = form.getFieldsValue();

  /**
   * Handles the validation of choices for a prescription stay.
   * @function
   *
   * @param {Object} values - The values to be validated.
   * @param {string} type - The type of the prescription.
   * @returns {Promise<void>} - A promise that resolves when the validation is complete.
   */
  const handleValidateChoices = async (values) => {
    try {
      setLoading(true);
      const { doctor } = values;

      const body = {
        checked_keys: [
          ...IMAGING,
          ...SRN_CARE_WITHOUT_COTATIONS,
          ...SRN_CARE_WITH_COTATIONS,
          ...BIOLOGY
        ],
        patient: patientInfos.infos._id,
        author: user._id,
        doctor: doctor || user._id,
        consultation: patientInfos?.infos?.current_consultation?._id,
        type
      };

      const { data: prescriptionsIds } =
        await Prescription.postStayPrescriptionChoices(type, body);

      if (prescriptionsIds.length > 0) {
        setTimeout(async () => {
          await printMultipleDocuments(prescriptionsIds);
        }, 1500);
      }

      setLoading(false);
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };

  return { handleValidateChoices };
};
