// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useReasonColumn } from './reasonColumn';
import { useDownloadColumn } from './downloadColumn';
import { usePrintColumn } from './printColumn';

/**
 * @hook
 * @name useColumnsRadiologyPrescriptions
 * @description A custom hook to return columns for radiology prescriptions table
 * @param {Boolean} isSwitched - A boolean to check if the table is in switched mode
 * @returns {Array} columns
 */
export const useColumnsRadiologyPrescriptions = (isSwitched) => {
  const { t } = useTranslation();
  const reasonColumn = useReasonColumn();
  const downloadColumn = useDownloadColumn();
  const printColumn = usePrintColumn();

  const formatDate = (date) => dayjs(date).format('DD-MM-YYYY');

  const formattedExaminations = (examinations) =>
    examinations
      .map((examination) =>
        t(`prescription.radiology.enums.regions.${examination?.region}`)
      )
      .join(', ');

  const dateColumn = {
    title: t('consultations.date'),
    key: 'created_at',
    dataIndex: 'created_at',
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    render: (created_at) => (created_at ? formatDate(created_at) : '-')
  };

  const examinationsPrescribedColumn = {
    title: t('consultations.documents.treatments_examinations_prescribed'),
    key: 'radiology_prescriptions',
    dataIndex: 'radiology_prescriptions',
    sorter: (a, b) =>
      formattedExaminations(b?.radiology_prescriptions).length -
      formattedExaminations(a?.radiology_prescriptions).length,
    render: (radiology_prescriptions) =>
      radiology_prescriptions && radiology_prescriptions.length > 0
        ? formattedExaminations(radiology_prescriptions)
        : '-'
  };

  return isSwitched
    ? [
        reasonColumn,
        dateColumn,
        examinationsPrescribedColumn,
        downloadColumn,
        printColumn
      ]
    : [
        dateColumn,
        reasonColumn,
        examinationsPrescribedColumn,
        downloadColumn,
        printColumn
      ];
};
