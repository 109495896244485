import PropTypes from 'prop-types';
import { Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { useFields } from './fields/fields';

const { Title } = Typography;

/**
 * Component for creating or updating visit reasons.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {string} props.purpose - The purpose of the form ('subOptions' or 'extraFields').
 * @returns {JSX.Element} The rendered ExtraFields component.
 */
export const ExtraFields = ({ purpose }) => {
  const resource = 'data_bases.visit-reasons';
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const { extraFields, subOptionsFields } = useFields();

  const title =
    purpose === 'subOptions'
      ? `${resource}.form.sub_options`
      : `${resource}.form.extra_fields`;

  return (
    <Input.Group>
      <Title level={5}>{t(title)}</Title>
      {purpose === 'subOptions'
        ? generateFields(resource, subOptionsFields)
        : extraFields.map((field) => generateFields(resource, field))}
    </Input.Group>
  );
};

ExtraFields.propTypes = {
  purpose: PropTypes.string.isRequired
};
