import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * MedicalBackgroundSelect component
 * @component
 * @param {Array} defaultValue - The default value of the select.
 * @returns {JSX.Element} MedicalBackgroundSelect component
 */
export const MedicalBackgroundSelect = ({ defaultValue }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { Item } = Form;
  const [surgicalsHistory, setSurgicalsHistory] = useState([]);

  const getSurgicalsHistory = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/surgical-history?archived=false'
      });
      setSurgicalsHistory(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getSurgicalsHistory();
    })();
  }, []);

  return (
    <Item name={['consultations', 'medicalBackground', 'surgical_history']}>
      <Select
        mode="tags"
        allowClear
        style={{
          width: '200px'
        }}
        placeholder={t('consultations.form.multiple_select_placeholder')}
        options={surgicalsHistory.map((item) => ({
          label: item.label,
          value: item.label
        }))}
        filterSort={(optionA, optionB) =>
          optionA.label.localeCompare(optionB.label)
        }
        defaultValue={defaultValue}
        notFoundContent={null}
        showSearch={false}
      />
    </Item>
  );
};

MedicalBackgroundSelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

MedicalBackgroundSelect.defaultProps = {
  defaultValue: ''
};
