import { useTranslation } from 'react-i18next';
import { Space, Switch, Tag } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { getPhoneNumber } from '../../utils/formatters';
import { Directory } from '../../models/Directory';
/**
 * Custom hook for generating columns for directory data table.
 * This hook provides columns configuration for displaying directory data.
 *
 *
 * @hook
 * @returns {Array} An array of column configurations for directory data table.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  const onChecked = async (id, checked) => {
    await Directory.displayDoctorFollowUpCare(id, { checked });
  };

  return [
    {
      title: t('directories.form.establishment').toUpperCase(),
      key: 'establishment',
      dataIndex: 'establishment',
      sorter: true
    },
    {
      title: t('directories.form.speciality').toUpperCase(),
      key: 'speciality',
      dataIndex: 'speciality',
      sorter: true
    },
    {
      title: t('directories.form.contact.title').toUpperCase(),
      children: [
        {
          title: t('directories.first_name').toUpperCase(),
          key: 'first_name',
          dataIndex: ['contact', 'first_name'],
          sorter: true
        },
        {
          title: t('directories.last_name').toUpperCase(),
          key: 'last_name',
          dataIndex: ['contact', 'last_name'],
          sorter: true
        }
      ]
    },
    {
      title: t('directories.form.short_number').toUpperCase(),
      key: 'short_number',
      dataIndex: 'short_number'
    },
    {
      title: t('directories.form.cell_phone_number').toUpperCase(),
      key: 'cell_phone_number',
      dataIndex: 'cell_phone_number',
      render: (mobile_number) => getPhoneNumber(mobile_number)
    },
    {
      title: t('directories.form.secretary_phone_number').toUpperCase(),
      key: 'secretary_phone_number',
      dataIndex: 'secretary_phone_number',
      render: (phone_number) => getPhoneNumber(phone_number)
    },
    {
      title: t('directories.form.secretary_email').toUpperCase(),
      key: 'secretary_email',
      dataIndex: 'secretary_email',
      render: (email) => email || '-'
    },
    {
      title: t('directories.form.follow_up_care').toUpperCase(),
      key: 'follow_up_care',
      render: (record) => {
        const { contact: { last_name } = {}, _id } = record || {};
        return (
          <Space direction="vertical">
            <Tag color="blue">DR {last_name}</Tag>
            <Switch
              disabled={user?.role === 'admins:SUPER-ADMIN'}
              checkedChildren={t('buttons.yes')}
              unCheckedChildren={t('buttons.no')}
              onChange={(checked) => onChecked(_id, checked)}
              defaultChecked={record.follow_up_care}
            />
          </Space>
        );
      }
    }
  ];
};
