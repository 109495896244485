import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { message, Form, Table, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useColumns } from './columns';
import { EditableCell } from './EditableCell';
import { getFullName } from '../../../../utils/formatters';

/**
 * IndividualTurnoverTargetTable component displays a table of individual turnover targets.
 * It allows editing and saving of the targets.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.position - The position of the user.
 * @param {string} props.centerId - The ID of the center.
 * @returns {JSX.Element} The IndividualTurnoverTargetTable component.
 */
export const IndividualTurnoverTargetTable = ({ position, centerId }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [resources, setResources] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  const [form] = Form.useForm();

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      daily_turnover_target: '',
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (_id) => {
    try {
      setSaving(true);
      const row = await form.validateFields();
      const newData = [...resources];
      const index = newData.findIndex((item) => _id === item._id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        setResources(newData);
        setEditingKey('');
        const formData = new FormData();
        formData.append(
          'values',
          JSON.stringify({ daily_turnover_target: row.daily_turnover_target })
        );
        await dispatchAPI('PATCH', {
          url: `/users/${item._id}`,
          body: formData
        });
        setSaving(false);
        message.success(t('success.message.daily_turnover_target'));
      } else {
        setSaving(false);
        message.error(t('errors.message.save'));
      }
    } catch (error) {
      setSaving(false);
      message.error(t('errors.message.save'));
    }
  };

  const columns = useColumns(
    position,
    isEditing,
    editingKey,
    cancel,
    save,
    edit,
    saving
  );
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        editing: isEditing(record),
        dataIndex: col.dataIndex,
        t
      })
    };
  });

  const getResource = async () => {
    try {
      setLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/users?archived=false&position=${position}&default_center=${centerId}`
      });
      const newData = [];
      data.forEach((resource) => {
        newData.push({
          ...resource,
          name: getFullName(resource)
        });
      });
      setResources(newData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(t('messages.error'));
    }
  };

  useEffect(() => {
    (async () => {
      await getResource();
    })();
  }, []);

  return (
    <Skeleton loading={loading}>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell
            }
          }}
          dataSource={resources}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel
          }}
        />
      </Form>
    </Skeleton>
  );
};

IndividualTurnoverTargetTable.propTypes = {
  position: PropTypes.string,
  centerId: PropTypes.string,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired
  }).isRequired
};

IndividualTurnoverTargetTable.defaultProps = {
  position: '',
  centerId: ''
};
