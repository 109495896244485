import { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes } from '../utils/constants/adminRoutes';
import { userRoutes } from '../utils/constants/userRoutes';
import { Login } from '../routes/login';
import { HomeDoctorKanban } from '../routes/home/HomeDoctorKanban';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { UserLayout } from '../components/layouts/UserLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import { PatientRouter } from '../routes/patients/PatientRouter';
import { ConsultationsRouter } from '../routes/consultations/ConsultationsRouter';
import { PrescriptionsRouter } from '../routes/prescription/PrescriptionsRouter';
import { FavoritesRouter } from '../routes/favorites/FavoritesRouter';

const RequireAuth = ({ isValid }) => {
  const location = useLocation();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <UserLayout />;
};

RequireAuth.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const UserRouter = () => {
  const { isValid } = useAuthContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);
  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route
            path={outOfNavRoutes.FAVORITES}
            element={<FavoritesRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={`${userRoutes.HOME}/*`} element={<PatientRouter />} />
          <Route
            path={`${userRoutes.ROOMS}/*`}
            element={<HomeDoctorKanban />}
          />
          <Route
            path={`${userRoutes.PATIENTS}/*`}
            element={<PatientRouter />}
          />
          <Route
            path={`${userRoutes.CONSULTATIONS}/*`}
            element={<ConsultationsRouter />}
          />
          <Route
            path={`${outOfNavRoutes.PRESCRIPTION}/*`}
            element={<PrescriptionsRouter />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
