/**
 * Custom hook to check if a patient is allergic to certain ingredients.
 * @hook
 *
 * @param {Object} patient - The patient object containing allergy information.
 * @returns {Object} - An object with the `isAllergic` function.
 */
export const usePatientAllergies = (patient) => {
  const patientAllergies = patient?.grounds?.allergies.flatMap(
    (allergy) => allergy.alt_labels
  );

  /**
   * Checks if the patient is allergic to the given ingredients.
   * @function
   *
   * @param {Array} ingredients - The ingredients to check for allergies.
   * @returns {boolean} - Returns `true` if the patient is allergic, `false` otherwise.
   */
  const isAllergic = (ingredients) =>
    patientAllergies?.some((item) => ingredients.includes(item));

  return { isAllergic };
};
