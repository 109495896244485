import { BaseModel } from './BaseModel';

/**
 * Represents a directory model.
 * @class
 * @extends BaseModel
 */
export class Directory extends BaseModel {
  static modelName = 'directories';

  /**
   * Creates a new instance of Directory.
   * @param {Object} data - The data for the directory.
   */
  constructor(data) {
    super('directory', undefined, data);
  }

  /**
   * Retrieves a directory by its ID.
   * @static
   * @function
   *
   * @param {string} id - The ID of the directory.
   * @param {Object} params - Additional parameters for the request.
   * @returns {Promise} A promise that resolves with the directory data.
   */
  static getDirectory(id, { ...params } = {}) {
    return BaseModel.fetchAPI(
      'GET',
      `${Directory.modelName}/${id}`,
      {},
      params
    );
  }

  static getDirectoriesByCenterId(centerId, { ...params } = {}) {
    return BaseModel.fetchAPI(
      'GET',
      `${Directory.modelName}/${centerId}`,
      {},
      params
    );
  }

  /**
   * Retrieves the importing permission for a directory.
   * @static
   * @function
   *
   * @param {string} id - The ID of the directory.
   * @param {Array} targetIds - The IDs of the targets.
   * @param {Object} params - Additional parameters for the request.
   * @returns {Promise} A promise that resolves with the importing permission data.
   */
  static getImportingPermission(id, targetIds, { ...params } = {}) {
    return BaseModel.fetchAPI(
      'POST',
      `${Directory.modelName}/importing-permission/${id}`,
      { targetIds },
      params
    );
  }

  /**
   * Retrieves all directories by center ID.
   * @static
   * @function
   *
   * @param {string} centerId - The ID of the center.
   * @param {Object} params - Additional parameters for the request.
   * @returns {Promise} A promise that resolves with the directories data.
   */
  static getAllDirectoriesByCenter({ ...params } = {}) {
    return BaseModel.fetchAPI('GET', `${Directory.modelName}`, {}, params);
  }

  /**
   * Archives a directory.
   * @static
   * @function
   *
   * @param {string} id - The ID of the directory.
   * @param {Object} archived - The archived data.
   * @returns {Promise} A promise that resolves when the directory is archived.
   */
  static archivingDirectory(id, { ...archived }) {
    return BaseModel.fetchAPI('PATCH', `${Directory.modelName}/${id}`, {
      ...archived
    });
  }

  /**
   * Imports a directory.
   * @static
   * @function
   *
   * @param {string} id - The ID of the directory.
   * @param {Object} imported - The imported data.
   * @returns {Promise} A promise that resolves when the directory is imported.
   */
  static importDirectory(id, { ...imported }) {
    return BaseModel.fetchAPI('POST', `${Directory.modelName}/import/${id}`, {
      ...imported
    });
  }

  /**
   * Displays the doctor's follow-up care for a directory.
   * @static
   * @function
   *
   * @param {string} id - The ID of the directory.
   * @param {Object} body - The body of the request.
   * @returns {Promise} A promise that resolves when the follow-up care is displayed.
   */
  static displayDoctorFollowUpCare(id, body) {
    return BaseModel.fetchAPI(
      'PATCH',
      `${Directory.modelName}/follow-up-care/${id}`,
      body
    );
  }
}
