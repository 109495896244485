import { Button, Card, Divider, Flex, List, Row, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MinusCircleOutlined } from '@ant-design/icons';
import { CardPrescriptionType } from './CardPrescriptionType';
import { CardLocations } from './cards/CardLocations';
import { CardLeteralities } from './cards/CardLeteralities';
import { CardEffects } from './cards/CardEffects';
import { CardComment } from './cards/CardComment';

/**
 * Renders a set of cards for the Radiology Prescription form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.imagingData - The data for imaging.
 * @param {string} props.prescriptionType - The selected prescription type.
 * @param {function} props.setPrescriptionType - The function to set the prescription type.
 * @param {Array} props.locations - The available locations.
 * @param {string} props.locationValue - The selected location value.
 * @param {function} props.setLocationValue - The function to set the location value.
 * @param {function} props.laterality - The function to check if laterality is available.
 * @param {Array} props.effectsData - The data for effects.
 * @param {Object} props.form - The form object.
 * @returns {JSX.Element} The rendered component.
 */
export const Cards = ({
  imagingData,
  prescriptionType,
  setPrescriptionType,
  locations,
  locationValue,
  setLocationValue,
  laterality,
  effectsData,
  mergedPrescriptions,
  setMergedPrescriptions,
  form
}) => {
  const { t } = useTranslation();
  const addingPrescription =
    prescriptionType && locationValue && laterality && effectsData;

  const addLocation = () => {
    if (addingPrescription) {
      const prescription = form.getFieldsValue();
      setMergedPrescriptions((prev) => [...prev, prescription]);
      form.resetFields();
      setPrescriptionType(null);
      setLocationValue(null);
    }
  };

  const handleDeletePrescription = (index) => {
    const newPrescription = mergedPrescriptions.filter((_, i) => i !== index);
    setMergedPrescriptions(newPrescription);
  };

  const location = (item) =>
    locations?.find((loc) => loc?.value === item)?.label;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        gap: '24px'
      }}
    >
      <Card
        style={{
          backgroundColor: 'var(--primaryHover)',
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto'
        }}
        extra={
          <Tooltip
            title={
              !addingPrescription &&
              t('prescription.radiology.form.complete_location')
            }
          >
            <Button
              disabled={!addingPrescription}
              onClick={() => {
                addLocation();
              }}
            >
              {t('prescription.radiology.form.add_locations')}
            </Button>
          </Tooltip>
        }
      >
        <Row gutter={[24, 24]} style={{ flex: '1 1 auto', height: '100%' }}>
          <CardPrescriptionType
            imagingData={imagingData}
            setPrescriptionType={setPrescriptionType}
            prescriptionType={prescriptionType}
          />
          <CardLocations
            locations={locations}
            locationValue={locationValue}
            setLocationValue={setLocationValue}
            form={form}
          />
          <CardLeteralities laterality={laterality} />
          <CardEffects effectsData={effectsData} />
        </Row>
        <List>
          {mergedPrescriptions.map((item, index) => (
            <List.Item key={item?.location[0]}>
              <Flex style={{ backgroundColor: 'white' }}>
                {t(
                  `data_bases.stay_prescriptions.columns.prescription_types.${item?.prescriptions_types?.[0]}`
                )}
                <Divider type="vertical" />
                {location(item?.location[0]) && (
                  <>
                    {location(item?.location[0])}
                    <Divider type="vertical" />
                  </>
                )}
                {item?.laterality?.join(', ') && (
                  <>
                    {item?.laterality
                      ?.flatMap((late) =>
                        t(`prescription.radiology.form.lateral.${late}`)
                      )
                      .join(', ')}
                    <Divider type="vertical" />
                  </>
                )}
                {item?.effects?.join(', ') && (
                  <>
                    {item?.effects?.join(', ')}
                    <Divider type="vertical" />
                  </>
                )}
                <MinusCircleOutlined
                  onClick={() => handleDeletePrescription(index)}
                />
              </Flex>
            </List.Item>
          ))}
        </List>
      </Card>
      <CardComment />
    </div>
  );
};

Cards.propTypes = {
  imagingData: PropTypes.shape({
    prescriptions_types: PropTypes.arrayOf(PropTypes.string)
  }),
  prescriptionType: PropTypes.string,
  setPrescriptionType: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  locationValue: PropTypes.string,
  setLocationValue: PropTypes.func,
  laterality: PropTypes.func,
  effectsData: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({
    resetFields: PropTypes.func,
    getFieldsValue: PropTypes.func
  }),
  mergedPrescriptions: PropTypes.arrayOf(PropTypes.shape({})),
  setMergedPrescriptions: PropTypes.func
};

Cards.defaultProps = {
  imagingData: {},
  prescriptionType: '',
  setPrescriptionType: () => {},
  locations: [],
  locationValue: '',
  setLocationValue: () => {},
  laterality: () => {},
  effectsData: [],
  form: {},
  mergedPrescriptions: [],
  setMergedPrescriptions: () => {}
};
