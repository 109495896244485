/**
 * Validates a phone number.
 *
 * @function
 * @param {Function} t - The translation function.
 * @param {Object} value - The value to be validated.
 * @param {string} value.number - The phone number to be validated.
 * @returns {Promise} - A promise that resolves if the phone number is valid, or rejects with an error message if it is not.
 */
export const phoneNumberValidator = (t, value) => {
  const { number = '' } = value || {};

  if (!number || number.trim() === '' || number.length < 10) {
    return Promise.reject(new Error(t('users.form.errors.phone_number')));
  }
  return Promise.resolve();
};
