/**
 * Initial state for the PrescriptionContext reducer.
 * @typedef {Object} initialState
 * @property {Array} categories - The list of categories.
 * @property {Array} entities - The list of entities.
 * @property {Array} posologies - The list of posologies.
 * @property {Array} doctors - The list of doctors.
 * @property {Object} patientInfos - The patient information.
 * @property {string|null} patientInfos.headerTitle - The header title for patient information.
 * @property {number|null} patientInfos.patientAge - The age of the patient.
 * @property {string|null} patientInfos.infos - Additional information about the patient.
 * @property {string} content - The content.
 * @property {Array} srnCareTreeKeys - The keys for the SRN care tree.
 * @property {Array} imagingTreeKeys - The keys for the imaging tree.
 * @property {Array} biologyTreeKeys - The keys for the biology tree.
 * @property {boolean} refresh - Indicates if the state needs to be refreshed.
 * @property {number|null} patientId - The ID of the patient.
 * @property {boolean} isLoading - Indicates if the state is currently loading.
 */
export const initialState = {
  categories: [],
  doctors: [],
  patientInfos: {
    headerTitle: null,
    patientAge: null,
    infos: null
  },
  content: '',
  srnCareTreeKeys: [],
  imagingTreeKeys: [],
  biologyTreeKeys: [],
  refresh: false,
  patientId: null,
  isLoading: false,
  protocols: [],
  favoritesProtocols: []
};

/**
 * Prescription reducer function.
 * @function prescriptionReducer
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @returns {Object} - The new state.
 * @throws {Error} - If the action type is not recognized.
 */
export const prescriptionReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CATEGORIES':
      return { ...state, categories: action.payload };
    case 'SET_DOCTORS':
      return { ...state, doctors: action.payload };
    case 'SET_PATIENT_INFOS':
      return { ...state, patientInfos: action.payload };
    case 'SET_CONTENT':
      return { ...state, content: action.payload };
    case 'SET_REFRESH':
      return { ...state, refresh: action.payload };
    case 'SET_PATIENT_ID':
      return { ...state, patientId: action.payload };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_SRN_CARE_TREE_KEYS':
      return { ...state, srnCareTreeKeys: action.payload };
    case 'SET_IMAGING_TREE_KEYS':
      return { ...state, imagingTreeKeys: action.payload };
    case 'SET_BIOLOGY_TREE_KEYS':
      return { ...state, biologyTreeKeys: action.payload };
    case 'SET_PROTOCOLS':
      return { ...state, protocols: action.payload };
    case 'SET_FAVORITES_PROTOCOLS':
      return { ...state, favoritesProtocols: action.payload };
    default:
      throw new Error();
  }
};
