import { Input, Select } from 'antd';
import { CardPicture } from '../patients/components/Fields/CardPicture';
import { SelectDiagnostic } from './PatientOverview/MedicalConsultation/SelectDiagnostic';
import { useConsultationsContext } from './ConsultationsContext';
import { getFullName } from '../../utils/formatters';

const { Option } = Select;
const { TextArea } = Input;

/**
 * Custom hook that returns field configurations for different form sections.
 *
 * This hook provides a structured way of defining field properties for various
 * sections in a form. Each section (e.g., general information, medical background, etc.)
 * gets its own field configuration, allowing for easy assembly of complex forms.
 *
 * @function
 *
 * @param {Object} defaultValue - The defaultValue.
 * @returns {{
 *   generalInfosFields: Object[],
 *   medicalBackgroundFields: Object[],
 *   resultsLaboratiesFields: Object[],
 *   identityCardFields: Object[],
 *   insuranceCardFields: Object[],
 *   medicalConsultationFields: Object[],
 *   newConsultationFields: Object[],
 *   photoFields: Object[],
 *   medicalCommentsFields: Object[]
 * }}
 * An object containing arrays of field configurations for different form sections.
 */
const useFields = () => {
  const { directory } = useConsultationsContext();

  const generalInfosFields = [
    {
      noLabel: true,
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      noLabel: true,
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      noLabel: true,
      name: ['gender']
    },
    {
      noLabel: true,
      name: ['birth_date'],
      rules: [{ required: true }, { type: 'date' }]
    }
  ];

  const resultsLaboratiesFields = [
    {
      noLabel: true,
      name: ['consultations', 'results_laboratories', 'date'],
      rules: [{ required: true }, { type: 'date' }]
    }
  ];

  const identityCardFields = [
    {
      noLabel: true,
      name: ['consultations', 'card', 'identity_card_picture'],
      rules: [{ type: 'file' }],
      input: <CardPicture />
    }
  ];
  const insuranceCardFields = [
    {
      noLabel: true,
      name: ['consultations', 'card', 'health_mutual_card_picture'],
      rules: [{ type: 'file' }],
      input: <CardPicture />
    }
  ];

  const directoryOptions = directory
    .map((d) => ({
      label: getFullName(d?.contact, 'Dr.'),
      value: d?.contact?._id
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const medicalConsultationFields = [
    {
      label: 'consultation',
      name: ['consultation', 'description'],
      rules: [{ required: true }],
      input: (
        <TextArea
          autoSize={{
            maxRows: 20,
            minRows: 10
          }}
        />
      )
    },
    {
      label: 'diagnostic',
      input: <SelectDiagnostic dbKey="diagnostic" />
    },
    {
      label: 'specialist_consultation',
      name: ['consultation', 'specialist_consultation'],
      input: (
        <Select mode="multiple" allowClear>
          {directoryOptions?.map((specialist) => (
            <Option key={specialist._id} value={specialist._id}>
              {specialist?.label}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const medicalCommentsFields = [
    {
      noLabel: true,
      name: 'written_by'
    },
    {
      label: 'description',
      name: 'description'
    }
  ];

  return {
    generalInfosFields,
    resultsLaboratiesFields,
    identityCardFields,
    insuranceCardFields,
    medicalConsultationFields,
    medicalCommentsFields
  };
};

export default useFields;
