import { AmoAmcSelectGlobalHealthCare } from '../components/Fields/AmoAmcSelectGlobalHealthCare';
import { AmoAmcSelect } from '../components/Fields/AmoAmcSelect';
import { CollapseAmoAmcInput } from '../components/AmoAmc/CollapseAmoAmcInput';

/**
 * Returns an array of fields for the amoAmc form.
 *
 * @hook
 * @param {Object} socialSecurityCenter - The social security center object.
 * @param {Array} longTermDiseases - The array of long term diseases.
 * @returns {Array} - An array of field objects.
 */
export const amoAmcFields = ({ socialSecurityCenter, longTermDiseases }) => [
  {
    label: 'general_practitioner',
    name: 'general_practitioner'
  },
  {
    label: 'social_security_center',
    name: 'social_security_center',
    input: (
      <CollapseAmoAmcInput
        dbKey="social_security_center"
        inputValue={socialSecurityCenter}
      />
    )
  },
  {
    label: 'long_term_diseases',
    name: 'long_term_diseases',
    input: (
      <AmoAmcSelect
        dbKey="long_term_diseases"
        longTermDiseases={longTermDiseases}
      />
    )
  },
  {
    label: 'global_health_care',
    name: 'global_health_care',
    input: (
      <AmoAmcSelectGlobalHealthCare
        dbKey="global_health_care"
        expandIconPosition="end"
      />
    )
  },
  {
    label: 'complementary_health_insurance',
    name: 'complementary_health_insurance',
    input: (
      <AmoAmcSelectGlobalHealthCare
        dbKey="complementary_health_insurance"
        expandIconPosition="end"
      />
    )
  },
  {
    label: 'health_mutual_code',
    name: 'health_mutual_code'
  }
];
