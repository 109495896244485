import { mdiStethoscope } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';

/**
 * Renders a Stethoscope icon if there is a specialist consultation in the consultation prop.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.consultation - The consultation object.
 * @returns {JSX.Element|null} - The Stethoscope icon component or null.
 */
export const Stethoscope = ({ consultation }) =>
  consultation?.consultation?.specialist_consultation?.length > 0 && (
    <Icon path={mdiStethoscope} size="18px" />
  );

Stethoscope.propTypes = {
  consultation: PropTypes.shape({
    consultation: PropTypes.shape({
      specialist_consultation: PropTypes.arrayOf(PropTypes.string)
    }).isRequired
  }).isRequired
};
