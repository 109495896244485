import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateUpdateCenter } from './CreateUpdateCenter';
import { ShowCenter } from './ShowCenter';
import { ListCenters } from './ListCenters';
import { CenterSettings } from './CenterSettings';

export const CenterRouter = () => (
  <Routes>
    <Route
      path="/create/:id"
      element={<CreateUpdateCenter purpose="create" />}
    />
    <Route
      path="/edit/:id/:centerId"
      element={<CreateUpdateCenter purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowCenter />} />
    <Route path="/settings/:id" element={<CenterSettings />} />
    <Route path="/" element={<ListCenters />} />
  </Routes>
);
