import PropTypes from 'prop-types';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useGenerateFormItemNumber } from '../../../../utils/generateFormItemNumber';

const resource = 'patients';

export const CollapsePatientForm = ({ fields }) => {
  const generateFields = useGenerateFormItem();
  const generateFieldsNumber = useGenerateFormItemNumber();

  return (
    <>
      {fields.map((field) =>
        field.label !== 'weight' &&
        field.label !== 'height' &&
        field.label !== 'ins'
          ? generateFields(resource, field)
          : generateFieldsNumber(resource, field)
      )}
    </>
  );
};

CollapsePatientForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
