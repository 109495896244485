import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Tag } from 'antd';
import { ManOutlined, WomanOutlined, FlagFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ConsultationsContext } from '../../ConsultationsContext';
import { getAge } from '../../../../utils/getAge';
import { getFullName } from '../../../../utils/formatters';
import { Badge } from './Badge';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * TitleInfos component displays information about a patient including name, age, gender, and visit details.
 * It shows a badge with either the daily consultation count or the arrival time based on user preference.
 *
 * @component
 * @example
 * return (
 *   <TitleInfos />
 * )
 *
 * @returns {JSX.Element|null} The rendered component or null if data is missing.
 */
export const TitleInfos = () => {
  const { patient } = useContext(ConsultationsContext);
  const [convertedAge, setConvertedAge] = useState(null);
  const { user } = useAuthContext();
  const [child, setChild] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (patient) {
      const { age, isChild } = getAge(patient?.birth_date);
      setChild(isChild);
      const converted = `${age}${t('patients.form.years')}`;

      if (age <= 0) {
        setConvertedAge('-');
      } else if (age > 0) {
        setConvertedAge(converted);
      }
    }
  }, [patient]);

  return (
    <Row>
      <Badge
        number={
          user.switch_option
            ? dayjs(patient.current_consultation?.date).format('HH:mm')
            : patient.current_consultation?.daily_count
        }
        visitType={patient.current_consultation?.visit_type}
      />
      {getFullName(patient)}
      &nbsp;
      {child ? <Tag color="yellow">{t('consultations.form.child')}</Tag> : null}
      {patient.gender === 'WOMAN' ? (
        <WomanOutlined style={{ fontSize: 18 }} />
      ) : (
        <ManOutlined style={{ fontSize: 18 }} />
      )}
      &nbsp;
      {convertedAge}
      &nbsp;
      {patient.reported ? <FlagFilled style={{ color: 'red' }} /> : null}
    </Row>
  );
};
