import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Spin, Row } from 'antd';

/**
 * A modal component used for displaying a loading state.
 * It is a non closable modal with a centered spinning icon and an optional message.
 * It automatically closed when the loading state is false.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.loading - Determines whether the modal is open or not.
 * @param {string} [props.customMessage] - An optional custom message to display in the modal.
 * @param {string} [props.customTitle] - An optional custom title to display in the modal.
 * @returns {JSX.Element} The loading modal component.
 * @example
 * return (
 *  <LoadingModal
 *   loading={true || false}
 *   customMessage={'Your custom message.'}
 *   customTitle={'Your custom title.'}
 *  />
 * );
 */
export const LoadingModal = ({ loading, customMessage, customTitle }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={customTitle}
      open={loading}
      closable={false}
      footer={null} // To remove useless Cancel and Ok buttons
    >
      <Row justify="center" style={{ margin: '24px 0' }}>
        <Spin size="large" />
      </Row>
      {customMessage && (
        <Row justify="center">
          {customMessage || t('modals.loading.message')}
        </Row>
      )}
    </Modal>
  );
};

LoadingModal.propTypes = {
  customTitle: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  customMessage: PropTypes.string
};

LoadingModal.defaultProps = {
  customTitle: null,
  customMessage: null
};
