import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreditCardOutlined } from '@ant-design/icons';
import { useStellairContext } from '../../../../contexts/StellairContext';

/**
 * Renders a button component for generating an invoice for a patient.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.record - The patient record object.
 * @param {string} props.record._id - The unique identifier of the patient.
 * @returns {JSX.Element} The rendered button component.
 */
export const InvoiceButton = ({ record }) => {
  const { t } = useTranslation();
  const { createFSE } = useStellairContext();

  const onClick = async () => {
    await createFSE(record._id);
  };

  return (
    <Button
      style={{
        borderRadius: '15px'
      }}
      icon={<CreditCardOutlined />}
      onClick={onClick}
    >
      {t('patients.form.patient_invoice_button')}
    </Button>
  );
};

InvoiceButton.propTypes = {
  record: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired
};
