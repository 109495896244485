import { useTranslation } from 'react-i18next';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Flex, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';
import { PrintButton } from '../../consultations/PatientOverview/Documents/components/PrintButton';
import { useFields } from '../../consultations/PatientOverview/Documents/fields';

/**
 * Renders the action icons for a patient.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.patient - The patient object.
 * @returns {JSX.Element} The JSX element representing the action icons.
 */
export const ActionsIcons = ({ patient }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { printFields, documentType, documentWording } = useFields();

  return (
    <Flex wrap="nowrap">
      {(user?.position === 'DOCTOR' || user?.position === 'NURSE') && (
        <Link to={`/consultations/show/${patient?._id}`}>
          <Tooltip placement="bottom" title={t('consultations.show')}>
            <Button
              type="link"
              style={{ padding: 0 }}
              icon={<EyeOutlined style={{ fontSize: 16, color: '#004A8F' }} />}
            />
          </Tooltip>
        </Link>
      )}
      <Link to={`/patients/edit/${patient?._id}?createConsultation=false`}>
        <Tooltip placement="bottom" title={t('patients.edit')}>
          <Button
            type="link"
            style={{ padding: 0 }}
            icon={<EditOutlined style={{ fontSize: 16, color: '#004A8F' }} />}
          />
        </Tooltip>
      </Link>
      <PrintButton
        fields={printFields}
        documentType={documentType}
        documentWording={documentWording}
        patientId={patient?._id}
      />
    </Flex>
  );
};

ActionsIcons.propTypes = {
  patient: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired
};
