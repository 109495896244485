import { Button, Divider, Form, Input, Modal, Row, Select, Space } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * Modal component for validating choices.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setOpenModal - The function to set the open state of the modal.
 * @param {boolean} props.openModal - The open state of the modal.
 * @param {Object} props.form - The form object for form validation and submission.
 * @param {Function} props.handleValidateChoices - The function to handle the validation of choices.
 * @param {Array} props.doctorsSelectOptions - The options for the doctors select input.
 * @param {Array} props.imagingTreeKeys - The keys for the imaging tree.
 * @returns {JSX.Element} The ModalValidateChoices component.
 */
export const ModalValidateChoices = ({
  setOpenModal,
  openModal,
  handleValidateChoices,
  doctorsSelectOptions,
  imagingTreeKeys
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user } = useAuthContext();

  return (
    <Modal
      open={openModal}
      onCancel={() => setOpenModal(false)}
      closable={false}
      footer={null}
    >
      <Form form={form} onFinish={handleValidateChoices}>
        {!user?.position === 'DOCTOR' && (
          <Form.Item
            rules={[{ required: true }]}
            name="doctor"
            label={t('consultations.prescriptions.doctor_choice')}
          >
            <Select style={{ width: 300 }} options={doctorsSelectOptions} />
          </Form.Item>
        )}
        {imagingTreeKeys.map(
          (item) =>
            item.scanner && (
              <Form.Item name={item.key} label={item.title}>
                <Input />
              </Form.Item>
            )
        )}
        <Row justify="end">
          <Divider />
          <Space>
            <Button onClick={() => setOpenModal(false)}>
              {t('buttons.cancel')}
            </Button>
            <Button htmlType="submit">{t('buttons.validate')}</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

ModalValidateChoices.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  form: PropTypes.shape({}).isRequired,
  handleValidateChoices: PropTypes.func.isRequired,
  doctorsSelectOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  imagingTreeKeys: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
