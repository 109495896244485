import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

/**
 * An utility hook that displays a success message using Ant Design's notification component.
 *
 * @hook
 * @returns {Object} An object with `successMessage` function.
 */
export const useSuccessMessage = () => {
  const { t } = useTranslation();
  return {
    successMessage: (
      key = null,
      duration = 4.5,
      notificationType = 'success'
    ) => {
      notification[notificationType]({
        message: t(`success.message.${key}`),
        key: key || 'network_feedback',
        duration
      });
    }
  };
};
