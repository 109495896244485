import { Radio, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReasonsSelect } from '../Components/Fields/ReasonsSelect';
import { SelectConsultationType } from '../Components/Fields/SelectConsultationType';
import { useConsultationsContext } from '../ConsultationsContext';
import { HyperalgesicRadio } from '../Components/Fields/HyperalgesicRadio';
import { SelectRoom } from '../Components/Fields/SelectRoom';
import { getFullName } from '../../../utils/formatters';

/**
 * Returns an object containing the fields for a new consultation form.
 * @hook
 * @param {object} form - The form object.
 * @returns {object} - An object containing the fields for the new consultation form.
 */
export const newConsultationFields = (form) => {
  const { directory, treeData, enums } = useConsultationsContext();
  const { t } = useTranslation();

  const [purposeOfCare, setPurposeOfCare] = useState(null);

  const purposeNameKey = {
    FOLLOW_UP_CARE: 'follow_up_doctor',
    VIA: 'via'
  };

  const directoryOptions = directory.map((specialist) => ({
    label: getFullName(specialist.contact, 'Dr.'),
    value: specialist._id
  }));

  const viaOptions = enums?.via_enums?.map((viaEnum) => ({
    label: t(`consultations.form.purpose_of_care.${viaEnum}`),
    value: viaEnum
  }));

  const purposeOptions = {
    FOLLOW_UP_CARE: directoryOptions,
    VIA: viaOptions
  };

  useEffect(() => {
    form.setFieldValue(['purpose_of_care', 'type'], 'SPONTANEOUS_CONSULTATION');
  }, []);

  const fields = [
    {
      noLabel: true,
      name: ['visit_type'],
      rules: [{ type: String, required: true }],
      input: <SelectConsultationType />
    },
    {
      Label: 'visit_reasons',
      name: ['visit_reasons'],
      rules: [{ required: true }],
      input: <ReasonsSelect treeData={treeData} form={form} />
    },
    {
      label: 'purpose_of_care.title',
      name: ['purpose_of_care', 'type'],
      rules: [{ required: true }],
      input: (
        <Radio.Group
          optionType="button"
          options={[
            {
              label: t(
                'consultations.form.purpose_of_care.SPONTANEOUS_CONSULTATION'
              ),
              value: 'SPONTANEOUS_CONSULTATION'
            },
            {
              label: t('consultations.form.purpose_of_care.FOLLOW_UP_CARE'),
              value: 'FOLLOW_UP_CARE'
            },
            {
              label: t('consultations.form.purpose_of_care.VIA'),
              value: 'VIA'
            }
          ]}
          onChange={(e) => setPurposeOfCare(e.target.value)}
          value={purposeOfCare}
        />
      )
    },
    {
      hidden: !purposeOfCare || purposeOfCare === 'SPONTANEOUS_CONSULTATION',
      name: ['purpose_of_care', purposeNameKey[purposeOfCare]],
      input: (
        <Select
          options={purposeOptions[purposeOfCare]}
          allowClear
          showSearch
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '')
              .toLowerCase()
              .localeCompare((optionB?.label ?? '').toLowerCase())
          }
        />
      )
    },
    {
      Label: 'description',
      name: ['description'],
      rules: [{ type: String }]
    },
    {
      Label: 'hyperalgesic',
      name: ['hyperalgesic'],
      rules: [{ type: Boolean, required: true }],
      input: <HyperalgesicRadio />
    },
    {
      Label: 'waiting_room',
      hidden: true,
      name: ['waiting_room'],
      rules: [{ type: String, required: true }],
      input: <SelectRoom form={form} />
    }
  ];

  return { fields };
};
