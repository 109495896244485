/**
 * A custom hook for creating and updating configuration related to visit reasons and prescriptions.
 *
 * @param {object} options - The options for the hook.
 * @param {array} options.visitReasonsData - The data related to visit reasons.
 * @param {array} options.prescriptionsData - The data related to prescriptions.
 * @param {function} options.setVisitReasonsData - The function to set the visit reasons data state.
 * @param {function} options.setPrescriptionsData - The function to set the prescriptions data state.
 * @returns {object} An object containing the configuration for creating and updating resources.
 */
export const useCreateUpdateconfig = ({
  visitReasonsData: visit_reasons = [],
  setVisitReasonsData,
  setPrescriptionsData,
  setProtocolWording,
  targetNodes,
  setTargetNodes
}) => {
  /**
   * Configuration object for handling the creation, retrieval, and update of resources.
   *
   * @type {object}
   * @property {object} onCreateResource - Configuration for creating a new resource.
   * @property {function} onCreateResource.setBody - Function to set the request body for creating a resource.
   * @property {object} onGetResource - Configuration for retrieving an existing resource.
   * @property {function} onGetResource.setFields - Function to set the fields after retrieving a resource.
   * @property {object} onUpdateResource - Configuration for updating an existing resource.
   * @property {function} onUpdateResource.setBody - Function to set the request body for updating a resource.
   */
  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        visit_reasons,
        checked_keys: targetNodes
      })
    },
    onGetResource: {
      setFields: (data) => {
        setVisitReasonsData(data.visit_reasons);
        setPrescriptionsData(data.prescriptions);
        setProtocolWording(data.protocol_wording);
        setTargetNodes(data.checked_keys);

        return data;
      }
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        visit_reasons,
        checked_keys: targetNodes.length ? targetNodes : undefined
      })
    }
  };

  return { config };
};
