import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getVisitReasons } from '../../../../utils/formatters';

/**
 * Custom hook that returns an array of columns for the stay history list.
 *
 * @hook
 * @returns {Array} Array of column objects
 */
export const useColumns = () => {
  const { t } = useTranslation();

  const resultArrival = (result_arrival_time) => {
    const arrivalTime = dayjs(result_arrival_time);
    return arrivalTime.format('DD/MM/YYYY');
  };

  const dateColumn = {
    title: t('consultations.stay_history.date_of_stay'),
    key: 'date',
    dataIndex: 'date',
    sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    render: (date_of_arrival) => resultArrival(date_of_arrival)
  };

  const reasonColumn = {
    title: t('consultations.stay_history.reason'),
    key: 'visit_reasons',
    dataIndex: 'visit_reasons',
    sorter: (a, b) => {
      const getReasons = (data) =>
        data.map((item) => item.reason_wording).join(', ');

      const aReasons = getReasons(a.visit_reasons);
      const bReasons = getReasons(b.visit_reasons);

      return aReasons.localeCompare(bReasons);
    },
    render: (visit_reasons) => getVisitReasons(visit_reasons)
  };

  return [dateColumn, reasonColumn];
};
