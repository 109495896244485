/**
 * Retrieves the Synapse token using the dispatchAPI.
 * @param {function} dispatchAPI - The dispatchAPI function.
 * @returns {Promise} - The response from the dispatchAPI.
 */
export async function getSynapseToken(dispatchAPI) {
  // dispatchAPI to GET /synapse/token

  const response = await dispatchAPI('GET', { url: '/synapse/token' });
  return response?.data?.token;
}
