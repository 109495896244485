import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import { ExitButton } from './ExitButton';
import { InvoiceButton } from './InvoiceButton';
import { RoomsSelect } from './RoomsSelect';
import {
  getVisitReasons,
  getAge,
  getFullName
} from '../../../../utils/formatters';
import UnpaidIcon from '../../../../assets/images/icons/noto_cross-mark.svg';

/**
 * Custom hook that returns an array of columns configuration for the patients list.
 *
 * @hook
 * @param {Object} enums - Object containing enums for gender options.
 * @returns {Array} - Array of column configuration objects.
 */
export const useColumns = (enums) => {
  const { t } = useTranslation();

  const renderExitButton = (status, record) => {
    switch (status) {
      case 'AWAITING_EXIT':
        return (
          record?._id &&
          record?.patient?.current_consultation && (
            <ExitButton record={record} />
          )
        );
      case 'CLOSED':
        return dayjs(record?.exit_time).format('HH:mm');
      default:
        return null;
    }
  };

  return [
    {
      key: 'unpaid',
      dataIndex: 'unpaid',
      render: (unpaid) =>
        unpaid ? <img src={UnpaidIcon} alt="unpaid" /> : null
    },
    {
      title: t('patients.form.order_of_arrival'),
      key: 'daily_count',
      dataIndex: ['daily_count'],
      sorter: true
    },
    {
      title: t('patients.form.time_of_arrival'),
      key: 'date',
      dataIndex: ['date'],
      sorter: true,
      render: (date) => (date ? dayjs(date).format('HH:mm') : '')
    },
    {
      title: t('patients.form.last_name'),
      key: 'last_name',
      dataIndex: ['patient', 'last_name'],
      render: (_, record) =>
        getFullName({ last_name: record?.patient.last_name }),
      sorter: (a, b) => a.patient.last_name.localeCompare(b.patient.last_name)
    },
    {
      title: t('patients.form.first_name'),
      key: 'first_name',
      dataIndex: ['patient', 'first_name'],
      render: (_, record) =>
        getFullName({ first_name: record?.patient.first_name }),
      sorter: (a, b) => a.patient.first_name.localeCompare(b.patient.first_name)
    },
    {
      title: t('patients.form.list_gender'),
      key: 'gender',
      dataIndex: ['patient', 'gender'],
      sorter: (a, b) => a.patient.gender.localeCompare(b.patient.gender),
      filters:
        enums?.genders &&
        enums?.genders?.map((gender) => ({
          key: gender,
          text: t(`patients.form.enums.${gender}`),
          value: gender
        })),
      render: (gender) => {
        switch (gender) {
          case 'MAN':
            return <ManOutlined />;
          case 'WOMAN':
            return <WomanOutlined />;
          default:
            return gender;
        }
      }
    },
    {
      title: t('patients.form.age'),
      key: 'age',
      dataIndex: ['patient', 'birth_date'],
      sorter: (a, b) => {
        const ageA = getAge(a.patient.birth_date);
        const ageB = getAge(b.patient.birth_date);
        return ageA - ageB;
      },
      render: (birth_date) => `${getAge(birth_date)} ${t('years')}`
    },
    {
      title: t('patients.form.appointment_purpose'),
      key: 'visit_reasons',
      dataIndex: 'visit_reasons',
      sorter: true,
      render: (visit_reasons) =>
        visit_reasons &&
        visit_reasons?.length > 0 &&
        getVisitReasons(visit_reasons)
    },
    {
      title: t('patients.form.invoicing'),
      key: 'invoicing',
      dataIndex: 'invoicing',
      render: (_, record) => <InvoiceButton record={record?.patient} />
    },
    {
      title: t('patients.form.room'),
      key: 'room',
      render: (record) => <RoomsSelect record={record} />
    },
    {
      title: t('patients.form.patient_exit'),
      key: 'status',
      dataIndex: 'status',
      render: (status, record) => renderExitButton(status, record)
    }
  ];
};
