import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DeleteOutlined } from '@ant-design/icons';
import { Divider, Flex, Button, Checkbox, Popconfirm } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { getFullName } from '../../utils/formatters';

/**
 * Renders a card component displaying previous comments.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.written_by - The name of the person who wrote the comment.
 * @param {string} props.comment - The content of the comment.
 * @param {function} props.deleteComment - The function to delete a comment.
 * @param {function} props.checkComment - The function to check a comment.
 * @param {boolean} props.withoutAction - Whether to display the action buttons or not.
 * @returns {JSX.Element} The rendered card component.
 */
export const PreviousComments = ({
  comment,
  deleteComment,
  checkComment,
  withoutAction
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const translationKey = 'patients.form';

  return (
    <>
      <Flex justify="space-between" align="center">
        {t(`${translationKey}.header_message`, {
          newDate: dayjs(comment?.date).format('DD/MM/YYYY'),
          written_by: getFullName(comment?.written_by) || comment?.written_by
        })}
        {user?._id === comment?.written_by?._id && !withoutAction && (
          <Popconfirm
            title={t(`${translationKey}.delete_comment`)}
            onConfirm={() => deleteComment(comment?._id)}
            okText={t(`${translationKey}.yes`)}
            cancelText={t(`${translationKey}.no`)}
          >
            <Button type="link">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        )}
      </Flex>

      {!withoutAction ? (
        <Flex align="center">
          <Checkbox
            defaultChecked={comment?.checked}
            onChange={() => checkComment(comment?._id)}
          >
            {comment.description || comment.reported_comment_reception}
          </Checkbox>
        </Flex>
      ) : (
        <Flex align="center">
          {comment.description || comment.reported_comment_reception}
        </Flex>
      )}
      <Divider />
    </>
  );
};

PreviousComments.propTypes = {
  comment: PropTypes.shape({
    date: PropTypes.string,
    written_by: PropTypes.string,
    description: PropTypes.string,
    checked: PropTypes.bool,
    _id: PropTypes.string,
    reported_comment_reception: PropTypes.string
  }),
  deleteComment: PropTypes.func,
  checkComment: PropTypes.func,
  withoutAction: PropTypes.bool
};
PreviousComments.defaultProps = {
  comment: {
    date: '',
    written_by: '',
    description: '',
    checked: false,
    _id: '',
    reported_comment_reception: ''
  },
  deleteComment: () => {},
  checkComment: () => {},
  withoutAction: false
};
