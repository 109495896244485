import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { translateTreeData } from '../../../utils/translateTreeData';

/**
 * A custom hook for making API calls to retrieve categories and stay prescriptions.
 *
 * @param {object} options - The options for the hook.
 * @returns {object} An object containing functions for making API calls.
 */
export const ProtocolMethods = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  /**
   * Gets the visit reasons categories and sets the state.
   *
   * @function
   * @async
   * @param {function} setState - The function to set the state.
   * @returns {Promise<void>} A Promise that resolves after fetching and processing data.
   */
  const getCategories = async (setState = null) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'visit-reasons/tree-data?archived=false&user_display=true'
      });

      const translatedTreeData = translateTreeData(
        data,
        'data_bases.visit-reasons.categories',
        t,
        1
      );
      if (setState) return setState(translatedTreeData);
      return translatedTreeData;
    } catch (error) {
      message(error);
    }
    return null;
  };

  /**
   * Gets the stay prescriptions categories and sets the state.
   *
   * @function
   * @async
   * @param {function} setState - The function to set the state.
   * @returns {Promise<void>} A Promise that resolves after fetching and processing data.
   */
  const getPrescriptions = async (setState) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/config-prescriptions/tree-data?archived=false&user_display=true'
      });

      const translatedTreeData = translateTreeData(
        data,
        'data_bases.stay_prescriptions.categories',
        t,
        0
      );
      if (setState) return setState(translatedTreeData);
      return translatedTreeData;
    } catch (error) {
      message(error);
    }
    return null;
  };

  return {
    getCategories,
    getPrescriptions
  };
};
