import PropTypes from 'prop-types';
import { Collapse, Col } from 'antd';
import { CollapseAmoAmcField } from './CollapseAmoAmcField';
import { CollapseAmoAmcSelect } from './CollapseAmoAmcSelect';

const { Panel } = Collapse;

/**
 * Renders a collapsible component for AmoAmc.
 *
 * @component
 * @param {Object[]} fields - The fields to be rendered in the collapsible component.
 * @param {string} header - The header text for the collapsible component.
 * @param {string[]} longTermDiseases - The array of long-term diseases.
 * @returns {JSX.Element} The rendered CollapseAmoAmc component.
 */
export const CollapseAmoAmc = ({ fields, header, longTermDiseases }) => (
  <Col span={24}>
    <Collapse expandIconPosition="end" className="patient-form-collapse">
      <Panel header={header} key="1">
        <Col>
          <CollapseAmoAmcField fields={fields} key="fields" />
          <CollapseAmoAmcSelect
            fields={fields}
            long_term_diseases={longTermDiseases}
            key="select"
          />
        </Col>
      </Panel>
    </Collapse>
  </Col>
);
CollapseAmoAmc.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired })
  ).isRequired,
  header: PropTypes.string.isRequired,
  longTermDiseases: PropTypes.arrayOf(String)
};
CollapseAmoAmc.defaultProps = {
  longTermDiseases: undefined
};
