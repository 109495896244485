import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { Card } from 'antd';
import { Patient } from './Patient';

/**
 * Represents a consulting room component.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.room - The room object.
 * @param {string} props.room._id - The room ID.
 * @param {string} props.room.title - The room title.
 * @param {string} props.room.color - The room color.
 * @param {Array} props.room.consultations - The consultations in the room.
 * @param {Function} props.setRefresh - The function to refresh the component.
 * @param {Object} props.prescriptionTypesEnums - The prescription types enums.
 *
 * @returns {JSX.Element} The consulting room component.
 */
export const ConsultingRoom = ({
  room,
  setRefresh,
  prescriptionTypesEnums
}) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'PATIENT',
    drop: () => ({ target: room?._id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  const isActive = canDrop && isOver;

  const cardStyle = {
    bodyStyle: {
      backgroundColor: isActive ? 'rgba(138, 199, 255, 0.6)' : '#EEF7FF',
      borderRadius: 0
    },
    headStyle: {
      backgroundColor: isActive ? 'rgba(138, 199, 255, 0.6)' : '#EEF7FF',
      color: room?.color,
      borderRadius: 0
    }
  };

  return (
    <div ref={drop}>
      <Card
        {...cardStyle}
        title={room?.title}
        className="kanban-consulting-room"
      >
        {room?.consultations?.map((consultation) => (
          <Patient
            key={consultation._id}
            departureRoomId={room._id}
            setRefresh={setRefresh}
            consultation={consultation}
            prescriptionTypesEnums={prescriptionTypesEnums}
          />
        ))}
      </Card>
    </div>
  );
};

ConsultingRoom.propTypes = {
  room: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.string,
    consultations: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        departureRoomId: PropTypes.string
      })
    )
  }),
  setRefresh: PropTypes.func,
  prescriptionTypesEnums: PropTypes.shape({})
};

ConsultingRoom.defaultProps = {
  room: {
    _id: '',
    title: '',
    color: '',
    patients: [
      {
        _id: '',
        name: '',
        departureRoomId: ''
      }
    ]
  },
  setRefresh: () => {},
  prescriptionTypesEnums: {}
};
