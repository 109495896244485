export const initialPatientState = {
  vitaleCardInformations: {},
  enums: [],
  noIdCard: null,
  noHealthInsuranceCard: null,
  forceRefresh: false,
  datePicking: new Date(),
  doctorsLetterFilesList: [],
  treatmentPrescriptions: [],
  patient: {}
};

/**
 * Reducer function for managing the state of the patient context.
 * @function
 *
 * @param {Object} state - The current state of the patient context.
 * @param {Object} action - The action object that contains the type and payload.
 * @returns {Object} - The new state of the patient context after applying the action.
 * @throws {Error} - If the action type is not recognized.
 */
export const patientReducer = (state, action) => {
  switch (action.type) {
    case 'SET_VITALE_CARD_INFORMATIONS':
      return { ...state, vitaleCardInformations: action.payload };
    case 'SET_ENUMS':
      return { ...state, enums: action.payload };
    case 'SET_NO_ID_CARD':
      return { ...state, noIdCard: action.payload };
    case 'SET_NO_HEALTH_INSURANCE_CARD':
      return { ...state, noHealthInsuranceCard: action.payload };
    case 'SET_FORCE_REFRESH':
      return { ...state, forceRefresh: action.payload };
    case 'SET_DATE_PICKING':
      return { ...state, datePicking: action.payload };
    case 'SET_DOCTORS_LETTER_FILES_LIST':
      return { ...state, doctorsLetterFilesList: action.payload };
    case 'SET_TREATMENT_PRESCRIPTIONS':
      return { ...state, treatmentPrescriptions: action.payload };
    case 'SET_PATIENT':
      return { ...state, patient: action.payload };
    default:
      throw new Error();
  }
};
