import dayjs from 'dayjs';
import { Badge, List, Typography, Row, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { listContent } from '../PatientOverview/Treatments/listContent';
import { useConsultationsContext } from '../ConsultationsContext';
import { usePatientAllergies } from '../../../utils/usePatientAllergies';

const { Text } = Typography;

/**
 * Renders a list of drugs prescriptions for a patient.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.fromShow - Indicates whether the component is rendered from the "ShowConsultation" view.
 * @param {Object[]} props.dataSource - The list of drugs prescriptions.
 * @param {Function} props.setDataSource - The function to set the data source.
 * @returns {JSX.Element} The TreatmentsList component.
 */
export const TreatmentsList = ({
  fromShow,
  dataSource,
  setDataSource,
  setCurrentMedications
}) => {
  const { t } = useTranslation();
  const { patient } = useConsultationsContext();
  const { isAllergic } = usePatientAllergies(patient);

  const colors = {
    true: 'red',
    false: 'green',
    undefined: 'grey',
    null: 'grey'
  };

  const handleDeleteEntitie = (brandname) => {
    setDataSource((prev) =>
      prev.filter((item) => item.brandname !== brandname)
    );
    setCurrentMedications((prev) =>
      prev.filter((item) => item.brandname !== brandname)
    );
  };

  return (
    <List
      style={{ width: '100%' }}
      itemLayout="horizontal"
      dataSource={fromShow ? listContent(patient) : dataSource}
      renderItem={(item) => (
        <List.Item
          actions={
            !fromShow && [
              <Text>{dayjs(item.date).format('DD-MM-YYYY')}</Text>,
              <DeleteOutlined
                onClick={() => handleDeleteEntitie(item.brandname)}
              />
            ]
          }
        >
          <Row style={{ width: '100%', display: 'flex' }}>
            <Col span={2}>
              <Badge
                color={
                  fromShow ? 'black' : colors[isAllergic(item.ingredients)]
                }
              />
            </Col>
            <Col span={10}>
              <Text>{item.brandname}</Text>
            </Col>
            <Col span={4}>
              <Text>{item.dose}</Text>
            </Col>
            <Col span={4}>
              {!fromShow && (
                <Text>
                  {isAllergic(item.ingredients) &&
                    t('consultations.treatments.allergic_reaction')}
                </Text>
              )}
            </Col>
          </Row>
        </List.Item>
      )}
      pagination={
        listContent(patient).length > 10 && {
          pageSize: 10
        }
      }
    />
  );
};

TreatmentsList.propTypes = {
  fromShow: PropTypes.bool,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  setDataSource: PropTypes.func,
  setCurrentMedications: PropTypes.func
};

TreatmentsList.defaultProps = {
  dataSource: [],
  setDataSource: () => {},
  setCurrentMedications: () => {},
  fromShow: false
};
