/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Select, Button } from 'antd';
import PropTypes from 'prop-types';
import { getIncomers } from 'react-flow-renderer/nocss';
import DecisionModale from './DecisionModale';
import decisionIconURL from '../../images/WFLDecisionIcon.png';
import { mapInputs } from '../../utils';

const { Option } = Select;
const { Title, Paragraph, Text } = Typography;

const WFLDecisionForm = ({
  selectedElement,
  elements,
  operations,
  setOperations
}) => {
  const [sideDecision, setSideDecision] = useState(
    operations.sideDecision || {
      left: true,
      right: false
    }
  );
  const [decisionConditions, setDecisionConditions] = useState(
    operations.decisionConditions || []
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const sourceElements = getIncomers(selectedElement, elements);

  const handleSelectChange = (side, value) => {
    let newSide = {};
    if (side === 'left') {
      newSide = { left: value, right: !value };
    } else {
      newSide = { left: !value, right: value };
    }
    setSideDecision(newSide);
    setOperations({ sideDecision: newSide, decisionConditions });
  };

  const handleModaleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setOperations({ sideDecision, decisionConditions });
  }, [decisionConditions]);

  return (
    <>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Title level={5}>Inputs</Title>
          <Paragraph>{mapInputs(sourceElements)}</Paragraph>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Title level={5} style={{ textAlign: 'center' }}>
            Test
          </Title>
          <Paragraph>
            {decisionConditions.map((condition) => (
              <Paragraph key={condition.key}>
                <Text code>
                  {`${condition.input1} ${condition.condition} ${condition.input2} ${condition.afterCondition}`}
                </Text>
              </Paragraph>
            ))}
          </Paragraph>
          <Button block type="primary" onClick={() => setIsModalVisible(true)}>
            Gérer les tests
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={5} style={{ textAlign: 'center' }}>
            Conditions
          </Title>
          <Row>
            <Col span={8}>
              <Title level={5}>Réponse : </Title>
              <Select
                onChange={(value) => handleSelectChange('left', value)}
                value={sideDecision.left}
                style={{ minWidth: 150, width: '100%' }}
              >
                <Option value={false}>
                  <span style={{ color: 'red' }}>FALSE</span>
                </Option>
                <Option value={true}>
                  <span style={{ color: 'green' }}>TRUE</span>
                </Option>
              </Select>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <img
                src={decisionIconURL}
                alt="Decision Icon"
                style={{ maxWidth: '100%', width: 150 }}
              />
            </Col>
            <Col span={8}>
              <Title level={5}>Réponse : </Title>
              <Select
                onChange={(value) => handleSelectChange('right', value)}
                value={sideDecision.right}
                style={{ minWidth: 150, width: '100%' }}
              >
                <Option value={false}>
                  <span style={{ color: 'red' }}>FALSE</span>
                </Option>
                <Option value={true}>
                  <span style={{ color: 'green' }}>TRUE</span>
                </Option>
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
      <DecisionModale
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleCancel={handleModaleCancel}
        sourceElements={sourceElements}
        decisionConditions={decisionConditions}
        setDecisionConditions={setDecisionConditions}
      />
    </>
  );
};

WFLDecisionForm.propTypes = {
  selectedElement: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  elements: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  operations: PropTypes.shape({
    sideDecision: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
      .isRequired,
    decisionConditions: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
      .isRequired
  }).isRequired,
  setOperations: PropTypes.func.isRequired
};

export default WFLDecisionForm;
