import { ProfileFilled } from '@ant-design/icons';
import { Card, Select, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';

/**
 * Renders a card component with a select dropdown for protocols.
 * @component ProtocolsCard
 *
 * @param {function} props.setCheckedKeys Function to set the checked keys.
 * @param {function} props.setSrnCareTreeKeys Function to set the SRN care tree keys.
 * @returns {JSX.Element} ProtocolsCard component.
 */
export const ProtocolsCard = ({
  setCheckedKeys,
  setSrnCareTreeKeys,
  setImagingTreeKeys,
  setBiologyTreeKeys,
  form
}) => {
  const { t } = useTranslation();
  const { protocols } = usePrescriptionContext();

  return (
    <Card
      style={{ borderRadius: '0 8px 8px 0' }}
      title={
        <Flex alignitem="center" gap={8}>
          <ProfileFilled />
          {t('prescription.cards.protocols.title')}
        </Flex>
      }
    >
      <Select
        allowClear
        onClear={() => setCheckedKeys([])}
        showSearch
        style={{
          width: '100%'
        }}
        onChange={(value, option) => {
          setSrnCareTreeKeys([]);
          setImagingTreeKeys([]);
          setBiologyTreeKeys([]);
          setCheckedKeys(option?.checkedKeys);
          form.setFieldsValue(option?.checkedKeys);
        }}
        placeholder={t('prescription.cards.protocols.placeholder')}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? '').includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '')
            .toLowerCase()
            .localeCompare((optionB?.label ?? '').toLowerCase())
        }
        options={protocols?.map((protocol) => ({
          value: protocol._id,
          label: protocol.protocol_wording,
          checkedKeys: protocol.checked_keys
        }))}
      />
    </Card>
  );
};

ProtocolsCard.propTypes = {
  setCheckedKeys: PropTypes.func,
  setSrnCareTreeKeys: PropTypes.func.isRequired,
  setImagingTreeKeys: PropTypes.func.isRequired,
  setBiologyTreeKeys: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired
};

ProtocolsCard.defaultProps = {
  setCheckedKeys: () => {}
};
