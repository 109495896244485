import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { Button, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { patchConsultation } from '../../Components/patchConsultation';
import { ConsultationsContext } from '../../ConsultationsContext';
import { getFullName } from '../../../../utils/formatters';

const { TextArea } = Input;
const { Item } = Form;

/**
 * MedicalComments component
 * @component
 * @param {string} resource - The resource to be used for translation.
 * @param {Array} fields - The fields to be displayed in the form.
 * @returns {JSX.Element} MedicalComments component
 */
export const MedicalComments = ({ resource, fields }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const { user } = useAuthContext();
  const { patient } = useContext(ConsultationsContext);
  const [form] = Form.useForm();
  const placeholder = t(`${resource}.form.add_comment`);
  const idConsultation = patient?.current_consultation?._id;
  const { handleSubmit } = patchConsultation(idConsultation);

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    form.setFieldsValue({
      written_by: getFullName(user)
    });
  }, [user, patient]);

  return (
    <Form
      form={form}
      onFinish={(values) => {
        handleSubmit(values);
        form.resetFields(['description']);
        setComment('');
      }}
      layout="vertical"
    >
      {fields?.map(
        (field) =>
          field.name === 'description' && (
            <Item key={field.name} name={field.name} rules={field.rules}>
              <TextArea
                value={comment}
                onChange={handleChange}
                placeholder={placeholder}
                variant="outlined"
                style={{ resize: 'none' }}
              />
            </Item>
          )
      )}
      <Row justify="end">
        <Button
          type="add"
          htmlType="submit"
          style={{
            borderRadius: '15px',
            margin: '2px'
          }}
        >
          {t('buttons.validate')}
        </Button>
      </Row>
    </Form>
  );
};

MedicalComments.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  resource: PropTypes.string.isRequired
};
