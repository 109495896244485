import { SelectGender } from './components/Fields/SelectGender';
import { FormatedDatePicker } from './components/Fields/FormatedDatePicker';

const useSearchFieldsToConsultation = ({ enums }) => {
  const searchPatientFieldsToConsultation = [
    {
      noLabel: true,
      name: ['patient', 'gender'],
      rules: [{ required: false }],
      input: <SelectGender enums={enums} />
    },
    {
      label: 'patient_last_name',
      name: ['patient', 'last_name'],
      rules: [{ required: true }]
    },
    {
      label: 'patient_birth_name',
      name: ['patient', 'birth_name'],
      rules: [{ required: false }]
    },
    {
      label: 'patient_first_name',
      name: ['patient', 'first_name'],
      rules: [{ required: true }]
    },
    {
      label: 'birthdate',
      name: ['patient', 'birth_date'],
      rules: [{ required: false }],
      input: <FormatedDatePicker antdKey="" dbKey="birth_date" />
    }
  ];

  return {
    searchPatientFieldsToConsultation
  };
};
export default useSearchFieldsToConsultation;
