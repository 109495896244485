import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Form, Row, Spin, Upload, Image, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CameraOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { outOfNavRoutes } from '../../utils/constants/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';
import useFields from './fields';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import { useErrorMessage } from '../../utils/errorMessage';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import {
  SignatureCanvas,
  ContentCustom,
  PageHeaderCustom
} from '../../components';

const { Dragger } = Upload;

/**
 * Component for updating user profile.
 *
 * @component
 * @returns {JSX.Element} UpdateProfile component.
 */
const UpdateProfile = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI, user, setUser } = useAuthContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { fields } = useFields(user.double_factor_authentication);
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState([]);
  const [signature, setSignature] = useState('');
  const [initialSignature, setInitialSignature] = useState('');
  const [editSignature, setEditSignature] = useState(false);

  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${user?._id}?populate=avatar`
      });
      form.setFieldsValue({
        ...data,
        double_factor_authentication: data.double_factor_authentication
      });
      setUser(data);
      setInitialSignature(data.signature);

      if (data.avatar) {
        const { avatar: { metadata: { originalName } = {}, _id = '-1' } = {} } =
          data || {};
        setFileList([{ name: originalName, uid: _id }]);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchUser();
    })();
  }, [fetchUser]);

  const updateProfile = async (body) => {
    try {
      const formData = new FormData();

      formData.append('avatar', fileList[0]);
      formData.append('values', JSON.stringify(body));

      await dispatchAPI('PATCH', {
        url: `/users/${user?._id}`,
        body: formData
      });
      await fetchUser();
      navigate(outOfNavRoutes.PROFILE);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleSubmit = async (values) => {
    const newSignature = editSignature ? signature : initialSignature;
    await updateProfile({ ...values, signature: newSignature });
  };

  const handleEditSignatureChange = (e) => {
    setEditSignature(e.target.value);
  };

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
      } else {
        message('Not a PNG or JPG file.');
        return true;
      }
      return false;
    },
    fileList
  };

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('profile.title')} />
      <Spin spinning={isLoading}>
        <Form
          layout="horizontal"
          form={form}
          onFinish={handleSubmit}
          {...formItemLayout}
        >
          <Form.Item {...formItemLayout} label="Avatar">
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <CameraOutlined style={{ color: 'var(--textColor)' }} />
              </p>
            </Dragger>
          </Form.Item>
          {fields.map((field) => generateFields('users', field))}
          <Form.Item {...formItemLayout} name="photo" label="Signature">
            <Radio.Group
              onChange={handleEditSignatureChange}
              value={editSignature}
            >
              <Radio value>{t('profile.edit.update_signature')}</Radio>
              <Radio value={false}>{t('profile.edit.display_signature')}</Radio>
            </Radio.Group>
            {editSignature && (
              <SignatureCanvas
                wrapperStyle={{ marginTop: 16 }}
                handleFinish={(image) =>
                  setSignature({ image, signature_date: dayjs().format() })
                }
              />
            )}
            {!editSignature && (
              <Image preview={false} src={initialSignature?.image} size={120} />
            )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Row justify="end">
              <Button
                style={{ margin: '0 10px' }}
                type="link"
                danger
                onClick={() => navigate(-1)}
              >
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
              <Button id="save-button" type="add" htmlType="submit">
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Spin>
    </ContentCustom>
  );
};

export default UpdateProfile;
