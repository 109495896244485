import { useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useConsultationsContext } from './ConsultationsContext';
import { ShowGeneralInfos } from './PatientOverview/ShowGeneralInfos';
import { ShowMedicalBackground } from './PatientOverview/ShowMedicalBackground';
import { ShowResultsLaboratories } from './PatientOverview/ShowResultsLaboratories';
import { Prescriptions } from './PatientOverview/Prescriptions/Prescription';
import { ShowDocuments } from './PatientOverview/Documents/ShowDocuments';
import { ShowStayHistory } from './PatientOverview/ShowStayHistory';
import { ShowPricing } from './PatientOverview/ShowPricing';
import { ShowTreatments } from './PatientOverview/ShowTreatments';
import { ShowMedicalConsultation } from './PatientOverview/MedicalConsultation/ShowMedicalConsultation';
import { ShowSurveyOfConstants } from './PatientOverview/ShowSurveyOfConstants';
import { ShowMedicalComments } from './PatientOverview/ShowMedicalComments';
import { ShowEntryNotifications } from './PatientOverview/ShowEntryNotifications';
import useFields from './fields';
import { ContentCustom } from '../../components';

/**
 * A component that displays a consultation.
 *
 * @component
 *
 * @returns {JSX.Element} The ShowConsultation component.
 */
const colItemStyle = {
  padding: '16px',
  backgroundColor: '#fff',
  borderRadius: '4px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  marginBottom: '16px'
};

export const ShowConsultation = () => {
  const { id } = useParams();
  const { getPatient, isLoading, setIsLoading, forcedRefresh } =
    useConsultationsContext();

  const {
    resultsLaboratiesFields,
    medicalCommentsFields,
    medicalBackgroundFields
  } = useFields({});

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getPatient(id);
      setIsLoading(false);
    })();
  }, [getPatient, forcedRefresh]);

  return (
    <ContentCustom style={{ background: 'transparent', margin: 0 }}>
      <Spin spinning={isLoading}>
        <Row gutter={[16, 16]} className="show-consultation-row">
          <Col
            xs={24}
            md={12}
            lg={12}
            xl={12}
            className="show-consultation-col"
          >
            <ShowGeneralInfos id={id} />
          </Col>
          <Col xs={24} md={6} lg={5} xl={5} className="show-consultation-col">
            <ShowDocuments />
          </Col>
          <Col xs={24} md={6} lg={7} xl={7} className="show-consultation-col">
            <ShowPricing />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="show-consultation-row">
          <Col xs={24} md={8} lg={6} xl={6} className="show-consultation-col">
            <ShowEntryNotifications id={id} style={colItemStyle} />
            <ShowMedicalBackground
              id={id}
              fields={medicalBackgroundFields}
              style={colItemStyle}
            />
            <ShowResultsLaboratories
              id={id}
              fields={resultsLaboratiesFields}
              style={colItemStyle}
            />
            <ShowStayHistory id={id} style={colItemStyle} />
          </Col>
          <Col
            xs={24}
            md={16}
            lg={12}
            xl={12}
            className="show-consultation-col"
          >
            <ShowMedicalConsultation id={id} />
            <Prescriptions className="prescription" />
          </Col>
          <Col xs={24} md={24} lg={6} xl={6} className="show-consultation-col">
            <ShowMedicalComments id={id} fields={medicalCommentsFields} />
            <ShowTreatments id={id} />
            <ShowSurveyOfConstants id={id} />
          </Col>
        </Row>
      </Spin>
    </ContentCustom>
  );
};
