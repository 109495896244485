import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useReducer
} from 'react';
import { useAuthContext } from '../AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { Prescription } from '../../models/Prescription';
import { User } from '../../models/User';
import { Patient } from '../../models/Patient';
import { prescriptionReducer, initialState } from './reducer';
import { Protocol } from '../../models/Protocol';

export const PrescriptionContext = createContext({});

/**
 * Prescription Context Provider component.
 * Provides prescription-related data and functions to its children components.
 *
 * @context
 *
 * @param {Object} children - The child components.
 * @returns {JSX.Element} PrescriptionContext.Provider component.
 */
export const PrescriptionContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { message } = useErrorMessage();

  const [state, dispatch] = useReducer(prescriptionReducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [entities, setEntities] = useState([]);
  const [posologies, setPosologies] = useState([]);

  /**
   * Fetches patient information from the server and updates the state with the retrieved data.
   * @async
   * @function getPatientInfos
   * @returns {Promise<void>} A promise that resolves when the patient information is fetched.
   */
  const getPatientInfos = async () => {
    try {
      const { data } = await Patient.getOneById(state.patientId, {
        populate:
          'ins_qrcode,current_consultation,current_consultation.doctor doctor.cpx_barcode'
      });
      dispatch({
        type: 'SET_PATIENT_INFOS',
        payload: {
          headerTitle: t('prescription.title', {
            first_name: data?.first_name,
            last_name: data?.last_name
          }),
          patientAge: dayjs().diff(data?.birth_date, 'year'),
          infos: data
        }
      });
    } catch (error) {
      message(error);
    }
  };

  /**
   * Fetches doctors by center.
   * @function
   * @returns {Promise<void>} A Promise that resolves when the doctors are fetched successfully.
   */
  const getDoctorsByCenter = async () => {
    try {
      const { data } = await User.getAll({
        position: 'DOCTOR',
        selected_center: user.selected_center
      });
      dispatch({ type: 'SET_DOCTORS', payload: data });
    } catch (error) {
      message(error);
    }
  };

  /**
   * Fetches protocols and sets them in the context state.
   * @async
   * @function getProtocols
   * @returns {Promise<void>} A promise that resolves when the protocols are fetched.
   */
  const getProtocols = async () => {
    const protocols = await Protocol.getAll({
      populate: 'prescriptions',
      archived: false
    });
    dispatch({ type: 'SET_PROTOCOLS', payload: protocols });
  };

  const getFavoritesProtocols = async (category) => {
    const favoritesProtocolsResponse = await Protocol.getFavoritesProtocols(
      category,
      {
        author: user._id
      }
    );
    dispatch({
      type: 'SET_FAVORITES_PROTOCOLS',
      payload: favoritesProtocolsResponse
    });
  };

  useEffect(() => {
    (async () => {
      await Promise.all([getProtocols(), getDoctorsByCenter()]);
    })();
  }, []);

  /**
   * Fetches the enum categories from the server.
   * @function
   * @returns {Promise<void>} A promise that resolves when the enum categories are fetched.
   */
  const getEnumsCategories = async () => {
    try {
      const { data } = await Prescription.getEnums('config-prescriptions');
      dispatch({ type: 'SET_CATEGORIES', payload: data.categories });
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    if (!state.patientId) return;
    (async () => {
      setIsLoading(true);
      await Promise.all([getPatientInfos(), getEnumsCategories()]);
      setIsLoading(false);
    })();
  }, [state.patientId]);

  return (
    <PrescriptionContext.Provider
      value={{
        ...state,
        isLoading,
        entities,
        setEntities,
        posologies,
        setPosologies,
        setSrnCareTreeKeys: (value) =>
          dispatch({ type: 'SET_SRN_CARE_TREE_KEYS', payload: value }),
        setContent: (value) =>
          dispatch({ type: 'SET_CONTENT', payload: value }),
        setPatientInfos: (value) =>
          dispatch({ type: 'SET_PATIENT_INFOS', payload: value }),
        setRefresh: (value) =>
          dispatch({ type: 'SET_REFRESH', payload: value }),
        setPatientId: (value) =>
          dispatch({ type: 'SET_PATIENT_ID', payload: value }),
        setImagingTreeKeys: (value) =>
          dispatch({ type: 'SET_IMAGING_TREE_KEYS', payload: value }),
        setBiologyTreeKeys: (value) =>
          dispatch({ type: 'SET_BIOLOGY_TREE_KEYS', payload: value }),
        getFavoritesProtocols
      }}
    >
      {children}
    </PrescriptionContext.Provider>
  );
};

export const usePrescriptionContext = () => useContext(PrescriptionContext);
