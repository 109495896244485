import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Modal,
  Form,
  Typography,
  Input,
  Button,
  message as Message
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * A modal component for activating double authentication.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setIsLoading - A function to set the loading state.
 * @returns {JSX.Element} The DoubleAuthActivationModal component.
 */
export const DoubleAuthActivationModal = ({ setIsLoading }) => {
  const { t } = useTranslation();
  const { dispatchAPI, setUser } = useAuthContext();
  const { message } = useErrorMessage();
  const [open, setOpen] = useState(true);

  const handleSubmit = async (values) => {
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: '/users/activation2FA',
        body: values
      });
      if (data && data.double_factor_authentication) {
        setUser((prev) => ({ ...prev, ...data }));
        setOpen(false);
        Message.success(t('settings.messages.double_factor_auth_activated'), 5);
      }
    } catch (error) {
      message(error);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelSubmit = async () => {
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: '/users/patch2FA',
        body: { double_factor_authentication: false }
      });

      setUser((prev) => ({ ...prev, ...data }));
      Message.error(t('settings.messages.double_factor_auth_deactivated'), 5);
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      message(error);
    }
  };

  return (
    <Modal
      title={t('settings.collapses.double_auth_activation')}
      open={open}
      footer={null}
      onCancel={() => {
        cancelSubmit();
      }}
    >
      <Typography.Paragraph>
        {t('settings.messages.waiting_message')}
      </Typography.Paragraph>
      <Form onFinish={handleSubmit}>
        <Form.Item
          label={t('settings.collapses.code')}
          name="code"
          rules={[{ required: true }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t('buttons.activate')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

DoubleAuthActivationModal.propTypes = {
  setIsLoading: PropTypes.func
};

DoubleAuthActivationModal.defaultProps = {
  setIsLoading: () => {}
};
