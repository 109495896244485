import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Image, Row, Tag, Space } from 'antd';
import {
  DeleteOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import { CompareButton } from '../components/CompareButton';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';

/**
 * @hook
 * @name useColumnsPhotos
 * @description A custom hook to return columns for photos table
 * @param {Boolean} isSwitched - A boolean to check if the table is in switched mode
 * @param {Array} consultationsWithPhotos - An array of consultations with photos
 * @param {Function} setRefresh - A function to trigger a refresh
 * @returns {Array} columns
 */
export const useColumnsPhotos = (
  isSwitched,
  consultationsWithPhotos,
  setRefresh
) => {
  const { t } = useTranslation();
  const { deleteDocument } = useDownloadDocument();
  const formatDate = (date) => dayjs(date).format('DD-MM-YYYY');

  const handleDelete = async (_id) => {
    await deleteDocument({ _id });
    setRefresh((prev) => !prev);
  };

  const handlePrint = (imageUrl) => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Imprimer l'image</title>
          <style>
            body, html {
              margin: 0;
              padding: 0;
              text-align: center;
            }
            img {
              max-width: 100%;
              height: auto;
            }
          </style>
        </head>
        <body>
          <img src="${imageUrl}" alt="Image à imprimer" />
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();

    printWindow.onafterprint = () => {
      printWindow.close();
    };

    printWindow.print();
  };

  const insightColumn = {
    title: t('consultations.documents.insight'),
    key: 'insight',
    dataIndex: 'photos',
    render: (photos) =>
      photos.map((p) => (
        <Image
          width={40}
          height={40}
          src={p?.photo?.metadata?.type}
          key={p?.photo?._id}
          preview={{
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: {
                  onFlipY,
                  onFlipX,
                  onRotateLeft,
                  onRotateRight,
                  onZoomOut,
                  onZoomIn
                }
              }
            ) => (
              <Space>
                <SwapOutlined rotate={90} onClick={onFlipY} />
                <SwapOutlined onClick={onFlipX} />
                <RotateLeftOutlined onClick={onRotateLeft} />
                <RotateRightOutlined onClick={onRotateRight} />
                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                <DeleteOutlined onClick={() => handleDelete(p?.photo?._id)} />
                <PrinterOutlined
                  onClick={() => handlePrint(p?.photo?.metadata?.type)}
                />
              </Space>
            )
          }}
        />
      ))
  };

  const reasonColumn = {
    title: t('consultations.reason'),
    key: 'diagnostic',
    dataIndex: 'diagnostic',
    sorter: true,
    render: (diagnostic, record) => {
      const displayValue =
        diagnostic && diagnostic.length > 0 ? (
          diagnostic.join(', ')
        ) : (
          <Tag color="#87d068" style={{ borderRadius: '15px' }}>
            {t('consultations.form.current_consultation')}
          </Tag>
        );

      const content =
        record.rowSpan > 1 ? (
          <>
            <Row>{displayValue}</Row>
            <Row>
              <CompareButton
                consultationsWithPhotos={consultationsWithPhotos}
                record={record}
              />
            </Row>
          </>
        ) : (
          displayValue
        );

      return content;
    },

    onCell: (record, rowIndex) => {
      const props = {};
      if (
        rowIndex > 0 &&
        consultationsWithPhotos[rowIndex - 1].diagnostic[0] ===
          record.diagnostic[0]
      ) {
        props.colSpan = 0;
      }
      if (record.rowSpan > 1) {
        props.rowSpan = record.rowSpan;
      }
      return props;
    }
  };

  const dateColumn = {
    title: t('consultations.date'),
    key: 'date',
    dataIndex: 'date',
    render: (prescription_date) =>
      prescription_date ? formatDate(prescription_date) : '-'
  };

  const comment = {
    title: t('consultations.comment'),
    key: 'comment',
    dataIndex: 'photos',
    render: (photos) => {
      const photoComments = photos.flatMap((photo) => photo.comment);

      return photoComments.join('\n');
    }
  };

  return isSwitched
    ? [insightColumn, reasonColumn, dateColumn, comment]
    : [insightColumn, dateColumn, reasonColumn, comment];
};
