import { Consultation } from '../../../models/Consultation';

/**
 * Custom hook for handling the confirmation of transmitted medications.
 * @module useHandleConfirmTransmition
 * @hook
 *
 * @param {function} setRefresh - A state setter function to trigger a refresh.
 * @returns {Object} An object containing the handleConfirm function.
 */
export const useHandleConfirmTransmition = (setRefresh) => {
  /**
   * Handles the confirmation of transmitted medications.
   * @async
   * @function
   *
   * @param {string} id - The consultation id.
   * @param {Array<Object>} transmittedData - The transmitted data.
   * @returns {Promise<void>} A promise that resolves when the transmitted medications are patched.
   */
  const handleConfirm = async (id, transmittedData) => {
    await Consultation.patchTransmittedMedications(id, transmittedData);
    setRefresh((prev) => !prev);
  };

  return { handleConfirm };
};
