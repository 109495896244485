import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Radio } from 'antd';
import { useState } from 'react';

export const AmoAmcSelectGlobalHealthCare = ({ dbKey }) => {
  const resource = 'patients';
  const { Item } = Form;
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };
  return (
    <Item name={`${dbKey}`}>
      <Radio.Group onChange={(e) => handleChange(e)} value={selectedValue}>
        <Radio value>{t(`${resource}.form.yes`)}</Radio>
        <Radio value={false}>{t(`${resource}.form.no`)}</Radio>
      </Radio.Group>
    </Item>
  );
};

AmoAmcSelectGlobalHealthCare.propTypes = {
  dbKey: PropTypes.string
};
AmoAmcSelectGlobalHealthCare.defaultProps = {
  dbKey: undefined
};
