import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Row, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { usefetchPatientForSearch } from '../fetchPatientForSearch';
import { SearchPatientResultModal } from './SearchPatientResultModal';
import { formItemLayoutCustom } from '../../../../utils/constants/formLayout';

const resource = 'patients';

/**
 * SearchPatientModal component.
 *
 * @component
 * @param {Array} fields - The fields to be displayed in the search form.
 * @param {object} enums - An object containing enums for dropdown options.
 * @returns {JSX.Element} The SearchPatientModal component.
 */
export const SearchPatientModal = ({ fields, enums }) => {
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const [isModalResultOpen, setIsModalResultOpen] = useState(false);
  const { handleSubmit, searchResults, isRequestComplete } =
    usefetchPatientForSearch(setIsModalOpenedLocaly);

  const handleCancelResult = () => {
    setIsModalResultOpen(false);
    form.resetFields();
  };
  const handleResultOk = () => {
    setIsModalResultOpen(false);
  };

  const onCancel = () => {
    setIsModalOpenedLocaly(false);
  };

  useEffect(() => {
    if (isRequestComplete) {
      setIsModalResultOpen(true);
    }
  }, [isRequestComplete]);

  return (
    <>
      <Modal
        open={isModalOpenedLocaly}
        onCancel={onCancel}
        width="60%"
        footer={null}
        enums={enums}
        title={t('patients.form.searchPatient')}
      >
        <Form
          form={form}
          onFinish={(values) => handleSubmit(values, searchResults)}
          {...formItemLayoutCustom}
        >
          {fields?.map((field) => generateFields(resource, field))}
          <Row justify="end">
            <Button key="cancel" onClick={() => onCancel()} type="link">
              {t('buttons.cancel')}
            </Button>
            <Button htmlType="submit" type="primary">
              {t('buttons.validate')}
            </Button>
          </Row>
        </Form>
      </Modal>

      {searchResults.length ? (
        <Modal
          open={isModalResultOpen}
          width="60%"
          footer={null}
          onCancel={handleCancelResult}
          enums={enums}
        >
          <SearchPatientResultModal
            searchResults={searchResults}
            enums={enums}
            handleCancelResult={() => handleCancelResult()}
          />
          <Row justify="end">
            <Button key="cancel" onClick={handleCancelResult} type="link">
              {t('buttons.cancel')}
            </Button>
          </Row>
        </Modal>
      ) : (
        <Modal
          open={isModalResultOpen}
          width="80%"
          footer={null}
          onCancel={handleCancelResult}
        >
          <SearchPatientResultModal searchResults={searchResults} />
          <Row justify="end">
            <Button key="cancel" onClick={handleCancelResult} type="link">
              {t('buttons.cancel')}
            </Button>
            <Link to={`${resource}/create?createConsultation=true`}>
              <Button key="validate" onClick={handleResultOk} type="primary">
                {t('buttons.validate')}
              </Button>
            </Link>
          </Row>
        </Modal>
      )}
    </>
  );
};
SearchPatientModal.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  enums: PropTypes.shape({
    genders: PropTypes.arrayOf(PropTypes.string),
    blood_group: PropTypes.arrayOf(PropTypes.string),
    cpamData: PropTypes.arrayOf(PropTypes.shape({})),
    allergies: PropTypes.arrayOf(PropTypes.shape({})),
    medical_history: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

SearchPatientModal.defaultProps = { fields: undefined, enums: undefined };
