import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import { Divider, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that returns the favorite columns configuration for a table.
 * @hook
 * @param {Object} options - The options object.
 * @param {Function} options.selected - The function to determine if a record is selected.
 * @param {Function} options.isEditing - The function to determine if a record is being edited.
 * @param {Function} options.save - The function to save a record.
 * @param {Function} options.edit - The function to edit a record.
 * @param {Function} options.del - The function to delete a record.
 * @returns {Object} - The columns configuration object.
 */
export const useFavoriteColumns = ({
  selected,
  isEditing,
  save,
  edit,
  del
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: '',
      dataIndex: 'icon',
      key: 'icon',
      render: (_, record) => {
        const selectable = selected(record);
        return selectable ? <CheckOutlined /> : <UnorderedListOutlined />;
      }
    },
    {
      title: 'label',
      dataIndex: 'label',
      key: 'label',
      editable: true
    },
    {
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <>
            {editable ? (
              <CheckOutlined
                onClick={async (event) => {
                  event.stopPropagation();
                  await save(record._id);
                }}
              />
            ) : (
              <EditOutlined
                onClick={(event) => {
                  event.stopPropagation();
                  edit(record);
                }}
              />
            )}
            <Divider type="vertical" />
            <Popconfirm
              title={t('warnings.message.delete')}
              onConfirm={(event) => {
                event.stopPropagation();
                del(record._id);
              }}
            >
              <DeleteOutlined onClick={(event) => event.stopPropagation()} />
            </Popconfirm>
          </>
        );
      }
    }
  ];

  return { columns };
};
