import { Col, Space, Spin, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { TeleservicesButton } from '../../../../../components/TeleservicesButton/TeleservicesButton';
import { Consultation } from '../../../../../models/Consultation';

const { Title } = Typography;

/**
 * Renders a row for work accident information in the medical consultation form.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.currentConsultationId - The ID of the current consultation.
 * @param {Object} props.workAccident - The work accident data.
 * @param {string} props.workAccident.date - The date of the work accident.
 * @param {boolean} props.workAccident.display - Indicates if the work accident should be displayed.
 * @returns {JSX.Element} The rendered component.
 */
export const RowWorkAccident = ({ currentConsultationId, workAccident }) => {
  const { t } = useTranslation();
  const { display } = workAccident || {};

  const [displayBtn, setDisplayBtn] = useState(display);

  useEffect(() => {
    if (display) setDisplayBtn(display);
  }, [workAccident]);

  const onChange = async (value, event) => {
    await Consultation.patchMedicalConsultation(currentConsultationId, {
      [event]: value
    });
    setDisplayBtn(value);
  };

  return (
    <Col span={24}>
      <Title level={5}>Accident de travail</Title>
      <Space direction="vertical">
        <Space direction="horizontal">
          {!workAccident ? (
            <Spin size="small" />
          ) : (
            <Switch
              checkedChildren={t('yes')}
              unCheckedChildren={t('no')}
              onChange={(value) => onChange(value, 'display')}
              defaultChecked={workAccident && display}
            />
          )}
        </Space>
        {displayBtn && <TeleservicesButton service="aati" justify="start" />}
      </Space>
    </Col>
  );
};

RowWorkAccident.propTypes = {
  currentConsultationId: PropTypes.string,
  workAccident: PropTypes.shape({
    date: PropTypes.string,
    display: PropTypes.bool
  })
};

RowWorkAccident.defaultProps = {
  currentConsultationId: '',
  workAccident: {
    date: '',
    display: false
  }
};
