import Proptypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { ListResource } from '../../../../components';
import { useColumns } from './columns';
import { DataBaseContext } from '../../../../contexts/DataBasesContext';
import { OptionsMethods } from './OptionsMethods';

/**
 * Renders a list of options related to stay prescriptions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.withOptions - Indicates whether to render the list with options.
 * @param {Array} props.options - The array of options to be displayed in the list.
 * @param {string} props.prescriptionWording - The wording for the prescription.
 * @param {string} props.category - The category of the prescription.
 * @returns {JSX.Element|null} The ListOptions component or null if `withOptions` is false.
 */
export const ListOptions = ({
  withOptions,
  options,
  setOptions,
  prescriptionWording,
  category,
  purpose
}) => {
  const { id } = useParams();
  const { columns } = useColumns();
  const { options: optimistOptions, setOptions: setOptimistOptions } =
    useContext(DataBaseContext);
  const { archiveOption } = OptionsMethods({
    setOptimistOptions,
    optimistOptions,
    options,
    setOptions
  });
  const path = '/data-base/config-prescriptions/prescription';

  const editOptionPathname = {
    create: false,
    edit: {
      pathname: (record) => `${path}/${id}/option/edit/${record._id}`
    }
  };

  return (
    withOptions && (
      <ListResource
        columns={columns}
        rowKey={(record) => record._id}
        dataSource={[...options, ...optimistOptions]}
        path={`${path}/${id}/option`}
        editAction={editOptionPathname[purpose]}
        showAction={false}
        withArchiveButton={false}
        withUploadButton={false}
        resourceName={`config-prescriptions/prescriptions/${id}`}
        withCreateButton={{
          path: `${path}/${id || 'create'}/option/create`
        }}
        deleteAction={false}
        archiveAction={{
          customAction: (record) =>
            record && archiveOption(record?.option_wording)
        }}
        isPageHeaderCustom={false}
        disabled={!prescriptionWording || !category}
      />
    )
  );
};

ListOptions.propTypes = {
  withOptions: Proptypes.bool.isRequired,
  options: Proptypes.arrayOf(Proptypes.shape({})).isRequired,
  prescriptionWording: Proptypes.string.isRequired,
  category: Proptypes.string.isRequired,
  setOptions: Proptypes.func.isRequired,
  purpose: Proptypes.string.isRequired
};
