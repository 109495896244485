import { Button, Col, Space, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Paragraph, Title } = Typography;

/**
 * A component that displays a row of specialist consultations.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.resource - The resource to be used for translation.
 * @param {Function} props.t - The translation function.
 * @param {Array<string>} props.specialistConsultation - The array of specialist consultations to be displayed.
 *
 * @returns {JSX.Element} The RowSpecialistConsultation component.
 */
export const RowSpecialistConsultation = ({
  resource,
  t,
  specialistConsultation,
  redirectToOmniDoc
}) => (
  <Col span={24}>
    <Space direction="horizontal">
      <Title level={5}>
        {t(`${resource}.medical_consultation.specialist_consultation`)}
      </Title>
      <Button type="primary" onClick={redirectToOmniDoc}>
        {t('buttons.omnidoc')}
      </Button>
    </Space>
    {specialistConsultation &&
      specialistConsultation?.map((specialist) => (
        <Paragraph
          key={specialist?._id}
        >{`${specialist?.last_name} ${specialist?.first_name}`}</Paragraph>
      ))}
  </Col>
);

RowSpecialistConsultation.propTypes = {
  resource: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  specialistConsultation: PropTypes.arrayOf(PropTypes.shape({})),
  redirectToOmniDoc: PropTypes.func
};

RowSpecialistConsultation.defaultProps = {
  specialistConsultation: [],
  redirectToOmniDoc: () => {}
};
