import { createGlobalStyle } from 'styled-components';

const DarkTheme = createGlobalStyle`
  body {
    --componentBackground: #001529;
    --clientColor: var(--componentBackground);
    --primaryColor: #1890ff;
    --addColor: #002059;
    --itemActiveBackground: var(--primaryColor);
    --primaryHover: #002059;
    --secondaryColor: #ff4d4f;
    --secondaryHover: #fff;
    --disabledColor: #bfbfbf;
    --bodyBackground: #2d2d2d;
    --subMenuBackground: #000010;
    --menuDropdownBackground: #0f0f0f;
    --textColor: rgba(255, 255, 255, 0.65);
    --textColorSecondary: rgba(255, 255, 255, 0.45);
    --itemHoverBackground: #0f0f0f;
    --itemHoverColor: #fff;
    --borderColor: #000;
    --contrastBackground: #020202;
    --pickerDropdown: var(--contrastBackground);
    --opacitySVG: 0.6;
    --opacitySpin: 0.2;
    --primaryColorA04
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --errorColor
    --error100
    --errorA08
   }
  `;

export default DarkTheme;
