import {
  getPhoneNumber,
  getBirthDate,
  getInsNumber
} from '../../../../utils/formatters';

/**
 * Custom hook that generates an array of label objects based on the provided data.
 *
 * @hook
 * @param {Object} data - The data object containing information about the patient.
 * @param {string} data.birth_date - The birth date of the patient.
 * @param {string} data.address - The address of the patient.
 * @param {string} data.ins - The insurance number of the patient.
 * @param {string} data.phone_number_mobile - The mobile phone number of the patient.
 * @returns {Array} - An array of label objects.
 */
export const useListContentTop = (data = {}) => {
  const { birth_date, address, ins, phone_number_mobile } = data;

  const labels = [
    {
      label: 'consultations.patient_date_of_birth',
      span: 2,
      content: birth_date ? getBirthDate(birth_date) : '-'
    },
    {
      label: 'consultations.form.ins',
      span: 1,
      content: ins ? getInsNumber(ins) : '-'
    },
    {
      label: 'consultations.form.patient_address',
      span: 2,
      content: address || '-'
    },
    {
      label: 'consultations.form.patient_phone_number',
      span: 1,
      content: getPhoneNumber(phone_number_mobile)
    }
  ];
  return labels;
};
