import { NewComment } from '../components/Fields/NewComment';

export const reportFields = [
  {
    noLabel: true,
    name: ['report_reception', 'reported_comment_reception'],
    input: (
      <NewComment
        antdKey="report_reception"
        dbKey="reported_comment_reception"
      />
    )
  }
];
