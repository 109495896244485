import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Button, Col, Flex, Form, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { patchPatientApi } from '../../Components/patchPatientApi';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useConsultationsContext } from '../../ConsultationsContext';
import { formItemLayout } from '../../../../utils/constants/formLayout';
import { SynapsePatientProfileWidget } from '../../../../components/Synapse/SynapsePatientProfileWidget';

const resource = 'consultations';

/**
 * This component renders the Medical Background form.
 * @component MedicalBackgroundForm
 * @param {Object} props Component props
 * @param {Array} props.fields Fields to display
 * @param {string} props.id Patient id
 * @param {Function} props.handleCloseModal Callback to execute on modal close
 * @returns {JSX.Element} Medical Background form
 */
export const MedicalBackgroundForm = ({ fields, id, handleCloseModal }) => {
  const generateFields = useGenerateFormItem();
  const { patient } = useConsultationsContext();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { handleSubmit } = patchPatientApi(id, handleCloseModal);

  useEffect(() => {
    form.setFieldsValue(patient);
  }, [patient, form, handleSubmit]);

  return (
    <>
      <Spin spinning={!patient}>
        <SynapsePatientProfileWidget patientInfos={patient} />
      </Spin>
      <Row justify="center">
        <Col span={24}>
          <Form
            form={form}
            onFinish={handleSubmit}
            {...formItemLayout}
            layout="vertical"
          >
            {fields.map((field) => generateFields(resource, field))}
            <Flex>
              <Button key="cancel" onClick={handleCloseModal}>
                {t('buttons.cancel')}
              </Button>
              <Button
                htmlType="submit"
                style={{
                  borderRadius: '15px',
                  margin: '2px'
                }}
              >
                {t('buttons.validate')}
              </Button>
            </Flex>
          </Form>
        </Col>
      </Row>
    </>
  );
};

MedicalBackgroundForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func
};
MedicalBackgroundForm.defaultProps = {
  fields: undefined,
  handleCloseModal: undefined
};
