import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that returns a function to render the description of a beneficiary.
 *
 * @hook
 * @param {Object} beneficiary - The beneficiary object.
 * @param {string} beneficiary.dateNaissance - The beneficiary's birth date.
 * @param {string} beneficiary.medecinTraitantDeclare - The beneficiary's general practitioner.
 * @returns {Object} - An object containing the renderDescription function.
 */
export const useRenderDescription = (beneficiary) => {
  const { t } = useTranslation();
  const { dateNaissance, medecinTraitantDeclare } = beneficiary; // These are keys from Stellair and yes, it's in french.
  const translationKey = 'patients.form';

  const description = [
    {
      title: t(`${translationKey}.birthdate`),
      content: dateNaissance ? dayjs(dateNaissance).format('DD-MM-YYYY') : '-'
    },
    {
      title: t(`${translationKey}.general_practitioner`),
      content: medecinTraitantDeclare || '-'
    }
  ];

  const renderDescription = () =>
    description.map(({ title, content }) => (
      <div>
        <strong>{title} : </strong>
        {content}
      </div>
    ));

  return { renderDescription };
};
