import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ModalTreatments } from './Treatments/ModalTreatments';
import { listContent } from './Treatments/listContent';
import { useConsultationsContext } from '../ConsultationsContext';
import { TreatmentsList } from '../Components/TreatmentsList';
import { getFullName } from '../../../utils/formatters';

/**
 * Renders a component that displays the treatments for a patient.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the patient.
 * @param {string} props.patientName - The name of the patient.
 * @returns {JSX.Element} The rendered component.
 */
export const ShowTreatments = ({ id }) => {
  const resource = 'consultations';
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState();

  const { patient } = useConsultationsContext();
  const patientAllergies = patient?.grounds?.allergies;

  const handleClick = () => {
    setOpenModal(true);
  };

  return (
    <Card
      title={t(`${resource}.treatments.personal_medicamentations`)}
      extra={
        <Button type="Link" icon={<EditOutlined />} onClick={handleClick} />
      }
      style={{ height: 'fit-content' }}
    >
      <ModalTreatments
        id={id}
        openModal={openModal}
        setOpenModal={setOpenModal}
        patientName={getFullName(patient)}
        patientAllergies={patientAllergies}
      />
      <Row>
        <Col span={24}>
          <Row>
            {listContent(patient)?.length ? (
              <TreatmentsList fromShow />
            ) : (
              t('consultations.treatments.no_treatments')
            )}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

ShowTreatments.propTypes = {
  id: PropTypes.string
};

ShowTreatments.defaultProps = {
  id: undefined
};
