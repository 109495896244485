import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { userRoles } from '../../utils/constants/tagColors';
import { getPhoneNumber, getFullName } from '../../utils/formatters';

/**
 * Custom hook that returns an array of labels for the user list.
 *
 * @hook
 * @param {Object} data - User data
 * @returns {Array} Array of label objects with their corresponding content
 */
export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    first_name,
    last_name,
    role,
    email,
    phone_number,
    cpx_number,
    position,
    replacing,
    associated_company,
    archived,
    archiving_date
  } = data;

  const labels = [
    {
      label: 'users.form.role',
      span: 3,
      content:
        (role && (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`)}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'users.form.position',
      span: 1,
      content: position ? t(`users.positions.${position}`) : '-'
    },
    {
      label: 'users.form.replacing',
      span: 1,
      content: replacing ? t('yes') : t('no')
    },
    {
      label: 'users.form.associated_company',
      span: 2,
      content:
        (associated_company && (
          <Link to={`/companies/show/${associated_company._id}`}>
            {associated_company.name}
          </Link>
        )) ||
        '-'
    },
    {
      label: 'users.form.last_name',
      span: 1,
      content: getFullName({ first_name, last_name })
    },
    {
      label: 'users.form.email',
      span: 1,
      content: email || '-'
    },
    {
      label: 'users.form.phone_number_full',
      span: 2,
      content: getPhoneNumber(phone_number)
    },
    {
      label: 'users.form.cpx_number',
      span: 1,
      content: cpx_number || '-'
    }
  ];

  if (archived) {
    labels.unshift(
      {
        label: 'users.form.archived',
        span: 1,
        content: t('yes')
      },
      {
        label: 'users.form.archiving_date',
        span: 2,
        content: dayjs(archiving_date).format('DD/MM/YYYY')
      }
    );
  }

  return labels;
};
