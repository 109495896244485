import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { IdcardOutlined } from '@ant-design/icons';
import { useStellairContext } from '../../contexts/StellairContext';
import { LoadingModal } from '../Modals/LoadingModal';

/**
 * A button component for reading vitale card data.
 *
 * @component
 * @prop {Function} props.setIsModalVisible - A function to set the visibility of the modal.
 * @returns {JSX.Element} - The rendered component.
 */
export const ReadVitaleCardButton = ({ setIsModalVisible }) => {
  const { t } = useTranslation();
  const { getVitaleCardData, setVitaleCardData } = useStellairContext();
  const [isLoading, setIsLoading] = useState(false);
  const translationKey = 'patients.form';
  const customMessage = t('stellair.vitale_card.loading');

  const readVitalCard = async () => {
    setIsLoading(true);
    setVitaleCardData(null);

    const response = await getVitaleCardData();
    const success = !!response?.success;

    setIsModalVisible({
      success,
      error: !success
    });

    setIsLoading(false);
  };

  return (
    <>
      <LoadingModal loading={isLoading} customMessage={customMessage} />
      <Button onClick={readVitalCard} type="primary" icon={<IdcardOutlined />}>
        {t(`${translationKey}.read_vitale_card`)}
      </Button>
    </>
  );
};

ReadVitaleCardButton.propTypes = {
  setIsModalVisible: PropTypes.func.isRequired
};
