import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Col, Dropdown, Input, Row, Switch } from 'antd';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ExportButton } from '../ExportButton/ExportButton';
import { ImportButton } from '../importButton';
import { Datatable } from '../DataTable/Datatable';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { ContentCustom } from '../ContentCustom/ContentCustom';

const { Search } = Input;

export const ListResource = ({
  isOriginalContentCustom,
  isPageHeaderCustom,
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  withCreateButton,
  withUploadButton,
  withImportButton,
  forceRefresh,
  resourceModelName,
  editAction,
  showAction,
  duplicateAction,
  deleteAction,
  archiveAction,
  onDoubleClickAction,
  withSearchBar,
  withArchiveButton,
  scroll,
  expandable,
  path,
  rowKey,
  formatter,
  isInConsultation,
  isWaitingForExit,
  dataSource,
  disabled
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);
  const [isArchived, setIsArchived] = useState(false);

  const searchResource = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const menu = {
    items: [
      ...(headers
        ? [
            {
              key: 'export',
              label: (
                <ExportButton
                  dataName={resourceName}
                  headers={headers}
                  url={`/${exportUrl || resourceName}`}
                  fileName={`${resourceName}.csv`}
                  populate={populate}
                  extraQuery={extraQuery}
                  formatter={formatter}
                />
              )
            }
          ]
        : []),
      ...(withImportButton
        ? {
            key: 'import',
            label: <ImportButton resourceName={resourceModelName} />
          }
        : [])
    ]
  };
  return (
    <>
      {isPageHeaderCustom ? (
        <PageHeaderCustom
          title={t(`${tradKey || resourceName}.title`)}
          extra={extraHeader}
          withoutBreadCrumbs
        />
      ) : null}
      <ContentCustom>
        {isOriginalContentCustom ? (
          <Row justify="space-between" gutter={[8, 16]}>
            <Col>
              {withSearchBar && (
                <Search
                  allowClear
                  placeholder={t('placeholder.search')}
                  defaultValue={searchValue}
                  onSearch={(value) => searchResource(value)}
                  disabled={disabled}
                />
              )}
            </Col>
            {!withCreateButton && headers && (
              <Col>
                <Dropdown menu={menu}>
                  <Button type="link">
                    <MenuOutlined
                      style={{ fontSize: 16, color: 'var(--textColor)' }}
                    />
                  </Button>
                </Dropdown>
              </Col>
            )}
            {withCreateButton ? (
              <Col>
                <Row gutter={[16, 16]} align="middle">
                  {extraButtons}
                  <Col>
                    {withArchiveButton && (
                      <Switch
                        checkedChildren={t('buttons.archived')}
                        unCheckedChildren={t('buttons.archived')}
                        onChange={() => setIsArchived(!isArchived)}
                      />
                    )}
                  </Col>
                  <Link to={withCreateButton?.path || `${pathname}/create`}>
                    <Button type="add" disabled={disabled}>
                      {withCreateButton?.buttonText || `${t('buttons.create')}`}
                      &nbsp;
                      {withCreateButton?.buttonIcon || <PlusOutlined />}
                    </Button>
                  </Link>
                  {withUploadButton && (
                    <Dropdown menu={menu}>
                      <Button type="link">
                        <MenuOutlined
                          style={{ fontSize: 16, color: 'var(--textColor)' }}
                        />
                      </Button>
                    </Dropdown>
                  )}
                </Row>
              </Col>
            ) : (
              <Row>{extraButtons}</Row>
            )}
          </Row>
        ) : (
          <Row>{extraButtons}</Row>
        )}
        <Row gutter={[8, 16]}>{children}</Row>
        <Datatable
          style={{ marginTop: 24 }}
          resourceName={dataToFetch || resourceName}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          editAction={editAction}
          archiveAction={archiveAction}
          showAction={showAction}
          isArchived={isArchived}
          duplicateAction={duplicateAction}
          deleteAction={deleteAction}
          onDoubleClickAction={onDoubleClickAction}
          scroll={scroll || { x: 1200 }}
          expandable={expandable}
          path={path}
          rowKey={rowKey}
          isInConsultation={isInConsultation}
          isWaitingForExit={isWaitingForExit}
          dataSource={dataSource}
        />
      </ContentCustom>
    </>
  );
};

ListResource.propTypes = {
  resourceName: PropTypes.string,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.element,
  isOriginalContentCustom: PropTypes.bool,
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withSearchBar: PropTypes.bool,
  withArchiveButton: PropTypes.bool,
  withCreateButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      path: PropTypes.string,
      buttonText: PropTypes.string,
      buttonIcon: PropTypes.element
    })
  ]),
  isPageHeaderCustom: PropTypes.bool,
  withUploadButton: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  resourceModelName: PropTypes.string,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  archiveAction: PropTypes.shape({
    pathname: PropTypes.string,
    customAction: PropTypes.func
  }),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  formatter: PropTypes.func,
  isInConsultation: PropTypes.bool,
  isWaitingForExit: PropTypes.bool,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  withImportButton: PropTypes.bool
};

ListResource.defaultProps = {
  resourceName: null,
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraButtons: null,
  exportUrl: null,
  populate: null,
  withSearchBar: true,
  withArchiveButton: true,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  dataToFetch: null,
  forceRefresh: null,
  resourceModelName: null,
  editAction: true,
  showAction: true,
  duplicateAction: false,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  archiveAction: null,
  expandable: undefined,
  path: null,
  rowKey: '_id',
  formatter: undefined,
  isPageHeaderCustom: true,
  isOriginalContentCustom: true,
  isInConsultation: false,
  isWaitingForExit: false,
  dataSource: null,
  disabled: false,
  withImportButton: false
};
