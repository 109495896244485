import PropTypes from 'prop-types';
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { usePatientMethods } from '../../methods';
import { usePatientContext } from '../../../../contexts/PatientContext/PatientContext';
import { LoadingModal } from '../../../../components/Modals/LoadingModal';

/**
 * ExitButton component.
 * Renders a button to exit a patient.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.record - The patient record.
 * @param {string} props.record._id - The ID of the patient.
 * @returns {JSX.Element} The ExitButton component.
 */
export const ExitButton = ({ record }) => {
  const { _id } = record;
  const { t } = useTranslation();
  const { datePicking } = usePatientContext();
  const { exitPatient } = usePatientMethods(datePicking);

  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await exitPatient(_id);
    setLoading(false);
  };

  return (
    <>
      <Spin spinning={loading}>
        <Button
          style={{
            borderRadius: '15px'
          }}
          icon={<ArrowRightOutlined />}
          onClick={handleClick}
        >
          {t('patients.form.patient_exit_button')}
        </Button>
      </Spin>
      <LoadingModal
        loading={loading}
        customMessage={t('modals.exiting_patient')}
      />
    </>
  );
};

ExitButton.propTypes = {
  record: PropTypes.shape({
    _id: PropTypes.string
  })
};

ExitButton.defaultProps = {
  record: {
    _id: ''
  }
};
