import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Tabs } from 'antd';
import { ContentCustom } from '../../components';
import { ListVisitReasons } from './visitReasons/ListVisitReasons';
import { ListStayPrescriptions } from './configPrescriptions/ListStayPrescriptions';
import { ListProtocol } from './protocols/ListProtocol';
import { ListSurgicalHistory } from './surgicalHistoric/ListSurgicalHistory';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { useDataBasesContext } from '../../contexts/DataBasesContext';
// import { ListDirectories } from './directories/ListDirectories';

/**
 * React component for displaying a list of data from various databases.
 *
 * @returns {JSX.Element} - The rendered React element.
 */
export const ListDataBase = () => {
  const { t } = useTranslation();
  const { activeKey, setActiveKey, resetPrescriptionState } =
    useDataBasesContext();

  useEffect(() => {
    resetPrescriptionState();
  }, []);

  // Configuration for the Tabs component, specifying keys, labels, and corresponding child components
  const items = [
    {
      key: 'visitReasons',
      label: t('data_bases.visit-reasons.title'),
      children: <ListVisitReasons />
    },
    {
      key: 'stayPrescriptions',
      label: t('data_bases.stay_prescriptions.title'),
      children: <ListStayPrescriptions />
    },
    {
      key: 'protocol',
      label: t('data_bases.protocols.title'),
      children: <ListProtocol />
    },
    {
      key: 'surgicalHistoric',
      label: t('data_bases.surgical_history.title'),
      children: <ListSurgicalHistory />
    }
    // TODO: waiting client decision
    // {
    //   key: 'directories',
    //   label: t('data_bases.directories.title'),
    //   children: <ListDirectories />
    // }
  ];

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('data_bases.title')} />
      <Tabs
        activeKey={activeKey}
        items={items}
        onChange={(key) => setActiveKey(key)}
      />
    </ContentCustom>
  );
};
