/* eslint-disable import/no-extraneous-dependencies */
// This rule is disabled because it is just a bug with eslint on MacOS
// The react-color is well installed in the dev dependencies
// and it is working good
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input, Popover } from 'antd';
import { CirclePicker } from 'react-color';

/**
 * Represents a cell in the ConsultingRoomsEditableTable component that can be edited.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.editing - Indicates whether the cell is in editing mode.
 * @param {string} props.dataIndex - The data index of the cell.
 * @param {string} props.inputType - The type of input for the cell. (ex: 'maximum_capacity', 'color')
 * @param {string} props.color - The color of the cell. (ex: '#000000')
 * @param {string} props.colorPicked - The color picked by the user. (ex: '#000000')
 * @param {Function} props.setColorPicked - The function to set the color picked by the user.
 * @param {ReactNode} props.children - The children of the cell.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const ConsultingRoomsEditableCell = ({
  editing,
  dataIndex,
  inputType,
  color,
  colorPicked,
  setColorPicked,
  children,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  let inputNode;

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  switch (inputType) {
    case 'maximum_capacity':
      inputNode = <Input type="number" />;
      break;
    case 'color':
      inputNode = (
        <Popover
          content={
            <CirclePicker
              onChange={(selectedColor) => {
                setColorPicked(selectedColor.hex);
                hide();
              }}
            />
          }
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: colorPicked || color
            }}
          />
        </Popover>
      );
      break;
    default:
      inputNode = <Input />;
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: t('warnings.message.fill')
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

ConsultingRoomsEditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  inputType: PropTypes.string,
  color: PropTypes.string,
  colorPicked: PropTypes.string,
  setColorPicked: PropTypes.func,
  children: PropTypes.node.isRequired
};

ConsultingRoomsEditableCell.defaultProps = {
  editing: false,
  dataIndex: '',
  inputType: '',
  color: '',
  colorPicked: '',
  setColorPicked: () => {}
};
