import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { PatientContext } from '../../../../contexts/PatientContext/PatientContext';

/**
 * Custom hook that provides fields and functions related to treatment prescriptions.
 * @hook
 * @param {Object} options - The options object.
 * @param {Array} options.filesList - The list of files.
 * @param {Function} options.setFilesList - The function to set the list of files.
 * @param {boolean} options.submittable - Indicates if the form is submittable.
 * @param {Object} options.form - The form object.
 * @returns {Object} - The treatment prescriptions fields.
 */
export const useFields = ({ filesList, setFilesList, submittable, form }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { id: patientId } = useParams();
  const { message } = useErrorMessage();
  const { getPatient } = useContext(PatientContext);
  const { id } = useParams();

  /**
   * Submits the treatment prescriptions.
   * @param {Object} values - The form values.
   * @param {File} file - The file to be uploaded.
   * @returns {void}
   */
  const submitTreatmentPrescriptions = async (values, file) => {
    try {
      form.resetFields();
      const formData = new FormData();
      const { doctors_letter, ...rest } = values;

      formData.append('treatment_prescriptions', file);
      formData.append('values', JSON.stringify(rest));

      await dispatchAPI('POST', {
        url: `treatment-prescriptions/${patientId}`,
        body: formData
      });
      await getPatient(id);
    } catch (error) {
      message(error);
    }
  };

  const uploadProps = {
    beforeUpload: async (file) => {
      setFilesList([
        ...filesList,
        {
          date: dayjs(),
          general_practitioner: form.getFieldValue('general_practitioner'),
          name: file.name,
          file
        }
      ]);

      const values = form.getFieldsValue();
      await submitTreatmentPrescriptions(values, file);
      return false;
    },
    showUploadList: false
  };

  const treatmentPrescriptionsFields = [
    {
      label: 'doctor',
      name: 'doctor',
      rules: [{ required: true }]
    },
    {
      noLabel: true,
      name: 'treatment_prescriptions',
      input: (
        <Upload {...uploadProps}>
          <Button
            disabled={!submittable}
            type="primary"
            icon={<UploadOutlined />}
          >
            {t('buttons.add_treatment_prescription')}
          </Button>
        </Upload>
      )
    }
  ];

  return { treatmentPrescriptionsFields };
};
