import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu, Tooltip } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/handleResize';
import {
  secretaryRoutes,
  doctorRoutes,
  subRoutes,
  pathSearches,
  userRoutes
} from '../../utils/constants/userRoutes';

/**
 * User navigation menu component.
 * Renders a menu with navigation links based on the user's position.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setCollapsed - Function to set the collapsed state of the menu.
 * @param {boolean} props.label - Flag indicating whether to display labels in the menu.
 * @returns {JSX.Element} The rendered UserNavMenu component.
 */
export const UserNavMenu = ({ setCollapsed, label }) => {
  const { user } = useAuthContext();
  const { position, landing_choice } = user || {};
  const { t } = useTranslation();
  const location = useLocation();
  const { width } = useHandleResize();

  const path = location?.pathname || '/';

  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  let routes;
  if (position) {
    if (position === 'SECRETARY') {
      const newRoutes = { ...secretaryRoutes };
      delete newRoutes.PATIENTS;
      delete newRoutes.CONSULTATIONS;

      delete newRoutes[landing_choice];

      routes = newRoutes;
    } else if (position === 'DOCTOR' || position === 'NURSE') {
      const newRoutes = { ...doctorRoutes };
      delete newRoutes.PATIENTS;
      delete newRoutes.CONSULTATIONS;

      delete newRoutes[landing_choice];

      routes = newRoutes;
    } else {
      routes = userRoutes;
    }
  }

  return (
    <Menu
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="horizontal"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      items={Object.entries(routes).map(([menuItem, p]) =>
        subRoutes[menuItem]
          ? {
              key: p,
              icon: navMenuLogos[menuItem],
              label: t(`basiclayout.menu.${menuItem.toLowerCase()}`),
              children: Object.entries(subRoutes[menuItem]).map(
                ([subMenuItem, subPath]) => ({
                  key: subPath,
                  disabled: subPath === '',
                  label: (
                    <NavLink
                      to={`${p}${subPath}${
                        pathSearches[subMenuItem]
                          ? pathSearches[subMenuItem]
                          : ''
                      }`}
                    >
                      <span>
                        {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                      </span>
                    </NavLink>
                  )
                })
              )
            }
          : {
              key: p,
              disabled: p === '',
              label: (
                <NavLink
                  to={`${p}${
                    pathSearches[menuItem] ? pathSearches[menuItem] : ''
                  }`}
                >
                  <Tooltip
                    title={t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
                  >
                    {navMenuLogos[menuItem]}
                  </Tooltip>
                  {label ? (
                    <span>
                      {t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
                    </span>
                  ) : null}
                </NavLink>
              )
            }
      )}
    />
  );
};

UserNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired,
  label: PropTypes.bool.isRequired
};
