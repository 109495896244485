import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateUpdateDirectory } from './CreateUpdateDirectory';
import { ListDirectory } from './ListDirectory';
import { Exception } from '../../components';

/**
 * Renders a router component for managing directory routes.
 * This component utilizes React Router's Routes and Route components to define directory routes.
 *
 * @component
 * @returns {JSX.Element} The rendered router component for managing directory routes.
 */
export const DirectoryRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateDirectory purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateDirectory purpose="edit" />}
    />
    <Route path="/" element={<ListDirectory withContentCustom />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
