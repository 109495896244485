import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource, ContentCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useColumns } from './columns';
import { Company } from '../../models/Company';
import { Directory } from '../../models/Directory';
import { headers } from './headers';

/**
 * Renders a component for listing directory entries.
 * This component utilizes a ListResource component for displaying directory data.
 *
 * @component
 *
 * @param {object} props Component props.
 * @param {boolean} [props.withContentCustom=false] Determines if the content is custom.
 * @param {boolean} [props.isPageHeaderCustom=true] Determines if the page header is custom.
 * @param {boolean} [props.withCreateButton=null] Determines if the create button is displayed.
 * @param {boolean} [props.customEdit=false] Determines if the edit button is custom.
 * @returns {JSX.Element} The rendered component for listing directory entries.
 */
export const ListDirectory = ({
  withContentCustom,
  isPageHeaderCustom,
  withCreateButton,
  customEdit
}) => {
  const { user } = useAuthContext();
  const { t } = useTranslation();

  const [centers, setCenters] = useState([]);
  const [targetIds, setTargetIds] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);

  const columns = useColumns();

  const companyId = user?.associated_company?._id;
  const selectedCenter = user?.selected_center;
  const extraQuery = selectedCenter ? `center=${selectedCenter}` : '';
  const resource = 'directories';

  const importDirectory = async ({ key }) => {
    await Directory.importDirectory(selectedCenter, { directory: key });
    setForceRefresh(!forceRefresh);
  };

  const getImportingPermission = async () => {
    if (targetIds.length) {
      const response = await Directory.getImportingPermission(
        selectedCenter,
        targetIds
      );
      setPermissions(response);
    }
  };

  useEffect(() => {
    (async () => {
      await getImportingPermission();
    })();
  }, [targetIds, forceRefresh]);

  useEffect(() => {
    (async () => {
      if (companyId) {
        const response = await Company.getCompany(companyId, {
          populate: 'centers'
        });
        const otherCenters = response.centers.filter(
          (center) => center?._id !== selectedCenter
        );
        const filterTargetIds = otherCenters.flatMap((center) => center?._id);
        setCenters(otherCenters);
        setTargetIds(filterTargetIds);
      }
    })();
  }, []);

  const items = centers?.map((center) => ({
    onclick: () => importDirectory(center?._id),
    label: (
      <Tooltip
        title={
          permissions[center?._id] &&
          t('directories.form.directory_already_imported')
        }
      >
        {center?.name}
      </Tooltip>
    ),
    key: center?._id,
    disabled: permissions[center?._id]
  }));

  const menu = {
    onClick: importDirectory,
    items
  };

  const extraButtons = [
    <Dropdown menu={menu}>
      <Button>Importer annuaire</Button>
    </Dropdown>
  ];

  const props = {
    'users:SUPER-USER': {
      forceRefresh,
      resourceName: resource,
      resourceModelName: 'Directory',
      columns,
      extraQuery,
      showAction: false,
      withArchiveButton: false,
      archiveAction: true,
      isPageHeaderCustom,
      headers,
      withUploadButton: true,
      withCreateButton: withCreateButton ? { path: 'directory/create' } : true,

      path: customEdit ? 'directory' : null,
      extraButtons
    },
    'admins:SUPER-ADMIN': {
      resource,
      resourceName: resource,
      path: resource,
      columns,
      isPageHeaderCustom: false,
      isOriginalContentCustom: false,
      showAction: false,
      archiveAction: false,
      editAction: false
    }
  };

  const ListComponent = (
    <ListResource onDoubleClickAction={false} {...props[user?.role]} />
  );

  return (
    <>
      {withContentCustom && <ContentCustom>{ListComponent}</ContentCustom>}
      {!withContentCustom && ListComponent}
    </>
  );
};

ListDirectory.propTypes = {
  withContentCustom: PropTypes.bool,
  isPageHeaderCustom: PropTypes.bool,
  withCreateButton: PropTypes.bool,
  customEdit: PropTypes.bool
};

ListDirectory.defaultProps = {
  withContentCustom: false,
  isPageHeaderCustom: true,
  withCreateButton: null,
  customEdit: false
};
