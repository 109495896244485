/**
 * Custom hook to handle the configuration for creating or updating a prescription.
 *
 * @hook
 * @param {Object} props - The component props.
 * @param {Function} props.setPrescriptionWording - Function to set the prescription wording.
 * @param {Function} props.setCategory - Function to set the prescription category.
 * @param {Function} props.setOptions - Function to set the prescription options.
 * @param {Function} props.setWithOptions - Function to set whether prescription has options.
 * @param {Object} props.optimistOptions - The optimist options.
 * @returns {Object} An object containing the configuration for creating or updating a prescription.
 * @property {Object} config - The configuration object.
 * @property {Object} config.onGetResource - Configuration for handling resource data on getting.
 * @property {Function} config.onGetResource.setFields - Function to set fields based on the resource data.
 * @param {Object} data - The resource data received from the API.
 *
 * @returns {Object} The resource data after setting fields.
 *
 * @example
 * const { config } = useCreateUpdateConfig({
 *   setPrescriptionWording,
 *   setCategory,
 *   setOptions,
 *   setWithOptions,
 *   optimistOptions
 * });
 */
export const useCreateUpdateConfig = ({
  setPrescriptionWording,
  setCategory,
  setOptions,
  setWithOptions,
  options,
  optimistOptions,
  setPrescriptionType
}) => {
  const config = {
    /**
     * Configuration object for creating or updating a prescription.
     *
     * @type {Object}
     * @property {Object} onGetResource - Configuration for handling resource data on getting.
     * @property {Function} onGetResource.setFields - Function to set fields based on the resource data.
     * @param {Object} data - The resource data received from the API.
     * @returns {Object} The resource data after setting fields.
     */
    onGetResource: {
      setFields: (data) => {
        setPrescriptionWording(data?.prescription_wording);
        setCategory(data?.category);
        setPrescriptionType(data?.prescription_type);
        setOptions(data?.options);
        setWithOptions(!!data?.options.length);
        return data;
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        options: optimistOptions
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        options: [...optimistOptions, ...options]
      })
    }
  };

  return { config };
};
