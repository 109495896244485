import { Route, Routes } from 'react-router-dom';
import Profile from './Profile';
import UpdateProfile from './UpdateProfile';
import UpdatePwd from './UpdatePwd';
import { Exception } from '../../components';

/**
 * Renders the profile router component.
 * @returns {JSX.Element} The profile router component.
 */
export const ProfileRouter = () => (
  <Routes>
    <Route path="/edit/:id" element={<UpdateProfile />} />
    <Route path="/change-pwd" element={<UpdatePwd />} />
    <Route index element={<Profile />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
