import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, Row } from 'antd';
import { StayPrescriptionsCard } from './cards/StayPrescriptionsCard';
import { ExitPrescriptionsCard } from './cards/ExitPrescriptionsCard';
import { RadiologyPrescriptionCard } from './cards/RadiologyPrescriptionCard';

/**
 * @component Prescriptions
 * @returns {React.Component} Card with buttons to create prescriptions
 */
export const Prescriptions = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const translationKey = 'consultations.prescriptions.buttons';

  return (
    <Card
      style={{ height: 'fit-content' }}
      title={t('consultations.prescriptions.title')}
    >
      <Row gutter={[16, 16]}>
        <StayPrescriptionsCard translationKey={translationKey} id={id} t={t} />
        <ExitPrescriptionsCard translationKey={translationKey} id={id} t={t} />
        <RadiologyPrescriptionCard
          translationKey={translationKey}
          id={id}
          t={t}
        />
      </Row>
    </Card>
  );
};
