import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Table, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useColumns } from './columns';
import { ConsultingRoomsEditableCell } from './ConsultingRoomsEditableCell';

/**
 * Represents a table component for displaying and managing consulting rooms.
 *
 * @component
 * @param {boolean} refresh - Indicates whether the table should be refreshed.
 * @param {function} setRefresh - A function to set the refresh state of the table.
 * @param {string} id - The ID of the consulting room.
 * @returns {JSX.Element} The rendered table component.
 */
export const ConsultingRoomsTable = ({ refresh, setRefresh, id }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const [rooms, setRooms] = useState([]);
  const [colorPicked, setColorPicked] = useState('');
  const [editingKey, setEditingKey] = useState('');

  const getConsultingRooms = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/rooms/consulting_room/${id}`
    });
    setRooms(data);
  };

  useEffect(() => {
    (async () => {
      await getConsultingRooms();
    })();
  }, [refresh]);

  const save = async (_id) => {
    try {
      const body = form.getFieldsValue();
      await dispatchAPI('PATCH', {
        url: `/rooms/${_id}`,
        body: {
          ...body,
          color: colorPicked || body.color
        }
      });
      setRefresh(!refresh);
      setEditingKey('');
    } catch (e) {
      message.error(t('errors.message.save'));
    }
  };

  const del = async (type, _id) => {
    try {
      await dispatchAPI('DELETE', { url: `/rooms/${type}/${_id}` });
      setRefresh(!refresh);
    } catch (e) {
      message.error(t('errors.message.delete'));
    }
  };

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      title: '',
      color: '',
      maximum_capacity: '',
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const columns = useColumns({ isEditing, save, cancel, edit, del });
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        color: record.color,
        editing: isEditing(record),
        colorPicked,
        setColorPicked
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: ConsultingRoomsEditableCell
          }
        }}
        bordered
        dataSource={rooms}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel
        }}
      />
    </Form>
  );
};

ConsultingRoomsTable.propTypes = {
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  id: PropTypes.string
};

ConsultingRoomsTable.defaultProps = {
  refresh: false,
  setRefresh: () => {},
  id: ''
};
