import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Col, Form, Button, Modal } from 'antd';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { ConsultationsContext } from '../../ConsultationsContext';
import { formItemLayoutCustom } from '../../../../utils/constants/formLayout';
import { usePostConstantsSurvey } from '../../Components/postConstants-survey';
import { getFullName } from '../../../../utils/formatters';
import { NegativeUrineTestButton } from './NegativeUrineTestButton';

/**
 * ConstantsAndOtherSurveyModal component
 * @component
 * @param {Array} fields - The fields to be displayed in the form.
 * @param {string} resource - The resource to be used for translation.
 * @param {string} title - The title of the modal.
 * @param {string} idPatient - The ID of the patient.
 * @param {object} form - The form instance object.
 * @returns {JSX.Element} The rendered component.
 */
export const ConstantsAndOtherSurveyModal = ({
  fields,
  resource,
  title,
  idPatient,
  form
}) => {
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const [isModalLocallyOpen, setIsModalLocallyOpen] = useState(true);
  const { patient } = useContext(ConsultationsContext) || {};
  const { _id } = patient?.current_consultation || {};

  const { handleSubmit } = usePostConstantsSurvey(
    setIsModalLocallyOpen,
    idPatient,
    _id
  );

  useEffect(() => {
    if (patient && patient.constants_survey) {
      form.setFieldsValue({
        ...patient.constants_survey
      });
    }
  }, [patient]);

  return (
    <Modal
      open={isModalLocallyOpen}
      onCancel={() => setIsModalLocallyOpen(false)}
      okText={t('buttons.validate')}
      width="50%"
      footer={null}
    >
      <Flex justify="start">
        <h3>
          {getFullName(patient)} - {t(`consultations.form.${title}`)}
        </h3>
      </Flex>
      <Form form={form} onFinish={handleSubmit} {...formItemLayoutCustom}>
        <Flex>
          <Col span={24}>
            {fields.map((field) => generateFields(resource, field))}
            {title === 'urinary_analysis' && (
              <NegativeUrineTestButton form={form} />
            )}
          </Col>
        </Flex>
        <Flex gap={8} justify="end">
          <Button
            style={{
              borderRadius: '15px'
            }}
            onClick={() => setIsModalLocallyOpen(false)}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            htmlType="submit"
            style={{
              borderRadius: '15px'
            }}
          >
            {t('buttons.register')}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

ConstantsAndOtherSurveyModal.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  resource: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  idPatient: PropTypes.string.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired
};
ConstantsAndOtherSurveyModal.defaultProps = {
  fields: []
};
