/**
 * Generates a list of medication information for a given patient.
 * @function
 *
 * @param {Object} patient - The patient object.
 * @returns {Array} - An array of medication information objects.
 */
export const listContent = (patient) =>
  patient?.current_medications?.map((medication) => ({
    brandname: medication.brandname,
    dose: medication.dose,
    color: medication.color,
    allergic: medication.allergic,
    ingredients: medication.ingredients
  }));
