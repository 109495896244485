import { Card, Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * A component that displays an exit prescriptions card.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.translationKey - The key to be used for translation.
 * @param {Function} props.t - The translation function.
 * @param {string} props.id - The id of the prescription.
 *
 * @returns {JSX.Element} The ExitPrescriptionsCard component.
 */
// TODO : WIP
const { Text } = Typography;

export const ExitPrescriptionsCard = ({ translationKey, t, id }) => (
  <Col span={8}>
    <Link to={`/prescription/prescription-choice/EXIT/${id}`}>
      <Card bordered={false} style={{ background: '#8AC7FF66' }} hoverable>
        <Row justify="center">
          <div>
            <svg
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 21V3H15V21H10V16H6V21H1ZM5 13H7V11H5V13ZM5 9H7V7H5V9ZM9 13H11V11H9V13ZM9 9H11V7H9V9ZM19.5 15.5L18.1 14.1L19.175 13H16V11H19.175L18.1 9.9L19.5 8.5L23 12L19.5 15.5Z"
                fill="#004A8F"
              />
            </svg>
          </div>
        </Row>
        <Row justify="center">
          <Text strong style={{ color: '#004A8F' }}>
            {t(`${translationKey}.exit`)}
          </Text>
        </Row>
      </Card>
    </Link>
  </Col>
);

ExitPrescriptionsCard.propTypes = {
  translationKey: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};
