import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { PageHeaderCustom } from '../PageHeader/PageHeader';

/**
 * Represents the header component of the FreeTextPrescriptionForm.
 * @component
 *
 * @param {boolean} showLabelInput - Determines whether to show the label input field.
 * @param {string} headerTitle - The title to be displayed in the header.
 * @param {string} title - The title to be displayed in the header if headerTitle is not provided.
 * @param {string} translationKey - The translation key used for localization.
 * @param {function} setLabel - A function to set the label value.
 * @returns {JSX.Element} - The rendered Header component.
 */
export const Header = ({
  showLabelInput,
  headerTitle,
  title,
  translationKey,
  setLabel
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={headerTitle || title} />
      {showLabelInput && (
        <Form.Item name="label" nolabel="true" rules={[{ required: true }]}>
          <Input
            placeholder={t(`${translationKey}.free_text_label`)}
            onChange={(e) => setLabel(e.target.value)}
          />
        </Form.Item>
      )}
    </>
  );
};

Header.propTypes = {
  showLabelInput: PropTypes.bool,
  headerTitle: PropTypes.string,
  title: PropTypes.string,
  translationKey: PropTypes.string,
  setLabel: PropTypes.func
};

Header.defaultProps = {
  showLabelInput: false,
  headerTitle: '',
  title: '',
  translationKey: '',
  setLabel: () => {}
};
