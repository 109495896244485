import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Form, Select, notification } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const { Item } = Form;

/**
 * React component for rendering a SelectDiagnostic component.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} [props.dbKey] - The key used in the Form.Item name (optional).
 * @returns {JSX.Element} The rendered React element.
 */
export const SelectDiagnostic = ({ dbKey }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState('');
  const [concepts, setConcept] = useState([]);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (counter === 1)
      notification.warning({
        message: t('errors.message.smt_502')
      });
  }, [counter]);

  /**
   * Function to search for concepts based on the provided text.
   *
   * @async
   * @function
   * @param {string} newValue - The text to search for concepts.
   * @returns {Promise<void>} A Promise that resolves when the search is complete.
   */
  const handleSearchConcept = async (newValue) => {
    if (newValue.length >= 3) {
      await axios
        .post(
          `https://smt.esante.gouv.fr/api/concepts/search?searchedText=${newValue}&pagination=1&size=10&lang=fr&terminologyFilters=terminologie-cim11-mms`
        )
        .then((data) => {
          setConcept(data?.data?.concepts);
        })
        .catch((e) => {
          if (e) setCounter((prevCount) => prevCount + 1);
        });
    }
    if (newValue.length < 3) {
      setConcept([]);
    }
  };

  return (
    <Item name={['consultation', `${dbKey}`]}>
      <Select
        mode={!concepts.length ? 'tags' : 'multiple'}
        onSearch={handleSearchConcept}
        onChange={(keyword) => setValue(keyword)}
        style={{ width: 400 }}
        value={value}
        notFoundContent={null}
        options={concepts?.map((item) => ({
          value: item.prefLabel,
          label: item.prefLabel
        }))}
      />
    </Item>
  );
};
SelectDiagnostic.propTypes = {
  dbKey: PropTypes.string
};
SelectDiagnostic.defaultProps = {
  dbKey: undefined
};
