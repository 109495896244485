import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useConsultationsContext } from '../../ConsultationsContext';

const { Item } = Form;

export const SelectRoom = ({ form = {} }) => {
  const { t } = useTranslation();
  const { centerRooms, selectedVisitValue } = useConsultationsContext();

  const conditions = {
    FIRST_VISIT: () => [
      {
        label: centerRooms?.first_visit_waiting_room?.title,
        value: centerRooms?.first_visit_waiting_room?._id
      }
    ],
    FOLLOW_UP_VISIT: () => [
      {
        label: centerRooms?.follow_up_waiting_room?.title,
        value: centerRooms?.follow_up_waiting_room?._id
      }
    ],
    RETURN_VISIT: () => [
      {
        label: centerRooms?.return_visit_waiting_room?.title,
        value: centerRooms?.return_visit_waiting_room?._id
      }
    ]
  };
  const options = (conditions[selectedVisitValue] || (() => []))();

  useEffect(() => {
    form.setFieldValue('waiting_room', options[0]?.value);
  }, [selectedVisitValue]);

  return (
    <Item name={['waiting_room']}>
      <Select
        disabled
        style={{
          width: '100%'
        }}
        placeholder={t('consultations.form.multiple_select_placeholder')}
        options={options}
      />
    </Item>
  );
};

SelectRoom.propTypes = {
  form: PropTypes.shape().isRequired
};
