import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ConstantsAndOtherSurveyModal } from './ConstantsAndOtherSurveyModal';
import { ConsultationsContext } from '../../ConsultationsContext';
import { useRenderContent } from './useRenderContent';
// import { ModalChart } from './ModalChart'; I commented this out because it can't be used with the new changes.

/**
 * ConstantsAndOtherSurvey component
 * @component
 * @param {string} resource - The resource to be used for translation.
 * @param {Array} fields - The fields to be displayed in the form.
 * @param {string} title - The title of the modal.
 * @param {string} idPatient - The ID of the patient.
 * @param {object} form - The form instance object.
 * @returns {JSX.Element} The rendered component.
 */
export const ConstantsAndOtherSurvey = ({
  resource,
  fields,
  title,
  idPatient,
  form
}) => {
  const { patient: { constants_survey } = {} } =
    useContext(ConsultationsContext);
  const { t } = useTranslation();
  const [modal, setModal] = useState(null);
  const content = useRenderContent({ title, constants_survey });

  const handleClick = () => {
    setModal(
      <ConstantsAndOtherSurveyModal
        key={new Date()} // Force the modal to re-render with the proper data
        fields={fields}
        resource={resource}
        title={title}
        idPatient={idPatient}
        form={form}
      />
    );
  };

  return (
    <>
      {modal}
      <Row justify="space-between">
        <h3>{t(`consultations.form.${title}`)}</h3>
        <Button type="link" icon={<EditOutlined />} onClick={handleClick} />
      </Row>
      <Row style={{ marginBottom: 16 }}>{content}</Row>
    </>
  );
};

ConstantsAndOtherSurvey.propTypes = {
  resource: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
  idPatient: PropTypes.string.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired
};
ConstantsAndOtherSurvey.defaultProps = {
  fields: []
};
