import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateUpdatePatient } from './CreateUpdatePatient';
import { ShowPatient } from './ShowPatient';
import { ListPatients } from './listPatients';
import { SecretaryDoctorView } from './SecretaryDoctorView';
import { PatientContextProvider } from '../../contexts/PatientContext/PatientContext';

export const PatientRouter = () => (
  <PatientContextProvider>
    <Routes>
      <Route
        path="/create/*"
        element={<CreateUpdatePatient purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdatePatient purpose="edit" />}
      />
      <Route path="/rooms" element={<ListPatients />} />
      <Route path="/view" element={<SecretaryDoctorView />} />
      <Route path="/show/:id" element={<ShowPatient />} />
      <Route path="/" element={<ListPatients />} />
    </Routes>
  </PatientContextProvider>
);
