import { Collapse, Col } from 'antd';
import PropTypes from 'prop-types';
import { TreatmentPrescriptionsForm } from './TreatmentPrescriptionsForm';

const { Panel } = Collapse;

/**
 * Renders a collapsible panel for treatment prescriptions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.header - The header text for the collapsible panel.
 * @param {Array} props.filesList - The list of files.
 * @param {Function} props.setFilesList - The function to update the files list.
 * @returns {JSX.Element} The rendered component.
 */
export const CollapseTreatmentPrescriptions = ({
  header,
  filesList,
  setFilesList
}) => (
  <Col span={24}>
    <Collapse expandIconPosition="end" className="patient-form-collapse">
      <Panel header={header} key="1">
        <TreatmentPrescriptionsForm
          filesList={filesList}
          setFilesList={setFilesList}
        />
      </Panel>
    </Collapse>
  </Col>
);

CollapseTreatmentPrescriptions.propTypes = {
  header: PropTypes.string.isRequired,
  filesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFilesList: PropTypes.func.isRequired
};
