/**
 * Custom hook that returns two dropdown lists for fields.
 * @hook
 *
 * @param {string} author - The author of the report.
 * @returns {Object} - An object containing two dropdown lists: reportListDropDown and administrativeCommentsListDropdown.
 */
const useFields = (author) => {
  const administrativeCommentsListDropdown = [
    {
      noLabel: true,
      name: 'written_by',
      rules: [{ required: false }]
    },
    {
      label: 'description',
      name: 'description',
      rules: [{ required: false }]
    }
  ];

  const reportListDropDown = [
    {
      label: 'report',
      name: 'reported',
      rules: [{ required: false }]
    },
    {
      noLabel: true,
      name: 'written_by',
      rules: [{ required: false }],
      initialValue: author || '-'
    },

    {
      label: 'comment',
      name: 'reported_comment_reception',
      rules: [{ required: false }]
    }
  ];
  return { reportListDropDown, administrativeCommentsListDropdown };
};

export default useFields;
