import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';

const DoubleFactorAuthForm = ({ switchForm }) => {
  const { dispatchAPI, user, chooseCenter } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const loginUser = async (login_code, rememberMe) => {
    setLoading(true);
    try {
      await dispatchAPI('LOGIN2FA', {
        login_code,
        user,
        rememberMe
      });
    } catch (e) {
      if (e.response) {
        message.error(t('login.error'));
      }
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    await loginUser(values.login_code, values.rememberMe);
  };

  useEffect(() => {
    if (chooseCenter) switchForm('chooseCenter');
  }, [chooseCenter]);

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item
        name="login_code"
        rules={[{ required: true, message: t('login.loginCodeMissing') }]}
      >
        <Input
          type="number"
          prefix={
            <LockOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.loginCode')}
        />
      </Form.Item>
      <Form.Item name="remember" valuePropName="checked" noStyle>
        <Checkbox>{t('login.rememberMe')}</Checkbox>
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={isLoading}>
        {t('login.connect')}
      </Button>
    </Form>
  );
};

export default DoubleFactorAuthForm;

DoubleFactorAuthForm.propTypes = {
  switchForm: PropTypes.func.isRequired
};
