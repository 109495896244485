/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Image, Typography, Row, Spin } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { getFullName } from '../../utils/formatters';

const { Text } = Typography;

/**
 * Displays information about a center and its doctors.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.center - The center object.
 * @param {Array} props.doctors - The array of doctor objects.
 * @param {Object} props.styles - The styles object.
 * @param {string} props.translationKey - The translation key.
 * @returns {JSX.Element} The rendered component.
 */
export const CentersInformations = ({
  center,
  doctors,
  styles,
  translationKey
}) => {
  const { t } = useTranslation();
  const { token } = useAuthContext();

  return (
    <Col
      span={4}
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: 40
      }}
    >
      {!center ? (
        <Spin />
      ) : center?.logo ? (
        <Image
          src={`${process.env.REACT_APP_API_URL}/files/download-image/${center?.logo}/${token}`}
          alt="logo"
          preview={false}
          style={{
            maxWidth: '200px'
          }}
        />
      ) : (
        <em>{t(`${translationKey}.center.center_logo`)}</em>
      )}
      <Text style={{ ...styles.text, fontSize: 18 }} strong>
        {center?.name || '-'}
      </Text>
      <Text style={styles.text} strong>
        {center?.address || ''}
      </Text>
      <Text style={styles.text} strong>
        {`${t(`${translationKey}.center.secretariat`)} : ${
          center?.secretariat_phone_number?.number
        }`}
      </Text>
      <Text style={styles.text} strong>
        {`${t(`${translationKey}.center.mail`)} : ${center?.email}`}
      </Text>
      <Text style={styles.text} strong>
        {t(`${translationKey}.center.opening_hours`)}
      </Text>
      <Text style={styles.text} strong>
        {t(`${translationKey}.center.urgence`)}
      </Text>
      <Divider style={{ borderColor: 'black' }} />
      {(doctors || []).map((doctor) => (
        <Row style={{ marginBottom: 20 }} key={doctor._id}>
          <Col style={{ alignItems: 'start' }}>
            <Text style={styles.text} strong>
              Dr {getFullName(doctor)}
            </Text>
            <Text style={styles.text} strong>
              {doctor.specialization || ''}
            </Text>
            <Text style={styles.text} strong>
              {`RPPS : ${doctor.cpx_number || ''}`}
            </Text>
            <Text style={styles.text} strong>
              {`AM : ${doctor.am_finess_number || ''}`}
            </Text>
          </Col>
        </Row>
      ))}
    </Col>
  );
};

CentersInformations.propTypes = {
  center: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    secretariat_phone_number: PropTypes.shape({
      number: PropTypes.string
    })
  }),
  doctors: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      specialization: PropTypes.string,
      cpx_number: PropTypes.number,
      am_finess_number: PropTypes.number
    })
  ),
  styles: PropTypes.shape({
    text: PropTypes.shape({
      textAlign: PropTypes.string
    })
  }),
  translationKey: PropTypes.string.isRequired
};

CentersInformations.defaultProps = {
  center: null,
  doctors: [],
  styles: {
    text: {
      textAlign: ''
    }
  }
};
