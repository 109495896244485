import { createContext, useContext, useEffect } from 'react';
import { ConfigProvider, Empty } from 'antd';
import dayjs from 'dayjs';
import numeral from 'numeral';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import frFR from 'antd/lib/locale/fr_FR';
import enGB from 'antd/lib/locale/en_GB';
import { useReducerWithSessionStorage } from '../utils';
import en_GB from '../locales/en_GB.json';
import fr_FR from '../locales/fr_FR.json';
import 'dayjs/locale/fr';
import 'numeral/locales/fr';

const locales = {
  fr_FR: frFR,
  en_GB: enGB
};

dayjs.locale('fr');
numeral.locale('fr');

if (!numeral.formats.siren) {
  numeral.register('format', 'siren', {
    regexps: { format: /(siren)/ },
    format: (value) => {
      const str = Object.values(`${value}`);
      let output = '';
      let count = 0;

      str.forEach((c) => {
        if (count % 3 === 0) output += ' ';
        output += c;
        count += 1;
      });
      return output;
    }
  });
}

const customizeRenderEmpty = () => <Empty />;

i18n.use(initReactI18next).init({
  resources: {
    en_GB,
    fr_FR
  },
  lng: 'fr_FR',
  fallbackLng: 'fr_FR',
  interpolation: {
    escapeValue: false
  }
});

const LanguageContext = createContext({});

const localeReducer = (state, action) => {
  if (action.type === 'SET_LOCALE') {
    if (locales[action.locale]) {
      const shortLocale = action.locale.split('_')[0];

      dayjs.locale(shortLocale);
      numeral.locale(shortLocale);
      i18n.changeLanguage(action.locale);
      return action.locale;
    }
  }
  return state;
};

export const LanguageContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [locale, dispatch] = useReducerWithSessionStorage(
    'locale',
    localeReducer,
    'fr_FR'
  );

  useEffect(() => {
    dispatch({ type: 'SET_LOCALE', locale });
  }, [locale, dispatch]);

  const validateMessages = {
    required: t('errors.form.REQUIRED'),
    types: {
      number: t('errors.form.NUMBER'),
      email: t('errors.form.EMAIL')
    }
  };

  return (
    <LanguageContext.Provider value={{ locale, dispatch }}>
      <ConfigProvider
        locale={locales[locale]}
        renderEmpty={customizeRenderEmpty}
        form={{ validateMessages }}
      >
        {children}
      </ConfigProvider>
    </LanguageContext.Provider>
  );
};

export default () => useContext(LanguageContext);
