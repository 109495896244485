/* eslint-disable import/no-extraneous-dependencies */
import { useTranslation } from 'react-i18next';
import { Input, Spin } from 'antd';
import PlacesAutocomplete from 'react-places-autocomplete';
import { PhoneNumberForm } from '../components/Fields/PhoneNumberForm';

/**
 * Custom hook that returns an array of administrative fields.
 *
 * @hook
 * @param {string} address - The address value.
 * @param {function} setAddress - The function to set the address value.
 * @returns {Array} - An array of administrative fields.
 */
export const useAdministrativeFields = (address, setAddress) => {
  const { t } = useTranslation();

  return [
    {
      label: 'address',
      name: 'address',
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={(value) => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: t('placeholder.search_address'),
                  className: 'location-search-input'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <Spin />}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    },
    {
      label: 'phone_number.number_fix',
      name: 'phone_number_fix',
      input: <PhoneNumberForm dbKey="phone_number_fix" />
    },
    {
      label: 'phone_number.number_mobile',
      name: 'phone_number_mobile',
      input: <PhoneNumberForm dbKey="phone_number_mobile" />
    },
    {
      label: 'email',
      name: 'email',
      rules: [{ type: 'email' }]
    },
    {
      label: 'trustworthy_person',
      name: 'trustworthy_person'
    },
    {
      label: 'phone_number.number_trustworthy_person',
      name: 'phone_number_trustworthy_person',
      input: <PhoneNumberForm dbKey="phone_number_trustworthy_person" />
    }
  ];
};
