import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Input, Row, Switch, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Proptypes from 'prop-types';
import { ContentCustom } from '../../../components';

const { Search } = Input;
const { Text } = Typography;

/**
 * Component for rendering custom actions such as search, switch, and create button.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.resource - The resource name.
 * @param {boolean} [props.defaultChecked=true] - The default checked state for the switch.
 * @param {boolean} [props.withSwitchBtn=false] - Whether to include the switch button.
 * @param {Function} [props.setChecked] - Callback function to handle switch button state change.
 * @returns {JSX.Element} JSX element representing the CustomAction component.
 */
export const CustomAction = ({
  resource,
  defaultChecked,
  withSwitchBtn,
  setChecked
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onSearch = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `?k=${value}`
      });
    } else {
      navigate(pathname);
    }
  };

  return (
    <ContentCustom>
      <Row justify="space-between" gutter={[8, 16]}>
        <Col>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            onSearch={(value) => onSearch(value)}
          />
        </Col>
        {withSwitchBtn && (
          <Space>
            <Text>{t('buttons.display_by_category')}</Text>
            <Switch
              defaultChecked={defaultChecked}
              onChange={(checked) => setChecked(checked)}
            />
            <Text>{t('buttons.display_all')}</Text>
          </Space>
        )}
        <Col>
          <Row gutter={[16, 16]} align="middle">
            <Link to={`${pathname}/${resource}/create`}>
              <Button type="add">
                {`${t('buttons.create')}`}
                &nbsp;
                <PlusOutlined />
              </Button>
            </Link>
          </Row>
        </Col>
      </Row>
    </ContentCustom>
  );
};

CustomAction.propTypes = {
  resource: Proptypes.string.isRequired,
  defaultChecked: Proptypes.bool,
  withSwitchBtn: Proptypes.bool,
  setChecked: Proptypes.func
};

CustomAction.defaultProps = {
  defaultChecked: true,
  withSwitchBtn: false,
  setChecked: () => {}
};
