import { useCallback, useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ContainerOutlined,
  WarningOutlined,
  SettingOutlined,
  RollbackOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { Center } from '../../models/Center';

/**
 * Renders the details of a sub-center.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const ShowCenter = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [center, setCenter] = useState({});
  const listContent = useListContent(center);
  const translationKey = 'datatable.column.action.delete';

  const getCenter = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/centers/${id}`
      });
      setCenter(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getCenter();
      setIsLoading(false);
    })();
  }, [getCenter]);

  const archivingCenter = async () => {
    const response = await Center.archivingCenter(center?._id, {
      archived: !center?.archived
    });

    await Promise.all([response, getCenter()]);
    navigate(-1);
  };

  return (
    <ContentCustom>
      <PageHeaderCustom
        title={center.name}
        extra={[
          !center?.archived ? (
            <Popconfirm
              title={t(`${translationKey}.title`)}
              okText={t(`${translationKey}.ok`)}
              cancelText={t(`${translationKey}.cancel`)}
              okButtonProps={{ type: 'danger' }}
              onConfirm={archivingCenter}
              icon={<WarningOutlined />}
            >
              <Link to={`/centers/settings/${id}`}>
                <Button type="primary">
                  {t('buttons.configuration')}
                  <SettingOutlined />
                </Button>
              </Link>
              <Button type="link" danger>
                {t('buttons.archive')}
                <ContainerOutlined />
              </Button>
            </Popconfirm>
          ) : (
            <Popconfirm
              title={t('datatable.column.action.unarchive.title')}
              okText={t('datatable.column.action.unarchive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.unarchive.cancel')}
              onConfirm={archivingCenter}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {t('buttons.unarchive')}
                <RollbackOutlined />
              </Button>
            </Popconfirm>
          )
        ]}
      />
      <Row>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <DescriptionList data={listContent} layout="vertical" translate />
        </Skeleton>
      </Row>
    </ContentCustom>
  );
};
