import { MedicineBoxOutlined } from '@ant-design/icons';
import { Checkbox, Popconfirm, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Icon from '@mdi/react';
import { mdiMinusCircle } from '@mdi/js';
import { usePatientAllergies } from '../../../utils/usePatientAllergies';
import { useHandleChange } from '../utils/handleChange';
import { useHandleConfirmTransmition } from '../utils/useHandleConfirmTransmition';
import { useColorsIcons } from '../utils/useColorsIcons';

/**
 * Represents a Tablet component.
 * @param {Object} props - The component props.
 * @param {Object} props.patient - The patient object.
 * @param {Object} props.consultation - The consultation object.
 * @param {Function} props.setRefresh - The function to set the refresh state.
 * @returns {JSX.Element} The Tablet component.
 */
export const Tablet = ({ patient, consultation, setRefresh }) => {
  const { t } = useTranslation();
  const { isAllergic } = usePatientAllergies(patient);
  const { handleConfirm } = useHandleConfirmTransmition(setRefresh);

  const [transmittedMedications, setTransmittedMedications] = useState([]);

  const { handleChange } = useHandleChange(
    transmittedMedications,
    setTransmittedMedications
  );

  const transmitionMethod = [
    t('patients.icons.intramuscular'),
    t('patients.icons.parenteral'),
    t('patients.icons.intravenous')
  ];

  const medications = consultation?.prescribed_medications?.filter(
    (medication) => !transmitionMethod.includes(medication.administration_route)
  );

  const allMedicationsPrescribed = medications?.every(
    (medication) => medication.transmitted
  );

  const { colorsIcons } = useColorsIcons(
    medications.length && allMedicationsPrescribed,
    'grey',
    'blue'
  );

  const prescribedMedications = medications.map(
    (medication) =>
      !isAllergic(medication.ingredients) &&
      !transmitionMethod.includes(medication.administration_route) && (
        <Checkbox
          defaultChecked={medication.transmitted}
          onChange={handleChange}
          value={medication._id}
          key={medication._id}
        >
          {medication.label}
        </Checkbox>
      )
  );

  return (
    medications.length > 0 && (
      <Tooltip title={t('patients.icons.tablet')}>
        <Popconfirm
          placement="bottomRight"
          title={t('patients.icons.medications')}
          description={
            <Space direction="vertical">{prescribedMedications}</Space>
          }
          okText={t('buttons.validate')}
          cancelText={t('buttons.cancel')}
          icon={<MedicineBoxOutlined />}
          onConfirm={() =>
            handleConfirm(patient.current_consultation, {
              transmittedMedications
            })
          }
        >
          <Icon path={mdiMinusCircle} size="18px" color={colorsIcons} />
        </Popconfirm>
      </Tooltip>
    )
  );
};

Tablet.propTypes = {
  patient: PropTypes.shape({
    prescribed_medications: PropTypes.arrayOf(PropTypes.shape({})),
    _id: PropTypes.string,
    current_consultation: PropTypes.string
  }).isRequired,
  consultation: PropTypes.shape({
    prescribed_medications: PropTypes.arrayOf(PropTypes.shape({})),
    _id: PropTypes.string
  }).isRequired,
  setRefresh: PropTypes.func.isRequired
};
