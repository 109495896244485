import { Modal, Button, Tooltip } from 'antd';
import { AimOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ConfidentialIcon } from '../../assets/images/icons/confidential.svg';

/**
 * Confidentiality Button component that displays a modal with confidential information
 * when clicked.
 * @component
 * @returns {JSX.Element} ConfidentialButton component
 */
export const ConfidentialButton = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Modal
        className="confidential-modal"
        open={isModalOpen}
        closable={false}
        footer={null}
      >
        <div style={{ marginBottom: '20px' }}>
          <ConfidentialIcon width="80" height="80" />
        </div>
        <h2>{t('basiclayout.headerButtons.anonymousMode')}</h2>
        <Button
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          {t('buttons.back')}
        </Button>
      </Modal>
      <Tooltip title={t('basiclayout.headerButtons.anonymousMode')}>
        <Button
          shape="circle"
          icon={<AimOutlined />}
          onClick={() => {
            setIsModalOpen(true);
          }}
        />
      </Tooltip>
    </>
  );
};
