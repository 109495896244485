import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { ListResource } from '../../components';
import { getPhoneNumber } from '../../utils/formatters';

/**
 * Renders a table displaying company users.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const ShowCompanyUsersTable = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState({});
  const [companies, setCompanies] = useState([]);

  const columns = [
    {
      title: t('users.form.last_name').toUpperCase(),
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: true
    },
    {
      title: t('users.form.first_name').toUpperCase(),
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.position').toUpperCase(),
      dataIndex: 'position',
      key: 'position',
      sorter: true,
      render: (position) => position && t(`users.positions.${position}`),
      filters:
        enums.positions &&
        enums.positions.map((position) => ({
          key: position,
          text: t(`users.positions.${position}`),
          value: position
        }))
    },
    {
      title: t('users.form.email').toUpperCase(),
      dataIndex: 'email',
      key: 'email',
      sorter: true
    },
    {
      title: t('users.form.phone_number.number').toUpperCase(),
      dataIndex: 'phone_number',
      key: 'phone_number',
      sorter: true,
      render: (phone_number) => getPhoneNumber(phone_number)
    },
    {
      title: t('users.form.default_center').toUpperCase(),
      dataIndex: 'default_center',
      key: 'default_center',
      sorter: true,
      render: (default_center) => default_center && default_center.name,
      filters:
        companies &&
        companies.map((company) => ({
          key: company._id,
          text: company.name,
          value: company._id
        }))
    }
  ];

  const getEnums = async () => {
    const { data } = await dispatchAPI('GET', {
      url: '/users/enums'
    });
    setEnums(data);
  };

  const getCentersByCenterId = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/centers/company/${id}`
    });
    setCompanies(data);
  };

  useEffect(() => {
    (async () => {
      await getEnums();
      await getCentersByCenterId();
    })();
  }, []);

  return (
    <Col span={24}>
      <ListResource
        isPageHeaderCustom={false}
        withCreateButton={{ path: `/users/create/${id}` }}
        withArchiveButton={false}
        withSearchBar={false}
        withUploadButton={false}
        columns={columns}
        resourceName={`users/company/${id}`}
        path="/users"
        populate="default_center"
        extraQuery="archived=false"
        deleteAction={false}
      />
    </Col>
  );
};
