import { Button, Form, Input, Space } from 'antd';
import {
  CheckCircleOutlined,
  CloseOutlined,
  DownOutlined,
  PlusOutlined,
  UpOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to generate fields configuration for sub-options in a form.
 *
 * @hook
 * @returns {Object} An object containing the fields configuration for sub-options.
 * @property {Array} subOptionsfields - An array of field configurations for sub-options.
 * @property {string} subOptionsfields[].name - The name of the field.
 * @property {string} subOptionsfields[].label - The label for the field.
 * @property {boolean} [subOptionsfields[].noLabel] - Indicates whether the field has no label.
 * @property {JSX.Element} subOptionsfields[].input - The input element for the field.
 * @property {string} [subOptionsfields[].valuePropName] - The name of the prop for passing the value to the input element.
 *
 * @example
 * const { subOptionsfields } = useSubOptionsFields();
 */
export const useSubOptionsFields = () => {
  const { t } = useTranslation();

  const subOptionsfields = [
    {
      name: 'option_wording',
      label: 'option_wording'
    },
    {
      noLabel: true,
      input: (
        <Form.List name={['sub_options']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key }) => (
                <Input.Group compact key={key}>
                  <Form.Item
                    name={[name, 'label']}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Space.Compact block>
                      <Button icon={<CheckCircleOutlined />} />
                      <Button icon={<UpOutlined />} />
                      <Button icon={<DownOutlined />} />
                      <Button
                        onClick={() => remove(key)}
                        icon={<CloseOutlined />}
                      />
                    </Space.Compact>
                  </Form.Item>
                </Input.Group>
              ))}
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                {t('data_bases.stay_prescriptions.form.add_sub_option')}
              </Button>
            </>
          )}
        </Form.List>
      )
    }
  ];
  return { subOptionsfields };
};
