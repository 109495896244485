/**
 * Custom hook to determine the colors of icons based on the checked state.
 * @hook
 *
 * @param {boolean} checked - The checked state.
 * @param {string} validateColor - The color for the checked state.
 * @param {string} unvalidateColor - The color for the unchecked state.
 * @returns {Object} - An object containing the colors of icons.
 */
export const useColorsIcons = (checked, validateColor, unvalidateColor) => {
  const colorsIcons = checked ? validateColor : unvalidateColor;

  return { colorsIcons };
};
