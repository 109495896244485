export default [
  'La santé est le trésor le plus précieux, la médecine en est la clé, et la prévention en est la serrure.',
  "Un médecin sage soigne non seulement le corps, mais aussi l'esprit, car la guérison véritable commence dans l'harmonie de l'âme.",
  "Mieux vaut prévenir que guérir : un bon régime alimentaire et de l'exercice sont les meilleurs médicaments.",
  'La médecine moderne guérit beaucoup, soulage souvent, console toujours.',
  "Là où règne l'amour pour la santé, la maladie ne peut pas subsister longtemps.",
  'Le médecin guérit, la nature opère la guérison.',
  "La patience est le premier remède, la tranquillité d'esprit est le meilleur médicament.",
  "La bonne santé et la paix d'esprit sont les véritables joyaux de la vie.",
  "Chaque repas est une occasion de nourrir son corps, chaque acte de bienveillance est une dose de médicament pour l'âme.",
  "L'art de guérir vient de la nature, non du médecin. Celui-ci n'est que l'instrument qui aide la nature à guérir."
];
