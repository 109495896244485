import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { getSynapseToken } from '../../utils/synapse';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * This component renders the Synapse Adverse Effect widget.
 * @component SynapseAdverseEffectWidget
 * @returns {JSX.Element} Synapse Adverse Effect widget
 */
export const SynapseAdverseEffectWidget = () => {
  const [JWT, setJWT] = useState(null);
  const { entities } = usePrescriptionContext();
  const [adverseEffectWidget, setAdverseEffectWidget] = useState(null);
  const { dispatchAPI } = useAuthContext();

  const fetchNewToken = async () => {
    const token = await getSynapseToken(dispatchAPI);
    setJWT(token);
    return token;
  };

  const createDataAdverseEffect = () => ({ entities });

  useEffect(() => {
    if (!adverseEffectWidget) return;

    const dataAdverseEffect = createDataAdverseEffect();
    adverseEffectWidget.update(dataAdverseEffect);
  }, [entities, adverseEffectWidget]);

  useEffect(() => {
    const dataAdverseEffect = createDataAdverseEffect();

    if (!JWT) {
      fetchNewToken();
      return;
    }

    const newAdverseEffectWidget = new window.Synapse.AdverseEffectWidget(JWT);
    newAdverseEffectWidget.init(
      document.getElementById('adverse-effect-container'),
      dataAdverseEffect
    );

    // eslint-disable-next-line no-unused-vars
    newAdverseEffectWidget.setCallback('onTokenExpiration', (err, data) => {
      fetchNewToken();
    });

    setAdverseEffectWidget(newAdverseEffectWidget);
  }, [JWT]);

  return <Card id="adverse-effect-container" />;
};
