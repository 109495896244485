import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Form, Row, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { usefetchPatientForSearchConsultation } from '../fetchPatientForSearchConsultation';
import { SearchPatientResultModal } from './SearchPatientResultModal';
import { formItemLayoutCustom } from '../../../../utils/constants/formLayout';
import { CreatePatientButton } from './CreatePatientButton';

const resource = 'patients';

/**
 * SearchPatientModalToConsultation component.
 * Renders a modal for searching patients and displaying search results.
 *
 * @component
 * @param {Array} fields - An array of field objects.
 * @param {Object} enums - An object containing enums for gender, blood group, cpamData, allergies, and medical history.
 * @returns {JSX.Element} SearchPatientModalToConsultation component.
 */
export const SearchPatientModalToConsultation = ({ fields, enums }) => {
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);
  const [isModalResultOpen, setIsModalResultOpen] = useState(false);
  const { handleSubmit, searchResults, isRequestComplete } =
    usefetchPatientForSearchConsultation(setIsModalOpenedLocaly);

  const closeResultModal = () => {
    setIsModalResultOpen(false);
  };

  const onCancel = () => {
    setIsModalOpenedLocaly(false);
  };

  useEffect(() => {
    if (isRequestComplete) {
      setIsModalResultOpen(true);
    }
  }, [isRequestComplete]);

  return (
    <>
      <Modal
        open={isModalOpenedLocaly}
        onCancel={onCancel}
        width="60%"
        footer={null}
        enums={enums}
        title={t('patients.form.searchPatient')}
      >
        <Form
          form={form}
          onFinish={(values) => handleSubmit(values, searchResults)}
          {...formItemLayoutCustom}
        >
          {fields?.map((field) => generateFields(resource, field))}
          <Row justify="end" style={{ marginTop: 16 }}>
            <Button type="link" key="cancel" onClick={() => onCancel()}>
              {t('buttons.cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('buttons.validate')}
            </Button>
          </Row>
        </Form>
      </Modal>

      {searchResults.length ? (
        <Modal
          open={isModalResultOpen}
          width="60%"
          footer={null}
          onCancel={closeResultModal}
          enums={enums}
        >
          <SearchPatientResultModal
            searchResults={searchResults}
            enums={enums}
            handleCancelResult={() => closeResultModal()}
          />
          <Row justify="end">
            <Button key="cancel" type="link" onClick={closeResultModal}>
              {t('buttons.cancel')}
            </Button>
            <CreatePatientButton
              form={form}
              onClick={closeResultModal}
              resource={resource}
            />
          </Row>
        </Modal>
      ) : (
        <Modal
          open={isModalResultOpen}
          width="80%"
          footer={null}
          onCancel={closeResultModal}
        >
          <SearchPatientResultModal searchResults={searchResults} />
          <Row justify="end">
            <Button key="cancel" type="link" onClick={closeResultModal}>
              {t('buttons.cancel')}
            </Button>
            <CreatePatientButton
              form={form}
              onClick={closeResultModal}
              resource={resource}
            />
          </Row>
        </Modal>
      )}
    </>
  );
};
SearchPatientModalToConsultation.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  enums: PropTypes.shape({
    genders: PropTypes.arrayOf(PropTypes.string),
    blood_group: PropTypes.arrayOf(PropTypes.string),
    cpamData: PropTypes.arrayOf(PropTypes.shape({})),
    allergies: PropTypes.arrayOf(PropTypes.shape({})),
    medical_history: PropTypes.arrayOf(PropTypes.shape({}))
  })
};
SearchPatientModalToConsultation.defaultProps = {
  fields: undefined,
  enums: undefined
};
