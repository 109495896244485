import { useTranslation } from 'react-i18next';
import { Row, Modal, Card, Checkbox, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { TreatmentsList } from '../../Components/TreatmentsList';
import { SynapseAutocompleteWidget } from '../../../../components/Synapse/SynapseAutoCompleteWidget';
import { listContent } from './listContent';
import { useConsultationsContext } from '../../ConsultationsContext';
import { useModalTreatmentsActions } from './utils/useModalTreatmentsActions';

/**
 * Modal component for managing treatments of a patient.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.openModal - Flag indicating whether the modal is open.
 * @param {function} props.setOpenModal - Function to set the openModal flag.
 * @param {string} props.patientName - The name of the patient.
 * @returns {JSX.Element} The ModalTreatments component.
 */
export const ModalTreatments = ({ openModal, setOpenModal, patientName }) => {
  const { t } = useTranslation();
  const { patient } = useConsultationsContext();

  const [entities, setEntities] = useState([]);
  const [currentMedications, setCurrentMedications] = useState([]);
  const [notCurrentMedications, setNotCurrentMedications] = useState();
  const [dataSource, setDataSource] = useState([]);

  const { patchCurrentMedication, handleSetCurrentMedications, addMedication } =
    useModalTreatmentsActions({
      notCurrentMedications,
      setCurrentMedications,
      dataSource,
      setDataSource,
      patient,
      entities,
      setEntities,
      setOpenModal
    });

  // Set the current medications when the entities are set
  useEffect(() => {
    if (entities.length) handleSetCurrentMedications();
  }, [entities]);

  // Set the data source and notCurrentMedications when the patient is set
  useEffect(() => {
    if (patient?.current_medications) {
      setDataSource([...(listContent(patient) || [])]);
      setNotCurrentMedications(patient.not_current_medications);
    }
  }, [patient]);

  // Add the current medications to the data source with no duplicates
  useEffect(() => {
    const newCurrentMedications = Array.from(new Set(currentMedications));

    newCurrentMedications.forEach(addMedication);
  }, [currentMedications]);

  return (
    <Row justify="space-between">
      <Modal
        title={t('consultations.treatments.title', { patientName })}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onOk={patchCurrentMedication}
        okText={t('buttons.validate')}
        width="60%"
      >
        <Card>
          <Checkbox
            defaultChecked={notCurrentMedications}
            onChange={(e) => setNotCurrentMedications(e.target.checked)}
          >
            Pas de traitement personnel
          </Checkbox>
          {!notCurrentMedications && (
            <>
              <Divider />
              <SynapseAutocompleteWidget
                fromPersonnalTreatments
                setEntitiesProps={setEntities}
                entitiesProps={entities}
              />
              <TreatmentsList
                setCurrentMedications={setCurrentMedications}
                dataSource={dataSource}
                setDataSource={setDataSource}
              />
            </>
          )}
        </Card>
      </Modal>
    </Row>
  );
};

ModalTreatments.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired,
  patientName: PropTypes.string.isRequired,
  patient: PropTypes.shape({
    _id: PropTypes.string,
    not_current_medications: PropTypes.bool
  })
};

ModalTreatments.defaultProps = {
  openModal: false,
  patient: {}
};
