import {
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
  faHourglassStart,
  faRadiation,
  faCommentMedical
} from '@fortawesome/free-solid-svg-icons';

export const icons = [
  { value: 'fa1', icon: fa1 },
  { value: 'fa2', icon: fa2 },
  { value: 'fa3', icon: fa3 },
  { value: 'fa4', icon: fa4 },
  { value: 'fa5', icon: fa5 },
  { value: 'fa6', icon: fa6 },
  { value: 'fa7', icon: fa7 },
  { value: 'fa8', icon: fa8 },
  { value: 'fa9', icon: fa9 },
  { value: 'faHourglassStart', icon: faHourglassStart },
  { value: 'faRadiation', icon: faRadiation },
  { value: 'faCommentMedical', icon: faCommentMedical }
];
