import { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useAuthContext } from './AuthContext';

const SocketContext = createContext({});

/**
 * @description Socket context provider
 * @param {Object} props Props
 * @param {React.ReactNode} props.children Children
 * @returns {React.ReactNode} Socket context provider
 */
export const SocketContextProvider = ({ children }) => {
  const { token } = useAuthContext();

  const [socket, setSocket] = useState();
  const [connectedUsers, setConnectedUsers] = useState([{}]);

  useEffect(() => {
    if (token) {
      setSocket(io(`${process.env.REACT_APP_API_URL}?token=${token}`));
    } else if (socket && !token) {
      socket.emit('logout');
      socket.close();
    }
    return () => {
      if (socket) socket.close();
    };
  }, [token]);

  useEffect(() => {
    if (socket) {
      socket.on('connectedUsers', (users) => setConnectedUsers(users));
    }
  }, [socket]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        connectedUsers
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
