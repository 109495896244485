import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { DataBaseMethods } from '../../DataBaseMethods';

/**
 * Generates columns configuration for a table.
 *
 * @hook
 * @returns {Object} An object containing the table columns.
 * @property {Array} columns - An array of column configurations.
 * @property {string} columns.title - The title of the column.
 * @property {string} columns.dataIndex - The key for the data index in the table data.
 * @property {string} columns.key - The unique key for the column.
 * @property {string} columns.align - The alignment of the column content.
 * @property {string} columns.fixed - Specifies whether the column is fixed ('left', 'right', or false).
 * @property {Function} columns.render - A function to customize the rendering of the column content.
 *
 * @example
 * const { columns } = useColumns();
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const { onChangeUserDisplay } = DataBaseMethods();

  const columns = [
    {
      title: t('data_bases.stay_prescriptions.columns.option_wording'),
      dataIndex: 'option_wording',
      key: 'region_wording'
    },
    {
      title: t('data_bases.stay_prescriptions.columns.user_display'),
      key: 'actions',
      align: 'rigth',
      fixed: 'rigth',
      render: (record) => (
        <Switch
          checkedChildren={t('buttons.displayed')}
          unCheckedChildren={t('buttons.hidden')}
          defaultChecked={record.user_display}
          onChange={(checked) =>
            onChangeUserDisplay(
              'config-prescriptions',
              checked,
              record._id,
              '/option-display'
            )
          }
        />
      )
    }
  ];

  return { columns };
};
