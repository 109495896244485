import { useTranslation } from 'react-i18next';
import { Tag, Tooltip } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { getFullName } from '../../utils/formatters';

/**
 * @hook useColumns
 * @description useColumns - hook for columns
 * @returns {array} columns
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  /**
   * @function renderAuthorized
   * @description renderAuthorized - render authorized
   * @param {array} authorized - authorized persons
   * @returns {array} tags - Tags with initials of authorized persons but not the current user
   */
  const renderAuthorized = (authorized) => {
    const tags = authorized.map((a) => {
      const { _id, first_name, last_name } = a;

      if (_id !== user._id) {
        const firstNameInitial = first_name
          .split(' ')
          .map((n) => (n ? n[0].toUpperCase() : ''))
          .join('');

        const lastNameInitial = last_name
          .split(' ')
          .map((n) => (n ? n[0].toUpperCase() : ''))
          .join('');

        return (
          <Tooltip key={_id} title={getFullName({ first_name, last_name })}>
            <Tag color="blue">{`${firstNameInitial}${lastNameInitial}`}</Tag>
          </Tooltip>
        );
      }

      return null;
    });

    return tags;
  };

  return [
    {
      title: t('prescription.favorites.list.columns.label'),
      dataIndex: 'label',
      key: 'label',
      sorter: (a, b) => a.label.localeCompare(b.label)
    },
    {
      title: t('prescription.favorites.list.columns.type'),
      dataIndex: 'purpose',
      key: 'purpose',
      render: (purpose) =>
        purpose ? t(`prescription.favorites.enums.${purpose}`) : '-'
    },
    {
      title: t('prescription.favorites.list.columns.shared_with'),
      dataIndex: 'authorized',
      key: 'authorized',
      render: (authorized) => renderAuthorized(authorized)
    }
  ];
};
