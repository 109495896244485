import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout, Modal } from 'antd';
import { Link, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Logo from '../../assets/images/logo_toola.svg';
import LogoCollapsed from '../../assets/images/logo_collapsed.svg';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import { useHandleResize } from '../../utils/handleResize';
import { UserNavMenu } from './UserNavMenu';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import useSearchFields from '../../routes/patients/searchFields';
import useSearchFieldsToConsultation from '../../routes/patients/searchFieldsToConsultation';
import { UserHeaderButtons } from './UserHeaderButtons';
import { SynapseAutocompleteWidget } from '../Synapse/SynapseAutoCompleteWidget';
import { SynapseMonographWidget } from '../Synapse/SynapseMonographWidget';
import packageJson from '../../../package.json';

const { Content, Footer, Header } = Layout;

const LogoDiv = styled.div`
  position: relative;
  height: 100%;
  padding-left: 0;
  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  justify-content: start;
  img {
    display: inline-block;
    height: 40px;
    vertical-align: middle;
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 24px;
  text-align: right;
  color: var(--primaryColor);
  font-size: 12px;

  a {
    color: var(--primaryColor);
  }
`;

/**
 * UserLayout component represents the layout for the user section of the application.
 * It includes a header, navigation menu, content area, and footer.
 *
 * @component
 * @returns {ReactElement} The UserLayout component
 */
export const UserLayout = () => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );
  const [enums, setEnums] = useState({});
  const { dispatchAPI, user } = useAuthContext();
  const { searchPatientFields } = useSearchFields({ enums });
  const { searchPatientFieldsToConsultation } = useSearchFieldsToConsultation({
    enums
  });
  const { message } = useErrorMessage();

  const [entities, setEntities] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const getEnumsPatients = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/patients/enums`
      });
      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
      return null;
    }
  };

  const getEnumsConsultations = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/consultations/enums`
      });
      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
      return null;
    }
  };

  const fetchEnums = async () => {
    try {
      const [enumsPatients, enumsConsultations] = await Promise.all([
        getEnumsPatients(),
        getEnumsConsultations()
      ]);
      const allEnums = {
        ...enumsPatients,
        ...enumsConsultations
      };
      setEnums(allEnums);
      return null;
    } catch (e) {
      if (e.response) message(e.response.status);
      return null;
    }
  };

  useEffect(() => {
    (async () => {
      await fetchEnums();
    })();
  }, []);

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    if (entities.length) {
      setOpenModal(true);
    }
  }, [entities]);

  return (
    <>
      <Header
        style={{
          overflow: 'visible'
        }}
      >
        <LogoDiv>
          <Link to="/">
            <img alt="Logo" src={collapsed ? LogoCollapsed : Logo} />
          </Link>
        </LogoDiv>
        <UserNavMenu setCollapsed={setCollapsed} label={false} />
        {user?.position !== 'SECRETARY' && (
          <SynapseAutocompleteWidget
            fromHeader
            setEntitiesProps={setEntities}
            entitiesProps={entities}
            style={{
              overflow: 'visible'
            }}
          />
        )}
        {entities.length > 0 && (
          <Modal
            open={openModal}
            footer={null}
            onCancel={() => {
              setOpenModal(false);
              setEntities([]);
            }}
          >
            <SynapseMonographWidget entity={entities[0]} />
          </Modal>
        )}
        <UserHeaderButtons
          setCollapsed={setCollapsed}
          searchPatientFields={searchPatientFields}
          searchPatientFieldsToConsultation={searchPatientFieldsToConsultation}
          enums={enums}
        />
        <HeaderLayout
          collapsed={collapsed}
          collapseSider={setCollapsed}
          collapseWidth={collapseWidth}
        />
      </Header>
      <Layout>
        <Content>
          <Outlet />
        </Content>
      </Layout>
      <StyledFooter as={Footer}>
        {`${t('global.title')} v${
          packageJson.version
        } ©${dayjs().year()} powered by `}
        <a href="https://strateg.in">Strateg.in</a>
      </StyledFooter>
    </>
  );
};
