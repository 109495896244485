import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { Card } from 'antd';
import { GridItem } from './GridItem';

/**
 * CardItem component represents a card item in the Kanban board.
 * @component
 *
 * @param {string} _id - The unique identifier of the card item.
 * @param {string} title - The title of the card item.
 * @param {Array} rooms - The array of rooms associated with the card item.
 * @param {function} refresh - The refresh function to update the card item.
 * @param {function} setRefresh - The function to set the refresh state of the card item.
 * @returns {JSX.Element} The rendered CardItem component.
 */
export const CardItem = ({ _id, title, rooms, refresh, setRefresh }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'GRID',
    drop: () => ({ target: _id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  const isActive = canDrop && isOver;

  const backgroundColor = isActive ? '#8AC7FF' : 'rgba(138, 199, 255, 0.2)';

  return (
    <div ref={drop}>
      <Card
        title={title}
        bodyStyle={{ width: '100%', backgroundColor, border: 'none' }}
        headStyle={{ backgroundColor, border: 'none' }}
      >
        {rooms.map((room) => (
          <GridItem
            key={room._id}
            room={room}
            _id={room._id}
            title={room.title}
            rooms={room.rooms}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        ))}
      </Card>
    </div>
  );
};

CardItem.propTypes = {
  _id: PropTypes.string,
  title: PropTypes.string,
  rooms: PropTypes.arrayOf(PropTypes.shape({})),
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired
};

CardItem.defaultProps = {
  _id: '',
  title: '',
  rooms: []
};
