import { CreateUpdateContainer } from '../../../components';
import { useFields } from './fields';

/**
 * Component for creating or updating a surgical historic.
 *
 * @component
 * @returns {JSX.Element} The CreateUpdateSurgicalHistoric component.
 */
export const CreateUpdateSurgicalHistory = () => {
  const { fields } = useFields();

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose="create"
      baseUrl="surgical-history"
      resource="data_bases.surgical_history"
    />
  );
};
