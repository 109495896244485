import { Form, Input } from 'antd';
import PropTypes from 'prop-types';

/**
 * Editable cell component used in a table.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.editing - Indicates whether the cell is in editing mode.
 * @param {string} props.dataIndex - The key of the data field associated with the cell.
 * @param {string} props.title - The title of the cell.
 * @param {string} props.inputType - The type of input to be rendered in the cell when in editing mode.
 * @param {Object} props.record - The data record associated with the cell.
 * @param {number} props.index - The index of the cell in the table.
 * @param {ReactNode} props.children - The content of the cell.
 * @param {Object} restProps - The rest of the props passed to the component.
 * @returns {JSX.Element} The rendered component.
 */
export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => (
  <td {...restProps}>
    {editing ? (
      <Form.Item
        name={dataIndex}
        style={{
          margin: 0
        }}
        rules={[
          {
            required: true,
            message: `Please Input ${title}!`
          }
        ]}
      >
        <Input />
      </Form.Item>
    ) : (
      children
    )}
  </td>
);

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.string,
  record: PropTypes.shape({}),
  index: PropTypes.number,
  children: PropTypes.node.isRequired
};

EditableCell.defaultProps = {
  editing: false,
  dataIndex: '',
  title: '',
  inputType: '',
  record: {},
  index: 0
};
