import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SorterDateReason } from '../../../Components/SorterDateReason';
import { useSwitchState } from '../../../Components/switchState';
import { useColumnsStayPrescriptions } from '../columns/columnsStayPrescriptions';

/**
 * Renders a table component for displaying SCOR data.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Array} props.data - The data to be displayed in the table.
 * @param {Function} props.onChange - The callback function triggered when the table's state changes.
 * @returns {JSX.Element} - The rendered table component.
 */
export const ScorTable = ({ data, onChange }) => {
  const { isSwitched, handleSwitchChange } = useSwitchState();
  const columns = useColumnsStayPrescriptions(isSwitched);
  const { t } = useTranslation();

  return (
    <>
      <SorterDateReason handleSwitchChange={handleSwitchChange} />
      <Table
        pagination={{
          pageSizeOptions: ['10', '20', '50', '100'],
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} ${t('consultations.form.of')} ${total} ${t(
              'consultations.form.items'
            )}`
        }}
        columns={columns}
        dataSource={data}
        onChange={onChange}
      />
    </>
  );
};

ScorTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired
};
