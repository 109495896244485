import dayjs from 'dayjs';
import React, { useContext, useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Collapse, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ConsultationsContext } from '../../../ConsultationsContext';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { getFullName } from '../../../../../utils/formatters';
import { useModalCareCollapseItems } from '../utils/modalCareCollapseItems';

const { Title } = Typography;

/**
 * @component ModalCares
 * @description ModalCares component which is a modal that contains four tables: StayTable, ExitTable, RadiologyTable and PhotosTable.
 * It is used in the Documents component.
 * It is used to display the patient's prescriptions and photos history.
 * @returns {JSX} JSX element
 */
export const ModalCares = () => {
  const { patient, isLoading, setIsLoading, forcedRefresh } =
    useContext(ConsultationsContext);
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const [prescriptions, setPrescriptions] = useState({});
  const [consultationsWithPhotos, setConsultationsWithPhotos] = useState([]);
  const [sortedField, setSortedField] = useState('date');
  const [sortedOrder, setSortedOrder] = useState('descend');
  const [refresh, setRefresh] = useState(false);

  const resource = 'consultations';

  /**
   * @function
   * @name preprocessData
   * @description preprocess data to add rowSpan and sameAsPrevious properties
   * @param {Array} data - array of consultations
   * @returns {Array} - array of consultations with rowSpan and sameAsPrevious properties
   */
  const preprocessData = useCallback(
    (data) =>
      data.map((record, index, array) => {
        if (
          index > 0 &&
          array[index - 1].diagnostic[0] === record.diagnostic[0]
        ) {
          return { ...record, sameAsPrevious: true };
        }
        let rowSpan = 1;
        let i = index + 1;
        while (
          i < array.length &&
          array[i].diagnostic[0] === record.diagnostic[0]
        ) {
          i += 1;
          rowSpan += 1;
        }
        return { ...record, rowSpan };
      }),
    []
  );

  const sortData = useCallback((data, field, order) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      let comparison = 0;
      if (field === 'diagnostic') {
        const aReasons = Array.isArray(a.diagnostic)
          ? a.diagnostic.join(', ')
          : a.diagnostic;
        const bReasons = Array.isArray(b.diagnostic)
          ? b.diagnostic.join(', ')
          : b.diagnostic;
        comparison = aReasons.localeCompare(bReasons);
      } else if (field === 'date') {
        comparison = dayjs(a.date).unix() - dayjs(b.date).unix();
      }
      if (order === 'descend') {
        comparison *= -1;
      }
      return comparison;
    });
    return sortedData;
  }, []);

  const getConsultationsWithPhotos = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/consultations/photos/patient/${id}?populate=photos.photo`
      });

      const sortedData = sortData(data, sortedField, sortedOrder);
      const preprocessedData = preprocessData(sortedData);

      setConsultationsWithPhotos(preprocessedData);
    } catch (e) {
      message(e);
    }
  }, [id, sortData, preprocessData, sortedField, sortedOrder, refresh]);

  const getAllPrescriptions = async () => {
    const population = [
      'patient',
      'consultation',
      'consultation.visit_reasons'
    ];
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/prescriptions/all_prescriptions?populate=${population.join(
          ','
        )}&patient=${id}`
      });

      setPrescriptions(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getConsultationsWithPhotos();
      await getAllPrescriptions();
      setIsLoading(false);
    })();
  }, [getConsultationsWithPhotos, forcedRefresh]);

  const onTableChange = useCallback(
    (pagination, filters, sorter) => {
      setSortedField(sorter.field);
      setSortedOrder(sorter.order);
      getConsultationsWithPhotos();
    },
    [getConsultationsWithPhotos]
  );

  const { modalCareCollapseItems } = useModalCareCollapseItems({
    resource,
    prescriptions,
    consultationsWithPhotos,
    onTableChange,
    setRefresh
  });

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Title level={4}>
          {t(`${resource}.documents.document_treatment`)}&nbsp;
          {getFullName(patient)}
        </Title>
      </Row>
      <Collapse expandIconPosition="end" items={modalCareCollapseItems} />
    </Spin>
  );
};
