import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { MedicalBackgroundSelect } from '../PatientOverview/MedicalBackground/MedicalBackgroundSelect';

/**
 * Custom hook that returns an array of fields for the medical background section.
 * @param {Object} patient - The patient object.
 * @returns {Object} - An object containing the fields array.
 */
export const useMedicalBackgroundFields = (patient) => {
  const { t } = useTranslation();
  const fields = [
    {
      label: 'surgical_history',
      name: ['surgical_history'],
      input: (
        <MedicalBackgroundSelect defaultValue={patient?.surgical_history} />
      )
    },
    {
      noLabel: true,
      name: ['no_allergies'],
      input: <Checkbox>{t('consultations.form.no_allergies')}</Checkbox>,
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: ['no_surgical_history'],
      input: <Checkbox>{t('consultations.form.no_surgical_history')}</Checkbox>,
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: ['no_medical_history'],
      input: <Checkbox>{t('consultations.form.no_medical_history')}</Checkbox>,
      valuePropName: 'checked'
    }
  ];

  return { fields };
};
