import PropTypes from 'prop-types';
import { ListResource } from '../../../../components';
import { useColumns } from './MiscRoomsColumns';
import { useActionColumns } from './ActionColumns';

export const MiscRooms = ({ type }) => {
  const resource = 'rooms';

  const columns = useColumns();
  const actionColumn = useActionColumns();

  return (
    <ListResource
      resourceName={`${resource}/room/${type}`}
      resourceModelName={resource}
      customActionColumn
      columns={[...columns, ...actionColumn]}
      deleteAction={false}
      populate="current_consultation"
      isOriginalContentCustom={false}
      isPageHeaderCustom={false}
      onDoubleClickAction={false}
      forceRefresh
      rowKey="patientId"
    />
  );
};
MiscRooms.propTypes = {
  type: PropTypes.string.isRequired
};
