import { Route, Routes } from 'react-router-dom';
import { CreateUpdateProtocol } from './CreateUpdateProtocol';
import { Exception } from '../../../components';

/**
 * Component for routing related to the protocols.
 *
 * @returns {JSX.Element} The ProtocolsRouter component.
 */
export const ProtocolsRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateProtocol purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateProtocol purpose="edit" />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
