/**
 * Returns an array of keys from the given values.
 *
 * @param {Array} values - The array of values.
 * @returns {Array} - The array of keys.
 */
export const getTreeKeys = (values) => {
  if (values?.length > 0) {
    const keys = values?.flatMap((value) => value.key);
    return keys;
  }
  return [];
};
