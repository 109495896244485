import { useEffect, useState } from 'react';
import { Card, Col, Flex, Form, Spin, Table, Tree } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiMedicalBag, mdiMicroscope, mdiRadioactive } from '@mdi/js';
import { FavoritesCard } from './FavoritesCard';
import { getTreeKeys } from '../../utils/getTreeKeys';
import { useHandleClick } from '../utils/useHandleClick';
import { usePostFavorite } from '../../utils/postFavorite';
import { ConfigPrescription } from '../../../../models/ConfigPrescription';

/**
 * Represents a TreeCard component.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {string} props.category - The category of the tree.
 * @param {function} props.setCheckedKeys - The function to set the checked keys.
 * @param {Array} props.globalCheckedKeys - The global checked keys.
 * @param {function} props.setTreeKeys - The function to set the tree keys.
 * @param {function} props.refresh - The refresh function.
 * @param {function} props.setRefresh - The function to set the refresh state.
 * @param {string} props.prescriptionType - The prescription type.
 * @param {Object} props.srnCheckedKeys - The SRN checked keys.
 * @returns {JSX.Element} The TreeCard component.
 */
export const TreeCard = ({
  form,
  category,
  setCheckedKeys: setBodyCheckedKeys,
  srnCheckedKeys,
  globalCheckedKeys,
  setTreeKeys,
  refresh,
  setRefresh,
  prescriptionType
}) => {
  const { t } = useTranslation();

  const [treeOptions, setTreeOptions] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedKeysValues, setCheckedKeysValues] = useState([]);

  const title = t(`prescription.enums.${category}`);
  const colSpan = category.includes('SRN_CARE') ? 8 : 12;

  const categoriesIcons = {
    BIOLOGY: <Icon path={mdiMicroscope} size={1} />,
    IMAGING: <Icon path={mdiRadioactive} size={1} />,
    SRN_CARE_WITH_COTATIONS: <Icon path={mdiMedicalBag} size={1} />,
    SRN_CARE_WITHOUT_COTATIONS: <Icon path={mdiMedicalBag} size={1} />
  };

  const { postFavorite } = usePostFavorite({
    form,
    setRefresh,
    refresh
  });

  const { handleClickFavoritePrescription } = useHandleClick({
    setCheckedKeys,
    category,
    form,
    setTreeKeys
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await ConfigPrescription.getTreeOptions({
        category: category.includes('SRN_CARE') ? 'SRN_CARE' : category,
        prescription_type: prescriptionType
      });
      setTreeOptions(response);
      setLoading(false);
    })();
  }, []);

  const translatedTreeData = treeOptions.map((item) => ({
    ...item,
    title: t(`consultations.prescriptions.${item.title}`)
  }));

  const dispatchTreeData = () => {
    if (
      category === 'SRN_CARE_WITH_COTATIONS' ||
      category === 'SRN_CARE_WITHOUT_COTATIONS'
    ) {
      return translatedTreeData.flatMap((item) => item.children);
    }
    return translatedTreeData;
  };

  const handleChecked = (checkedNodes) => {
    setTreeKeys([...checkedNodes]);

    form.setFieldValue(category, [...checkedNodes]);
  };

  useEffect(() => {
    if (refresh) {
      setCheckedKeysValues([]);
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (
      checkedKeys[category] ||
      globalCheckedKeys[category] ||
      srnCheckedKeys?.[category]
    ) {
      setCheckedKeysValues(
        getTreeKeys(
          checkedKeys[category] ||
            globalCheckedKeys[category] ||
            srnCheckedKeys?.[category]
        ).filter((item) => !checkedKeysValues.includes(item))
      );
    }
  }, [checkedKeys, globalCheckedKeys, srnCheckedKeys]);

  useEffect(() => {
    if (checkedKeys.length <= 0) {
      form.setFieldValue(category, []);
      setCheckedKeysValues([]);
    }
    if (checkedKeys) {
      setBodyCheckedKeys(checkedKeys);
    }
  }, [checkedKeys]);

  useEffect(() => {
    if (globalCheckedKeys.length <= 0) {
      form.setFieldValue(category, []);
      setCheckedKeysValues([]);
    }
    if (globalCheckedKeys) {
      setBodyCheckedKeys(globalCheckedKeys);
    }
  }, [globalCheckedKeys]);

  useEffect(() => {
    if (
      srnCheckedKeys?.[category]?.length <= 0 ||
      !srnCheckedKeys?.[category]
    ) {
      form.setFieldValue(category, []);
      setCheckedKeysValues([]);
    }
    if (srnCheckedKeys) {
      setBodyCheckedKeys(srnCheckedKeys);
    }
  }, [srnCheckedKeys]);

  return (
    <Form component={false} form={form}>
      {!category.includes('SRN_CARE') && (
        <Col span={12}>
          <FavoritesCard
            category={category}
            form={form}
            setCheckedKeys={setCheckedKeys}
            setTreeKeys={setTreeKeys}
            editableRowHandleClick={handleClickFavoritePrescription}
            postFavorite={postFavorite}
            refresh={refresh}
            setRefresh={setRefresh}
            withCreateButton
          />
        </Col>
      )}
      <Col span={colSpan}>
        <Card
          style={{ borderRadius: '0 8px 8px 0', width: '100%' }}
          title={
            <Flex alignitem="center" gap={8}>
              {categoriesIcons[category]}
              {title}
            </Flex>
          }
        >
          <Spin spinning={loading}>
            {treeOptions.length > 0 ? (
              <Form.Item name={category}>
                <Tree
                  checkable
                  onCheck={(checkedKeysValue, { checkedNodes }) => {
                    handleChecked(checkedNodes);
                    setCheckedKeysValues(checkedKeysValue);
                  }}
                  checkedKeys={checkedKeysValues}
                  treeData={dispatchTreeData()}
                  autoExpandParent
                />
              </Form.Item>
            ) : (
              <Table />
            )}
          </Spin>
        </Card>
      </Col>
    </Form>
  );
};

TreeCard.propTypes = {
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired
  }).isRequired,
  category: PropTypes.string.isRequired,
  setCheckedKeys: PropTypes.func.isRequired,
  setTreeKeys: PropTypes.func.isRequired,
  globalCheckedKeys: PropTypes.shape([]),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  prescriptionType: PropTypes.string,
  srnCheckedKeys: PropTypes.shape({})
};

TreeCard.defaultProps = {
  globalCheckedKeys: [],
  refresh: false,
  setRefresh: () => {},
  prescriptionType: undefined,
  srnCheckedKeys: {}
};
