import PropTypes from 'prop-types';
import { Collapse, Col } from 'antd';
import { CollapseCommentsForm } from './CollapseCommentsForm';

const { Panel } = Collapse;

/**
 * Renders a collapsible panel for comments.
 *
 * @component
 * @param {Object[]} fields - The array of fields for the comments form.
 * @param {Object} header - The header object for the collapsible panel.
 * @param {Object[]} comments - The array of comments.
 * @param {string} commentType - The type of comments.
 * @returns {JSX.Element} The rendered CollapseComments component.
 */
export const CollapseComments = ({ fields, header, comments, commentType }) => (
  <Col span={24}>
    <Collapse expandIconPosition="end" className="patient-form-collapse">
      <Panel header={header} key="1">
        <CollapseCommentsForm
          fields={fields}
          comments={comments}
          commentType={commentType}
        />
      </Panel>
    </Collapse>
  </Col>
);
CollapseComments.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
  header: PropTypes.shape({}).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  commentType: PropTypes.string.isRequired
};
CollapseComments.defaultProps = {
  comments: undefined,
  fields: undefined
};
