import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { PatientContext } from '../../../../contexts/PatientContext/PatientContext';

export const AmoAmcSelect = ({ dbKey }) => {
  const { t } = useTranslation();
  const { Item } = Form;
  const { enums } = useContext(PatientContext);

  const options = enums?.medical_history?.map((item) => ({
    label: item.name,
    value: item.name
  }));

  return (
    <Item name={`${dbKey}`}>
      <Select
        mode="multiple"
        allowClear
        style={{
          width: '100%'
        }}
        placeholder={t('consultations.form.multiple_select_placeholder')}
        options={options}
      />
    </Item>
  );
};
AmoAmcSelect.propTypes = {
  dbKey: PropTypes.string.isRequired
};
