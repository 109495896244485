import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { PDFDocument } from 'pdf-lib';
import { useErrorMessage } from './errorMessage';
import { useAuthContext } from '../contexts/AuthContext';

/**
 * @hook useDownloadDocument
 * @description Hook to view in an other tab, download or print a document
 * @returns {Object} { downloadDocument, viewDocument, printDocument }
 */
export const useDownloadDocument = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  /**
   * @function downloadDocument
   * @async
   * @description Download a document
   * @param {Object} doc Document to download
   * @returns {void}
   * @example
   * downloadDocument(doc);
   * @example
   * downloadDocument({
   * _id: '5f7b1b7b9f6b2e0017b0b3b5',
   * metadata: { originalName: 'test.pdf' },
   * contentType: 'application/pdf'
   * });
   */
  const downloadDocument = async ({
    _id,
    metadata: { originalName },
    contentType
  }) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${_id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], { type: contentType });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = originalName;
      a.click();
      notification.success({ message: t('messages.succes_download') });
    } catch (e) {
      message(e);
    }
  };

  /**
   * @function viewDocument
   * @async
   * @description View a document in an other tab
   * @param {Object} doc Document to view
   * @returns {void}
   * @example
   * viewDocument(doc);
   * @example
   * viewDocument({
   * _id: '5f7b1b7b9f6b2e0017b0b3b5',
   * metadata: { originalName: 'test.pdf' },
   * contentType: 'application/pdf'
   * });
   */
  const viewDocument = async (doc) => {
    try {
      let blob = {};
      if (!doc.data) {
        const response = await dispatchAPI('GET', {
          url: `/files/${doc._id}`,
          responseType: 'blob'
        });
        blob = new Blob([response.data], {
          type: response.data.type
        });
        const fileURL = URL.createObjectURL(blob);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  /**
   * @function printDocument
   * @async
   * @description Print a document
   * @param {Object} doc Document to print
   * @returns {void}
   * @example
   * printDocument(doc);
   * @example
   * printDocument({
   * _id: '5f7b1b7b9f6b2e0017b0b3b5',
   * metadata: { originalName: 'test.pdf' },
   * contentType: 'application/pdf'
   * });
   */
  const printDocument = async (doc) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${doc._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        type: data.type
      });

      const url = window.URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;

      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  /**
   * Prints multiple documents by merging them into a single PDF and opening a print dialog.
   * @function
   *
   * @param {Array<string>} documentsIds - An array of document IDs.
   * @returns {void}
   */
  const printMultipleDocuments = async (documentsIds) => {
    /**
     * Merges an array of PDF blobs into a single PDF document.
     * @function
     *
     * @param {Blob[]} blobs - An array of PDF blobs to merge.
     * @returns {Promise<Blob>} - A Promise that resolves to the merged PDF blob.
     */
    const mergePDFs = async (blobs) => {
      const mergedPdfDoc = await PDFDocument.create();

      const promises = blobs.map(async (blob) => {
        const pdfBytes = await blob.arrayBuffer();
        const pdfDoc = await PDFDocument.load(pdfBytes);

        const copiedPages = await mergedPdfDoc.copyPages(
          pdfDoc,
          pdfDoc.getPageIndices()
        );

        copiedPages.forEach((page) => {
          mergedPdfDoc.addPage(page);
        });
      });

      await Promise.all(promises);

      const mergedPdfBytes = await mergedPdfDoc.save();

      return new Blob([mergedPdfBytes], { type: 'application/pdf' });
    };

    /**
     * Merges multiple documents into a single Blob object.
     * @function
     *
     * @param {Array<string>} prescriptionsIds - An array of prescription IDs.
     * @returns {Promise<Blob>} - A Promise that resolves to the merged Blob object.
     */
    const mergeDocumentsToBlob = async (prescriptionsIds) => {
      const promises = prescriptionsIds.map(async (id) => {
        const { data } = await dispatchAPI('GET', {
          url: `/files/${id}`,
          responseType: 'blob'
        });
        return data;
      });

      const blobs = await Promise.all(promises);
      const mergedBlobs = await mergePDFs(blobs);
      return mergedBlobs;
    };

    try {
      const mergedBlob = await mergeDocumentsToBlob(documentsIds);
      const url = window.URL.createObjectURL(mergedBlob);

      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;

      document.body.appendChild(iframe);

      iframe.contentWindow.print();
    } catch (e) {
      message(e);
    }
  };

  /**
   * Creates a file from JSON data.
   * @async
   * @function
   * @param {Object} file - The file object.
   * @returns {Promise<Object>} A promise that resolves to the formatted file object.
   */
  const createFileFromJSON = async (file) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${file._id}`,
        responseType: 'blob'
      });

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          const formattedFile = {
            ...file,
            url: base64data,
            file: new File([data], file.metadata.originalName, {
              type: data.type
            })
          };
          resolve(formattedFile);
        };
        reader.onerror = reject;
        reader.readAsDataURL(data);
      });
    } catch (e) {
      return message(e);
    }
  };

  /**
   * Deletes a document by its ID.
   * @param {Object} documentData - Object containing the ID of the document to delete.
   * @param {string} documentData._id - The ID of the document to delete.
   * @returns {Promise<void>} A Promise that resolves when the document is successfully deleted.
   */
  const deleteDocument = async ({ _id }) => {
    try {
      await dispatchAPI('DELETE', { url: `/files/${_id}` });

      notification.success({ message: t('messages.success_delete') });
    } catch (e) {
      message(e);
    }
  };

  return {
    downloadDocument,
    viewDocument,
    printDocument,
    printMultipleDocuments,
    deleteDocument,
    createFileFromJSON
  };
};
