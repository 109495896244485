import { MedicineBoxFilled } from '@ant-design/icons';
import { Col, Card, Row, Modal, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ModalCares } from '../modals/ModalCares';

/**
 * A component that displays a care card.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.resource - The resource to be used for translation.
 *
 * @returns {JSX.Element} The CareCard component.
 */
const { Text } = Typography;

export const CareCard = ({ resource }) => {
  const [isModalTreatment, setModalTreatment] = useState(false);
  const { t } = useTranslation();

  return (
    <Col span={12}>
      <Card
        bordered={false}
        style={{ background: '#8AC7FF66' }}
        onClick={() => setModalTreatment(true)}
        hoverable
      >
        <Row justify="center">
          <MedicineBoxFilled
            style={{ fontSize: '3em', color: 'rgb(0, 74, 143)' }}
          />
        </Row>
        <Row justify="center">
          <Text strong style={{ color: '#004A8F' }}>
            {t(`${resource}.form.treatment`)}
          </Text>
        </Row>
      </Card>
      <Modal
        open={isModalTreatment}
        onCancel={() => setModalTreatment(false)}
        okText={t('buttons.validate')}
        width="50%"
        footer={null}
      >
        <ModalCares />
      </Modal>
    </Col>
  );
};

CareCard.propTypes = {
  resource: PropTypes.string.isRequired
};
