/**
 * Renders a title with an underline.
 * @function
 *
 * @param {string} title - The title to render.
 * @returns {JSX.Element} The rendered title component.
 */
export const renderTitle = (title) => (
  <div style={{ position: 'relative', paddingBottom: '8px' }}>
    <span>{title}</span>
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '40px',
        height: '4px',
        backgroundColor: 'var(--secondaryColor)',
        borderBottomRightRadius: '4px',
        borderTopRightRadius: '4px'
      }}
    />
  </div>
);
