import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Row } from 'antd';

/**
 * Footer component for the FreeTextPrescriptionForm.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.showLabelInput - Flag indicating whether to show the label input.
 * @param {boolean} props.loading - Flag indicating whether the button is in a loading state.
 * @param {Function} props.isEmptyContent - Function to check if the content is empty.
 * @param {Function} props.postPrescription - Function to post the prescription.
 * @returns {JSX.Element} The rendered Footer component.
 */
export const Footer = ({
  showLabelInput,
  loading,
  isEmptyContent,
  postPrescription
}) => {
  const { t } = useTranslation();

  return (
    <Row justify="center">
      <Popover
        content={
          showLabelInput
            ? t('warnings.message.empty_content_or_label')
            : t('warnings.message.empty_content')
        }
        trigger={isEmptyContent() ? 'hover' : 'none'}
      >
        <Button
          loading={loading}
          disabled={isEmptyContent() || loading}
          type="primary"
          onClick={() => postPrescription()}
        >
          {t('buttons.validate')}
        </Button>
      </Popover>
    </Row>
  );
};

Footer.propTypes = {
  showLabelInput: PropTypes.bool,
  loading: PropTypes.bool,
  isEmptyContent: PropTypes.func,
  postPrescription: PropTypes.func
};

Footer.defaultProps = {
  showLabelInput: false,
  loading: false,
  isEmptyContent: () => {},
  postPrescription: () => {}
};
