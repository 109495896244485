import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Progress, Flex, Typography } from 'antd';

const { Text } = Typography;

/**
 * ProgressModal component displays a modal with a progress bar.
 * It shows the progress of an ongoing task and can display a waiting message.
 *
 * @component
 * @param {boolean} loading - Indicates whether the progress modal is visible or not.
 * @param {string} status - The status of the task. It can be 'active', 'success', or 'exception'.
 * @param {string} message - The message to be displayed when the task is completed successfully.
 * @returns {JSX.Element} - The rendered ProgressModal component.
 */
export const ProgressModal = ({ loading, status, message }) => {
  const { t } = useTranslation();
  const [percent, setPercent] = useState(0);

  const increase = () => {
    let newPercent = percent < 80 ? percent + 20 : percent;
    if (status === 'success') {
      newPercent = 100;
    } else if (newPercent > 100) {
      newPercent = 80;
    }

    setPercent(newPercent);
  };

  useEffect(() => {
    if (!loading) {
      setPercent(0);
    } else {
      const interval = setInterval(() => {
        increase();
      }, 1000);
      return () => clearInterval(interval);
    }
    return null;
  }, [percent, loading, status]);

  return (
    <Modal open={loading} closable={false} footer={false}>
      <Flex justify="center" align="center" style={{ margin: '24px 0' }}>
        <Text>{message && t(`success.message.${message}`)}</Text>
        <Progress
          type="circle"
          status={status}
          percent={status === 'success' ? 100 : percent}
        />
      </Flex>
    </Modal>
  );
};

ProgressModal.propTypes = {
  loading: PropTypes.bool,
  status: PropTypes.string.isRequired,
  message: PropTypes.string
};

ProgressModal.defaultProps = {
  loading: false,
  message: null
};
