import { useErrorMessage } from './errorMessage';
import { useAuthContext } from '../contexts/AuthContext';

/**
 * Retrieves the enums for a given resource.
 * @hook
 * @description You have to call this function with the name of the route to get the enums. (ex: 'users')
 * @returns {Object} An object containing the `getEnums` function.
 */
export const GetEnums = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getEnums = async (resource) => {
    if (!resource) return null;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resource}/enums`
      });

      return data || [];
    } catch (error) {
      return message(error);
    }
  };

  return { getEnums };
};
