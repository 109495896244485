import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Tooltip } from 'antd';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { SearchPatientModalToConsultation } from '../../routes/patients/components/SearchPatientModalToConsultation/SearchPatientModalToConsultation';
import { SearchPatientModal } from '../../routes/patients/components/SearchPatientModal/SearchPatientModal';
import { useHandleResize } from '../../utils/handleResize';
import { ConfidentialButton } from '../ConfidentialButton/ConfidentialButton';

import Stellair from '../../assets/images/Stellair.svg';
import Barco from '../../assets/images/Barco.svg';
import Doctor from '../../assets/images/Doctolib.svg';
import Lifen from '../../assets/images/Lifen.svg';
import Antibioclic from '../../assets/images/BCB.svg';
import Reco from '../../assets/images/Reco.svg';

/**
 * UserHeaderButtons component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setCollapsed - Function to set the collapsed state.
 * @param {Array} [props.searchPatientFields] - Array of search patient fields.
 * @param {Array} [props.searchPatientFieldsToConsultation] - Array of search patient fields for consultation.
 * @param {Object} [props.enums] - Object containing various enums.
 * @param {Array} props.enums.genders - Array of genders.
 * @param {Array} props.enums.blood_group - Array of blood groups.
 * @param {Array} props.enums.cpamData - Array of CPAM data.
 * @param {Array} props.enums.allergies - Array of allergies.
 * @param {Array} props.enums.medical_history - Array of medical history.
 * @returns {JSX.Element} The rendered UserHeaderButtons component.
 */
export const UserHeaderButtons = ({
  searchPatientFields,
  searchPatientFieldsToConsultation,
  enums,
  setCollapsed
}) => {
  const { width } = useHandleResize();
  const { t } = useTranslation();
  const [modal, setModal] = useState(null);
  /**
   * Generates a modal component based on the provided modal type and fields.
   *
   * @function
   * @param {string} modalType - The type of the modal ('search' or 'consultation').
   * @param {object} fields - The fields to pass to the modal component.
   * @returns {JSX.Element} - The generated modal component.
   */
  const generateModal = (modalType, fields) => {
    const modalKey = Date.now(); // Use a unique key, or current timestamp
    const modalComponent =
      modalType === 'search' ? (
        <SearchPatientModal key={modalKey} fields={fields} enums={enums} />
      ) : (
        <SearchPatientModalToConsultation
          key={modalKey}
          fields={fields}
          enums={enums}
        />
      );

    return modalComponent;
  };
  /**
   * Opens a modal based on the provided parameters.
   *
   * @function
   * @param {boolean} searchPatient - Indicates whether the modal is for searching a patient.
   * @returns {void}
   */
  const openModal = (searchPatient) => {
    const fields = searchPatient
      ? searchPatientFields
      : searchPatientFieldsToConsultation;
    const modalToOpen = generateModal(
      searchPatient ? 'search' : 'consultation',
      fields
    );

    setModal(modalToOpen);
  };

  const redirect = (url) => {
    window.open(url, '_blank');
  };

  const buttons = [
    {
      title: 'Stellair',
      url: `${process.env.REACT_APP_STELLAIR_URL}`,
      icon: Stellair
    },
    {
      title: 'Doctolib',
      url: 'https://pro.doctolib.fr/signin',
      icon: Doctor
    },
    {
      title: 'Lifen',
      url: 'https://app.planning.lifen.health/users/sign_in',
      icon: Lifen
    },
    {
      title: 'Reco Médicales',
      url: 'https://recomedicales.fr/',
      icon: Reco
    },
    {
      title: 'Antibioclic',
      url: 'https://antibioclic.com/',
      icon: Antibioclic
    },
    {
      title: 'Barco',
      url: 'https://auth.demetra.barco.com/login?redirect_uri=https%3A%2F%2Fdemetra.barco.com%2Foauth%2Fredirect&response_type=code&client_id=490kon7oalt11j2i4btcetjvfj',
      icon: Barco
    }
  ];

  return (
    <>
      <Row
        style={{
          gap: 12,
          display: 'flex',
          alignItems: 'center'
        }}
        mode="horizontal"
        onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      >
        {modal}
        <ConfidentialButton />
        <Tooltip title={t(`basiclayout.headerButtons.addPatient`)}>
          <Button
            shape="circle"
            onClick={() => openModal(false)}
            icon={<PlusCircleOutlined />}
          />
        </Tooltip>
        <Tooltip title={t(`basiclayout.headerButtons.searchPatient`)}>
          <Button
            shape="circle"
            icon={<SearchOutlined onClick={() => openModal(true)} />}
          />
        </Tooltip>
      </Row>
      <Row
        style={{
          gap: 8,
          display: 'flex',
          alignItems: 'center'
        }}
        mode="horizontal"
        onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      >
        {buttons.map((button) => (
          <Tooltip title={button.title} key={button.title}>
            <Button
              type="primary"
              onClick={() => redirect(button.url)}
              style={{
                background: 'none',
                border: 'none',
                boxShadow: 'none',
                padding: 0,
                margin: 0,
                height: '40px', // Adjust as needed
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <img
                src={button.icon}
                alt={button.title}
                style={{ width: '30px', height: '30px', objectFit: 'contain' }}
              />
            </Button>
          </Tooltip>
        ))}
      </Row>
    </>
  );
};

UserHeaderButtons.propTypes = {
  setCollapsed: PropTypes.func.isRequired,
  searchPatientFields: PropTypes.arrayOf(PropTypes.shape({})),
  searchPatientFieldsToConsultation: PropTypes.arrayOf(PropTypes.shape({})),
  enums: PropTypes.shape({
    genders: PropTypes.arrayOf(PropTypes.string),
    blood_group: PropTypes.arrayOf(PropTypes.string),
    cpamData: PropTypes.arrayOf(PropTypes.shape({})),
    allergies: PropTypes.arrayOf(PropTypes.shape({})),
    medical_history: PropTypes.arrayOf(PropTypes.shape({}))
  })
};
UserHeaderButtons.defaultProps = {
  searchPatientFields: undefined,
  enums: undefined,
  searchPatientFieldsToConsultation: undefined
};
