import { useAuthContext } from '../../contexts/AuthContext';
import { HomeDoctorKanban } from '../../routes/home/HomeDoctorKanban';
import { PatientRouter } from '../../routes/patients/PatientRouter';

/**
 * Renders the default landing page based on the user's landing choice.
 * @component
 * @returns {JSX.Element} The rendered landing page component.
 */
export const DefaultLanding = () => {
  const { user } = useAuthContext();
  const { landing_choice } = user || {};

  const dispatchLanding = () => {
    switch (landing_choice) {
      case 'ROOMS':
        return <HomeDoctorKanban />;
      case 'ADMINISTRATIVE':
        return <PatientRouter />;
      default:
        return <HomeDoctorKanban />;
    }
  };

  return <>{dispatchLanding()}</>;
};
