import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useReasonColumn } from './reasonColumn';
import { useDownloadColumn } from './downloadColumn';
import { usePrintColumn } from './printColumn';

/**
 * @hook
 * @name useColumnsExitPrescriptions
 * @description A custom hook to return columns for exit prescriptions
 * @param {Boolean} isSwitched - switch columns
 * @returns {Array} - array of columns
 */
export const useColumnsExitPrescriptions = (isSwitched) => {
  const { t } = useTranslation();
  const reasonColumn = useReasonColumn();
  const downloadColumn = useDownloadColumn();
  const printColumn = usePrintColumn();

  const formatDate = (date) => dayjs(date).format('DD-MM-YYYY');

  /**
   * @function
   * @name entitiesList
   * @description return a string of entities names
   * @param {Array} posologies - array of posologies
   * @returns {String} - string of entities
   */
  const entitiesList = (posologies) => {
    if (!Array.isArray(posologies)) {
      return '-';
    }

    const entityStrings = posologies.map((posology) => {
      const { entity } = posology || {};
      const { shortname, label_dose } = entity || {};

      return shortname && label_dose ? `${shortname} ${label_dose}` : '-';
    });

    return entityStrings.join(', ');
  };

  /**
   * @function
   * @name contentToDisplay
   * @description return a string of entities names or 'Texte libre'
   * @param {Object} record - record object
   * @returns {String} - string of entities or 'Texte libre'
   */
  const contentToDisplay = (record) => {
    if (record) {
      const { posologies, content } = record;

      if (posologies) {
        return entitiesList(posologies);
      }
      return content ? t('prescription.favorites.enums.FREE-TEXT') : '-';
    }
    return '-';
  };

  const dateColumn = {
    title: t('consultations.date'),
    key: 'created_at',
    dataIndex: 'created_at',
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    render: (created_at) => (created_at ? formatDate(created_at) : '-')
  };

  const treatmentsMedicamentationsPrescribedColumn = {
    title: t('consultations.documents.treatments_medicamentations_prescribed'),
    key: 'posologies',
    sorter: (a, b) => contentToDisplay(b).length - contentToDisplay(a).length,
    render: (record) => contentToDisplay(record)
  };

  return isSwitched
    ? [
        reasonColumn,
        dateColumn,
        treatmentsMedicamentationsPrescribedColumn,
        downloadColumn,
        printColumn
      ]
    : [
        dateColumn,
        reasonColumn,
        treatmentsMedicamentationsPrescribedColumn,
        downloadColumn,
        printColumn
      ];
};
