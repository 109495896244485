import { useTranslation } from 'react-i18next';

/**
 * Custom hook for defining columns used in the ListSurgicalHistory component.
 *
 * @hook
 * @returns {object} An object containing the columns configuration.
 * @property {array} columns - Array of column objects for rendering in a table.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('data_bases.surgical_history.form.label'),
      dataIndex: 'label',
      key: 'label',
      sorter: true
    }
  ];

  return { columns };
};
