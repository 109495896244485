import dayjs from 'dayjs';
import { Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { useDownloadDocument } from '../../../../utils/downloadDoc';

// TODO : WIP
export const useColumns = () => {
  const { t } = useTranslation();
  const { downloadDocument } = useDownloadDocument();

  const iconSize = 18;

  const columns = [
    {
      title: t('consultations.results_laboratories.result_date_arrival'),
      key: 'created_at',
      dataIndex: 'created_at',
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (date) => dayjs(date).format('DD-MM-YYYY')
    },
    {
      title: t('consultations.results_laboratories.prescribed_analysis'),
      key: 'prescriptions',
      dataIndex: 'prescriptions',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <EyeOutlined style={{ fontSize: iconSize }} />
          <Divider type="vertical" />
          <Button
            icon={
              <DownloadOutlined
                style={{ fontSize: iconSize }}
                onClick={() => downloadDocument(record?.prescription_document)}
              />
            }
            style={{ fontSize: iconSize }}
          />
        </>
      )
    }
  ];

  return { columns };
};
