import { Card, Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiRadioactive } from '@mdi/js';

/**
 * A component that displays a radiology prescription card.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.translationKey - The key to be used for translation.
 * @param {Function} props.t - The translation function.
 * @param {string} props.id - The id of the prescription.
 *
 * @returns {JSX.Element} The RadiologyPrescriptionCard component.
 */
const { Text } = Typography;

export const RadiologyPrescriptionCard = ({ translationKey, id, t }) => (
  <Col span={8}>
    <Link to={`/prescription/create/radiology/${id}`}>
      <Card bordered={false} style={{ background: '#8AC7FF66' }} hoverable>
        <Row justify="center">
          <Icon path={mdiRadioactive} size="40px" color="#004A8F" />
        </Row>
        <Row justify="center">
          <Text strong style={{ color: '#004A8F' }}>
            {t(`${translationKey}.radiology`)}
          </Text>
        </Row>
      </Card>
    </Link>
  </Col>
);

RadiologyPrescriptionCard.propTypes = {
  translationKey: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};
