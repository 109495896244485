import { Link } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';

/**
 * Returns an array of action columns for the centers.
 * @function
 *
 * @returns {Array} An array of action columns.
 */
export const useActionColumns = () => [
  {
    key: 'actions',
    render: (record) => (
      <Link to={`/centers/settings/${record._id}`}>
        <SettingOutlined />
      </Link>
    )
  }
];
