import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListResource, ContentCustom } from '../../components';
import { useColumns } from './columns';
import { centersTable } from './centers/CentersTable';
import { Center } from '../../models/Center';
import { Company } from '../../models/Company';

/**
 * Renders a list of centers.
 *
 * @component
 * @returns {JSX.Element} The list of centers component.
 */
export const ListCompanies = () => {
  const { t } = useTranslation();
  const columns = useColumns();
  const [refresh, setRefresh] = useState(false);

  const archiveAction = {
    centers: async (id, archived) => {
      await Center.archivingCenter(id, {
        archived: !archived
      });
      setRefresh(!refresh);
    },
    companies: async (record) => {
      await Company.archiveUnarchiveCompany(record._id, {
        archived: !record.archived
      });
      setRefresh(!refresh);
    }
  };

  return (
    <ContentCustom>
      <ListResource
        resourceName="companies"
        columns={columns}
        forceRefresh={refresh}
        resourceModelName="Company"
        populate="manager,centers"
        withUploadButton={false}
        expandable={{
          expandedRowRender: (record) => {
            const filteredType = record?.centers.flatMap(
              (center) => center.type
            );
            const typeArr = Array.from(new Set(filteredType));
            return centersTable(t, record, typeArr, archiveAction.centers);
          },
          rowExpandable: () => true
        }}
        archiveAction={{
          customAction: (record) => archiveAction.companies(record)
        }}
      />
    </ContentCustom>
  );
};
