import PropTypes from 'prop-types';
import { Collapse, Col } from 'antd';
import { Datatable } from '../../../../components';
import { useColumns } from './columnsUnpaidList';

const { Panel } = Collapse;

/**
 * Renders a collapsible component for displaying unpaid invoices.
 *
 * @component
 * @param {string} header - The header text for the collapsible panel.
 * @returns {JSX.Element} The CollapseUnpaid component.
 */
export const CollapseUnpaid = ({ header }) => {
  const columns = useColumns();

  return (
    <Col span={24}>
      <Collapse expandIconPosition="end" className="patient-form-collapse">
        <Panel header={header} key="1">
          <Datatable
            resourceName="invoices"
            columns={columns}
            resourceModelName="Invoice"
          />
        </Panel>
      </Collapse>
    </Col>
  );
};
CollapseUnpaid.propTypes = {
  header: PropTypes.string.isRequired
};
