import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Modal, Col, Form, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import useFields from '../../fields';
import { patchConsultation } from '../../Components/patchConsultation';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { formItemLayoutCustom } from '../../../../utils/constants/formLayout';
import { CardUpload } from '../../../../components/CardUpload/CardUpload';

/**
 * ModalMedicalConsultation component displays a modal for medical consultation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.resource - The resource for the consultation.
 * @param {string} props.idConsultation - The ID of the consultation.
 * @param {boolean} props.isModal - Indicates whether the modal is open or not.
 * @param {function} props.setModal - Function to set the modal state.
 * @param {Object} props.patient - The patient object.
 * @returns {JSX.Element} The ModalMedicalConsultation component.
 */
export const ModalMedicalConsultation = ({
  resource,
  idConsultation,
  isModal,
  setModal,
  patient
}) => {
  const { medicalConsultationFields } = useFields();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const photoField = {
    noLabel: true,
    name: ['photos'],
    input: <CardUpload dbKey="photos" resource="consultations" />
  };

  const { handleSubmit } = patchConsultation(idConsultation, (value) =>
    setModal(value)
  );

  useEffect(() => {
    form.setFieldsValue(patient.current_consultation);
  }, [patient]);

  return (
    <Row justify="space-between">
      <Modal
        open={isModal}
        onCancel={() => setModal(false)}
        okText={t('buttons.validate')}
        width="50%"
        resource={resource}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          {...formItemLayoutCustom}
          layout="vertical"
        >
          <Row justify="space-between" style={{ paddingTop: '2em' }}>
            <h1>{t(`${resource}.form.medical_consultation`)}</h1>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={photoField.noLabel ? null : 'Photo'}
                name={photoField.name}
                rules={photoField.rules}
              >
                {photoField.input}
              </Form.Item>
            </Col>
            <Col span={24}>
              {medicalConsultationFields.map((field) =>
                generateFields(resource, field)
              )}
            </Col>
          </Row>
          <div style={{ textAlign: 'right', padding: '1em' }}>
            <Button
              htmlType="submit"
              icon={<CheckOutlined />}
              style={{ borderRadius: '15px' }}
            >
              {t('buttons.register')}
            </Button>
          </div>
        </Form>
      </Modal>
    </Row>
  );
};
ModalMedicalConsultation.propTypes = {
  resource: PropTypes.string,
  id: PropTypes.string,
  idConsultation: PropTypes.string,
  isModal: PropTypes.bool,
  setModal: PropTypes.func,
  patient: PropTypes.shape({
    current_consultation: PropTypes.shape({})
  })
};
ModalMedicalConsultation.defaultProps = {
  resource: undefined,
  id: undefined,
  idConsultation: undefined,
  isModal: undefined,
  setModal: () => {},
  patient: null
};
