import { Route, Routes } from 'react-router-dom';
import { CreateUpdatePrescriptions } from './CreateUpdatePrescriptions';
import { CreateUpdateOptions } from './options/CreateUpdateOptions';
import { Exception } from '../../../components';

/**
 * Router component for Stay Prescriptions handling create and edit routes.
 *
 * @component
 * @returns {JSX.Element} JSX element representing the PrescriptionsRouter component.
 */
export const PrescriptionsRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdatePrescriptions purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdatePrescriptions purpose="edit" />}
    />
    <Route
      path="/prescription/:id/option/create"
      element={<CreateUpdateOptions purpose="createOption" />}
    />
    <Route
      path="/prescription/:id/option/edit/:optionId"
      element={<CreateUpdateOptions purpose="editOptions" />}
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
