import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { WFLStatus } from '../../utils/constants/tagColors';
import { ListResource } from '../../components';

/**
 * Component that displays the list of workflows.
 *
 * @component
 * @returns {ReactElement} The ListWorkflow component
 */
const ListWorkflow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const headers = [
    {
      label: 'title',
      key: 'title'
    },
    {
      label: 'status',
      key: 'status'
    },
    {
      label: 'description',
      key: 'description'
    },
    {
      label: 'created_at',
      key: 'created_at'
    },
    {
      label: 'updated_at',
      key: 'updated_at'
    }
  ];

  const columns = [
    {
      title: t('workflows.form.title'),
      key: 'title',
      dataIndex: 'title',
      sorter: true
    },
    {
      title: t('workflows.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => <Tag color={WFLStatus[status]}>{t(status)}</Tag>,
      sorter: true
    },
    {
      title: t('workflows.form.description'),
      key: 'description',
      dataIndex: 'description',
      sorter: true
    },
    {
      title: t('workflows.form.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) =>
        `le ${dayjs(created_at).format('DD-MM-YYYY à HH:mm')}`,
      sorter: true
    },
    {
      title: t('workflows.form.updated_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) =>
        `le ${dayjs(created_at).format('DD-MM-YYYY à HH:mm')}`,
      sorter: true
    }
  ];

  return (
    <ListResource
      resourceName="workflows"
      // withSubRoutes
      columns={columns}
      headers={headers}
      resourceModelName="Workflow"
      onDoubleClickAction={{
        action: (record) => {
          navigate(`/workflows/edit/${record._id}`);
        }
      }}
      showAction={false}
      duplicateAction
    />
  );
};

export default ListWorkflow;
