import PropTypes from 'prop-types';
import { Button, List } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useStellairContext } from '../../contexts/StellairContext';
import { useRenderDescription } from './renderDescription';
import { fillFormDatasFromVitaleCard } from '../../routes/patients/fillFormDatasFromVitaleCard';

const { Item } = List;

/**
 * Renders a card item for a Vitale card beneficiary.
 *
 * @component
 * @prop {Object} props - The component props.
 * @prop {Object} props.beneficiary - The beneficiary data.
 * @prop {Object} props.beneficiary.donneesPatient - The patient data.
 * @prop {Object} props.beneficiary.donneesPatient.CV - The vitale card data.
 * @prop {Object} props.beneficiary.donneesPatient.CV.donneesAmo - The AMO data.
 * @prop {string} props.beneficiary.donneesPatient.CV.donneesAmo.nom - The patient's last name.
 * @prop {string} props.beneficiary.donneesPatient.CV.donneesAmo.prenom - The patient's first name.
 * @prop {Function} props.fillForm - A function to fill the form.
 * @prop {Function} props.setIsModalVisible - A function to set the visibility of the modal.
 * @returns {JSX.Element} The rendered component.
 */
export const VitaleCardItem = ({ beneficiary, form, setIsModalVisible }) => {
  const patientData = beneficiary?.donneesPatient?.CV?.donneesAmo || {}; // These are keys from Stellair and yes, it's in french.
  const { setPatientStellairId } = useStellairContext();
  const { renderDescription } = useRenderDescription(patientData);

  return (
    <Item
      actions={[
        <Button
          type="link"
          icon={<CheckOutlined />}
          onClick={() =>
            fillFormDatasFromVitaleCard(
              patientData,
              beneficiary.id,
              form,
              setPatientStellairId,
              setIsModalVisible
            )
          }
        />
      ]}
    >
      <Item.Meta
        title={`${patientData.nom} ${patientData.prenom}`} // These are keys from Stellair and yes, it's in french.
        description={renderDescription()}
      />
    </Item>
  );
};

VitaleCardItem.propTypes = {
  beneficiary: PropTypes.shape({
    donneesPatient: PropTypes.shape({
      CV: PropTypes.shape({
        donneesAmo: PropTypes.shape({
          nom: PropTypes.string,
          prenom: PropTypes.string
        })
      })
    }),
    id: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({}).isRequired,
  setIsModalVisible: PropTypes.func.isRequired
};
