/* eslint-disable radix */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { Button, Row, Col, Select, Mentions } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { displayTagInMention, mapInputs } from '../../utils';

const { Option } = Select;
const conditionsArray = ['!==', '<', '<=', '===', '>=', '>'];

const DecisionConditionsBuilder = ({
  sourceElements,
  decisionConditions,
  fields,
  setFields
}) => {
  const getCurrentKeyMax = (list) => {
    let max = 0;
    list.forEach((field) => {
      if (field.key > max) {
        max = field.key;
      }
    });
    return max;
  };

  const [keyMax, setKeyMax] = useState(
    getCurrentKeyMax(decisionConditions) || 1
  );

  const addField = () => {
    const newKeyMax = keyMax + 1;
    const newField = {
      key: newKeyMax,
      input1: null,
      condition: '===',
      input2: null,
      afterCondition: ''
    };
    const newFields = [...fields, newField];
    setKeyMax(newKeyMax);
    setFields(newFields);
  };

  const removeField = (key) => {
    const newFields = fields.filter((field) => field.key !== key);
    setFields(newFields);
  };

  const updateField = (key, type, value) => {
    const newFields = fields.map((field) => {
      const newField = field;
      if (field.key === key) {
        newField[type] = value;
        return newField;
      }
      return field;
    });
    setFields(newFields);
  };

  const tagDrag = (event, ElmLabel, field) => {
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
    const data = `@{${ElmLabel}|${field}}`;
    event.dataTransfer.setData('text', data);
  };

  return (
    <Row>
      <Col span={24}>{mapInputs(sourceElements, true, tagDrag)}</Col>
      <Col span={24}>
        {fields.map((field, index) => (
          <Row key={`${field}`} justify="center">
            <Col span={7}>
              <Mentions
                placeholder={`Input ${
                  2 * index + 1
                }: Type @{ to get suggestions`}
                value={field.input1}
                onChange={(value) => updateField(field.key, 'input1', value)}
                style={{ width: '100%' }}
                key={`output1-${field.key}`}
                className="input-code"
              >
                {displayTagInMention(sourceElements, true)}
              </Mentions>
            </Col>
            <Col span={3} style={{ marginLeft: 10 }}>
              <Select
                placeholder="Condition"
                value={field.condition}
                onChange={(value) => updateField(field.key, 'condition', value)}
                style={{ width: '100%' }}
                key={`condition-${field.key}`}
              >
                {conditionsArray.map((condition) => (
                  <Option key={condition} value={condition}>
                    {condition}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={7} style={{ marginLeft: 10 }}>
              <Mentions
                placeholder={`Input ${
                  2 * index + 2
                }: Type @{ to get suggestions`}
                value={field.input2}
                onChange={(value) => updateField(field.key, 'input2', value)}
                style={{ width: '100%' }}
                key={`output2-${field.key}`}
                className="input-code"
              >
                {displayTagInMention(sourceElements, true)}
              </Mentions>
            </Col>

            <Col span={4} style={{ marginLeft: 10 }}>
              <Select
                placeholder="Condition additionnelle"
                value={field.afterCondition}
                onChange={(value) =>
                  updateField(field.key, 'afterCondition', value)
                }
                style={{ width: '100%' }}
                key={`afterCondition-${field.key}`}
              >
                <Option value="AND">AND</Option>
                <Option value="OR">OR</Option>
              </Select>
            </Col>
            <Col>
              <Col span={2} style={{ marginBottom: 10 }}>
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => removeField(field.key)}
                  style={{ marginLeft: 10, marginTop: 10, color: 'red' }}
                />
              </Col>
            </Col>
          </Row>
        ))}

        <Row>
          <Button
            type="dashed"
            onClick={() => addField()}
            style={{ width: '100%', margin: '10px 0px' }}
            icon={<PlusOutlined />}
            block
          >
            Ajouter un output
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

DecisionConditionsBuilder.propTypes = {
  sourceElements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  decisionConditions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      input1: PropTypes.string.isRequired,
      condition: PropTypes.oneOf(['!==', '<', '<=', '===', '>=', '>'])
        .isRequired,
      input2: PropTypes.string.isRequired,
      afterCondition: PropTypes.oneOf(['AND', 'OR', '']).isRequired
    })
  ).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      input1: PropTypes.string,
      condition: PropTypes.oneOf(['!==', '<', '<=', '===', '>=', '>']),
      input2: PropTypes.string,
      afterCondition: PropTypes.oneOf(['AND', 'OR'])
    })
  ).isRequired,
  setFields: PropTypes.func.isRequired
};

export default DecisionConditionsBuilder;
