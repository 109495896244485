import { BaseModel } from './BaseModel';

/**
 * Represents a User model.
 * @class
 * @extends BaseModel
 */
export class User extends BaseModel {
  /**
   * Creates a new User instance.
   * @constructor
   * @param {Object} data - The data for the User.
   */
  constructor(data) {
    super('user', undefined, data);
  }

  /**
   * The name of the model.
   * @static
   * @type {string}
   */
  static modelName = 'users';

  /**
   * Retrieves all users.
   * @static
   * @function getAll
   *
   * @param {Object} params - The query parameters.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static getAll({ ...params } = {}) {
    return BaseModel.fetchAPI('GET', User.modelName, {}, params);
  }

  /**
   * Retrieves a user by ID.
   * @static
   * @function getOneById
   *
   * @param {string} id - The ID of the user.
   * @param {Object} params - The query parameters.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static getOneById(id, { ...params } = {}) {
    return BaseModel.dispatchAPI('GET', {
      url: `${User.modelName}/${id}`,
      params: { ...params }
    });
  }

  /**
   * Posts stay prescription choices.
   * @static
   * @function postStayPrescriptionChoices
   *
   * @param {Object} body - The request body.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static postStayPrescriptionChoices(body) {
    return BaseModel.dispatchAPI('POST', {
      url: User.modelName,
      body
    });
  }

  /**
   * Retrieves doctors by center.
   * @static
   * @function getDoctorsByCenter
   * @param {Object} params - The parameters for the API request.
   * @returns {Promise} A promise that resolves with the API response.
   */
  static getDoctorsByCenter({ ...params } = {}) {
    return BaseModel.fetchAPI('GET', `${User.modelName}`, {}, params);
  }

  /**
   * Archive or unarchive a user.
   * @static
   * @function archiveUnarchiveUser
   *
   * @param {string} id - The ID of the user to archive or unarchive.
   * @param {Object} archived - The archived status of the user.
   * @returns {Promise} A promise that resolves with the API response.
   */
  static archiveUnarchiveUser(id, { ...archived }) {
    return BaseModel.fetchAPI('PATCH', `${User.modelName}/archive/${id}`, {
      ...archived
    });
  }

  /**
   * Retrieves the enums for a given model.
   * @static
   * @function getEnums
   *
   * @param {string} modelName - The name of the model.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static getEnums() {
    return BaseModel.dispatchAPI('GET', {
      url: `${User.modelName}/enums`
    });
  }
}
