import { Card, List, Typography } from 'antd';
import PropTypes from 'prop-types';
import {
  CheckCircleOutlined,
  DownloadOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { TitleSeeMoreButton } from '../Components/TitleSeeMoreButton';
import { useDownloadDocument } from '../../../utils/downloadDoc';

const { Text } = Typography;

/**
 * Renders a component to display laboratory results for a patient.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the patient.
 * @param {Array} props.fields - The fields to display.
 * @returns {JSX.Element} The rendered component.
 */
export const ShowResultsLaboratories = ({ id, fields }) => {
  const { viewDocument, downloadDocument } = useDownloadDocument();

  const title = 'results_laboratories';

  return (
    <Card>
      <TitleSeeMoreButton id={id} fields={fields} title={title} />
      <List
        dataSource={[]}
        renderItem={(item) => (
          <List.Item
            actions={[
              <EyeOutlined
                onClick={() => viewDocument(item?.prescription_document)}
              />,
              <DownloadOutlined
                onClick={() => downloadDocument(item?.prescription_document)}
              />
            ]}
          >
            <CheckCircleOutlined />
            {item?.prescriptions?.map((prescription) => (
              <Text>{prescription}</Text>
            ))}
            <Text>{item.date}</Text>
          </List.Item>
        )}
      />
    </Card>
  );
};
ShowResultsLaboratories.propTypes = {
  id: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({}))
};
ShowResultsLaboratories.defaultProps = {
  fields: undefined
};
