import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {
  doctorRoutes,
  secretaryRoutes
} from '../../../../utils/constants/userRoutes';

export const EditButton = ({ id, position }) => {
  const { t } = useTranslation();

  let routes;
  if (position === 'DOCTOR' || position === 'NURSE') {
    routes = doctorRoutes;
  } else if (position === 'SECRETARY') {
    routes = secretaryRoutes;
  }
  return (
    <Link to={{ pathname: `${routes.PATIENTS}/edit/${id}` }}>
      <Button
        style={{
          borderRadius: '15px',
          margin: '2px'
        }}
      >
        {t('buttons.edit')}
        <EditOutlined />
      </Button>
    </Link>
  );
};

EditButton.propTypes = {
  id: PropTypes.string.isRequired,
  position: PropTypes.string
};
EditButton.defaultProps = {
  position: ''
};
