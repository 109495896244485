import { Card, Checkbox, Col, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { renderTitle } from '../../utils/renderTitle';
import { cardStyles } from '../../utils/cardStyles';

/**
 * Renders a card component for displaying locations in a radiology prescription form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.locations - The array of locations to be displayed.
 * @param {string} props.locationValue - The selected location value.
 * @param {function} props.setLocationValue - The function to set the selected location value.
 * @param {object} props.form - The form object.
 * @returns {JSX.Element} The rendered CardLocations component.
 */
export const CardLocations = ({
  locations,
  locationValue,
  setLocationValue,
  form
}) => {
  const { t } = useTranslation();

  return (
    <Col xs={24} md={24} lg={6} style={{ display: 'flex' }}>
      <Card
        title={renderTitle(
          t('prescription.radiology.form.card_titles.location')
        )}
        style={{
          border: 'none',
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
        styles={cardStyles}
      >
        {locations && (
          <Form.Item
            name="location"
            style={{
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Checkbox.Group
              style={{
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {locations.map((location) => (
                <Checkbox
                  key={location.value}
                  disabled={
                    locationValue !== null && location.value !== locationValue
                  }
                  value={location.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setLocationValue(e.target.value);
                    } else {
                      setLocationValue(null);
                      form.resetFields(['laterality', 'effects']);
                    }
                  }}
                >
                  {location.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        )}
      </Card>
    </Col>
  );
};

CardLocations.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  locationValue: PropTypes.string,
  setLocationValue: PropTypes.func.isRequired,
  form: PropTypes.shape({
    resetFields: PropTypes.func.isRequired
  }).isRequired
};

CardLocations.defaultProps = {
  locations: [],
  locationValue: null
};
