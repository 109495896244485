import PropTypes from 'prop-types';
import { Collapse, Col } from 'antd';
import { CollapsePatientForm } from './CollapsePatientsForm';

const { Panel } = Collapse;

/**
 * Renders a collapsible panel for a patient form.
 *
 * @component
 * @param {Object[]} fields - The fields to be displayed in the patient form.
 * @param {string} header - The header text for the collapsible panel.
 * @param {string[]} defaultActiveKey - The default active key for the collapsible panel.
 * @param {JSX.Element} [extra] - Additional JSX elements to be rendered in the collapsible panel.
 * @returns {JSX.Element} The rendered CollapsePatient component.
 */
export const CollapsePatient = ({
  fields,
  header,
  defaultActiveKey,
  extra
}) => (
  <Col xs={24} xxl={12}>
    <Collapse
      defaultActiveKey={defaultActiveKey}
      expandIconPosition="end"
      className="patient-form-collapse"
    >
      <Panel header={header} key="1">
        <CollapsePatientForm fields={fields} />
        {extra && extra}
      </Panel>
    </Collapse>
  </Col>
);

CollapsePatient.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
  header: PropTypes.string.isRequired,
  defaultActiveKey: PropTypes.arrayOf(String).isRequired,
  extra: PropTypes.element
};

CollapsePatient.defaultProps = {
  fields: undefined,
  extra: null
};
