import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Card, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ConstantsAndOtherSurvey } from './SurveyOfConstants/ConstantsAndOtherSurvey';
import { useConsultationsContext } from '../ConsultationsContext';
import useFields from '../fieldsConstantsSurvey';

/**
 * Renders the survey of constants for a patient.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the patient.
 * @returns {JSX.Element} The rendered component.
 */
export const ShowSurveyOfConstants = ({ id }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { patient } = useConsultationsContext();
  const {
    constantsAndOtherSurveyFields,
    delocalizedBiologyFields,
    urineAnalysisFields
  } = useFields(form);

  const constantsSurveyProps = [
    {
      title: 'constants_and_other_surveys',
      fields: constantsAndOtherSurveyFields
    },
    {
      title: 'delocalized_biology',
      fields: delocalizedBiologyFields
    },
    {
      title: 'urinary_analysis',
      fields: urineAnalysisFields
    }
  ];

  useEffect(() => {
    form.setFieldsValue({
      height: patient?.height || null,
      weight: patient?.weight || null
    });
  }, [patient]);

  return (
    <Card
      style={{ height: 'fit-content' }}
      title={t('consultations.form.constants_survey')}
    >
      {constantsSurveyProps.map(({ title, fields }) => (
        <ConstantsAndOtherSurvey
          key={title}
          title={title}
          fields={fields}
          resource="constants_surveys"
          idPatient={id}
          form={form}
        />
      ))}
    </Card>
  );
};

ShowSurveyOfConstants.propTypes = {
  id: PropTypes.string.isRequired
};
