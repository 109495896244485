import PropTypes from 'prop-types';
import { TreeTransfer } from '../../../../../components/TreeTransfer/TreeTransfer';

/**
 * Transfer component for prescriptions.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setPrescriptionsData - Function to set prescriptionData with target nodes.
 * @param {Object[]} props.stayPrescriptions - Array of stay prescriptions data.
 * @returns {JSX.Element} JSX.Element.
 */
export const PrescriptionsTranfer = ({
  targetKeys,
  setTargetKeys,
  setTargetNodes,
  stayPrescriptions
}) => {
  const onChange = (keys) => {
    setTargetKeys(keys);
  };

  return (
    <TreeTransfer
      dataSource={stayPrescriptions}
      targetKeys={targetKeys}
      onChange={onChange}
      setTargetKeys={setTargetKeys}
      setTargetNodes={setTargetNodes}
    />
  );
};

PrescriptionsTranfer.propTypes = {
  stayPrescriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  targetKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTargetKeys: PropTypes.func.isRequired,
  setTargetNodes: PropTypes.func.isRequired
};
