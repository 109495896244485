/**
 * Translates prescription types based on the category.
 * @function
 *
 * @param {string} category - The category of the prescription.
 * @param {object} prescription_types - The object containing prescription types.
 * @param {function} t - The translation function.
 * @returns {Array} - The array of translated prescription types.
 */
export const translatePrescriptionTypes = (category, prescription_types, t) => {
  let prescriptionsTypesOptionsArray;
  if (category === 'IMAGING') {
    prescriptionsTypesOptionsArray = category
      ? (prescription_types[category] || []).map((item) => ({
          label: t(
            `data_bases.stay_prescriptions.form.prescription_types.${category}.${item}`
          ),
          value: item
        }))
      : [];
  } else if (category === 'BIOLOGY') {
    prescriptionsTypesOptionsArray = Object.values(prescription_types?.BIOLOGY)
      .flatMap((item) => item)
      .map((item) => ({
        label: t(
          `data_bases.stay_prescriptions.form.prescription_types.${category}.${item}`
        ),
        value: item
      }));
  } else if (category === 'SRN_CARE') {
    prescriptionsTypesOptionsArray = Object.values(prescription_types?.SRN_CARE)
      .flatMap((item) => item)
      .map((item) => ({
        label: t(
          `data_bases.stay_prescriptions.form.prescription_types.${category}.${item}`
        ),
        value: item
      }));
  }

  return prescriptionsTypesOptionsArray;
};
