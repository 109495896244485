import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

export const DMPButton = () => {
  const { t } = useTranslation();
  return (
    <Button
      style={{
        borderRadius: '15px',
        margin: '2px'
      }}
    >
      {t('buttons.shared_medical_record')}
    </Button>
  );
};
