import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Row } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

export const RegisterBackButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Row justify="end">
      <Button
        style={{ margin: '0 10px', borderRadius: '15px' }}
        type="link"
        onClick={() => navigate(-1)}
      >
        {`${t('buttons.cancel')} `}
        <CloseOutlined />
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        style={{
          borderRadius: '15px'
        }}
      >
        {`${t('buttons.register')} `}
        <CheckOutlined />
      </Button>
    </Row>
  );
};
