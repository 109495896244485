import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';

/**
 * CreateUpdateContainer component is a reusable container component for creating and updating resources.
 *
 * @component
 * @example
 * return (
 *   <CreateUpdateContainer
 *     purpose="create"
 *     fields={[]}
 *     baseUrl="/api/resources"
 *     resource="Resource"
 *   />
 * )
 *
 * @param {string} purpose - The purpose of the container (create or edit).
 * @param {Array} fields - The array of fields to be rendered in the form.
 * @param {string} baseUrl - The base URL for API requests.
 * @param {string} resource - The name of the resource.
 * @param {string} layout - The layout of the form (vertical or horizontal).
 * @param {boolean} loadingFields - Whether the fields are being loaded.
 * @param {boolean} isPageHeaderCustom - Whether to use a custom page header.
 * @param {boolean} isFormExtra - Whether to use a custom form extra.
 * @param {Object} config - The configuration object for API requests.
 * @param {Object} config.onGetResource - The configuration for getting a resource.
 * @param {Function} config.onGetResource.setFields - The function to set fields when getting a resource.
 * @param {Object} config.onCreateResource - The configuration for creating a resource.
 * @param {Function} config.onCreateResource.setBody - The function to set the request body when creating a resource.
 * @param {Object} config.onUpdateResource - The configuration for updating a resource.
 * @param {Function} config.onUpdateResource.setBody - The function to set the request body when updating a resource.
 * @param {ReactElement} formExtra - The additional content to be rendered in the form.
 * @param {string} tradKey - The translation key for the resource.
 * @param {string} submitLabel - The label for the submit button.
 * @param {Function} customSubmit - The custom submit function.
 * @param {boolean} isParentLoading - Whether the parent component is loading.
 * @param {boolean} navigateCustom - Whether to use a custom navigation.
 * @param {string} customUrl - The custom URL for navigation.
 * @param {string} extraQuery - The extra query string for API requests.
 * @param {Object} customFormInstance - The custom form instance.
 *
 * @returns {ReactElement} The rendered CreateUpdateContainer component.
 */
export const CreateUpdateContainer = forwardRef(
  (
    {
      layout,
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading,
      isFormExtra,
      isPageHeaderCustom,
      navigateCustom,
      customUrl,
      extraQuery,
      customFormInstance
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI, user } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const generateFields = useGenerateFormItem();
    const [form] = Form.useForm();
    const { onGetResource, onCreateResource, onUpdateResource } = config;

    const updateResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('PATCH', {
          url: customUrl || `${baseUrl}/${id}`,
          body:
            onUpdateResource && onUpdateResource.setBody
              ? onUpdateResource.setBody(body)
              : body
        });
        navigate(-1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const createResource = async (body) => {
      setIsSubmitting(true);
      try {
        const updatedBody = {
          ...body,
          written_by: `${user?.first_name} ${user?.last_name}`
        };
        const requestBody =
          onCreateResource && onCreateResource.setBody
            ? onCreateResource.setBody(updatedBody)
            : updatedBody;

        await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body: requestBody
        });

        if (navigateCustom) {
          navigate(customUrl);
        } else {
          navigate(-1);
        }
      } catch (e) {
        message(e);
      } finally {
        setIsSubmitting(false);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: customUrl || `${baseUrl}/${id}${extraQuery || ''}`
        });
        (customFormInstance || form).setFieldsValue(
          onGetResource && onGetResource.setFields
            ? onGetResource.setFields(data)
            : data
        );
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl]);

    useEffect(() => {
      if (purpose !== 'create' && purpose !== 'createOption' && id) {
        setIsLoading(true);
        if (!loadingFields)
          (async () => {
            await getResource();
          })();
      }
    }, [getResource]);

    const handleSubmit = async (values) => {
      if (customSubmit) {
        customSubmit(values);
      } else if (purpose === 'edit') {
        await updateResource(values);
      } else if (purpose === 'create') {
        await createResource(values);
      }
    };

    return (
      <>
        {isPageHeaderCustom ? (
          <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
        ) : null}
        <ContentCustom>
          <Spin spinning={isLoading || isParentLoading}>
            <Form
              ref={ref}
              {...formItemLayout}
              onFinish={handleSubmit}
              form={customFormInstance || form}
              layout={layout || 'horizontal'}
            >
              {fields.map((field) =>
                generateFields(tradKey || resource, field)
              )}
              {formExtra}
              {!isFormExtra ? (
                <Form.Item {...tailFormItemLayout}>
                  <Row justify="end">
                    <Button
                      style={{ margin: '0 10px' }}
                      type="link"
                      danger
                      onClick={() => navigate(-1)}
                    >
                      {`${t('buttons.cancel')} `}
                      <CloseOutlined />
                    </Button>
                    <Button
                      id="save-button"
                      type="add"
                      htmlType="submit"
                      loading={isSubmitting}
                    >
                      {`${t(submitLabel || 'buttons.save')} `}
                      <CheckOutlined />
                    </Button>
                  </Row>
                </Form.Item>
              ) : null}
            </Form>
          </Spin>
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  layout: PropTypes.string,
  loadingFields: PropTypes.bool,
  isPageHeaderCustom: PropTypes.bool,
  isFormExtra: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  navigateCustom: PropTypes.bool,
  customUrl: PropTypes.string,
  extraQuery: PropTypes.string,
  customFormInstance: PropTypes.shape({})
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  isPageHeaderCustom: false,
  isFormExtra: false,
  layout: 'vertical',
  navigateCustom: false,
  customUrl: null,
  extraQuery: '',
  customFormInstance: null
};
