import PropTypes from 'prop-types';
import { TreeTransfer } from '../../../../../components/TreeTransfer/TreeTransfer';

/**
 * A React component for transferring items using a tree structure.
 *
 * @param {Object} props - The component's properties.
 * @param {string[]} props.targetKeys - The keys of the target items.
 * @param {function} props.setTargetKeys - A function to set the target keys.
 * @param {Object[]} props.categories - An array of category objects.
 * @returns {JSX.Element} - The rendered React element.
 */
export const VisitReasonsTransfer = ({
  targetKeys,
  setTargetKeys,
  categories
}) => {
  const onChange = (keys) => {
    setTargetKeys(keys);
  };

  return (
    <TreeTransfer
      dataSource={categories}
      targetKeys={targetKeys}
      onChange={onChange}
    />
  );
};

VisitReasonsTransfer.propTypes = {
  targetKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTargetKeys: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
