import { BaseModel } from './BaseModel';

/**
 * Represents a Stay Prescription model.
 * @class
 * @extends BaseModel
 */
export class ConfigPrescription extends BaseModel {
  /**
   * Constructs a new StayPrescription instance.
   * @constructor
   * @param {Object} data - The data for the StayPrescription.
   */
  constructor(data) {
    super('prescriptions', undefined, data);
  }

  /**
   * Retrieves a single stay prescription by its ID.
   * @static
   * @function
   * @param {string} id - The ID of the stay prescription to retrieve.
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves to the fetched stay prescription.
   */
  static getOne({ ...params } = {}) {
    return BaseModel.fetchAPI('GET', `prescriptions`, {}, params);
  }

  /**
   * Fetches the enums for stay prescriptions.
   * @static
   * @function
   * @param {Object} params - The parameters for the API request.
   * @returns {Promise} A promise that resolves to the fetched enums.
   */
  static getEnums({ ...params } = {}) {
    return BaseModel.fetchAPI('GET', 'config-prescriptions/enums', {}, params);
  }

  static getImagingTreeData({ ...params } = {}) {
    return BaseModel.fetchAPI(
      'GET',
      'config-prescriptions/imaging-tree-data',
      {},
      params
    );
  }

  static getTreeOptions({ ...params } = {}) {
    return BaseModel.fetchAPI(
      'GET',
      `/config-prescriptions/tree-options?archived=false&user_display=true`,
      {},
      params
    );
  }
}
