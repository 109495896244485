import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Row } from 'antd';

/**
 * ErrorModal component displays a modal dialog for showing an error message.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.visible - Specifies whether the modal is visible or not.
 * @param {function} props.setVisible - Callback function to update the visibility of the modal.
 * @param {string} [props.customTitle] - An optional custom title to display in the modal.
 * @param {string|JSX.Element} [props.customMessage] - An optional custom message to display in the modal.
 * @returns {JSX.Element} The rendered ErrorModal component.
 * @example
 * return (
 *  <ErrorModal
 *   visible={true || false}
 *   setVisible={function}
 *   customTitle={'Your custom title.' || JSX.Element}
 *   customMessage={'Your custom message.' || JSX.Element}
 *  />
 * );
 */
export const ErrorModal = ({
  visible,
  setVisible,
  customTitle,
  customMessage
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={customTitle}
      open={visible}
      closable={false}
      footer={null} // To remove useless cancel button
    >
      <Row justify="center" style={{ margin: '24px 0' }}>
        {customMessage || t('errors.an_error_occurred')}
      </Row>
      <Row justify="center">
        <Button onClick={() => setVisible({ success: false, error: false })}>
          {t('buttons.close')}
        </Button>
      </Row>
    </Modal>
  );
};

ErrorModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  customTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  customMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

ErrorModal.defaultProps = {
  customTitle: null,
  customMessage: null
};
