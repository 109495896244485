/**
 * Custom hook that returns an array of fields for the surgical historic form.
 *
 * @hook
 * @returns {Array} An array of fields for the surgical historic form.
 */
export const useFields = () => {
  const fields = [
    {
      name: ['label'],
      label: 'label',
      rules: [{ required: true }]
    }
  ];

  return { fields };
};
