import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getVisitReasons } from '../../../../utils/formatters';

/**
 * Custom hook that returns an array of columns for the stay history list.
 *
 * @hook
 * @param {boolean} isSwitched - Determines whether to switch the columns or not.
 * @returns {Array} Array of column objects
 */
export const useColumns = (isSwitched) => {
  const { t } = useTranslation();

  const resultArrival = (result_arrival_time) => {
    const arrivalTime = dayjs(result_arrival_time);
    return arrivalTime.format('DD/MM/YYYY');
  };

  const dateColumn = {
    title: t('consultations.stay_history.date_of_stay'),
    key: 'date',
    dataIndex: 'date',
    sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    render: (date_of_arrival) => resultArrival(date_of_arrival)
  };

  const reasonColumn = {
    title: t('consultations.stay_history.reason'),
    key: 'visit_reasons',
    dataIndex: 'visit_reasons',
    sorter: (a, b) => {
      const getReasons = (data) => data.map((item) => item.wording).join(', ');

      const aReasons = getReasons(a.visit_reasons);
      const bReasons = getReasons(b.visit_reasons);

      return aReasons.localeCompare(bReasons);
    },
    render: (visit_reasons) => getVisitReasons(visit_reasons)
  };

  const remarksColumn = {
    title: t('consultations.stay_history.remarks'),
    key: 'diagnostic',
    dataIndex: 'diagnostic',
    sorter: (a, b) => {
      const aDiagnostic = Array.isArray(a.diagnostic)
        ? a.diagnostic.join(', ')
        : a.diagnostic;
      const bDiagnostic = Array.isArray(b.diagnostic)
        ? b.diagnostic.join(', ')
        : b.diagnostic;
      return aDiagnostic.localeCompare(bDiagnostic);
    }
  };
  return isSwitched
    ? [reasonColumn, dateColumn, remarksColumn]
    : [dateColumn, reasonColumn, remarksColumn];
};
