import { Col } from 'antd';
import PropTypes from 'prop-types';
import { FavoritesCard } from '../stay/cards/FavoritesCard';
import { TreeCard } from '../stay/cards/TreeCard';
import { useHandleClick } from '../stay/utils/useHandleClick';

/**
 * Renders the SRNContent component.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Function} props.setCheckedKeys - The function to set the checked keys.
 * @param {Function} props.setTreeKeys - The function to set the tree keys.
 * @param {Function} props.postFavorite - The function to post a favorite.
 * @param {boolean} props.refresh - The refresh state.
 * @param {Function} props.setRefresh - The function to set the refresh state.
 * @param {Array} props.globalCheckedKeys - The global checked keys array.
 * @param {string} props.category - The category string.
 * @param {string} props.prescriptionType - The prescription type string.
 * @param {Array} props.checkedKeys - The SRN checked keys array.
 * @returns {JSX.Element} The rendered SRNContent component.
 */
export const SRNContent = ({
  form,
  setCheckedKeys,
  checkedKeys,
  setTreeKeys,
  postFavorite,
  refresh,
  setRefresh,
  globalCheckedKeys,
  category
}) => {
  const { handleClickFavoritePrescription } = useHandleClick({
    setCheckedKeys,
    category,
    form,
    setTreeKeys
  });

  return (
    <>
      <Col span={8}>
        <FavoritesCard
          category={category}
          form={form}
          setCheckedKeys={setCheckedKeys}
          setTreeKeys={setTreeKeys}
          editableRowHandleClick={handleClickFavoritePrescription}
          postFavorite={postFavorite}
          refresh={refresh}
          setRefresh={setRefresh}
          withCreateButton
        />
      </Col>
      <TreeCard
        form={form}
        category="SRN_CARE_WITHOUT_COTATIONS"
        setCheckedKeys={setCheckedKeys}
        srnCheckedKeys={checkedKeys}
        globalCheckedKeys={globalCheckedKeys}
        setTreeKeys={setTreeKeys}
        refresh={refresh}
        setRefresh={setRefresh}
        prescriptionType="SRN_CARE_WITHOUT_COTATIONS"
      />
      <TreeCard
        form={form}
        category="SRN_CARE_WITH_COTATIONS"
        setCheckedKeys={setCheckedKeys}
        srnCheckedKeys={checkedKeys}
        globalCheckedKeys={globalCheckedKeys}
        setTreeKeys={setTreeKeys}
        refresh={refresh}
        setRefresh={setRefresh}
        prescriptionType="SRN_CARE_WITH_COTATIONS"
      />
    </>
  );
};

SRNContent.propTypes = {
  form: PropTypes.shape({}).isRequired,
  setCheckedKeys: PropTypes.func.isRequired,
  setTreeKeys: PropTypes.func.isRequired,
  postFavorite: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  globalCheckedKeys: PropTypes.shape({}).isRequired,
  category: PropTypes.string.isRequired,
  checkedKeys: PropTypes.shape([])
};

SRNContent.defaultProps = {
  checkedKeys: []
};
