import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import { CardItem } from './CardItem';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * Kanban component.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.refresh - The refresh flag.
 * @param {function} props.setRefresh - The function to set the refresh flag.
 * @returns {JSX.Element} The Kanban component.
 */
export const Kanban = ({ refresh, setRefresh }) => {
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const [columns, setColumns] = useState([]);

  /**
   * Fetches the Kanban columns from the server.
   * @returns {Promise<void>} A Promise that resolves when the columns are fetched.
   */
  const getKanbanColumns = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `kanban-columns/${id}`
    });
    setColumns(data);
  };

  useEffect(() => {
    (async () => {
      await getKanbanColumns();
    })();
  }, [refresh]);

  return (
    <Row>
      {columns.map((column) => (
        <Col span={6} key={column._id}>
          <CardItem
            _id={column._id}
            title={column.title}
            rooms={column.rooms}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Col>
      ))}
    </Row>
  );
};

Kanban.propTypes = {
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired
};
