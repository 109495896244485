import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Image } from 'antd';
import { getFullName } from '../../utils/formatters';

/**
 * Component for displaying the doctor's signature.
 *
 * @component
 * @param {boolean} showLabelInput - Determines whether to show the label input or the doctor's full name.
 * @param {object} doctor - The doctor object containing the doctor's information.
 * @param {string} doctor._id - The ID of the doctor.
 * @param {object} doctor.signature - The doctor's signature object.
 * @param {string} doctor.signature.image - The URL of the doctor's signature image.
 * @param {string} translationKey - The translation key for the component.
 * @returns {JSX.Element} - The rendered component.
 */
export const DoctorsSignature = ({
  showLabelInput,
  doctor,
  translationKey
}) => {
  const { t } = useTranslation();

  return (
    <Row justify="end">
      <Col>
        <Row justify="end" style={{ marginBottom: 16 }}>
          {showLabelInput
            ? t(`${translationKey}.doctor.doctor_name`)
            : `Dr. ${getFullName(doctor)}`}
        </Row>
        <Row justify="end">
          {showLabelInput
            ? t(`${translationKey}.doctor.signature`)
            : doctor?.signature?.image && (
                <Image
                  src={doctor?.signature?.image}
                  alt={t(`${translationKey}.doctor.signature`)}
                  preview={false}
                  style={{
                    maxWidth: '200px'
                  }}
                />
              )}
        </Row>
      </Col>
    </Row>
  );
};

DoctorsSignature.propTypes = {
  showLabelInput: PropTypes.bool,
  doctor: PropTypes.shape({
    _id: PropTypes.string,
    signature: PropTypes.shape({
      image: PropTypes.string
    })
  }),
  translationKey: PropTypes.string.isRequired
};

DoctorsSignature.defaultProps = {
  showLabelInput: false,
  doctor: null
};
