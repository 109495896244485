import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Typography } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Text } = Typography;

/**
 * A component that represents a free text zone for prescription.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.content - The content of the free text zone.
 * @param {function} props.setContent - A function to set the content of the free text zone.
 * @param {string} props.prescriptionType - The type of the prescription. (STAY or EXIT)
 * @param {Object} props.styles - The styles for the component.
 * @param {string} props.translationKey - The translation key for the component.
 * @returns {JSX.Element} The rendered component.
 */
export const FreeTextZone = ({
  content,
  setContent,
  prescriptionType,
  styles,
  translationKey
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row
        style={{
          marginBottom: 24,
          paddingLeft: 4,
          border: '2px solid black',
          backgroundColor: '#92CCDD'
        }}
      >
        <Text style={{ ...styles.text, fontSize: 18 }} strong>
          {t(`${translationKey}.types.${prescriptionType}`)}
        </Text>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <Form>
            <Form.Item
              nolabel="true"
              name="content"
              initialValue={content}
              rules={[{ required: true }]}
            >
              <ReactQuill
                style={styles.reactQuill}
                theme="snow"
                modules={{
                  toolbar: {
                    container: [
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ indent: '-1' }, { indent: '+1' }],
                      ['clean']
                    ]
                  }
                }}
                value={content}
                onChange={(value) => setContent(value)}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

FreeTextZone.propTypes = {
  content: PropTypes.string,
  setContent: PropTypes.func,
  prescriptionType: PropTypes.string,
  styles: PropTypes.shape({
    text: PropTypes.shape({}),
    reactQuill: PropTypes.shape({})
  }),
  translationKey: PropTypes.string.isRequired
};

FreeTextZone.defaultProps = {
  content: '',
  setContent: () => {},
  prescriptionType: '',
  styles: {}
};
