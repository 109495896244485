import { useTranslation } from 'react-i18next';
import { Button, Card, Flex, Form, Input, Modal, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useConsultationsContext } from '../ConsultationsContext';
import { getVisitReasons } from '../../../utils/formatters';
import { ReasonsSelect } from '../Components/Fields/ReasonsSelect';
import { Consultation } from '../../../models/Consultation';

const { Text } = Typography;

/**
 * Component to display entry notifications for a patient.
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const ShowEntryNotifications = () => {
  const { t } = useTranslation();
  const { patient, treeData, setForcedRefresh } = useConsultationsContext();
  const [form] = Form.useForm();

  const [openModal, setOpenModal] = useState();

  const resource = 'consultations';
  const comment =
    patient?.current_consultation?.entry_notification?.description;
  const visitReasons = patient?.current_consultation?.visit_reasons;
  const consultationId = patient?.current_consultation?._id;

  const handleEditEntryNotif = async (body) => {
    const response = await Consultation.patchMedicalConsultation(
      consultationId,
      body
    );

    if (response) {
      setForcedRefresh(true);
      setOpenModal(false);
    }

    form.resetFields();
  };

  return (
    <>
      <Card
        title={t(`${resource}.entry_reason`)}
        extra={<EditOutlined onClick={() => setOpenModal(true)} />}
      >
        <Flex vertical>
          <Text>{getVisitReasons(visitReasons)}</Text>
          <Text>{comment}</Text>
        </Flex>
      </Card>
      <Modal
        open={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
      >
        <Form form={form} onFinish={handleEditEntryNotif}>
          <Form.Item
            label={t('consultations.form.visit_reasons')}
            name={['visit_reasons']}
          >
            <ReasonsSelect treeData={treeData} form={form} />
          </Form.Item>
          <Form.Item
            label={t('consultations.form.description')}
            name={['description']}
          >
            <Input />
          </Form.Item>
          <Flex justify="end">
            <Button type="primary" htmlType="submit">
              {t('buttons.validate')}
            </Button>
          </Flex>
        </Form>
      </Modal>
    </>
  );
};
