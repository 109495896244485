import { mdiRadioactive } from '@mdi/js';
import Icon from '@mdi/react';
import { Checkbox, Popconfirm, Space, Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHandleConfirmTransmition } from '../utils/useHandleConfirmTransmition';
import { useHandleChange } from '../utils/handleChange';
import { useColorsIcons } from '../utils/useColorsIcons';

/**
 * Renders the ImagingIcon component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.consultation - The consultation object.
 * @param {Function} props.setRefresh - The function to set the refresh state.
 * @param {Object} props.prescriptionTypesEnums - The prescription types enums object.
 * @returns {JSX.Element|null} The rendered ImagingIcon component.
 */
export const ImagingIcon = ({
  consultation,
  setRefresh,
  prescriptionTypesEnums
}) => {
  const [transmittedExaminations, setTransmittedExaminations] = useState([]);

  const { t } = useTranslation();
  const { handleConfirm } = useHandleConfirmTransmition(setRefresh);
  const { handleChange } = useHandleChange(
    transmittedExaminations,
    setTransmittedExaminations
  );

  const { IMAGING: imagingEnums } = prescriptionTypesEnums || {};

  const examinations = consultation?.prescribed_examinations?.filter(
    (prescription) => imagingEnums?.includes(prescription.type)
  );

  const allExaminationsPrescribed = examinations?.every(
    (examination) => examination.transmitted
  );

  const { colorsIcons } = useColorsIcons(
    allExaminationsPrescribed,
    '#51BF70',
    '#F59700'
  );

  const prescribedImagingExaminations = examinations.map((prescription) => (
    <Checkbox
      defaultChecked={prescription.transmitted}
      onChange={handleChange}
      value={prescription._id}
      key={prescription._id}
    >
      {t(`patients.icons.imaging.${prescription.type}`)} {prescription.label}
    </Checkbox>
  ));

  return (
    examinations.length > 0 && (
      <Tooltip title={t('patients.icons.imaging.title')}>
        <Popconfirm
          placement="bottomRight"
          title={t('patients.icons.imaging.title')}
          description={
            <Space direction="vertical">{prescribedImagingExaminations}</Space>
          }
          okText={t('buttons.validate')}
          cancelText={t('buttons.cancel')}
          onConfirm={() =>
            handleConfirm(consultation._id, { transmittedExaminations })
          }
        >
          <Icon path={mdiRadioactive} size="18px" color={colorsIcons} />
        </Popconfirm>
      </Tooltip>
    )
  );
};

ImagingIcon.propTypes = {
  consultation: PropTypes.shape({
    prescribed_examinations: PropTypes.arrayOf(PropTypes.shape({})),
    _id: PropTypes
  }).isRequired,
  setRefresh: PropTypes.func.isRequired,
  prescriptionTypesEnums: PropTypes.shape({}).isRequired
};
