import { useTranslation } from 'react-i18next';
import { Row, Col, Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHourglassStart,
  faRadiation,
  faCommentDots
} from '@fortawesome/free-solid-svg-icons';
import { MiscRooms } from './components/SecretaryDoctorView/MiscRooms';
import { ConsultationRooms } from './components/SecretaryDoctorView/ConsultationRooms';

export const SecretaryDoctorView = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col span={12}>
          <Card>
            <h3>
              {' '}
              <FontAwesomeIcon icon={faHourglassStart} />{' '}
              {t('patients.rooms.waiting_room')}
            </h3>
            <MiscRooms type="WAITING_ROOM" />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <h3>
              {' '}
              <FontAwesomeIcon icon={faRadiation} />{' '}
              {t('patients.rooms.radiology_room')}
            </h3>
            <MiscRooms type="RADIOLOGY_ROOM" />
          </Card>
          <Card>
            <h3>
              {' '}
              <FontAwesomeIcon icon={faHourglassStart} />{' '}
              {t('patients.rooms.awaiting_examinations_results')}
            </h3>
            <MiscRooms type="AWAITING_EXAM_RESULTS" />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card>
            <h3>
              {' '}
              <FontAwesomeIcon icon={faCommentDots} />{' '}
              {t('patients.rooms.consultation_room')}
            </h3>
            <ConsultationRooms type="CONSULTING_ROOM" />
          </Card>
        </Col>
      </Row>
    </>
  );
};
