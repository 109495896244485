import React from 'react';
import PropTypes from 'prop-types';
import { Badge as AntdBadge } from 'antd';

/**
 * Displays a badge component with a number inside, colored based on the visit type.
 * @component
 *
 * @param {Object} props - The props of the component.
 * @param {string} props.content - The number to display inside the badge.
 * @param {string} props.color - The type of visit, determines the color of the badge. Should be one of 'FIRST_VISIT' or any other string.
 *
 * @returns {JSX.Element} A Badge component.
 */
export const Badge = ({ content, color }) => {
  const badgeStyle = {
    backgroundColor: color,
    color: 'white',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold'
  };

  return <AntdBadge count={content} style={badgeStyle} />;
};

Badge.propTypes = {
  content: PropTypes.string,
  color: PropTypes.string
};

Badge.defaultProps = {
  content: '',
  color: '#004a8f'
};
