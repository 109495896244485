import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * CreateUpdateUser component is a container for creating and updating users.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component.
 * @returns {ReactElement} The CreateUpdateUser component
 */
export const CreateUpdateUser = ({ purpose }) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [fileList, setFileList] = useState([]);

  const [form] = Form.useForm();
  const { companyId } = useParams();
  const { user, setUser } = useAuthContext();
  const { fields, isFieldsLoading } = useFields({
    selectedCompanyId,
    setSelectedCompanyId,
    fileList,
    setFileList,
    form
  });

  useEffect(() => {
    if (companyId) setSelectedCompanyId(companyId);
    form.setFieldValue('associated_company', companyId);
  }, [companyId]);

  const config = {
    onGetResource: {
      setFields: (data) => {
        setSelectedCompanyId(data.associated_company);
        return {
          ...data,
          date_of_birth: data.date_of_birth && dayjs(data.date_of_birth)
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        if (data._id === user._id) setUser({ ...user, ...data });

        const formData = new FormData();

        formData.append('avatar', fileList[0]);
        formData.append('values', JSON.stringify(data));

        return formData;
      }
    }
  };

  return (
    <CreateUpdateContainer
      layout="horizontal"
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="users"
      resource="users"
      config={config}
      isPageHeaderCustom
      customFormInstance={form}
    />
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};
