import PropTypes from 'prop-types';
import { Col } from 'antd';
import { Room } from './Room';

/**
 * Renders a Kanban column with rooms.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setRefresh - The function to refresh the component.
 * @param {Object} props.column - The column object containing rooms.
 * @param {Array} props.column.rooms - The array of rooms in the column.
 * @param {Object} props.prescriptionTypesEnums - The prescription types enums.
 *
 * @returns {JSX.Element} The KanbanColumn component.
 */
export const KanbanColumn = ({
  setRefresh,
  column,
  prescriptionTypesEnums
}) => (
  <Col span={8} className="kanban-col">
    {column?.rooms?.map((room) => (
      <Room
        key={room._id}
        setRefresh={setRefresh}
        room={room}
        prescriptionTypesEnums={prescriptionTypesEnums}
      />
    ))}
  </Col>
);

KanbanColumn.propTypes = {
  setRefresh: PropTypes.func,
  column: PropTypes.shape({
    rooms: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  prescriptionTypesEnums: PropTypes.shape({})
};

KanbanColumn.defaultProps = {
  setRefresh: () => {},
  column: {
    rooms: []
  },
  prescriptionTypesEnums: {}
};
