import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table } from 'antd';
import { useColumnsSearch } from './columnsSearchList';

export const SearchPatientResultModal = ({
  searchResults,
  handleCancelResult
}) => {
  const columnsSearch = useColumnsSearch(() => handleCancelResult());

  const { t } = useTranslation();
  const searchResultsWithKey = searchResults.map((item) => ({
    ...item,
    key: item._id
  }));

  return (
    <Col span={24}>
      {searchResults.length > 0 ? (
        <>
          <Row>{t(`patients.form.patient_found`)}</Row>
          <Table
            dataSource={searchResultsWithKey}
            columns={columnsSearch}
            handleClose={handleCancelResult}
          />
        </>
      ) : (
        <>
          <Row>{t('patients.form.searchPatient')}</Row>
          <Row>{t('patients.form.no_patient_found')}</Row>
          <Row>{t('patients.form.redirect')}</Row>
        </>
      )}
    </Col>
  );
};

SearchPatientResultModal.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.shape({})),
  handleCancelResult: PropTypes.func
};
SearchPatientResultModal.defaultProps = {
  searchResults: undefined,
  handleCancelResult: undefined
};
