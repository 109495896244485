/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { getSynapseToken } from '../../utils/synapse';

export const SynapseMonographWidget = ({ entity }) => {
  const [JWT, setJWT] = useState(null);

  const { dispatchAPI } = useAuthContext();

  const fetchNewToken = async () => {
    const token = await getSynapseToken(dispatchAPI);
    setJWT(token);
  };

  useEffect(() => {
    if (!JWT) {
      fetchNewToken();
      return;
    }

    // Initialize the widget once the component is mounted
    const dataMonograph = {
      researchEnabled: false,
      brandedDrug: entity
    };
    const monographWidget = new window.Synapse.MonographWidget(JWT);
    monographWidget.init(
      document.getElementById('monograph-container'),
      dataMonograph
    );

    // Set callbacks for the widget
    monographWidget.setCallback('onTokenExpiration', (err, data) => {
      const token = fetchNewToken();
      monographWidget.setToken(token);
    });
  }, [entity, JWT]);

  // const updateMonograph = (newEntity) => {
  //   // Implement logic to update the MonographWidget with a new entity
  //   const dataMonograph = {
  //     researchEnabled: false,
  //     brandedDrug: newEntity
  //   };
  //   monographWidget.update(dataMonograph);
  // };

  return <div id="monograph-container" />;
};

SynapseMonographWidget.propTypes = {
  entity: PropTypes.string.isRequired
};
