import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer, PageHeaderCustom } from '../../../components';
import { useFields } from './useFields';
import { useCreateUpdateconfig } from './forms/createUpdateConfig';
import { FormExtra } from './forms/FormExtra';

/**
 * React component for creating or updating a protocol.
 *
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either 'create' or 'update'.
 * @returns {JSX.Element} - The rendered React element.
 */
export const CreateUpdateProtocol = ({ purpose }) => {
  const translationKey = 'data_bases.protocols';
  const { t } = useTranslation();
  const fields = useFields();
  const [visitReasonsData, setVisitReasonsData] = useState([]);
  const [prescriptionsData, setPrescriptionsData] = useState([]);
  const [targetNodes, setTargetNodes] = useState([]);
  const [protocolWording, setProtocolWording] = useState('');
  const { config } = useCreateUpdateconfig({
    visitReasonsData,
    prescriptionsData,
    setVisitReasonsData,
    setPrescriptionsData,
    setProtocolWording,
    targetNodes,
    setTargetNodes
  });

  return (
    <>
      <PageHeaderCustom
        title={
          purpose === 'edit'
            ? t(`${translationKey}.form.edit_title`, {
                protocolWording
              })
            : t(`${translationKey}.form.title`)
        }
        withoutBreadCrumbs
      />
      <CreateUpdateContainer
        fields={fields}
        config={config}
        purpose={purpose}
        baseUrl="protocols"
        resource={translationKey}
        formExtra={
          <FormExtra
            visitReasonsData={visitReasonsData}
            setVisitReasonsData={setVisitReasonsData}
            prescriptionsData={prescriptionsData}
            setPrescriptionsData={setPrescriptionsData}
            targetNodes={targetNodes}
            setTargetNodes={setTargetNodes}
          />
        }
      />
    </>
  );
};

CreateUpdateProtocol.propTypes = {
  purpose: PropTypes.string.isRequired
};
