import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Switch } from 'antd';
import { CalendarOutlined, QuestionCircleOutlined } from '@ant-design/icons';

export const SorterDateReason = ({ handleSwitchChange }) => {
  const resource = 'consultations';
  const { t } = useTranslation();

  return (
    <Row justify="center" align="middle">
      <CalendarOutlined />
      &nbsp;
      <span>{t(`${resource}.stay_history.by_date`)}</span>&nbsp;
      <Switch onChange={handleSwitchChange} /> &nbsp;
      <QuestionCircleOutlined />
      &nbsp;
      <span>{t(`${resource}.stay_history.by_reason`)}</span>
    </Row>
  );
};

SorterDateReason.propTypes = {
  handleSwitchChange: PropTypes.func.isRequired
};
