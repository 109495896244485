import { Button, Modal, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * Modal component for displaying CCMU classifications in a medical consultation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.openModal - Flag indicating whether the modal is open.
 * @param {function} props.setOpenModal - Function to set the openModal flag.
 * @returns {JSX.Element} The rendered ModalCCMU component.
 */
export const ModalCCMU = ({
  openModal,
  setOpenModal,
  ccmuClassificationsEnums
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={openModal}
      onCancel={() => setOpenModal(false)}
      title={t('consultations.medical_consultation.ccmu_classifications.title')}
      footer={[
        <Button onClick={() => setOpenModal(false)}>{t('buttons.ok')}</Button>
      ]}
    >
      {ccmuClassificationsEnums &&
        ccmuClassificationsEnums?.map((item) => (
          <React.Fragment key={item}>
            <Text>
              <strong>{item} = </strong>
              {t(
                `consultations.medical_consultation.ccmu_classifications.${item}`
              )}
            </Text>
            <br />
          </React.Fragment>
        ))}
    </Modal>
  );
};

ModalCCMU.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  ccmuClassificationsEnums: PropTypes.arrayOf(PropTypes.string).isRequired
};
