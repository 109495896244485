import { useCallback, useEffect, useState } from 'react';
import { Form, Input, Select, Upload, Tag, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons/lib';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;
const { Dragger } = Upload;

// This function convert the PDF to base64 format
export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

/**
 * Custom hook that provides form fields and related functionality for user creation/editing.
 *
 * @hook
 * @param {Object} options - Options for the hook.
 * @param {string} options.selectedCompanyId - The ID of the selected company.
 * @param {function} options.setSelectedCompanyId - Function to set the selected company ID.
 * @param {Array} options.fileList - The list of uploaded files.
 * @param {function} options.setFileList - Function to set the list of uploaded files.
 * @returns {Object} - Object containing the form fields and loading state.
 */
export const useFields = ({
  selectedCompanyId = '',
  setSelectedCompanyId,
  fileList,
  setFileList,
  form
}) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const {
    dispatchAPI,
    user: { role }
  } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [companies, setCompanies] = useState([]);
  const [centers, setCenters] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const allowToUpdate =
    role.includes('admins:SUPER-ADMIN') || role.includes('DEV');

  const onChangePosition = (value) => {
    setSelectedPosition(value);
  };

  const onChangeRole = (value) => {
    setSelectedRole(value);
  };

  const onChangeSelectedCompany = (value) => {
    setSelectedCompanyId(value);
    form.setFieldValue('default_center', null);
  };

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const statusField = {
    name: ['replacing'],
    label: 'status',
    rules: [{ required: true }],
    input: (
      <Radio.Group>
        <Radio value>{t('users.form.options.substitute')}</Radio>
        <Radio value={false}>{t('users.form.options.registered')}</Radio>
      </Radio.Group>
    )
  };

  const fields = [
    {
      name: ['avatar'],
      input: (
        <Dragger disabled={!allowToUpdate} {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['position'],
      rules: [{ required: true }],
      input: (
        <Select
          allowClear
          loading={isFieldsLoading}
          onChange={onChangePosition}
          disabled={!allowToUpdate}
        >
          {(enums?.positions || []).map((position) => (
            <Option key={position} value={position}>
              {t(`users.positions.${position}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['role'],
      rules: [{ required: true }],
      input: (
        <Select
          disabled={!allowToUpdate}
          allowClear
          loading={isFieldsLoading}
          onChange={onChangeRole}
        >
          {(enums?.roles || []).map((r) => (
            <Option key={r} value={r}>
              <Tag color={userRoles[r.split(':')[1]]}>
                {t(`users.tags.${r.split(':')[1]}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['cpx_number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    ...(selectedPosition === 'DOCTOR'
      ? [
          {
            name: ['am_finess_number'],
            rules: [
              {
                required: selectedPosition === 'DOCTOR'
              }
            ],
            input: <Input type="number" />
          }
        ]
      : []),
    ...(selectedRole !== 'admins:SUPER-ADMIN'
      ? [
          {
            name: ['associated_company'],
            rules: [
              {
                required: !['ADMIN', 'SUPER_ADMIN', 'DEV']?.includes(
                  selectedRole
                )
              }
            ],
            input: (
              <Select
                allowClear
                loading={isFieldsLoading}
                onChange={onChangeSelectedCompany}
                disabled={!allowToUpdate}
              >
                {companies.map((company) => (
                  <Option key={company._id} value={company._id}>
                    {company.name}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['default_center'],
            rules: [
              {
                required: !selectedRole?.includes(
                  'ADMIN' || 'SUPER_ADMIN' || 'DEV'
                )
              }
            ],
            input: (
              <Select
                allowClear
                disabled={!selectedCompanyId || !allowToUpdate}
                loading={isFieldsLoading}
              >
                {centers.map((center) => (
                  <Option key={center._id} value={center._id}>
                    {center.name}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      disabled: !allowToUpdate,
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input style={{ width: '75%' }} maxLength={10} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  switch (selectedPosition) {
    case 'DOCTOR':
      fields.push(statusField);
      break;
    case 'NURSE':
      fields.push(statusField);
      break;
    default:
      break;
  }

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies?deleted=false'
      });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCenters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/centers/company/${selectedCompanyId}`
      });
      setCenters(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getCompanies();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedCompanyId) await getCenters();
    })();
  }, [selectedCompanyId]);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};
