// eslint-disable-next-line import/no-extraneous-dependencies
import { CirclePicker } from 'react-color';
import {
  Card,
  Collapse,
  Flex,
  Typography,
  Popover,
  Form,
  Radio,
  Modal,
  Spin,
  Row
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Component for managing user appearance settings, including the color of the user chip.
 * @component
 * @example
 * // Usage in a parent component:
 * <Appearance />
 * @returns {JSX.Element} Returns the JSX element for the appearance settings component.
 */
const { Panel } = Collapse;
const { Text } = Typography;

const Appearance = () => {
  const { dispatchAPI, user, setUser } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleSubmit = async (color) => {
    const { _id } = user;
    const { hex } = color;

    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/users/color-user/${_id}`,
        body: { color: hex }
      });

      setUser(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const openModal = () => {
    setOpen(true);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <Card style={{ marginBottom: 24 }}>
      <Collapse expandIconPosition="end" defaultActiveKey={['1']} ghost>
        <Panel header={t('settings.collapses.appearance')} key="1">
          <Flex vertical>
            <Text>{t('users.form.user_chip_color')}</Text>
            <Popover
              content={
                <CirclePicker
                  color={user.user_chip_color || '#F1F5F2'}
                  onChange={handleSubmit}
                />
              }
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  marginLeft: '8px',
                  backgroundColor: user?.user_chip_color || '#F1F5F2'
                }}
              />
            </Popover>
            <Form.Item
              label={t('settings.landing_choice')}
              name="landing_choice"
            >
              <Radio.Group
                options={[
                  {
                    label: t('settings.landing_choice_kanban'),
                    value: 'ROOMS'
                  },
                  {
                    label: t('settings.landing_choice_administrative'),
                    value: 'ADMINISTRATIVE'
                  }
                ]}
                optionType="button"
                defaultValue={user.landing_choice}
                buttonStyle="solid"
                onChange={openModal}
              />
            </Form.Item>
            <Modal
              open={open}
              footer={null}
              closable={false}
              title={t('settings.messages.refresh_settings')}
            >
              <Row justify="center">
                <Spin spinning />
              </Row>
            </Modal>
          </Flex>
        </Panel>
      </Collapse>
    </Card>
  );
};

export default Appearance;
