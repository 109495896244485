import { useEffect } from 'react';
import { Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ErrorBoundary } from 'react-error-boundary';
import { LanguageContextProvider } from '../contexts/LanguageContext';
import { AuthContextProvider } from '../contexts/AuthContext';
import { ThemeContextProvider } from '../contexts/ThemeContext';
import { FallbackComponent } from './FallbackModal/FallbackComponent';
import { RouterDispatch } from '../routers/RouterDispatch';
import { OfflineTray } from './OfflineTray/OfflineTray';
import { StellairContextProvider } from '../contexts/StellairContext';
import { SocketContextProvider } from '../contexts/SocketContext';

Spin.setDefaultIndicator(<LoadingOutlined spin />);

const handleError = async (error, { componentStack }) => {
  if (process.env.NODE_ENV === 'production') {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/gitlab-issues`, {
        method: 'POST',
        body: JSON.stringify({
          error: { title: error?.message, description: error.toString() },
          path: window.location.href,
          stack: componentStack
        }),
        headers: new Headers({ 'Content-type': 'application/json' })
      });
    } catch (e) {
      notification.error({
        message: "Impossible de signaler l'erreur",
        key: 'gitlab_error'
      });
    }
  }
};

const App = () => {
  useEffect(() => {
    window.addEventListener('error', (event) => {
      if (
        event.message === 'ResizeObserver loop limit exceeded' ||
        event.message ===
          'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onError={handleError}>
      <LanguageContextProvider>
        <AuthContextProvider>
          <SocketContextProvider>
            <StellairContextProvider>
              <ThemeContextProvider>
                <OfflineTray />
                <RouterDispatch />
              </ThemeContextProvider>
            </StellairContextProvider>
          </SocketContextProvider>
        </AuthContextProvider>
      </LanguageContextProvider>
    </ErrorBoundary>
  );
};

export default App;
