import PropTypes from 'prop-types';
import { Modal, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStellairContext } from '../../contexts/StellairContext';
import { VitaleCardItem } from './VitaleCardItem';

/**
 * Represents a modal component that displays vitale card information.
 * @component
 * @prop {boolean} visible - Indicates whether the modal is visible or not.
 * @prop {function} setVisible - A function to set the visibility of the modal.
 * @prop {function} fillForm - A function to fill the form.
 * @prop {function} setIsModalVisible - A function to set the visibility of the modal.
 * @returns {JSX.Element} The rendered modal component.
 */
export const VitaleCardInformationsModal = ({
  visible,
  setVisible,
  form,
  setIsModalVisible
}) => {
  const { t } = useTranslation();
  const { vitaleCardData } = useStellairContext();
  const beneficiaries = vitaleCardData?.list || [];

  const onClose = () => {
    setVisible((prevState) => ({ ...prevState, success: false }));
  };

  return (
    <Modal
      title={t('stellair.vitale_card.title')}
      open={visible}
      onCancel={onClose}
      onOk={onClose}
      onClose={onClose}
      width={640}
    >
      <List
        itemLayout="horizontal"
        dataSource={beneficiaries}
        renderItem={(beneficiary) => (
          <VitaleCardItem
            key={beneficiary.id}
            beneficiary={beneficiary}
            form={form}
            setIsModalVisible={setIsModalVisible}
          />
        )}
      />
    </Modal>
  );
};

VitaleCardInformationsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired,
  setIsModalVisible: PropTypes.func.isRequired
};
