import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useSubOptionsFields } from './fields';
import { useDataBasesContext } from '../../../../contexts/DataBasesContext';
import { CreateUpdateContainer } from '../../../../components';
import { OptionsMethods } from './OptionsMethods';

/**
 * Renders a component for creating or updating options related to stay prescriptions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component, whether it is for creating or updating.
 * @returns {JSX.Element} The CreateUpdateOptions component.
 */
export const CreateUpdateOptions = ({ purpose }) => {
  const navigate = useNavigate();
  const { patchMethod } = OptionsMethods({});
  const { id, optionId } = useParams();
  const { subOptionsfields } = useSubOptionsFields();
  const { prescriptionWording, category, purposeOptions, handleAddOption } =
    useDataBasesContext();
  const path = `/config-prescriptions/prescriptions/${id}/option`;

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        prescription_wording: prescriptionWording,
        category
      })
    }
  };

  const patchOptions = async (values) => {
    if (values && optionId) {
      await patchMethod(values, path, optionId, navigate);
    }
  };

  const purposeActions = {
    createOption: (values) => values && handleAddOption(values),
    editOptions: (values) => values && patchOptions(values)
  };

  return (
    <CreateUpdateContainer
      purpose={purpose || purposeOptions}
      fields={subOptionsfields}
      customSubmit={purposeActions[purpose || purposeOptions]}
      config={config}
      baseUrl={path}
      customUrl={`${path}/${optionId}`}
      resource="data_bases.stay_prescriptions"
    />
  );
};

CreateUpdateOptions.propTypes = {
  purpose: PropTypes.string
};

CreateUpdateOptions.defaultProps = {
  purpose: null
};
