import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Col } from 'antd';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useSuccessMessage } from '../../utils/successMessage';
import { getSynapseToken } from '../../utils/synapse';

/**
 * SynapseAutocompleteWidget
 * @component
 * @summary Autocomplete widget for Synapse
 * @description Autocomplete widget for Synapse that allows the user to search for drugs
 * @param {object} props - See PropTypes
 * @param {boolean} props.fromHeader - If the widget is in the header or in the prescription form
 * @param {boolean} props.fromPersonnalTreatments - If the widget is in the personnal treatments form
 * @param {function} props.setPersonnalTreatmentsEntities - Function to set the personnal treatments entities
 * @param {array} props.personnalTreatmentsEntities - Array of personnal treatments entities
 * @returns {node} jsx markup
 */

const StyledCol = styled(Col)`
  .autocomplete-input {
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    line-height: normal !important; /* Override inherited line-height */
  }

  .autocomplete-dropdown {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-x: auto !important;
  }

  .BOTO-63-textInput
    BOTO-65-SYNAPSE-BOTO-id-2-textInput
    BOTO-67-SYNAPSE-BOTO-id-1-searchInput
    BOTO-67-SYNAPSE-BOTO-id-1-searchInputIcon {
    width: 350px;
  }
  .BOTO-3-SYNAPSE-BOTO-id-8-icon {
    display: flex;
  }
`;

export const SynapseAutocompleteWidget = ({
  fromHeader,
  fromPersonnalTreatments,
  setEntitiesProps,
  entitiesProps
}) => {
  const { entities, setEntities, setRefresh } = usePrescriptionContext();
  const [JWT, setJWT] = useState(null);
  const { dispatchAPI, user, setUser } = useAuthContext();
  const { message } = useErrorMessage();
  const { successMessage } = useSuccessMessage();
  const componentId = fromHeader
    ? 'autocomplete-container-header'
    : 'autocomplete-container';

  const containerRef = useRef(null);

  const fetchNewToken = async () => {
    const token = await getSynapseToken(dispatchAPI);
    setJWT(token);
  };

  useEffect(() => {
    if (!JWT) {
      fetchNewToken();
      return;
    }

    const autocompleteWidget =
      new window.Synapse.MedicalResourceAutocompleteWidget(JWT);

    const dataAutocomplete = {
      entityTypes: [
        window.Synapse.MedicalResourceEntityType.BRANDED_DRUGS,
        window.Synapse.MedicalResourceEntityType.PHARMACEUTICAL_INGREDIENTS,
        window.Synapse.MedicalResourceEntityType.CLINICAL_DRUGS
      ],
      enableFavorite: true,
      favorites: user?.favorites_entities || []
    };

    if (containerRef.current) {
      autocompleteWidget.init(containerRef.current, dataAutocomplete);
      autocompleteWidget.update(dataAutocomplete);

      const shouldUpdateEntities = !fromHeader && !fromPersonnalTreatments;
      const shouldUpdateEntitiesProps = fromHeader || fromPersonnalTreatments;

      const callback = (entity) => {
        if (shouldUpdateEntities) {
          setEntities((curEntities) => [...curEntities, entity]);
          setRefresh((prevRefresh) => !prevRefresh);
        }

        if (shouldUpdateEntitiesProps) {
          setEntitiesProps((curEntities) => [...curEntities, entity]);
        }
      };

      const handleFavorite = async (entity, purpose) => {
        try {
          const operator = purpose === 'add' ? '$push' : '$pull';

          const { data } = await dispatchAPI('PATCH', {
            url: `/synapse/favorites-entities`,
            body: {
              [operator]: { favorites_entities: entity }
            }
          });

          setUser((prev) => ({
            ...prev,
            favorites_entities: data
          }));

          successMessage(`favorites.${purpose}`);
        } catch (error) {
          message(error);
        }
      };

      autocompleteWidget.setCallback('onAddFavorite', (entity) => {
        handleFavorite(entity, 'add');
      });

      autocompleteWidget.setCallback('onDeleteFavorite', (entity) => {
        handleFavorite(entity, 'remove');
      });

      // Set callbacks for the widget
      autocompleteWidget.setCallback('onSelectEntity', callback);

      // eslint-disable-next-line no-unused-vars
      autocompleteWidget.setCallback('onTokenExpiration', async (err, data) => {
        const newToken = await fetchNewToken();
        autocompleteWidget.setToken(newToken);
      });
    }
  }, [
    JWT,
    entities,
    entitiesProps,
    fromHeader,
    fromPersonnalTreatments,
    setEntitiesProps,
    user,
    setEntities,
    setRefresh,
    dispatchAPI,
    successMessage,
    message
  ]);

  return (
    <StyledCol className={componentId} id={componentId} ref={containerRef} />
  );
};

SynapseAutocompleteWidget.propTypes = {
  fromHeader: PropTypes.bool,
  fromPersonnalTreatments: PropTypes.bool,
  setEntitiesProps: PropTypes.func,
  entitiesProps: PropTypes.arrayOf(PropTypes.shape({}))
};

SynapseAutocompleteWidget.defaultProps = {
  fromHeader: false,
  fromPersonnalTreatments: false,
  setEntitiesProps: () => {},
  entitiesProps: []
};
