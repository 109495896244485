import { Link } from 'react-router-dom';
import { getPhoneNumber, getFullName } from '../../utils/formatters';

/**
 * Custom hook that generates a list of labels for company data.
 *
 * @hook
 * @param {Object} data - The company data object.
 * @returns {Array} - An array of label objects.
 */
export const useListContent = (data = {}) => {
  const { secretariat_phone_number, name, address, manager, provider } = data;

  const labels = [
    {
      label: 'companies.form.name',
      span: 1,
      content: name || '-'
    },
    {
      label: 'companies.form.address',
      span: 1,
      content: address || '-'
    },
    {
      label: 'users.form.phone_number_full',
      span: 1,
      content: getPhoneNumber(secretariat_phone_number)
    },
    {
      label: 'companies.form.manager',
      span: 1,
      content: manager ? (
        <Link to={`/users/show/${manager._id}`}>{getFullName(manager)}</Link>
      ) : (
        '-'
      )
    },
    {
      label: 'companies.form.manager_email',
      span: 1,
      content: manager?.email ? manager.email : '-'
    },
    {
      label: 'companies.form.manager_phone',
      span: 1,
      content: getPhoneNumber(manager?.phone_number)
    },
    {
      label: 'companies.form.provider',
      span: 1,
      content: provider || '-'
    }
  ];

  return labels;
};
