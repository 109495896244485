import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tree } from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * A component that renders a consulting tree with draggable nodes.
 *
 * @component
 * @param {Object[]} rooms - An array of room objects.
 * @param {boolean} refresh - A boolean value indicating whether the component should refresh.
 * @param {function} setRefresh - A function to set the refresh state.
 * @returns {JSX.Element} The rendered consulting tree component.
 */
export const ConsultingTree = ({ rooms, refresh, setRefresh }) => {
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [treeData, setTreeData] = useState([]);
  const [draggedRoomId, setDraggedRoomId] = useState('');

  useEffect(() => {
    const updatedTreeData = rooms.map((room) => ({
      title: room.title,
      key: room._id,
      index: room.index,
      children: []
    }));
    setTreeData(updatedTreeData);
  }, [rooms]);

  const handleDragStart = (info) => {
    const { node } = info;
    setDraggedRoomId(node.key);
  };

  const handleDrop = async (info) => {
    const { dropPosition } = info;

    await dispatchAPI('PATCH', {
      url: '/rooms/consulting-rooms-order',
      body: {
        draggedRoomId,
        dropPosition,
        centerId: id
      }
    });
    setDraggedRoomId('');
    setRefresh(!refresh);
  };

  const renderTreeNodes = (data) =>
    data.map((node) => (
      <Tree.TreeNode key={node.key} title={node.title} index={node.index}>
        {renderTreeNodes(node.children)}
      </Tree.TreeNode>
    ));

  return (
    <Tree
      draggable
      onDragStart={handleDragStart}
      onDrop={handleDrop}
      treeData={treeData}
    >
      {renderTreeNodes(treeData)}
    </Tree>
  );
};

ConsultingTree.propTypes = {
  rooms: PropTypes.arrayOf(PropTypes.shape({})),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

ConsultingTree.defaultProps = {
  rooms: [],
  refresh: false,
  setRefresh: () => {}
};
