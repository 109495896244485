import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Collapse, Form, Input } from 'antd';
import { IndividualTurnoverTargetTable } from '../IndividualTurnoverTargetTable/IndividualTurnoverTargetTable';

const { Panel } = Collapse;

/**
 * Renders the turnover targets component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {string} props.id - The ID of the component.
 * @returns {JSX.Element} The turnover targets component.
 */
export const TurnoverTargets = ({ form, id }) => {
  const { t } = useTranslation();
  return (
    <Card style={{ marginBottom: 24 }}>
      <Collapse expandIconPosition="end" defaultActiveKey={['2']} ghost>
        <Panel header={t('centers.settings.turnover_targets')} key="2">
          <Form.Item
            name={['center_turnover_target']}
            label={`${t('centers.form.center')} - ${t(
              'centers.form.daily_turnover_target'
            )}`}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name={['doctor_turnover_target']}
            label={`${t(`centers.form.DOCTOR`)} - ${t(
              'centers.form.daily_turnover_target'
            )}`}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name={['nurse_turnover_target']}
            label={`${t(`centers.form.NURSE`)} - ${t(
              'centers.form.daily_turnover_target'
            )}`}
          >
            <Input type="number" />
          </Form.Item>
        </Panel>
        <Card style={{ margin: '16px 0 16px 0' }}>
          <Collapse expandIconPosition="end" ghost>
            <Panel
              header={`${t(`centers.form.DOCTOR`)} - ${t(
                'centers.form.individual_turnover_target'
              )}`}
              key="1"
            >
              <IndividualTurnoverTargetTable
                position="DOCTOR"
                centerId={id}
                form={form}
              />
            </Panel>
          </Collapse>
        </Card>
        <Card style={{ margin: '16px 0 16px 0' }}>
          <Collapse expandIconPosition="end" ghost>
            <Panel
              header={`${t(`centers.form.NURSE`)} - ${t(
                'centers.form.individual_turnover_target'
              )}`}
              key="1"
            >
              <IndividualTurnoverTargetTable
                position="NURSE"
                centerId={id}
                form={form}
              />
            </Panel>
          </Collapse>
        </Card>
      </Collapse>
    </Card>
  );
};

TurnoverTargets.propTypes = {
  form: PropTypes.shape({}),
  id: PropTypes.string
};

TurnoverTargets.defaultProps = {
  form: {},
  id: ''
};
