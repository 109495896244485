import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Button, Flex, Col } from 'antd';
import PropTypes from 'prop-types';
import { mdiPill } from '@mdi/js';
import Icon from '@mdi/react';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';
import { FavoritesCard } from '../stay/cards/FavoritesCard';
import { useHandleClick } from '../stay/utils/useHandleClick';

/**
 * @component MedicationCard
 * @returns {React.Component} Card to display the button to search with synapse
 * @description This component is used in the exit prescription
 */
export const MedicationCard = ({ category, form }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, type } = useParams();
  const { setPosologies, setEntities } = usePrescriptionContext();
  const translationKey = 'prescription.cards.medications';

  const handleSearchWithSynapse = () => {
    setPosologies([]);
    setEntities([]);
    navigate(`/prescription/create/medications/${type}/${id}`);
  };

  const { handleClickFavoriteMedication } = useHandleClick({
    category,
    form
  });

  const title = (
    <Flex gap={8}>
      <Icon path={mdiPill} size={1} />
      {t(`${translationKey}.title`)}
    </Flex>
  );

  return (
    <>
      <Col span={12}>
        <FavoritesCard
          style={{ borderRadius: '0 8px 8px 0' }}
          category={category}
          editableRowHandleClick={handleClickFavoriteMedication}
          type={type}
        />
      </Col>
      <Col span={12}>
        <Card title={title} style={{ borderRadius: '0 8px 8px 0' }}>
          <Button onClick={() => handleSearchWithSynapse()}>
            {t(`${translationKey}.search_with_synapse`).toUpperCase()}
          </Button>
        </Card>
      </Col>
    </>
  );
};

MedicationCard.propTypes = {
  category: PropTypes.string,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired
  }).isRequired
};

MedicationCard.defaultProps = {
  category: 'medication'
};
