import { Card, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadPhotoButton } from './components/UploadPhotoButton';
import { CareCard } from './cards/CareCard';
import { AdministrationCard } from './cards/AdministrationCard';
import { PrintButton } from './components/PrintButton';
import { useFields } from './fields';
import { useConsultationsContext } from '../../ConsultationsContext';

/**
 * A component that displays documents.
 *
 * @component
 *
 * @returns {JSX.Element} The ShowDocuments component.
 */
export const ShowDocuments = () => {
  const resource = 'consultations';
  const { t } = useTranslation();

  const { photoFields, printFields, documentType, documentWording } =
    useFields();
  const { patient } = useConsultationsContext();

  return (
    <Card
      title={t(`${resource}.form.documents`)}
      extra={
        <div>
          <Space>
            <UploadPhotoButton fields={photoFields} />
            <PrintButton
              fields={printFields}
              documentType={documentType}
              documentWording={documentWording}
              patientId={patient._id}
            />
          </Space>
        </div>
      }
    >
      <Row justify="space-between" gutter={[16, 16]}>
        <CareCard resource={resource} />
        <AdministrationCard resource={resource} />
      </Row>
    </Card>
  );
};
