import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Row, Col } from 'antd';

/**
 * Component for displaying prescription dates.
 *
 * @component
 * @param {boolean} showLabelInput - Determines whether to show the label input.
 * @param {string} dateFormat - The format of the date.
 * @param {object} infos - Information about the current consultation.
 * @param {object} infos.current_consultation - Information about the current consultation.
 * @param {string} infos.current_consultation.date - The date of the current consultation.
 * @returns {JSX.Element} The rendered component.
 */
export const PrescriptionsDate = ({
  showLabelInput,
  dateFormat,
  infos,
  translationKey
}) => {
  const { t } = useTranslation();

  return (
    <Row justify="end">
      <Col>
        <Row justify="end">
          {t(`${translationKey}.consultation.current_date`, {
            current_date: showLabelInput
              ? t(`${translationKey}.consultation.print_date`)
              : dayjs().format(dateFormat)
          })}
        </Row>
        <Row style={{ marginBottom: 40 }} justify="end">
          {t(`${translationKey}.consultation.consultation_date`, {
            consultation_date: showLabelInput
              ? t(`${translationKey}.consultation.consulting_date`)
              : dayjs(infos?.current_consultation?.date).format(dateFormat)
          })}
        </Row>
      </Col>
    </Row>
  );
};

PrescriptionsDate.propTypes = {
  showLabelInput: PropTypes.bool,
  dateFormat: PropTypes.string,
  infos: PropTypes.shape({
    current_consultation: PropTypes.shape({
      date: PropTypes.string
    })
  }),
  translationKey: PropTypes.string.isRequired
};

PrescriptionsDate.defaultProps = {
  showLabelInput: false,
  dateFormat: '',
  infos: null
};
