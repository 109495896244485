import { Button, Card, Col, Form, Flex, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiHeart } from '@mdi/js';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { Protocol } from '../../../../models/Protocol';
import { EditableCell } from '../EditableCell';
import { useFavoriteColumns } from '../utils/useFavoriteColumns';
import { FavoriteMedication } from '../../../../models/FavoriteMedication';

/**
 * Represents a Favorites Card component.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.category - The category of the favorites.
 * @param {function} props.setGlobalCheckedKeys - The function to set the global checked keys.
 * @param {function} props.editableRowHandleClick - The function to handle editable row click.
 * @param {function} props.postFavorite - The function to post a favorite.
 * @param {boolean} props.refresh - The refresh flag.
 * @param {function} props.setRefresh - The function to set the refresh flag.
 * @param {boolean} props.withCreateButton - The flag to show the create button.
 * @returns {JSX.Element} The Favorites Card component.
 */
export const FavoritesCard = ({
  category,
  setGlobalCheckedKeys,
  editableRowHandleClick,
  postFavorite,
  refresh,
  setRefresh,
  withCreateButton,
  type
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [editableCellForm] = Form.useForm();

  const [editingKey, setEditingKey] = useState('');
  const [selectedKey, setSelectedKey] = useState('');
  const [dataSource, setDataSource] = useState([]);

  const isEditing = (record) => record._id === editingKey;
  const selected = (record) => record._id === selectedKey;

  const fetchFavorites = (categoryWording, userId) => {
    if (category === 'MEDICATIONS') {
      return FavoriteMedication.getFavoriteMedication({
        author: userId,
        archived: false,
        type
      });
    }
    return Protocol.getFavoritesProtocols(categoryWording, {
      author: userId,
      archived: false
    });
  };

  useEffect(() => {
    const loadFavorites = async () => {
      const response = await fetchFavorites(category, user._id);
      setDataSource(response);
    };

    loadFavorites();
  }, [category, refresh]);

  const edit = (record) => {
    editableCellForm.setFieldsValue({
      wording: '',
      ...record
    });
    setEditingKey(record._id);
  };

  const save = async (id) => {
    const label = editableCellForm.getFieldValue(['label']);
    if (category === 'MEDICATIONS') {
      await FavoriteMedication.patchFavoriteMedication(id, { label }, {});
    } else {
      await Protocol.patchFavoriteProtocol(id, { label }, {});
    }
    setEditingKey('');
    setRefresh(!refresh);
  };

  const del = async (id) => {
    if (category === 'MEDICATIONS') {
      await FavoriteMedication.deleteFavoriteMedication(id);
    } else {
      await Protocol.deleteFavoriteProtocol(id);
    }
    setRefresh(!refresh);
  };

  const { columns } = useFavoriteColumns({
    selected,
    isEditing,
    save,
    edit,
    del
  });

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  return (
    <Col>
      <Card
        style={{ borderRadius: '8px 0 0 8px' }}
        title={
          <Flex gap={8}>
            <Icon path={mdiHeart} size={1} color="#FFB94A" />
            {t(`prescription.enums.favorites_${category}`)}
          </Flex>
        }
        extra={
          withCreateButton && (
            <Button
              icon={<PlusOutlined />}
              onClick={() => postFavorite(category)}
            />
          )
        }
      >
        <Form form={editableCellForm}>
          <Table
            size="small"
            components={{
              body: {
                cell: EditableCell
              }
            }}
            bordered={false}
            dataSource={dataSource}
            columns={mergedColumns}
            showHeader={false}
            onRow={(record) => ({
              onClick: () =>
                editableRowHandleClick({
                  record,
                  setSelectedKey,
                  isEditing,
                  selectedKey,
                  setGlobalCheckedKeys
                }),
              style: { cursor: 'pointer' }
            })}
          />
        </Form>
      </Card>
    </Col>
  );
};

FavoritesCard.propTypes = {
  category: PropTypes.string.isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  setGlobalCheckedKeys: PropTypes.func.isRequired,
  editableRowHandleClick: PropTypes.func.isRequired,
  postFavorite: PropTypes.func.isRequired,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  withCreateButton: PropTypes.bool,
  type: PropTypes.string
};

FavoritesCard.defaultProps = {
  refresh: false,
  setRefresh: () => {},
  withCreateButton: false,
  type: ''
};
