import PropTypes from 'prop-types';
import { useState } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const { TextArea } = Input;

const resource = 'patients';

export const NewComment = ({ antdKey, dbKey }) => {
  const [comment, setComment] = useState('');
  const { t } = useTranslation();

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const placeholder = t(`${resource}.form.add_comment`);

  return (
    <Item name={[`${antdKey}`, `${dbKey}`]}>
      <TextArea
        value={comment}
        onChange={handleChange}
        placeholder={placeholder}
        style={{ resize: 'none' }}
      />
    </Item>
  );
};

NewComment.propTypes = {
  antdKey: PropTypes.string.isRequired,
  dbKey: PropTypes.string.isRequired
};
