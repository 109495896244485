import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';

/**
 * A button component that displays vitale card data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setIsModalVisible - A function to set the visibility of the modal.
 * @returns {JSX.Element} The JSX button element.
 */
export const DisplayVitaleCardDataButton = ({ setIsModalVisible }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={() => setIsModalVisible((prev) => ({ ...prev, success: true }))}
    >
      <UnorderedListOutlined />
      {t('patients.form.display_vitale_card_data')}
    </Button>
  );
};

DisplayVitaleCardDataButton.propTypes = {
  setIsModalVisible: PropTypes.func.isRequired
};
