import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, FlagFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import { getBirthDate } from '../../../../utils/formatters';

/**
 * Custom hook that returns an array of columns for the search results table.
 *
 * @hook
 * @param {Function} handleCancelResult - Function to handle canceling the search result.
 * @returns {Array} - Array of column objects.
 */
export const useColumnsSearch = (handleCancelResult) => {
  const iconSize = 18;
  const { t } = useTranslation();

  return [
    {
      key: 'reported',
      dataIndex: 'reported',
      render: (reported) =>
        reported ? <FlagFilled style={{ color: 'red' }} /> : null
    },
    {
      title: t('patients.form.civility'),
      key: 'civility',
      dataIndex: 'gender',
      sorter: true,
      render: (gender) => (
        <Tag color="purple">{t(`patients.form.${gender}`)}</Tag>
      )
    },
    {
      title: t('patients.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('patients.form.birth_name'),
      key: 'birth_name',
      dataIndex: 'birth_name',
      sorter: true
    },
    {
      title: t('patients.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('patients.form.birthdate'),
      dataIndex: 'birth_date',
      key: 'birth_date',
      render: (birth_date) => getBirthDate(birth_date)
    },
    {
      render: (record) => (
        <Link
          to={`/patients/edit/${record?._id}`}
          state={{ create_consultation: true }}
          onClick={handleCancelResult}
        >
          <CheckOutlined style={{ fontSize: iconSize }} />
        </Link>
      )
    }
  ];
};
