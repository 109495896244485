import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';

/**
 * Hook to generate columns configuration for doctor mails.
 *
 * @hook
 * @param {boolean} isSwitched - A boolean indicating whether the switch is on or off.
 *
 * @returns {Array} An array of objects, each representing a column configuration.
 * @property {string} title - The title of the column.
 * @property {string} key - The key of the column.
 * @property {string} dataIndex - The dataIndex of the column.
 * @property {boolean} sorter - Whether the column has a sorter.
 * @property {Function} [render] - Function to render the column content.
 */
export const useDoctorsLettersColumns = (isSwitched) => {
  const { t } = useTranslation();
  const { downloadDocument, viewDocument } = useDownloadDocument();

  const formatDate = (date) => dayjs(date).format('DD-MM-YYYY');

  const dateColumn = {
    title: t('consultations.date'),
    key: 'created_at',
    dataIndex: 'created_at',
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    render: (prescription_date) =>
      prescription_date ? formatDate(prescription_date) : '-'
  };
  const reasonColumn = {
    title: t('consultations.reason'),
    key: 'wording',
    dataIndex: 'visit_reasons',
    sorter: (a, b) => {
      const aReasons = a.visit_reasons
        .map((reason) => reason.wording)
        .join(', ');
      const bReasons = b.visit_reasons
        .map((reason) => reason.wording)
        .join(', ');

      return aReasons.length - bReasons.length;
    },
    render: (visit_reasons) =>
      visit_reasons.map((reason) => reason.wording).join(', ')
  };
  const generalPractitionnerColumn = {
    title: t('consultations.doctor'),
    key: 'doctor',
    dataIndex: 'doctor',
    sorter: (a, b) => b.doctor.length - a.doctor.length
  };

  const actionColumns = {
    key: 'action',
    align: 'right',
    render: (record) => (
      <>
        <DownloadOutlined
          onClick={() => downloadDocument(record.doctors_letter_document)}
        />
        <Divider type="vertical" />

        <EyeOutlined
          onClick={() =>
            viewDocument({ _id: record.doctors_letter_document._id })
          }
        />
      </>
    )
  };

  return isSwitched
    ? [reasonColumn, dateColumn, generalPractitionnerColumn, actionColumns]
    : [dateColumn, reasonColumn, generalPractitionnerColumn, actionColumns];
};
