import { Routes, Route } from 'react-router-dom';
import { PrescriptionContextProvider } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { CreateUpdateFavoriteMedicationPrescription } from './CreateUpdateFavoriteMedicationPrescription';
import { CreateUpdateFavoriteFreeTextPrescription } from './CreateUpdateFavoriteFreeTextPrescription';
import { ListFavoritesPrescriptions } from './ListFavoritesPrescriptions';
import { Exception } from '../../components';

export const FavoritesRouter = () => (
  <PrescriptionContextProvider>
    <Routes>
      <Route index element={<ListFavoritesPrescriptions />} />
      <Route
        path="/create/medication/:type"
        element={
          <CreateUpdateFavoriteMedicationPrescription purpose="create" />
        }
      />
      <Route
        path="/create/free-text/:type"
        element={<CreateUpdateFavoriteFreeTextPrescription purpose="create" />}
      />
      <Route path="*" element={<Exception />} />
    </Routes>
  </PrescriptionContextProvider>
);
