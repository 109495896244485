import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-components';
import { HomeOutlined } from '@ant-design/icons';
import { routes } from '../../utils/constants/adminRoutes';

/**
 * Custom page header component.
 *
 * @component
 * @param {string} title - The title of the page.
 * @param {node} extra - The extra content to display on the page header.
 * @param {boolean} onBack - Whether to display the back button.
 * @param {boolean} withoutBreadCrumbs - Whether to display the breadcrumbs.
 * @param {object} style - The style object to apply to the page header.
 * @returns {JSX.Element} The custom page header component.
 */
export const PageHeaderCustom = ({
  title,
  extra,
  onBack,
  withoutBreadCrumbs,
  style
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitPathname = pathname.split('/').filter((p) => p !== '');
  let tmpPath;
  const breadCrumbs = splitPathname
    .slice(0, splitPathname.length - 1)
    .map((p) => {
      tmpPath = `/${p}`;
      return {
        path: tmpPath,
        breadcrumbName: t(`breadcrumbs.${p}`)
      };
    });
  const dynamicRoutes = [
    {
      path: routes.HOME,
      breadcrumbName: <HomeOutlined />
    },
    ...breadCrumbs
  ];

  const itemRender = (
    // eslint-disable-next-line react/prop-types
    { path, breadcrumbName, state, search, ...route },
    params,
    routesList
  ) => {
    const last = routesList.indexOf(route) === routesList.length - 1;
    return last ? (
      <span key={path}>{breadcrumbName}</span>
    ) : (
      <Link key={path} to={{ pathname: path, state, search }}>
        {breadcrumbName}
      </Link>
    );
  };

  return (
    <PageHeader
      breadcrumb={
        !withoutBreadCrumbs ? { items: dynamicRoutes, itemRender } : false
      }
      title={title}
      onBack={onBack ? () => navigate(-1) : false}
      ghost={false}
      extra={extra}
      style={style}
    />
  );
};

PageHeaderCustom.propTypes = {
  title: PropTypes.string,
  extra: PropTypes.node,
  onBack: PropTypes.bool,
  withoutBreadCrumbs: PropTypes.bool,
  style: PropTypes.shape({})
};

PageHeaderCustom.defaultProps = {
  title: undefined,
  extra: null,
  onBack: true,
  withoutBreadCrumbs: false,
  style: {}
};
