import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Select, Tag } from 'antd';
import { GetEnums } from '../../utils/getEnums';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;

/**
 * ExtraButtons component.
 * Renders a row of Select components for selecting positions and roles.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setSelectedPositions - Callback function to set selected positions.
 * @param {Function} props.setSelectedRoles - Callback function to set selected roles.
 * @param {string} props.resourceName - The name of the resource. (e.g. 'users')
 * @returns {JSX.Element} - The rendered ExtraButtons component.
 */
export const ExtraButtons = ({
  setSelectedPositions,
  setSelectedRoles,
  resourceName
}) => {
  const { t } = useTranslation();
  const { getEnums } = GetEnums();
  const [enums, setEnums] = useState({});

  const getUserEnums = async () => {
    const data = await getEnums(resourceName);
    setEnums(data);
  };

  useEffect(() => {
    (async () => {
      await getUserEnums();
    })();
  }, []);

  return (
    <Row style={{ gap: 8, paddingRight: 16 }} align="middle">
      <Select
        style={{ width: 240 }}
        mode="multiple"
        allowClear
        showSearch={false}
        placeholder={t('users.form.position')}
        onChange={(value) => setSelectedPositions(value)}
      >
        {enums?.positions?.map((position) => (
          <Option key={position} value={position}>
            {t(`users.positions.${position}`)}
          </Option>
        ))}
      </Select>
      <Select
        style={{ width: 240 }}
        mode="multiple"
        allowClear
        showSearch={false}
        placeholder={t('users.form.role')}
        onChange={(value) => setSelectedRoles(value)}
      >
        {enums?.roles?.map((role) => (
          <Option key={role} value={role}>
            <Tag color={userRoles[role.split(':')[1]]}>
              {t(`users.tags.${role.split(':')[1]}`, {
                defaultValue: role.split(':')[1]
              })}
            </Tag>
          </Option>
        ))}
      </Select>
    </Row>
  );
};

ExtraButtons.propTypes = {
  setSelectedPositions: PropTypes.func.isRequired,
  setSelectedRoles: PropTypes.func.isRequired,
  resourceName: PropTypes.string.isRequired
};
