import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Menu, Tooltip } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/handleResize';
import {
  routes as superUserRoutes,
  pathSearches
} from '../../utils/constants/super-userRoutes';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * SuperUserNavMenu component displays the navigation menu for super users.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setCollapsed - The function to set the collapsed state.
 * @returns {JSX.Element} - The rendered SuperUserNavMenu component.
 */
export const SuperUserNavMenu = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { width } = useHandleResize();
  const { user: { landing_choice } = {} } = useAuthContext();

  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  let routes;
  if (superUserRoutes) {
    const newRoutes = { ...superUserRoutes };
    delete newRoutes.PATIENTS;
    delete newRoutes[landing_choice];

    routes = newRoutes;
  }

  const items = Object.entries(routes).map(([menuItem, p]) => ({
    key: p,
    disabled: p === '',
    label: (
      <NavLink
        to={`${p}${pathSearches[menuItem] ? pathSearches[menuItem] : ''}`}
      >
        <Tooltip title={t(`basiclayout.menu.${menuItem.toLowerCase()}`)}>
          {navMenuLogos[menuItem]}
        </Tooltip>
      </NavLink>
    )
  }));

  return (
    <Flex>
      <Menu
        selectedKeys={activeKeys}
        defaultOpenKeys={activeKeys}
        style={{ width: '100%' }}
        mode="horizontal"
        onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
        items={items}
      />
    </Flex>
  );
};

SuperUserNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
