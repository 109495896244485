import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const DataBaseContext = createContext();

/**
 * Context to manage database-related state in a healthcare application.
 * @namespace DataBaseContext
 * @typedef {Object} DataBaseContext
 * @property {string} activeKey - The active key in the database.
 * @property {Function} setActiveKey - Function to set the active key.
 * @property {string} prescriptionWording - The prescription wording in the database.
 * @property {Function} setPrescriptionWording - Function to set the prescription wording.
 * @property {string} category - The category in the database.
 * @property {Function} setCategory - Function to set the category.
 */

/**
 * Provider component for the DataBaseContext.
 * @function
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the provider.
 * @returns {JSX.Element} JSX element representing the DataBaseContextProvider.
 */
export const DataBaseContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState('visitReasons');
  const [prescriptionWording, setPrescriptionWording] = useState('');
  const [category, setCategory] = useState('');
  const [prescriptionType, setPrescriptionType] = useState('');
  const [options, setOptions] = useState([]);
  const [purposeOptions, setPurposeOptions] = useState('');

  const handleAddOption = (values) => {
    setOptions((option) => [...option, values]);
    navigate(-1);
  };

  const resetPrescriptionState = () => {
    setPrescriptionWording('');
    setCategory('');
    setOptions([]);
  };

  return (
    <DataBaseContext.Provider
      value={{
        activeKey,
        setActiveKey,
        category,
        setCategory,
        prescriptionType,
        setPrescriptionType,
        prescriptionWording,
        setPrescriptionWording,
        handleAddOption,
        options,
        setOptions,
        resetPrescriptionState,
        purposeOptions,
        setPurposeOptions
      }}
    >
      {children}
    </DataBaseContext.Provider>
  );
};

export const useDataBasesContext = () => useContext(DataBaseContext);
