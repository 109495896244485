import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * OptionsMethods is a utility function that provides methods for patching and posting data.
 * @returns {Object} An object containing the patchMethod and postMethod functions.
 */
export const OptionsMethods = ({
  setOptimistOptions = () => {},
  optimistOptions = [],
  options = [],
  setOptions = () => {}
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  /**
   * OptionsMethods is a custom hook that provides methods for interacting with stay prescription options in the database.
   * @function
   * @async
   * @param {string} optionWording - The wording of the option.
   * @returns {Object} An object containing the patchMethod and postMethod functions.
   */
  const archiveOption = (optionWording) => {
    const newOptimistOptions = optimistOptions.filter(
      (option) => option.option_wording !== optionWording
    );
    const newOptions = options.filter(
      (option) => option.option_wording !== optionWording
    );
    if (setOptimistOptions) setOptimistOptions(newOptimistOptions);
    if (setOptions) setOptions(newOptions);
  };

  /**
   * Patch data using the PATCH method.
   * @function
   * @async
   * @param {Object} body - The data to be patched.
   * @param {string} path - The path to the resource.
   * @param {string} optionId - The ID of the option.
   * @param {function} navigate - The navigation function.
   * @returns {Promise} The result of the patch operation.
   */
  const patchMethod = async (body, path, optionId, navigate) => {
    if (body && optionId) {
      try {
        await dispatchAPI('PATCH', {
          url: `${path}/${optionId}`,
          body
        });
        navigate(-1);
      } catch (error) {
        message(error);
      }
    }
  };

  /**
   * Post data using the POST method.
   * @function
   * @async
   * @param {Object} body - The data to be posted.
   * @param {string} path - The path to the resource.
   * @param {function} navigate - The navigation function.
   * @returns {Promise} The result of the post operation.
   */
  const postMethod = async (body, path, navigate = null) => {
    if (body && path) {
      try {
        await dispatchAPI('POST', {
          url: path,
          body
        });
        if (navigate) navigate(-1);
      } catch (error) {
        message(error);
      }
    }
  };

  return {
    archiveOption,
    patchMethod,
    postMethod
  };
};
