import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { PatientContext } from '../../../../contexts/PatientContext/PatientContext';

const { Option } = Select;
const { Item } = Form;

export const CollapseAmoAmcInput = ({ dbKey }) => {
  const { t } = useTranslation();
  const { enums } = useContext(PatientContext);
  return (
    <Item name={`${dbKey}`}>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toString().toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toString()
            .toLowerCase()
            .localeCompare(optionB.children.toString().toLowerCase())
        }
        placeholder={t('patients.form.local_social_security_center')}
      >
        {enums?.cpamData?.map((cpam) => (
          <Option key={cpam.department} value={cpam.department}>
            {cpam.department} {cpam.name}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

CollapseAmoAmcInput.propTypes = {
  dbKey: PropTypes.string.isRequired
};
