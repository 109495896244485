/**
 * Array of header objects used in the directory component.
 *
 * @typedef {Object} Header
 * @property {string} label - The label of the header.
 * @property {string} key - The key used to access the corresponding data in the table.
 */

/**
 * Array of headers used in the directory component.
 *
 * @type {Header[]}
 */
export const headers = [
  {
    label: 'establishment',
    key: 'establishment'
  },
  {
    label: 'speciality',
    key: 'speciality'
  },
  {
    label: 'contact.first_name',
    key: 'contact.first_name'
  },
  {
    label: 'contact.last_name',
    key: 'contact.last_name'
  },
  {
    label: 'short_number',
    key: 'short_number'
  },
  {
    label: 'secretary_phone_number',
    key: 'secretary_phone_number'
  },
  {
    label: 'secretary_email',
    key: 'secretary_email'
  },
  {
    label: 'cell_phone_number',
    key: 'cell_phone_number'
  },
  {
    label: 'follow_up_care',
    key: 'follow_up_care'
  }
];
