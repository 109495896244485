import PropTypes from 'prop-types';
import { Button, Card, Collapse } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ConsultingRoomsTable } from '../ConsultingRooms/ConsultingRoomsTable';

const { Panel } = Collapse;

/**
 * Renders the component for managing consulting rooms.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the consulting room.
 * @param {boolean} props.refresh - The refresh flag.
 * @param {function} props.setRefresh - The function to set the refresh flag.
 * @param {function} props.postRoom - The function to post a new room.
 * @returns {JSX.Element} The JSX element representing the consulting rooms component.
 */
export const ConsultingRooms = ({ id, refresh, setRefresh, postRoom }) => {
  const { t } = useTranslation();
  return (
    <Card style={{ marginBottom: 24 }}>
      <Collapse expandIconPosition="end" defaultActiveKey={['4']} ghost>
        <Panel header={t('centers.settings.consulting_rooms')} key="4">
          <ConsultingRoomsTable
            refresh={refresh}
            setRefresh={setRefresh}
            id={id}
          />
          <Button
            style={{ width: '100%' }}
            type="dashed"
            onClick={() => postRoom('CONSULTING_ROOM')}
          >
            <PlusOutlined />
            {t('buttons.add_room')}
          </Button>
        </Panel>
      </Collapse>
    </Card>
  );
};

ConsultingRooms.propTypes = {
  form: PropTypes.shape({}),
  id: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  postRoom: PropTypes.func
};

ConsultingRooms.defaultProps = {
  form: {},
  id: '',
  refresh: false,
  setRefresh: () => {},
  postRoom: () => {}
};
