import React, { useState } from 'react';
import { Switch, Typography, Card, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Text } = Typography;
const { Panel } = Collapse;

/**
 * SwitchNumHour component renders a switch to toggle between "Numéro de consultation" and "Heure d'arrivée".
 * It updates the user's preference on the server and manages the state accordingly.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered component.
 */
const SwitchNumHour = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user, setUser } = useAuthContext();
  const [checked, setChecked] = useState(user.switch_option);
  const { message } = useErrorMessage();

  const handleChange = async (check) => {
    setChecked(check);

    try {
      const formData = new FormData();
      formData.append('values', JSON.stringify({ switch_option: check }));
      const { data } = await dispatchAPI('PATCH', {
        url: `/users/${user._id}`,
        body: formData
      });
      setUser(data);
    } catch (e) {
      message(e);
    }
  };

  return (
    <Card style={{ marginBottom: 24 }}>
      <Collapse expandIconPosition="end" defaultActiveKey={['1']} ghost>
        <Panel header={t('settings.collapses.badge_display')} key="1">
          <Switch
            checked={checked}
            onChange={handleChange}
            style={{ marginRight: 8 }}
          />
          <Text>
            {checked
              ? t('consultations.arrival_time')
              : t('consultations.consult_num')}
          </Text>
        </Panel>
      </Collapse>
    </Card>
  );
};

export default SwitchNumHour;
