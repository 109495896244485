import { useTranslation } from 'react-i18next';
import { Radio, Row, Form, Col } from 'antd';
import { useConsultationsContext } from '../../ConsultationsContext';

export const SelectConsultationType = () => {
  const resource = 'consultations';
  const { Item } = Form;
  const { enums, selectedVisitValue, setSelectedVisitValue } =
    useConsultationsContext();
  const { t } = useTranslation();

  const handleChange = (e) => {
    setSelectedVisitValue(e.target.value);
  };

  return (
    <Item name={['visit_type']}>
      <Row>
        <Col span={24} offset={19}>
          <Radio.Group
            value={selectedVisitValue}
            onChange={handleChange}
            buttonStyle="solid"
          >
            {enums?.visits_types?.map((visit_type) => (
              <Radio.Button key={visit_type} value={visit_type}>
                {t(`${resource}.form.visits_type.${visit_type}`)}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Col>
      </Row>
    </Item>
  );
};
