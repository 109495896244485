import { Table } from 'antd';
import { useCentersColumns } from './CentersColumns';
import { useCentersActionsColumns } from './CentersActionsColumns';

/**
 * Renders a table component for displaying centers.
 *
 * @component
 * @param {function} t - i18n translate function.
 * @param {Object} record - The record object containing centers data.
 * @param {Array} typeArr - An array of center types.
 * @param {Function} archivingCenter - The function to archive / unarchive center.
 * @returns {JSX.Element} - The rendered table component.
 */
export const centersTable = (t, record, typeArr, archivingCenter) => (
  <Table
    columns={[
      ...useCentersColumns(t, typeArr),
      ...useCentersActionsColumns(t, record._id, archivingCenter)
    ]}
    dataSource={record.centers}
    pagination={false}
    rowKey={(r) => r._id}
  />
);
