import { useTranslation } from 'react-i18next';
import { Radio, Row, Form } from 'antd';

/**
 * HyperalgesicRadio component
 * @component HyperalgesicRadio
 * @return {object} - The UI of the HyperalgesicRadio component
 */
export const HyperalgesicRadio = () => {
  const { Item } = Form;
  const { t } = useTranslation();

  return (
    <Item name={['hyperalgesic']} valuePropName="checked">
      <Row>
        <Radio.Group defaultValue={false}>
          <Radio value>{t(`buttons.yes`)}</Radio>
          <Radio value={false}>{t(`buttons.no`)}</Radio>
        </Radio.Group>
      </Row>
    </Item>
  );
};
