import { Card, Checkbox, Col, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { renderTitle } from '../../utils/renderTitle';

/**
 * Renders a card component for displaying laterality options in a radiology prescription form.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Function} props.laterality - The function to determine if laterality options should be displayed.
 * @returns {JSX.Element} The rendered CardLeteralities component.
 */
export const CardLeteralities = ({ laterality }) => {
  const { t } = useTranslation();

  return (
    <Col xs={24} md={24} lg={6} style={{ display: 'flex' }}>
      <Card
        title={renderTitle(
          t('prescription.radiology.form.card_titles.laterality')
        )}
        style={{
          border: 'none',
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {laterality() && (
          <Form.Item
            name="laterality"
            style={{ flex: '1 1 auto', height: '100%' }}
          >
            <Checkbox.Group
              options={[
                {
                  label: t('prescription.radiology.form.lateral.left'),
                  value: 'left'
                },
                {
                  label: t('prescription.radiology.form.lateral.right'),
                  value: 'right'
                },
                {
                  label: t('prescription.radiology.form.lateral.bilateral'),
                  value: 'bilateral'
                }
              ]}
              style={{
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column'
              }}
            />
          </Form.Item>
        )}
      </Card>
    </Col>
  );
};

CardLeteralities.propTypes = {
  laterality: PropTypes.func.isRequired
};
