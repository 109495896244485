import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Select, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const { Item } = Form;

export const ReasonsSelect = ({ enums }) => {
  const { t } = useTranslation();

  const options = enums?.medical_history?.map((item) => ({
    label: item.name,
    value: item.name
  }));

  return (
    <Form.List name={['patient', 'visit_reasons']}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <Row key={field.name} align="middle">
              <Item {...field} name={[field.name]} key={[field.name]}>
                <Select
                  allowClear
                  style={{
                    width: '100%'
                  }}
                  placeholder={t(
                    'consultations.form.multiple_select_placeholder'
                  )}
                  options={options}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Item>
              {fields.length > 1 && (
                <CloseOutlined onClick={() => remove(field.name)} />
              )}
            </Row>
          ))}
          <Button
            onClick={() => add()}
            style={{
              borderRadius: '15px'
            }}
          >
            {t('consultations.form.add_reason')}
          </Button>
        </>
      )}
    </Form.List>
  );
};
ReasonsSelect.propTypes = {
  enums: PropTypes.shape({
    medical_history: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

ReasonsSelect.defaultProps = {
  enums: { medical_history: [] }
};
