import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * Hook to generate columns configuration for date and reasons.
 *
 * @hook
 * @returns {Array} An array of objects, each representing a column configuration.
 * @property {string} title - The title of the column.
 * @property {string} key - The key of the column.
 * @property {string} dataIndex - The dataIndex of the column.
 * @property {boolean} sorter - Whether the column has a sorter.
 * @property {Function} [render] - Function to render the column content.
 */
export const useColumnsDateReasons = () => {
  const { t } = useTranslation();

  const formatDate = (date) => dayjs(date).format('DD-MM-YYYY');

  return [
    {
      title: t('consultations.date'),
      key: 'prescription_date',
      dataIndex: 'prescription_date',
      sorter: true,
      render: (prescription_date) =>
        prescription_date ? formatDate(prescription_date) : '-'
    },
    {
      title: t('consultations.reason'),
      key: 'prescription_type',
      dataIndex: 'prescription_type',
      sorter: true
    }
  ];
};
