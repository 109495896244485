/* eslint-disable import/no-extraneous-dependencies */
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Select, Input, Form, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;

/**
 * Custom hook that provides fields for a form in the centers component.
 *
 * @hook
 *
 * @param {string} address - The address of the center.
 * @param {Function} setAddress - The function to set the address of the center.
 * @returns {Object} An object containing the fields for the form.
 */
const useFields = (address, setAddress) => {
  const { id, companyId } = useParams();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const [center, setCenter] = useState({});

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/centers/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    await getEnums();
  }, []);

  const getCompany = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/company/${companyId || id}`
      });
      setCenter(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getSelectOptions();
      await getCompany();
      setIsLoading(false);
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select
          placeholder={t('centers.form.select_type')}
          loading={isLoading}
          disabled={isLoading}
          style={{ width: '100%' }}
        >
          {enums.types?.map((type) => (
            <Option key={type} value={type}>
              {t(`centers.enums.types.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['associated_company'],
      rules: [{ required: true }],
      input: (
        <Form.Item
          noStyle
          name={['associated_company']}
          initialValue={companyId || id}
        >
          <Select
            placeholder={t('centers.form.select_center')}
            loading={isLoading}
            style={{ width: '100%' }}
            disabled
          >
            <Option value={companyId || id}>{center?.name}</Option>
          </Select>
        </Form.Item>
      )
    },
    {
      name: ['secretariat_phone_number'],
      label: 'secretariat_phone_number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['secretariat_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['secretariat_phone_number', 'number']}>
            <Input style={{ width: '75%' }} maxLength={10} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email'],
      rules: [{ type: 'email', required: true }]
    },
    {
      label: 'address',
      name: ['address'],
      rules: [{ required: true }],
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={(value) => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: t('placeholder.search_address'),
                  className: 'location-search-input'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <Spin />}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    }
  ];

  return {
    fields
  };
};

export default useFields;
