import { Transfer, Tree } from 'antd';
import PropTypes from 'prop-types';

/**
 * Checks whether a given key is included in the selected keys.
 *
 * @param {string[]} selectedKeys - The array of selected keys.
 * @param {string} eventKey - The key to check.
 * @returns {boolean} - True if the key is included, false otherwise.
 */
const isChecked = (selectedKeys, eventKey) => selectedKeys.includes(eventKey);

/**
 * Recursively generates a tree structure with disabled nodes based on checked keys.
 *
 * @param {Object[]} treeNodes - The array of tree nodes.
 * @param {string[]} checkedKeys - The array of checked keys.
 * @returns {Object[]} - The generated tree structure.
 */
const generateTree = (treeNodes = [], checkedKeys = []) =>
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    disabled: !!children?.length || checkedKeys.includes(props.key),
    children: generateTree(children, checkedKeys)
  }));

/**
 * A transfer component with a tree on the left side for data source selection.
 *
 * @param {Object} props - The component's properties.
 * @param {Object[]} props.dataSource - The array of tree nodes for data source.
 * @param {string[]} props.targetKeys - The keys of the target items.
 * @param {...any} restProps - Additional properties to be passed to the underlying Transfer component.
 * @returns {JSX.Element} - The rendered React element.
 */
export const TreeTransfer = ({
  dataSource,
  targetKeys,
  setTargetNodes = () => {},
  ...restProps
}) => {
  const transferDataSource = [];
  const flatten = (list = []) => {
    list.forEach((item) => {
      transferDataSource.push(item);
      flatten(item.children);
    });
  };
  flatten(dataSource);

  return (
    <Transfer
      {...restProps}
      targetKeys={targetKeys}
      dataSource={transferDataSource}
      className="tree-transfer"
      render={(item) => item.title}
      showSelectAll={false}
    >
      {({ direction, onItemSelect, selectedKeys }) => {
        if (direction === 'left') {
          const checkedKeys = [...selectedKeys, ...targetKeys];

          return (
            <div>
              <Tree
                blockNode
                checkable
                checkStrictly
                defaultExpandAll
                selectable={false}
                checkedKeys={checkedKeys}
                treeData={generateTree(dataSource, targetKeys)}
                onCheck={(
                  checkedKeysValue,
                  { node: { key }, checkedNodes }
                ) => {
                  onItemSelect(key, !isChecked(checkedKeys, key));
                  setTargetNodes(checkedNodes);
                }}
              />
            </div>
          );
        }
        return null;
      }}
    </Transfer>
  );
};

TreeTransfer.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  targetKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTargetNodes: PropTypes.func.isRequired
};
