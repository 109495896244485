import Proptype from 'prop-types';
import { CreateUpdateContainer } from '../../../components';
import { useFields } from './fields/fields';
import { ExtraFields } from './ExtraFields';

/**
 * Component for creating or updating visit reasons.
 *
 * @component
 * @param {object} props - The properties passed to the component.
 * @param {string} props.purpose - The purpose of the form (create or edit).
 * @returns {JSX.Element} The rendered CreateUpdateVisitReasons component.
 */
export const CreateUpdateVisitReasons = ({ purpose }) => {
  const { mainFields } = useFields();
  const fields = ['extraFields', 'subOptions'];

  return (
    <CreateUpdateContainer
      fields={mainFields}
      purpose={purpose}
      baseUrl="visit-reasons"
      resource="data_bases.visit-reasons"
      formExtra={fields.map((field) => (
        <ExtraFields key={field} purpose={field} />
      ))}
    />
  );
};

CreateUpdateVisitReasons.propTypes = {
  purpose: Proptype.string.isRequired
};
