import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar, Button, FloatButton, message } from 'antd';
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
  HeartOutlined,
  ApartmentOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import HeaderDropdown from '../../HeaderDropdown/HeaderDropdown';
import { getFullName } from '../../../utils/formatters';

const StyledContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledDropdownContainer = styled.span`
  height: 100%;
  cursor: pointer;
  padding: 0 12px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  :hover {
    background: var(--itemHoverBackground);
  }
  .ant-btn-link {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  > .anticon {
    color: var(--primaryColor);
  }
`;

const StyledFullNameSpan = styled.span`
  color: var(--primaryColor);
  font-weight: 600;
`;

const StyledRoleP = styled.span`
  font-size: 10px;
  color: var(--primaryColor);
`;

/**
 * @component HeaderLayout component displays the header layout of the application.
 * It includes user profile information, language selection, and dropdown menus.
 *
 * @returns {JSX.Element} The JSX element representing the header layout.
 */
const HeaderLayout = () => {
  const navigate = useNavigate();
  const { user, setUser, token, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [centersOptions, setCentersOptions] = useState([]);

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logoutSuccess'));
    }
  };

  const handleCenterChange = async (id) => {
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/users/${user._id}/choose-center`,
        body: {
          selected_center: id
        }
      });
      setUser(data);
      if (
        ![
          '/',
          '/administrative',
          '/profile',
          '/favorites',
          '/settings'
        ].includes(window.location.pathname)
      ) {
        navigate('/');
      }
    } catch (error) {
      message(error);
    }
  };

  const profileMenu = {
    onClick: onProfileMenuClick,
    items: [
      ...(window.innerWidth <= 992 && user
        ? [
            {
              key: 'user',
              label: (
                <Button
                  style={{
                    width: '100%',
                    height: 50
                  }}
                  type="link"
                >
                  <StyledFullNameSpan>{getFullName(user)}</StyledFullNameSpan>
                  <StyledRoleP>
                    {user.position}
                    {user.role === 'users:SUPER-USER' &&
                      t(`users.tags.${user.role.split(':')[1]}`)}
                  </StyledRoleP>
                </Button>
              )
            }
          ]
        : []),
      {
        key: 'profile',
        onClick: () => navigate('/profile'),
        label: (
          <>
            <UserOutlined
              style={{
                fontSize: 16
              }}
            />
            {` ${t('header.menu.user.profile')}`}
          </>
        )
      },
      ...(user?.position === 'DOCTOR'
        ? [
            {
              key: 'favorites',
              onClick: () => navigate('/favorites'),
              label: (
                <>
                  <HeartOutlined
                    style={{
                      fontSize: 16
                    }}
                  />
                  {` ${t('header.menu.user.favorites')}`}
                </>
              )
            }
          ]
        : []),
      {
        key: 'settings',
        onClick: () => navigate('/settings'),
        label: (
          <>
            <SettingOutlined
              style={{
                fontSize: 16
              }}
            />
            {` ${t('header.menu.user.settings')}`}
          </>
        )
      },
      ...(user?.role === 'users:SUPER-USER'
        ? [
            {
              key: 'centers',
              label: (
                <>
                  <ApartmentOutlined
                    style={{
                      fontSize: 16
                    }}
                  />
                  {` ${t('header.menu.user.change_center')}`}
                </>
              ),
              children: centersOptions
            }
          ]
        : []),
      {
        key: 'logout',
        label: (
          <>
            <LogoutOutlined
              style={{
                fontSize: 16
              }}
            />
            {` ${t('header.menu.user.logout')}`}
          </>
        )
      }
    ]
  };

  useEffect(() => {
    const company = user?.associated_company?.centers
      ?.map((center) => {
        if (center?._id !== user?.selected_center) {
          return {
            key: center?._id,
            label: center?.name,
            onClick: () => {
              handleCenterChange(center?._id);
            }
          };
        }
        return null;
      })
      .filter(Boolean);

    setCentersOptions(company);
  }, [user]);

  return (
    <StyledContainer>
      <HeaderDropdown overlayMenu={profileMenu}>
        {window.innerWidth <= 992 ? (
          <StyledDropdownContainer>
            <UserOutlined />
          </StyledDropdownContainer>
        ) : (
          <StyledDropdownContainer>
            <Avatar
              size="medium"
              icon={user?.photo ? '' : <UserOutlined />}
              src={
                user?.avatar
                  ? `${process.env.REACT_APP_API_URL}/files/download-image/${user?.avatar}/${token}`
                  : ''
              }
            >
              {getFullName(user)}
            </Avatar>
            <Button type="link">
              <StyledFullNameSpan>{getFullName(user)}</StyledFullNameSpan>
              <StyledRoleP>
                {user && user?.role === 'admins:SUPER-ADMIN' ? (
                  t(`users.tags.${user?.role?.split(':')[1]}`)
                ) : (
                  <>
                    {user ? t(`users.positions.${user?.position}`) : ''}
                    {user
                      ? user?.role === 'users:SUPER-USER' &&
                        ` - ${t(`users.tags.${user?.role?.split(':')[1]}`)}`
                      : ''}
                  </>
                )}
              </StyledRoleP>
            </Button>
          </StyledDropdownContainer>
        )}
      </HeaderDropdown>
      <FloatButton
        icon={<QuestionCircleOutlined />}
        type="default"
        style={{
          right: 24
        }}
      />
    </StyledContainer>
  );
};

export default HeaderLayout;
