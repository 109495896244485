import { Col, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Title } = Typography;

/**
 * A component that displays a row of consultations.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.resource - The resource to be used for translation.
 * @param {Function} props.t - The translation function.
 * @param {string} props.consultation - The consultation to be displayed.
 *
 * @returns {JSX.Element} The RowConsultation component.
 */
export const RowConsultation = ({ resource, t, consultation }) => (
  <Col span={24}>
    <Title level={5}>
      {t(`${resource}.medical_consultation.consultation`)}
    </Title>
    <pre>{consultation}</pre>
  </Col>
);

RowConsultation.propTypes = {
  resource: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  consultation: PropTypes.string
};

RowConsultation.defaultProps = {
  consultation: ''
};
