import { useTranslation } from 'react-i18next';
import { Col } from 'antd';

/**
 * Custom hook that returns the constants survey informations to be displayed in the card.
 * @hook
 *
 * @param {Object} options - The options object.
 * @param {string} options.title - The title of the card.
 * @param {Object} options.constants_survey - The constants survey object.
 * @returns {JSX.Element | null} The rendered component or null if the constants survey is empty.
 */
export const useRenderContent = ({ title, constants_survey }) => {
  const { t } = useTranslation();

  const arrayKeys = {
    urinary_analysis: ['ket', 'glu', 'leu', 'nit', 'ery', 'pro'],
    constants_and_other_surveys: [
      'PAS_PAD',
      'temperature',
      'heart_rate',
      'OS',
      'respiratory_rate',
      'oxygen_therapy',
      'peakflow',
      'capillary_blood_glucose',
      'hemocue',
      'micro_INR',
      'quick_tetanos_test',
      'antigenic_test',
      'exacto_pro'
    ],
    delocalized_biology: [
      'hemoglobin',
      'sodium',
      'potassium',
      'chlorine',
      'creatinine',
      'clearance',
      'CRP',
      'tropo',
      'D_Dimer',
      'B_HCG'
    ]
  };

  const units = {
    PAS_PAD: 'mmHg',
    temperature: '°C',
    heart_rate: 'bpm',
    OS: '%',
    respiratory_rate: 'c/min',
    oxygen_therapy: 'L/min',
    peakflow: 'L/min',
    capillary_blood_glucose: 'g/L ou mmol/L',
    hemocue: 'g/L ou g/dL',
    micro_INR: 'microg/L ou micromol/L',
    hemoglobin: 'g/L'
  };

  if (constants_survey) {
    return Object.entries(constants_survey)
      .filter(([key]) => arrayKeys[title]?.includes(key))
      .map(([key, value]) => (
        <Col span={12} key={key}>
          <span>{t(`constants_surveys.form.${key}`)} : </span>
          {value}
          {units[key] && <span>{units[key]}</span>}
        </Col>
      ));
  }
  return null;
};
