import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrag } from 'react-dnd';
import {
  Card,
  Spin,
  message as Message,
  Typography,
  Space,
  Tag,
  Flex,
  Row
} from 'antd';
import dayjs from 'dayjs';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { getAge } from '../../utils/getAge';
import { PatientWording } from './patientIcons/PatientWording';
import { ActionsIcons } from './patientIcons/ActionsIcons';
import { Badge } from '../consultations/PatientOverview/GeneralInformations/Badge';
import { usePatientAllergies } from '../../utils/usePatientAllergies';
import { PatientIcons } from './PatientIcons';
import { getFullName, getUserInitial } from '../../utils/formatters';
import { usePurposeOfCare } from './utils/purposeOfCare';

/**
 * Represents a patient component.
 *
 * @component
 * @param {string} departureRoomId - The ID of the departure room.
 * @param {function} setRefresh - A function to refresh the component.
 * @param {object} consultation - The consultation object.
 * @param {string} consultation._id - The ID of the consultation.
 * @param {object} consultation.patient - The patient object.
 * @param {string} consultation.patient.first_name - The first name of the patient.
 * @param {string} consultation.patient.last_name - The last name of the patient.
 * @param {string} consultation.patient.gender - The gender of the patient.
 * @param {string} consultation.patient.birth_date - The birth date of the patient.
 * @param {object} consultation.patient.grounds - The grounds object of the patient.
 * @param {string[]} consultation.patient.grounds.allergies - An array of allergies of the patient.
 * @param {object} consultation.patient.current_consultation - The current consultation object of the patient.
 * @param {string} consultation.patient.current_consultation._id - The ID of the current consultation.
 * @param {object[]} consultation.patient.current_consultation.visit_reasons - An array of visit reasons for the current consultation.
 * @param {string} consultation.patient.current_consultation.visit_reasons._id - The ID of the visit reason.
 * @param {string} consultation.patient.current_consultation.visit_reasons.reason_wording - The wording of the visit reason.
 * @param {boolean} consultation.patient.current_consultation.hyperalgesic - Indicates if the patient is hyperalgesic.
 * @param {boolean} consultation.patient.not_current_medications - Indicates if the patient is not taking current medications.
 *
 * @param {object} prescriptionTypesEnums - The prescription types enums.
 *
 * @returns {JSX.Element} The patient component.
 */
export const Patient = ({
  departureRoomId,
  setRefresh,
  consultation,
  prescriptionTypesEnums
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const [child, setChild] = useState(false);
  const [convertedAge, setConvertedAge] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    _id,
    patient,
    purpose_of_care: { follow_up_doctor = '', via = '', type: careType } = {},
    consultation: medicalConsultation
  } = consultation || {};
  const { gender, birth_date, grounds } = patient || {};
  const [switch_value, setSwitchValue] = useState('');

  const { isAllergic } = usePatientAllergies(patient);
  const { purposeOfCare } = usePurposeOfCare({
    doctor: `${follow_up_doctor?.last_name}`,
    via
  });

  useEffect(() => {
    if (user.switch_option) {
      const hour = dayjs(consultation?.date).format('HH:mm');
      setSwitchValue(hour);
    } else setSwitchValue(consultation.daily_count);
  }, [user, consultation]);

  useEffect(() => {
    if (birth_date) {
      const { age, isChild } = getAge(birth_date);
      const converted = `${age} ${t('patients.form.years')}`;
      setChild(isChild);
      setConvertedAge(converted);
    }
  }, [birth_date]);

  const handlePatientDrop = async (dropResult) => {
    try {
      setLoading(true);
      await dispatchAPI('PATCH', {
        url: `/rooms/change-patient-room/${departureRoomId}`,
        body: {
          targeted_room_id: dropResult.target,
          user_last_name: user.last_name,
          user_first_name: user.first_name,
          consultation_id: consultation._id
        }
      });
      setRefresh((prevRefresh) => !prevRefresh);
    } catch (error) {
      if (error.response.data.status_code === 413) {
        Message.error(
          t(`errors.message.${error.response.data.description}`, {
            room: error.response.data.message
          }),
          4.5
        );
      } else {
        message(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'PATIENT',
    item: { _id },
    end: async (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        if (departureRoomId !== dropResult.target) {
          await handlePatientDrop(dropResult);
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const border = isDragging ? '1px solid #FFB94A' : '1px solid #fff';
  const cursor = isDragging ? 'grabbing' : 'grab';

  const badgeColor = (value) => {
    if (value?.visit_type) {
      if (value.visit_type === 'FIRST_VISIT') {
        return '#004a8f';
      }
      return '#ec3e40';
    }
    if (value?.written_by) {
      return value.written_by.user_chip_color;
    }
    return '#004a8f';
  };

  return (
    <div
      className="kanban-patient-dragger"
      style={{ border, cursor }}
      ref={drag}
    >
      <Card className="kanban-patient">
        <Spin spinning={loading}>
          <Flex gap={6} style={{ flexDirection: 'column' }}>
            <Flex style={{ justifyContent: 'space-between' }}>
              <Flex align="center" style={{ flex: '1 0 auto' }}>
                <Space>
                  <Badge
                    content={switch_value}
                    color={badgeColor(consultation)}
                  />
                  {medicalConsultation?.written_by && (
                    <Badge
                      content={getUserInitial(medicalConsultation.written_by)}
                      color={badgeColor(medicalConsultation)}
                    />
                  )}
                </Space>
                <PatientWording
                  patientName={getFullName(patient)}
                  child={child}
                  gender={gender}
                  convertedAge={convertedAge}
                />
              </Flex>

              <ActionsIcons patient={patient} />
            </Flex>
            <Row>
              <Flex vertical>
                <Flex horizontal gap="small">
                  <Typography.Text>
                    {consultation?.visit_reasons
                      ?.map((reason) => reason.wording || '')
                      .join(', ')}
                  </Typography.Text>
                  {purposeOfCare[careType] && (
                    <Tag color="blue">{purposeOfCare[careType]}</Tag>
                  )}
                </Flex>
                <Typography.Text>
                  {consultation?.entry_notification?.description}
                </Typography.Text>
              </Flex>
            </Row>
            <Row>
              <PatientIcons
                consultation={consultation}
                patient={patient}
                setRefresh={setRefresh}
                isAllergic={isAllergic}
                prescriptionTypesEnums={prescriptionTypesEnums}
                grounds={grounds}
              />
              {consultation?.hyperalgesic && (
                <div className="hyperalgesic-corner" />
              )}
            </Row>
          </Flex>
        </Spin>
      </Card>
    </div>
  );
};

Patient.propTypes = {
  departureRoomId: PropTypes.string,
  setRefresh: PropTypes.func,
  prescriptionTypesEnums: PropTypes.shape({}),
  consultation: PropTypes.shape({
    _id: PropTypes.string,
    date: PropTypes.string,
    entry_notification: PropTypes.shape({
      description: PropTypes.string
    }),
    purpose_of_care: PropTypes.shape({
      follow_up_doctor: PropTypes.string,
      via: PropTypes.string,
      type: PropTypes.string
    }),
    patient: PropTypes.shape({
      _id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      gender: PropTypes.string,
      birth_date: PropTypes.string,
      grounds: PropTypes.shape({
        allergies: PropTypes.arrayOf(PropTypes.shape({}))
      }),
      current_consultation: PropTypes.shape({
        _id: PropTypes.string,
        visit_reasons: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string,
            reason_wording: PropTypes.string
          })
        ),
        hyperalgesic: PropTypes.bool
      }),
      not_current_medications: PropTypes.bool
    }),
    visit_reasons: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        reason_wording: PropTypes.string
      })
    ),
    hyperalgesic: PropTypes.bool,
    daily_count: PropTypes.number,
    visit_type: PropTypes.string
  })
};

Patient.defaultProps = {
  departureRoomId: '',
  setRefresh: () => {},
  prescriptionTypesEnums: {},
  consultation: {
    _id: '',
    visit_reasons: [],
    hyperalgesic: false,
    date: '',
    patient: {
      _id: '',
      first_name: '',
      last_name: '',
      gender: '',
      birth_date: '',
      grounds: {
        allergies: []
      },
      current_consultation: {
        _id: '',
        visit_reasons: [],
        hyperalgesic: false
      },
      prescribed_medications: [],
      not_current_medications: false
    }
  }
};
