import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { DataBaseMethods } from '../DataBaseMethods';

/**
 * Hook for generating columns for the visit reasons table.
 *
 * @param {boolean} displayAll - Whether to display all columns or not.
 * @returns {object} columns - Object containing the columns.
 */
export const useColumns = (displayAll) => {
  const { t } = useTranslation();
  const { onChangeUserDisplay } = DataBaseMethods();

  const columns = [
    {
      title: t('data_bases.visit-reasons.columns.reason_wording'),
      dataIndex: 'reason_wording',
      key: 'reason_wording',
      sorter: true
    },
    ...(displayAll
      ? [
          {
            title: t('data_bases.visit-reasons.columns.category'),
            dataIndex: 'category',
            key: 'category',
            sorter: true,
            render: (category) =>
              category
                ? t(`data_bases.visit-reasons.categories.${category}`)
                : '-'
          }
        ]
      : []),
    {
      title: t('data_bases.visit-reasons.columns.user_display'),
      key: 'actions',
      align: 'rigth',
      fixed: 'rigth',
      render: (record) => (
        <Switch
          checkedChildren={t('buttons.displayed')}
          unCheckedChildren={t('buttons.hidden')}
          defaultChecked={record.user_display}
          onChange={(checked) =>
            onChangeUserDisplay('visit-reasons', checked, record._id)
          }
        />
      )
    },
    ...(displayAll
      ? [
          {
            title: t('data_bases.visit-reasons.columns.sub_options'),
            dataIndex: 'sub_options',
            key: 'sub_options',
            render: (subOptions) => {
              if (subOptions.length > 0) {
                return <CheckOutlined />;
              }
              return '-';
            }
          },
          {
            title: t('data_bases.visit-reasons.columns.extra_fields'),
            dataIndex: 'extra_fields',
            key: 'extra_fields',
            render: (extraFields) => {
              const containExtraFields = Object.values(extraFields).some(
                (value) => value
              );
              if (containExtraFields) {
                return <CheckOutlined />;
              }
              return '-';
            }
          }
        ]
      : [])
  ];

  return { columns };
};
