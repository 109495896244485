import { mdiTestTube } from '@mdi/js';
import Icon from '@mdi/react';
import { Checkbox, Popconfirm, Space, Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MedicineBoxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useHandleChange } from '../utils/handleChange';
import { useHandleConfirmTransmition } from '../utils/useHandleConfirmTransmition';
import { useColorsIcons } from '../utils/useColorsIcons';

/**
 * Represents the Tube component.
 * @param {Object} props - The component props.
 * @param {Object} props.consultation - The consultation object.
 * @param {Function} props.setRefresh - The function to refresh the component.
 * @returns {JSX.Element} The Tube component.
 */
export const Tube = ({ consultation, setRefresh, prescriptionTypesEnums }) => {
  const [transmittedBiologies, setTransmittedBiologies] = useState([]);

  const { t } = useTranslation();
  const { handleConfirm } = useHandleConfirmTransmition(setRefresh);
  const { handleChange } = useHandleChange(
    transmittedBiologies,
    setTransmittedBiologies
  );

  const { BIOLOGY: { bacteriology: bacteriologyEnums } = {} } =
    prescriptionTypesEnums || {};

  const bacteriology = consultation?.prescribed_examinations?.filter(
    (prescription) => bacteriologyEnums?.includes(prescription.type)
  );

  const allMedicationsPrescribed = bacteriology?.every(
    (medication) => medication.transmitted
  );

  const { colorsIcons } = useColorsIcons(
    allMedicationsPrescribed,
    'grey',
    'purple'
  );

  const prescribedBiologies = bacteriology.map((prescription) => (
    <Checkbox
      defaultChecked={prescription.transmitted}
      onChange={handleChange}
      value={prescription._id}
      key={prescription._id}
    >
      {prescription.label}
    </Checkbox>
  ));

  return (
    bacteriology.length > 0 && (
      <Tooltip title={t('patients.icons.tube')}>
        <Popconfirm
          placement="bottomRight"
          title={t('patients.icons.sampling')}
          description={
            <Space direction="vertical">{prescribedBiologies}</Space>
          }
          okText={t('buttons.validate')}
          cancelText={t('buttons.cancel')}
          icon={<MedicineBoxOutlined />}
          onConfirm={() =>
            handleConfirm(consultation._id, { transmittedBiologies })
          }
        >
          <Icon path={mdiTestTube} size="18px" color={colorsIcons} />
        </Popconfirm>
      </Tooltip>
    )
  );
};

Tube.propTypes = {
  consultation: PropTypes.shape({
    _id: PropTypes.string,
    prescribed_examinations: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  setRefresh: PropTypes.func.isRequired,
  prescriptionTypesEnums: PropTypes.shape({}).isRequired
};
