import { useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Form } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useSuccessMessage } from '../../utils/successMessage';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { RegisterBackButton } from '../../components/RegisterBackButton/RegisterBackButton';
import { newConsultationFields } from './fields/newConsultationFields';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { ProgressModal } from '../../components/Modals/ProgressModal';

/**
 * CreateUpdateConsultation component
 * @component
 * @return {object} - The UI of the CreateUpdateConsultation component
 */
export const CreateUpdateConsultation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { successMessage } = useSuccessMessage();
  const [form] = Form.useForm();
  const { fields } = newConsultationFields(form);
  const { printDocument } = useDownloadDocument();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('active');

  const resource = 'consultations';

  const customSubmit = async (body) => {
    try {
      setLoading(true);
      setStatus('active');

      const { data } = await dispatchAPI('POST', {
        url: `${resource}/${id}`,
        body
      });

      setTimeout(async () => {
        setStatus('success');
        if (data && data._id) {
          // If there is a care consent file, we wait for the file to be generated
          await printDocument(data);
          return navigate('/');
        }
        // If the care consent file date is less than a year, we can navigate to the home page without waiting for the file to be generated
        successMessage('consultation.create_success_care_consent_ok');
        return navigate('/');
      }, 1000);
      return null;
    } catch (error) {
      setStatus('exception');
      setLoading(false);
      return message(error);
    }
  };

  return (
    <>
      <ProgressModal
        loading={loading}
        status={status}
        message="consultation.create"
      />
      <CreateUpdateContainer
        purpose="create"
        customFormInstance={form}
        fields={fields}
        resource="consultations"
        isPageHeaderCustom={false}
        layout="horizontal"
        isFormExtra
        formExtra={<RegisterBackButton />}
        customSubmit={customSubmit}
      />
    </>
  );
};
