import { useContext } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { PatientContext } from '../../../contexts/PatientContext/PatientContext';
import { useErrorMessage } from '../../../utils/errorMessage';

export const usePatchApiCalls = (resource, id) => {
  const { setForceRefresh, forceRefresh } = useContext(PatientContext);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `${resource}/consultation/${id}`,
        body
      });
    } catch (e) {
      message(e);
    }
  };

  const handleSubmit = async (values) => {
    await updateResource(values);
    setForceRefresh(!forceRefresh);
  };

  return { handleSubmit };
};
