import PropTypes from 'prop-types';
import { ListResource } from '../../../../components';
import { useColumns } from './ConsultationRoomsColumns';
import { useActionColumns } from './ActionColumns';

export const ConsultationRooms = ({ type }) => {
  const resource = 'rooms';
  const columns = useColumns();
  const actionColumn = useActionColumns();

  return (
    <ListResource
      resourceName={`${resource}/room/${type}`}
      customActionColumn
      columns={[...columns, ...actionColumn]}
      resourceModelName="Room"
      deleteAction={false}
      populate="patients"
      isOriginalContentCustom={false}
      isPageHeaderCustom={false}
      onDoubleClickAction={false}
      forceRefresh
    />
  );
};
ConsultationRooms.propTypes = {
  type: PropTypes.string.isRequired
};
