import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import { useLocation } from 'react-router-dom';
import { Datatable } from '../../../components';
import { DataBaseMethods } from '../DataBaseMethods';

/**
 * Component that renders the collapse component with the defined resources
 * @component
 * @param {string} resource - The resource to fetch
 * @param {string} translationKey - The translation key for the categories
 * @param {string} category - The category of the stay prescriptions
 * @param {Array} columns - The columns for the table
 * @param {Object} showAction - The action to show the record
 * @param {Object} editAction - The action to edit the record
 * @returns {JSX.Element} - The collapse component with the stay prescriptions
 */
export const CollapseRessources = ({
  resource,
  translationKey,
  category,
  columns,
  showAction,
  editAction,
  onDoubleClickAction
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('k');

  const { t } = useTranslation();
  const { getPrescriptions } = DataBaseMethods(searchValue);

  const getDataSource = async () => {
    const filters = `?archived=false&category=${category}`;
    const data = await getPrescriptions(resource, filters, setLoading);
    setDataSource(data);
  };

  useEffect(() => {
    (async () => {
      await getDataSource();
    })();
  }, [forceRefresh, searchValue]);

  return (
    <Collapse>
      <Collapse.Panel
        header={t(`${translationKey}.categories.${category}`)}
        key={category}
      >
        <Datatable
          resourceName={resource}
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          isArchived={false}
          deleteAction={false}
          showAction={showAction}
          editAction={editAction}
          forceRefresh={forceRefresh}
          setForceRefresh={setForceRefresh}
          archiveAction
          onDoubleClickAction={onDoubleClickAction}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

CollapseRessources.propTypes = {
  resource: PropTypes.string.isRequired,
  translationKey: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  onDoubleClickAction: PropTypes.shape({
    action: PropTypes.func
  }).isRequired
};

CollapseRessources.defaultProps = {
  showAction: false,
  editAction: false
};
