/**
 * @hook
 * Hook to provide a list of document types.
 *
 * @returns {Object} An object containing the recommendations array.
 * @property {Array<string>} recommendations - An array of document types.
 */
export const useDocumentsTypes = () => {
  const recommendations = [
    'biological',
    'plastering',
    'renal_colic',
    'residue_free_diet',
    'scabies',
    'urinary_calculus'
  ];
  const labels = ['unit', 'board'];

  const passage_certificates = [];

  const patients_record = [];

  return {
    recommendations,
    labels,
    'patients/patients-record': patients_record,
    'passage-certificates': passage_certificates,
    'care-consent': []
  };
};
