/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { getSynapseToken } from '../../utils/synapse';

/**
 * This component renders the Synapse Posology Checker widget.
 * @component SynapsePosologyCheckerWidget
 * @returns {JSX.Element} Synapse Posology Checker widget
 */
export const SynapsePosologyCheckerWidget = () => {
  const { entities, posologies, patientInfos } = usePrescriptionContext();
  const [JWT, setJWT] = useState(null);
  const [posologyCheckerWidget, setPosologyCheckerWidget] = useState(null);

  const { dispatchAPI } = useAuthContext();

  const fetchNewToken = async () => {
    const token = await getSynapseToken(dispatchAPI);
    setJWT(token);
  };

  const getEntityWithPrescription = () => {
    if (posologies.length > 0) {
      entities.forEach((entity, index) => {
        posologies.forEach((posology) => {
          if (entity._id === posology.entity._id) {
            entities[index].entityPrescription = posology.entityPrescription;
          }
        });
      });
    }
    return entities;
  };

  const createDataPosologyChecker = () => ({
    entities: getEntityWithPrescription(),
    profile: {
      weight: patientInfos?.infos?.weight,
      birthDate: patientInfos?.infos?.birth_date
    },
    hideRedundancies: false
  });

  useEffect(() => {
    if (!posologyCheckerWidget) return;

    const dataPosologyChecker = createDataPosologyChecker();
    posologyCheckerWidget.update(dataPosologyChecker);
  }, [entities, posologyCheckerWidget]);

  useEffect(() => {
    if (!JWT) {
      fetchNewToken();
      return;
    }
    const dataPosologyChecker = createDataPosologyChecker();
    const newPosologyCheckerWidget = new window.Synapse.PosologyCheckerWidget(
      JWT
    );
    newPosologyCheckerWidget.init(
      document.getElementById('posology-container'),
      dataPosologyChecker
    );

    newPosologyCheckerWidget.setCallback('onViewDrugInfo', (err, data) => {
      const bdpmUrl =
        'https://base-donnees-publique.medicaments.gouv.fr/extrait.php';
      window.open(`${bdpmUrl}?specid=${data.cis}`);
    });

    newPosologyCheckerWidget.setCallback(
      'onChangeRedundanciesVisibility',
      (visibility) => {
        // Handle visibility change
      }
    );

    newPosologyCheckerWidget.setCallback('hasResults', (hasResults) => {
      // Handle results
    });

    newPosologyCheckerWidget.setCallback('onTokenExpiration', (err, data) => {
      const newToken = fetchNewToken();
      newPosologyCheckerWidget.setToken(newToken);
    });

    setPosologyCheckerWidget(newPosologyCheckerWidget);
  }, [JWT]);

  return <Card id="posology-container" />;
};
