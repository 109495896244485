import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Card } from 'antd';
import { ContentCustom } from '../../../components';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext'; // Corrected the path
import { PrescriptionsContent } from '../components/PrescriptionsContent';

/**
 * @component PrescriptionChoiceExit
 * @returns {React.Component} Page of the exit prescription
 */
export const PrescriptionChoiceExit = () => {
  const { t } = useTranslation();
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { patientInfos, setContent } = usePrescriptionContext();

  const handleNewFreeTextPrescription = () => {
    setContent('');
    navigate(`/prescription/create/free-text/${type}/${id}`);
  };

  const title = (
    <div>
      <Flex gap={8} style={{ alignItems: 'center' }}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 21V3H15V21H10V16H6V21H1ZM5 13H7V11H5V13ZM5 9H7V7H5V9ZM9 13H11V11H9V13ZM9 9H11V7H9V9ZM19.5 15.5L18.1 14.1L19.175 13H16V11H19.175L18.1 9.9L19.5 8.5L23 12L19.5 15.5Z"
            fill="#004A8F"
          />
        </svg>
        {t('prescription.cards.exit_prescription.title')}
      </Flex>
    </div>
  );

  return (
    <ContentCustom>
      <PageHeaderCustom
        title={patientInfos.headerTitle}
        onBack={() => navigate(-1)}
      />
      <Card title={title} className="prescription-choice-exit-card">
        <PrescriptionsContent
          type="EXIT"
          handleNewFreeTextPrescription={handleNewFreeTextPrescription}
        />
      </Card>
    </ContentCustom>
  );
};
