import { useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { ListResource, ContentCustom } from '../../components';
import { ExtraButtons } from './ExtraButtons';
import { useColumns } from './columns';
import { User } from '../../models/User';

/**
 * Component for listing users.
 *
 * @component
 * @returns {JSX.Element} The ListUsers component.
 */
export const ListUsers = () => {
  const {
    user: { role }
  } = useAuthContext();
  const columns = useColumns();

  const [selectedPositions, setSelectedPositions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  const resourceName = 'users';

  const extraQuery = `${
    selectedPositions.length ? `&position=${selectedPositions}` : ''
  }${selectedRoles.length ? `&role=${selectedRoles}` : ''}`;

  const archiveAction = async (record) => {
    await User.archiveUnarchiveUser(record._id, {
      archived: !record.archived
    });
    setForceRefresh(!forceRefresh);
  };

  const customArchiveAction = () => {
    if (role === 'users:SUPER-USER') {
      return null;
    }
    return {
      customAction: (record) => archiveAction(record)
    };
  };

  return (
    <ContentCustom>
      <ListResource
        resourceName={resourceName}
        forceRefresh={forceRefresh}
        columns={columns}
        resourceModelName="User"
        extraButtons={
          <ExtraButtons
            setSelectedPositions={setSelectedPositions}
            setSelectedRoles={setSelectedRoles}
            resourceName={resourceName}
          />
        }
        extraQuery={extraQuery}
        archiveAction={customArchiveAction()}
        withCreateButton={role !== 'users:SUPER-USER'}
      />
    </ContentCustom>
  );
};
