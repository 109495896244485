import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { getSynapseToken } from '../../utils/synapse';

/**
 * This component renders the Synapse Posology Form widget.
 * @component SynapsePosologyFormWidget
 * @param {Object} props Component props
 * @param {Object} props.entity Medication entity
 * @param {Function} props.onOk Callback to execute on success
 * @returns {JSX.Element} Synapse Posology Form widget
 */
export const SynapsePosologyFormWidget = ({ entity, onOk }) => {
  const { setPosologies, setEntities } = usePrescriptionContext();
  const [JWT, setJWT] = useState(null);

  const { dispatchAPI } = useAuthContext();

  const fetchNewToken = async () => {
    const token = await getSynapseToken(dispatchAPI);
    setJWT(token);
  };

  const detailedPosology = {};

  const options = {
    enableSaveAsModel: true,
    enablePosologyChecker: true,
    enableNotForGenericSubstitution: true
  };

  useEffect(() => {
    if (!JWT) {
      fetchNewToken();
      return;
    }
    // Initialize the widget once the component is mounted
    const dataPosology = {
      entity,
      detailedPosology,
      options
    };
    const newPosologyFormWidget = new window.Synapse.NextPosologyFormWidget(
      JWT
    );
    newPosologyFormWidget.init(
      document.getElementById(`posology-form-container-${entity.drug_id}`),
      dataPosology
    );

    // Set callback for the 'onValidate' event
    newPosologyFormWidget.setCallback('onValidate', (err, data) => {
      if (data) {
        // Use a functional update to ensure we have the latest posologies state
        setPosologies((currentPosologies) => [
          ...currentPosologies.filter(
            (p) => p.entity.drug_id !== entity.drug_id
          ),
          { ...data, entity }
        ]);

        // Function to update the entity in the list of entities
        const updateEntity = (currentEntities) => {
          const index = currentEntities.findIndex(
            (e) => e.drug_id === entity.drug_id
          );
          const updatedEntity = {
            ...currentEntities[index],
            entityPrescription: data.entityPrescription
          };

          return Object.assign([], currentEntities, {
            [index]: updatedEntity
          });
        };

        setEntities((currentEntities) => updateEntity(currentEntities));

        onOk();
      }
    });

    // Set callback for token expiration
    newPosologyFormWidget.setCallback('onTokenExpiration', () => {
      const newToken = fetchNewToken();
      newPosologyFormWidget.setToken(newToken);
    });
  }, [entity, detailedPosology, JWT]);

  return <div id={`posology-form-container-${entity.drug_id}`} />;
};

SynapsePosologyFormWidget.propTypes = {
  onOk: PropTypes.func,
  entity: PropTypes.shape({
    type: PropTypes.string,
    indication: PropTypes.string,
    drug_id: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    posologies: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        duration: PropTypes.number,
        durationUnit: PropTypes.string,
        isChronic: PropTypes.bool,
        frequency: PropTypes.number,
        frequencyUnit: PropTypes.string,
        doseUnit: PropTypes.string,
        dosage: PropTypes.shape({
          morning: PropTypes.number,
          noon: PropTypes.number,
          evening: PropTypes.number,
          night: PropTypes.number
        })
      })
    ),
    comment: PropTypes.string
  }).isRequired
};

SynapsePosologyFormWidget.defaultProps = {
  onOk: () => {}
};
