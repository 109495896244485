import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { MedicalComments } from './MedicalComments/MedicalComments';
import { PreviousComments } from '../../../components/PreviousComments/PreviousComments';
import { useConsultationsContext } from '../ConsultationsContext';
import { ModalMedicalComments } from './MedicalComments/ModalMedicalComments';

/**
 * Component that displays the medical comments for a patient.
 *
 * @component
 * @param {Object} fields - The fields object.
 * @returns {JSX.Element} The JSX element representing the ShowMedicalComments component.
 */
export const ShowMedicalComments = ({ fields }) => {
  const resource = 'consultations';
  const { t } = useTranslation();
  const { patient, deleteComment, checkComment } = useConsultationsContext();
  const medicalComments = patient?.current_consultation?.medical_comments;

  const commentRef = useRef(null);
  const [commentHeight, setCommentHeight] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (commentRef.current) {
      setCommentHeight(commentRef.current.getBoundingClientRect().height);
    }
  }, [medicalComments]);

  return (
    <Card
      title={t('consultations.form.medical_comments')}
      style={{ height: 'fit-content' }}
      extra={
        <Button
          onClick={() => setOpenModal(true)}
          type="link"
          icon={<PlusOutlined />}
        >
          {t('buttons.see_more')}
        </Button>
      }
    >
      <div
        style={{
          height: 'fit-content',
          maxHeight: commentHeight * 1.5,
          overflowY: 'auto'
        }}
      >
        {medicalComments
          ? medicalComments.map(
              (comment) =>
                !comment.checked && (
                  <div ref={commentRef} key={comment?._id}>
                    <PreviousComments
                      key={comment?._id}
                      comment={comment}
                      deleteComment={deleteComment}
                      checkComment={checkComment}
                    />
                  </div>
                )
            )
          : null}
      </div>
      <MedicalComments resource={resource} fields={fields} />
      <ModalMedicalComments
        openModal={openModal}
        setOpenModal={setOpenModal}
        deleteComment={deleteComment}
        checkComment={checkComment}
        medicalComments={medicalComments}
      />
    </Card>
  );
};
ShowMedicalComments.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({}))
};
ShowMedicalComments.defaultProps = {
  fields: []
};
