import PropTypes from 'prop-types';
import { Radio, Row } from 'antd';
import { useTranslation } from 'react-i18next';

export const SelectGender = ({ value, onChange, enums }) => {
  const resource = 'patients';
  const { t } = useTranslation();

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <Row justify="end">
      <Radio.Group value={value} onChange={handleChange} buttonStyle="solid">
        {enums?.genders?.map((gender) => (
          <Radio.Button key={gender} value={gender}>
            {t(`${resource}.form.enums.${gender}`)}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Row>
  );
};

SelectGender.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  enums: PropTypes.shape({
    genders: PropTypes.arrayOf(PropTypes.string)
  })
};

SelectGender.defaultProps = {
  value: undefined,
  onChange: undefined,
  enums: { genders: [] }
};
