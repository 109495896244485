import { BaseModel } from './BaseModel';

/**
 * Represents a Company model.
 * @class
 * @extends BaseModel
 */
export class Company extends BaseModel {
  static modelName = 'companies';

  /**
   * Creates a new instance of the Company model.
   * @constructor
   * @param {Object} data - The data for the Company model.
   */
  constructor(data) {
    super('company', undefined, data);
  }

  /**
   * Archives a center.
   * @static
   * @async
   * @function
   *
   * @param {string} id - The ID of the center to be archived.
   * @param {Object} archived - The archived data for the center.
   * @returns {Promise} A promise that resolves with the API response.
   */
  static archiveUnarchiveCenter(id, { ...archived }) {
    return BaseModel.fetchAPI('PATCH', `${Company.modelName}/archive/${id}`, {
      ...archived
    });
  }

  /**
   * Retrieves a company by its ID.
   * @static
   * @function
   *
   * @param {string} id - The ID of the company to retrieve.
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the company data.
   */
  static getCompany(id, { ...params } = {}) {
    return BaseModel.fetchAPI('GET', `${Company.modelName}/${id}`, {}, params);
  }
}
