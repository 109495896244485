import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Table, Card } from 'antd';
import { useColumns } from './StayHistory/ColumnsStayHistory';
import { useConsultationsContext } from '../ConsultationsContext';
import { TitleSeeMoreButton } from '../Components/TitleSeeMoreButton';

/**
 * Component for displaying the stay history of a patient.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the component.
 * @param {Object} props.fields - The fields of the component.
 * @returns {JSX.Element} The JSX element representing the stay history component.
 */
export const ShowStayHistory = ({ id, fields }) => {
  const title = 'stay_history';
  const [consultationsArray, setConsultationsArray] = useState([]);
  const columns = useColumns();
  const { patient } = useConsultationsContext();

  useEffect(() => {
    setConsultationsArray(patient?.previous_consultations?.slice().reverse());
  }, [patient]);

  return (
    <Card>
      <TitleSeeMoreButton id={id} fields={fields} title={title} />
      <Table
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={consultationsArray}
        pagination={{
          pageSize: 2
        }}
      />
    </Card>
  );
};
ShowStayHistory.propTypes = {
  id: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({}))
};
ShowStayHistory.defaultProps = {
  fields: undefined
};
