import { MailOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * Renders a doctor's letter icon for a patient.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.patient - The patient object.
 * @returns {JSX.Element|null} - The doctor's letter icon component.
 */
export const DoctorsLetter = ({ patient }) => {
  const { t } = useTranslation();
  return (
    patient?.doctors_letters.length > 0 && (
      <Tooltip title={t('patients.icons.doctors_letters')}>
        <MailOutlined style={{ color: '#004a8f', fontSize: 18 }} />
      </Tooltip>
    )
  );
};

DoctorsLetter.propTypes = {
  patient: PropTypes.shape({
    doctors_letters: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};
