/**
 * File: /home/icare/StrategIn/Toola_V1/toola-app/src/routes/prescription/utils/cardStyles.js
 *
 * @module cardStyles
 * @description Contains the styles for a card component.
 * @exports cardStyles
 */
export const cardStyles = {
  body: {
    height: '100%'
  }
};
