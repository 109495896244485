import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('patients.form.invoice_number'),
      key: 'invoice_number',
      dataIndex: 'invoice_number',
      sorter: true
    },
    {
      title: t('patients.form.wording'),
      key: 'wording',
      dataIndex: 'wording',
      sorter: true
    },

    {
      title: t('patients.form.amount_euros'),
      key: 'amount_euros',
      dataIndex: 'amount_euros',
      sorter: true
    },
    {
      title: t('patients.form.due_date'),
      key: 'due_date',
      dataIndex: 'due_date',
      sorter: true
    },
    {
      title: t('patients.form.reminders'),
      key: 'reminders',
      dataIndex: 'reminders',
      sorter: true
    }
  ];
};
