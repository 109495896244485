import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';
import { Datatable } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useCentersActionsColumns } from './centers/CentersActionsColumns';
import { Center } from '../../models/Center';
import { getPhoneNumber } from '../../utils/formatters';

/**
 * ShowCompaniesCentersTable component.
 * Renders a table of centers for a company.
 *
 *
 * @param {Function} t - Fonction de traduction i18n.
 * @param {Object} record - L'objet d'enregistrement contenant les données des sous-centres.
 * @param {Array} typeArr - Un tableau de types de sous-centres.
 * @param {Function} archiveAction - La fonction pour archiver / désarchiver le sous-centre.
 * @returns {JSX.Element} - Le composant de tableau rendu.
 */
export const ShowCompaniesCentersTable = ({ company }) => {
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { t } = useTranslation();
  const [enums, setEnums] = useState({});
  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      title: t('centers.form.name').toUpperCase(),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => b.name.length - a.name.length
    },
    {
      title: t('centers.form.type').toUpperCase(),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => b.type.length - a.type.length,
      filters: enums?.types?.map((type) => ({
        text: type,
        value: type
      })),
      onFilter: (value, record) => record.type.indexOf(value) === 0
    },
    {
      title: t('centers.form.address').toUpperCase(),
      dataIndex: 'address',
      key: 'address',
      render: (address) => address || '-'
    },
    {
      title: t('centers.form.secretariat_phone_number').toUpperCase(),
      dataIndex: 'secretariat_phone_number',
      key: 'secretariat_phone_number',
      render: (secretariat_phone_number) =>
        getPhoneNumber(secretariat_phone_number)
    },
    {
      title: t('centers.form.number_of_users').toUpperCase(),
      dataIndex: 'number_of_users',
      key: 'number_of_users',
      sorter: true
    }
  ];

  const archiveUnarchiveCenter = async (centerId, archived) => {
    await Center.archivingCenter(centerId, { archived: !archived });
    setRefresh(!refresh);
  };

  const getEnums = async () => {
    const { data } = await dispatchAPI('GET', {
      url: '/centers/enums'
    });
    setEnums(data);
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <Col span={24}>
      <Row justify="end" align="middle" style={{ marginBottom: 16 }}>
        <Link to={`/centers/create/${id}`}>
          <Button type="primary">{t('buttons.create')}</Button>
        </Link>
      </Row>
      <Datatable
        forceRefresh={refresh}
        columns={[
          ...columns,
          ...useCentersActionsColumns(t, company._id, archiveUnarchiveCenter)
        ]}
        customActionColumn
        resourceName={`centers/company/${id}`}
        path="/centers"
        companyId={id}
        extraQuery="archived=false"
      />
    </Col>
  );
};

ShowCompaniesCentersTable.propTypes = {
  company: PropTypes.string.isRequired
};
