import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  WarningOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { ShowCompaniesCentersTable } from './ShowCompaniesCentersTable';
import { ShowCompanyUsersTable } from './ShowCompanyUsersTable';
import { routes } from '../../utils/constants/adminRoutes';

/**
 * Renders the page for displaying a company's details.
 *
 * @returns {JSX.Element} The JSX element representing the ShowCompany component.
 */
export const ShowCompany = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState({});
  const listContent = useListContent(company);

  const getCompany = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${id}?populate=manager,centers`
      });
      setCompany(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getCompany();
      setIsLoading(false);
    })();
  }, [getCompany]);

  const deleteCompany = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/companies/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <ContentCustom>
      <PageHeaderCustom
        title={company.name}
        withoutBreadCrumbs
        extra={[
          <>
            <Link to={{ pathname: `${routes.COMPANIES}/edit/${id}` }}>
              <Button type="primary">
                {t('buttons.edit')}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteCompany}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.archive')} `}
                <ContainerOutlined />
              </Button>
            </Popconfirm>
          </>
        ]}
      />
      <ContentCustom>
        <Row>
          <Divider orientation="left">{t('basiclayout.menu.company')}</Divider>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList data={listContent} layout="horizontal" translate />
          </Skeleton>
        </Row>
        <Row>
          <Divider orientation="left">{t('basiclayout.menu.centers')}</Divider>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <ShowCompaniesCentersTable company={company} />
          </Skeleton>
        </Row>
        <Row>
          <Divider orientation="left">{t('users.title')}</Divider>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <ShowCompanyUsersTable />
          </Skeleton>
        </Row>
      </ContentCustom>
    </ContentCustom>
  );
};
