import PropTypes from 'prop-types';
import { Col, Card } from 'antd';
import { PreviousComments } from '../../../../components/PreviousComments/PreviousComments';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { usePatientContext } from '../../../../contexts/PatientContext/PatientContext';

const resource = 'patients';

/**
 * Renders a form for collapsing comments.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.fields - The fields to be rendered in the form.
 * @param {Array} props.comments - The comments to be displayed.
 * @param {string} props.commentType - The type of comment.
 * @returns {JSX.Element} The rendered CollapseCommentsForm component.
 */
export const CollapseCommentsForm = ({ fields, comments, commentType }) => {
  const generateFields = useGenerateFormItem();
  const { deleteComment, checkComment } = usePatientContext();

  return (
    <Col>
      {comments
        ? comments.map((data) => (
            <PreviousComments
              key={data[commentType] + data.written_by}
              written_by={data.written_by}
              date={data.date}
              comment={data}
              deleteComment={deleteComment}
              checkComment={checkComment}
              withoutAction
            />
          ))
        : null}
      <Card>{fields.map((field) => generateFields(resource, field))}</Card>
    </Col>
  );
};

CollapseCommentsForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  commentType: PropTypes.string.isRequired
};
CollapseCommentsForm.defaultProps = {
  comments: undefined
};
