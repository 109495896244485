import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { CreateUpdateContainer, PageHeaderCustom } from '../../../components';
import { useFields } from './fields';
import { useDataBasesContext } from '../../../contexts/DataBasesContext';
import { useCreateUpdateConfig } from './utils/useCreateUpdateConfig';
import { ListOptions } from './options/ListOptions';

/**
 * Component for creating or updating Stay Prescriptions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the form (create/update).
 * @returns {JSX.Element} JSX element representing the CreateUpdatePrescriptions component.
 */
export const CreateUpdatePrescriptions = ({ purpose }) => {
  const [options, setOptions] = useState([]);

  const [form] = Form.useForm();

  const { t } = useTranslation();
  const {
    prescriptionWording,
    setPrescriptionWording,
    category,
    setCategory,
    prescriptionType,
    setPrescriptionType,
    options: optimistOptions,
    setPurposeOptions
  } = useDataBasesContext();

  const [withOptions, setWithOptions] = useState(
    !!options.length || !!optimistOptions.length
  );

  const { fields } = useFields({
    prescriptionWording,
    setPrescriptionWording,
    category,
    setCategory,
    prescriptionType,
    setPrescriptionType,
    withOptions,
    setWithOptions,
    purpose,
    form
  });

  const { config } = useCreateUpdateConfig({
    prescriptionWording,
    setPrescriptionWording,
    setCategory,
    setPrescriptionType,
    setOptions,
    setWithOptions,
    options,
    optimistOptions
  });

  useEffect(() => {
    setPurposeOptions(purpose === 'create' ? 'create' : purpose);
  }, [purpose]);

  return (
    <>
      <PageHeaderCustom
        withoutBreadCrumbs
        title={t('data_bases.stay_prescriptions.form.title')}
      />
      <CreateUpdateContainer
        fields={fields}
        customFormInstance={form}
        purpose={purpose === 'create' ? 'create' : purpose}
        config={config}
        baseUrl="config-prescriptions"
        resource="data_bases.stay_prescriptions"
        formExtra={
          <ListOptions
            options={options}
            setOptions={setOptions}
            withOptions={withOptions}
            prescriptionWording={prescriptionWording}
            category={category}
            purpose={purpose}
          />
        }
      />
    </>
  );
};

CreateUpdatePrescriptions.propTypes = {
  purpose: PropTypes.string.isRequired
};
