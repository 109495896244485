/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Image } from 'antd';
import { getFullName, getBirthDate } from '../../utils/formatters';

/**
 * Component for displaying patient information.
 *
 * @component
 * @param {boolean} showLabelInput - Determines whether to show label input or not.
 * @param {object} infos - Object containing patient information.
 * @param {string} infos.last_name - Last name of the patient.
 * @param {string} infos.birth_name - Birth name of the patient.
 * @param {string} infos.first_name - First name of the patient.
 * @param {string} infos.birth_date - Birth date of the patient.
 * @param {string} infos.gender - Gender of the patient.
 * @param {string} infos.ins - INS number of the patient.
 * @param {object} infos.ins_qrcode - INS QR code of the patient.
 * @param {string} infos.ins_qrcode.data - INS QR code data of the patient.
 * @param {string} translationKey - Translation key for the component.
 * @returns {JSX.Element} - Rendered component.
 */
export const PatientsInformations = ({
  showLabelInput,
  infos,
  translationKey
}) => {
  const { t } = useTranslation();
  const insQrCode = infos?.ins_qrcode || null;

  return (
    <Row>
      <Col span={12}>
        <Row>
          {showLabelInput
            ? t(`${translationKey}.patient.placeholders.name`)
            : t(`${translationKey}.patient.name`, {
                name: getFullName({
                  last_name: infos?.last_name
                })
              })}
        </Row>
        <Row>
          {showLabelInput
            ? t(`${translationKey}.patient.placeholders.birth_name`)
            : t(`${translationKey}.patient.birth_name`, {
                birth_name: getFullName({
                  last_name: infos?.birth_name
                })
              })}
        </Row>
        <Row>
          {showLabelInput
            ? t(`${translationKey}.patient.placeholders.first_name`)
            : t(`${translationKey}.patient.first_name`, {
                first_name: infos?.first_name
              })}
        </Row>
        <Row>
          {showLabelInput
            ? t(`${translationKey}.patient.placeholders.gender`)
            : t(`${translationKey}.patient.gender`, {
                gender: t(`patients.form.enums.${infos?.gender}`)
              })}
        </Row>
        <Row>
          {showLabelInput
            ? t(`${translationKey}.patient.placeholders.birth_date`)
            : t(`${translationKey}.patient.birth_date`, {
                birth_date: getBirthDate(infos?.birth_date)
              })}
        </Row>
      </Col>
      <Col span={12}>
        <Row justify="end">
          {showLabelInput ? (
            t(`${translationKey}.patient.placeholders.ins_qr_code`)
          ) : insQrCode?.data ? (
            <Image
              style={{ minHeight: 140, maxHeight: 200 }}
              alt={t(`${translationKey}.patient.placeholders.ins_qr_code`)}
              preview={false}
              src={insQrCode?.data}
            />
          ) : (
            ''
          )}
        </Row>
        <Row justify="end">
          {!showLabelInput && t(`${translationKey}.patient.unsigned_ins`)}
        </Row>
        <Row justify="end">
          {showLabelInput
            ? t(`${translationKey}.patient.placeholders.ins_number`)
            : t(`${translationKey}.patient.ins_number`, {
                ins: infos?.ins
              })}
        </Row>
      </Col>
    </Row>
  );
};

PatientsInformations.propTypes = {
  showLabelInput: PropTypes.bool,
  infos: PropTypes.shape({
    last_name: PropTypes.string,
    birth_name: PropTypes.string,
    first_name: PropTypes.string,
    birth_date: PropTypes.string,
    gender: PropTypes.string,
    ins: PropTypes.string,
    ins_qrcode: PropTypes.shape({
      data: PropTypes.string
    })
  }),
  translationKey: PropTypes.string.isRequired
};

PatientsInformations.defaultProps = {
  showLabelInput: false,
  infos: null
};
