import { useTranslation } from 'react-i18next';
import { Button, DatePicker } from 'antd';
import { ListResource, ContentCustom } from '../../components';
// import { SorterButtonsHeader } from '../patients/components/ListPatients/SorterButtonsHeader';

// TODO WIP
export const ListLaboratory = () => {
  const { t } = useTranslation();
  // const [forceRefresh, setForceRefresh] = useState(false);

  const columns = [
    {
      title: t('laboratories.hour'),
      dataIndex: 'hour',
      key: 'hour',
      sorter: true
    },
    {
      title: t('laboratories.laboratory'),
      dataIndex: 'laboratory',
      key: 'laboratory',
      sorter: true,
      filters: []
    },
    {
      title: t('laboratories.prescribing_physician'),
      dataIndex: 'prescribing_physician',
      key: 'prescribing_physician',
      sorter: true,
      filters: []
    },
    {
      title: t('laboratories.patient_last_name'),
      dataIndex: 'patient_name',
      key: 'patient_name',
      sorter: true
    },
    {
      title: t('laboratories.patient_first_name'),
      dataIndex: 'patient_first_name',
      key: 'patient_first_name'
    },
    {
      title: t('laboratories.patient_birth_date'),
      dataIndex: 'patient_birth_date',
      key: 'patient_birth_date'
    },
    {
      title: t('laboratories.ins'),
      dataIndex: 'ins',
      key: 'ins'
    },
    {
      title: t('laboratories.prescribed_biologies'),
      dataIndex: 'prescribed_biologies',
      key: 'prescribed_biologies'
    }
  ];

  const onClick = async () => {
    // try {
    //   const response = await
    // } catch (error) {
    //   console.log('🚀 error:', error);
    // }
  };

  return (
    <ContentCustom>
      <ListResource
        resourceName="laboratories"
        resourceModelName="Laboratory"
        columns={columns}
        // forceRefresh={forceRefresh}
        extraButtons={
          <>
            <Button onClick={onClick} />
            <DatePicker />
          </>
        }
        withCreateButton={false}
        withArchiveButton={false}
        withUploadButton={false}
        dataSource={[]}
      />
    </ContentCustom>
  );
};
