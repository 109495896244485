import { Button, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { SorterDateReason } from '../../../Components/SorterDateReason';

/**
 * Custom hook that returns an array of objects representing the items for a modal administration collapse.
 * @hook
 *
 * Each object contains a key, label, and children property.
 * The children property contains JSX elements representing the content of each item.
 * @param {Object} options - The options for the hook.
 * @param {string} options.resource - The resource string used for translation.
 * @param {function} options.setOpenModal - The function to open the modal.
 * @param {Array} options.doctorsLettersColumns - The columns for the doctors letters table.
 * @param {Object} options.patient - The patient object.
 * @param {function} options.handleSwitchChange - The function to handle switch change.
 * @param {Array} options.treatmentPrescriptionsColumns - The columns for the treatment prescriptions table.
 * @param {Array} options.columnsDateReasons - The columns for the visit certificates table.
 * @returns {Object} - The modal administration collapse items.
 */
export const useModalAdministrationCollapseItems = ({
  resource,
  setOpenModal,
  doctorsLettersColumns,
  patient,
  handleSwitchChange,
  treatmentPrescriptionsColumns,
  columnsDateReasons
}) => {
  const { t } = useTranslation();

  const modalAdministrationCollapseItems = [
    {
      key: '1',
      label: t(`${resource}.documents.doctor`),
      children: (
        <>
          <Space>
            <SorterDateReason handleSwitchChange={handleSwitchChange} />
            <Button onClick={() => setOpenModal(true)}>
              {t('buttons.add')}
            </Button>
          </Space>
          <Table
            columns={doctorsLettersColumns}
            dataSource={patient?.doctors_letters}
            className="ant-table-wrapper"
          />
        </>
      )
    },
    {
      key: '2',
      label: t(`${resource}.documents.treatment_prescriptions`),
      children: (
        <Table
          dataSource={patient?.treatment_prescriptions}
          columns={treatmentPrescriptionsColumns}
          className="ant-table-wrapper"
        />
      )
    },
    {
      key: '3',
      label: t(`${resource}.documents.visit_certificates`),
      children: (
        <Table columns={columnsDateReasons} className="ant-table-wrapper" />
      )
    }
  ];

  return { modalAdministrationCollapseItems };
};
