import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateUpdateCompany } from './CreateUpdateCompany';
import { ShowCompany } from './ShowCompany';
import { ListCompanies } from './ListCompanies';

/**
 * Renders the router for managing company routes.
 *
 * /create - Route to create a new company.
 * /edit/:id - Route to edit a company.
 * /show/:id - Route to show a company.
 * / - Route to list all companies.
 *
 * @returns {JSX.Element} The router component.
 */
export const CompanyRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateCompany purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateCompany purpose="edit" />} />
    <Route path="/show/:id" element={<ShowCompany />} />
    <Route index element={<ListCompanies />} />
  </Routes>
);
