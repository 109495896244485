export const useListContentBottom = (data = {}) => {
  const { height, weight } = data;

  const labels = [
    {
      label: 'consultations.form.height',
      span: 3,
      content: (height && height) || '-'
    },
    {
      label: 'consultations.form.weight',
      span: 1,
      content: (weight && weight) || '-'
    },
    {
      label: 'consultations.form.bmi',
      span: 2,
      content:
        weight && height
          ? (weight / (((height / 100) * height) / 100)).toFixed(2)
          : '-'
    }
  ];
  return labels;
};
