import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout, Flex, Col } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Logo from '../../assets/images/logo_toola.svg';
import LogoCollapsed from '../../assets/images/logo_collapsed.svg';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import { useHandleResize } from '../../utils/handleResize';
import { AdminNavMenu } from './AdminNavMenu';
import { AdminHeaderButtons } from './AdminHeaderButtons';
import packageJson from '../../../package.json';

const { Content, Footer, Header } = Layout;

const LogoDiv = styled.div`
  position: relative;
  height: 100%;
  padding-left: 0;
  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  justify-content: start;
  align-items: center;
  img {
    display: inline-block;
    height: 40px;
    vertical-align: middle;
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 24px;
  text-align: right;
  color: var(--primaryColor);
  font-size: 12px;

  a {
    color: var(--primaryColor);
  }
`;

/**
 * AdminLayout component represents the layout for the admin section of the application.
 * It includes a header, navigation menu, content area, and footer.
 *
 * @component
 * @returns {ReactElement} The AdminLayout component
 */
export const AdminLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    document
      .getElementById('mainContent')
      .scrollTo({ behavior: 'smooth', top: 0 });
  }, [location]);

  return (
    <>
      <Header>
        <Flex
          wrap
          justify="space-between"
          align="middle"
          style={{ width: '100%', flexWrap: 'wrap' }}
        >
          <LogoDiv>
            <Link to="/">
              <img alt="Logo" src={collapsed ? LogoCollapsed : Logo} />
            </Link>
          </LogoDiv>
          <Col>
            <AdminNavMenu setCollapsed={setCollapsed} label={false} />
          </Col>
          <Col>
            <AdminHeaderButtons setCollapsed={setCollapsed} />
          </Col>
          <Col>
            <HeaderLayout
              collapsed={collapsed}
              collapseSider={setCollapsed}
              collapseWidth={collapseWidth}
            />
          </Col>
        </Flex>
      </Header>
      <Layout>
        <Content id="mainContent">
          <Outlet />
        </Content>
        <StyledFooter as={Footer}>
          {`${t('global.title')} v${
            packageJson.version
          } ©${dayjs().year()} powered by `}
          <a href="https://strateg.in">Strateg.in</a>
        </StyledFooter>
      </Layout>
    </>
  );
};
