import PropTypes from 'prop-types';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';

export const CardsDisplay = ({ fields, resource, translationKey }) => {
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();

  return (
    <Card
      className="patient-form-card"
      title={t(`${resource}.form.${translationKey}`)}
      extra={t(`${resource}.form.history`)}
    >
      {fields.map((field) => generateFields(resource, field))}
    </Card>
  );
};
CardsDisplay.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  resource: PropTypes.string.isRequired,
  translationKey: PropTypes.string
};
CardsDisplay.defaultProps = {
  translationKey: undefined
};
