import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

/**
 * Renders a DatePicker component with a specific format.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string|Date} props.value - The date value.
 * @param {Function} props.onChange - The change event handler.
 * @param {string} props.dbKey - The key for the database.
 * @param {string} props.antdKey - The key for the Ant Design component.
 * @returns {JSX.Element} The rendered DatePicker component.
 */
export const FormatedDatePicker = ({ value, onChange, antdKey, dbKey }) => {
  const dateValue = value ? dayjs(value) : null;

  return (
    <DatePicker
      value={dateValue}
      format="DD/MM/YYYY"
      onChange={onChange}
      name={[`${antdKey}`, `${dbKey}`]}
      style={{ width: '100%' }}
      panelRender={() => null}
    />
  );
};

FormatedDatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  dbKey: PropTypes.string.isRequired,
  antdKey: PropTypes.string.isRequired
};
FormatedDatePicker.defaultProps = {
  value: undefined,
  onChange: undefined
};
