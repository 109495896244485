import { Col, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Title } = Typography;

/**
 * A component that displays a row of diagnostics.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.resource - The resource to be used for translation.
 * @param {Function} props.t - The translation function.
 * @param {Array<string>} props.diagnostic - The array of diagnostics to be displayed.
 *
 * @returns {JSX.Element} The RowDiagnostic component.
 */
export const RowDiagnostic = ({ resource, t, diagnostic }) => (
  <Col span={24}>
    <Title level={5}>{t(`${resource}.medical_consultation.diagnostic`)}</Title>
    <ul>
      {diagnostic?.map((d) => (
        <li key={d}>{d}</li>
      ))}
    </ul>
  </Col>
);

RowDiagnostic.propTypes = {
  resource: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  diagnostic: PropTypes.arrayOf(PropTypes.string)
};

RowDiagnostic.defaultProps = {
  diagnostic: []
};
