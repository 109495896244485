import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Form, Radio, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { usePatientContext } from '../../../../contexts/PatientContext/PatientContext';

const { Item } = Form;

/**
 * Renders a group of radio buttons for selecting genders.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.value - The currently selected gender value.
 * @returns {JSX.Element} - The rendered component.
 */
export const GendersButtons = ({ value, onGenderChange }) => {
  const { t } = useTranslation();
  const { enums } = usePatientContext();
  const [selectedValue, setSelectedValue] = useState(value);
  const resource = 'patients';

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    onGenderChange(e.target.value);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <Item name={['gender']}>
      <Row justify="end">
        <Radio.Group
          value={selectedValue}
          onChange={handleChange}
          buttonStyle="solid"
        >
          {enums?.genders?.map((gender) => (
            <Radio.Button key={gender} value={gender} name={['gender']}>
              {t(`${resource}.form.enums.${gender}`)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Row>
    </Item>
  );
};

GendersButtons.propTypes = {
  value: PropTypes.string,
  onGenderChange: PropTypes.string
};

GendersButtons.defaultProps = {
  value: undefined,
  onGenderChange: undefined
};
