import { BaseModel } from './BaseModel';

/**
 * Represents a Consultation model.
 * @class
 * @extends BaseModel
 */
export class Consultation extends BaseModel {
  /**
   * Creates a new instance of the Consultation model.
   * @constructor
   * @param {Object} data - The data for the consultation.
   */
  constructor(data) {
    super('consultation', undefined, data);
  }

  /**
   * The name of the model.
   * @static
   * @type {string}
   */
  static modelName = 'consultations';

  /**
   * Retrieves a single consultation by its ID.
   * @static
   * @function
   * @param {string} id - The ID of the consultation to retrieve.
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the fetched consultation.
   */
  static getOne(id, { ...params } = {}) {
    return BaseModel.fetchAPI(
      'GET',
      `${Consultation.modelName}/${id}`,
      {},
      params
    );
  }

  /**
   * Retrieves all consultations for a given ID.
   * @static
   * @function
   *
   * @param {string} id - The ID of the consultation.
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves to the API response.
   */
  static getAll(id, { ...params } = {}) {
    return BaseModel.fetchAPI(
      'GET',
      `${Consultation.modelName}/${id}`,
      {},
      params
    );
  }

  /**
   * Updates a medical consultation record by sending a PATCH request to the API.
   * @static
   * @function
   *
   * @param {string} _id - The ID of the medical consultation to update.
   * @param {Object} medicalConsultation - The updated medical consultation data.
   * @returns {Promise} A promise that resolves to the updated medical consultation data.
   */
  static patchMedicalConsultation(_id, { ...medicalConsultation }) {
    return BaseModel.fetchAPI('PATCH', `${Consultation.modelName}/${_id}`, {
      ...medicalConsultation
    });
  }

  /**
   * Patch transmitted medications for a consultation.
   *
   * @param {string} id - The ID of the consultation.
   * @param {Object[]} paramedicalsTransmissions - The array of transmitted medications.
   * @returns {Promise} A promise that resolves with the API response.
   */
  static patchTransmittedMedications(id, paramedicalsTransmissions) {
    return BaseModel.fetchAPI(
      'PATCH',
      `${Consultation.modelName}/${id}/transmissions`,
      paramedicalsTransmissions
    );
  }
}
