import { createContext, useContext, useState } from 'react';
import { message as AntdMessage } from 'antd';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

export const StellairContext = createContext({});
/**
 * Stellair Context Provider component.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
export const StellairContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [vitaleCardData, setVitaleCardData] = useState(null);
  const [patientStellairId, setPatientStellairId] = useState('');

  /**
   * Redirects the user to the OAuth page for Stellair authentication.
   *
   * @function
   * @async
   * @returns {Promise<void>} A promise that resolves when the redirect is complete.
   */
  const redirectToOAuth = async () => {
    const encodedUrl = encodeURIComponent(window.location.href); // Encode the current URL to pass to the server.
    const response = await dispatchAPI('GET', {
      url: `/stellair/getStellairStateAndRedirect/${encodedUrl}`
    });

    if (response.status === 200) {
      window.location.href = response.data.redirectPath;
    }
  };

  /**
   * Retrieves the vital card data from the stellair server.
   *
   * @function
   * @async
   * @returns {Promise<Object|null>} A promise that resolves with the vital card data if successful, or null if there was an error.
   */
  const getVitaleCardData = async () => {
    try {
      const response = await dispatchAPI('GET', {
        url: '/stellair/readVitalCard'
      });

      if (response?.data?.vitaleData) {
        setVitaleCardData(response?.data?.vitaleData);
        return { success: true };
      }
    } catch (error) {
      if (error.response.status === 401) {
        redirectToOAuth();
      } else {
        AntdMessage.error(error.response.data.description, 20);
        return { sucess: false };
      }
    }
    return null;
  };

  /**
   * Creates a new FSE (Fiche de Soins Electronique) for the specified patient.
   *
   * @function
   * @async
   * @param {string} patientId - The ID of the patient.
   * @returns {Promise<Object|null>} A promise that resolves with the FSE data if successful, or null if there was an error.
   */
  const createFSE = async (patientId) => {
    try {
      const response = await dispatchAPI('POST', {
        url: `/stellair/fse/${patientId}`
      });

      if (response.status === 200) {
        window.open(response.data.redirectPath, '_blank');
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        redirectToOAuth();
      } else {
        message(error);
      }
    }
    return null;
  };

  // eslint-disable-next-line require-await
  const getPatientData = async (form) => {
    const data = form.getFieldsValue();
    // eslint-disable-next-line no-console
    console.log('data:', data);
  };

  return (
    <StellairContext.Provider
      value={{
        redirectToOAuth,
        getVitaleCardData,
        createFSE,
        setVitaleCardData,
        vitaleCardData,
        patientStellairId,
        setPatientStellairId,
        getPatientData
      }}
    >
      {children}
    </StellairContext.Provider>
  );
};

export const useStellairContext = () => useContext(StellairContext);
