import React from 'react';
import PropTypes from 'prop-types';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Row, Col, Form, Select, Typography, Button, Mentions } from 'antd';
// import WFLInputForm from '../Input/WFLInputForm';
import { mapInputs, mapOutputs, displayTagInMention } from '../../../utils';

const { Option } = Select;
const { Item } = Form;
const { Title } = Typography;

const CRUDCollectionSelection = ({
  sourceElements,
  collections,
  selectedCollection,
  setSelectedCollection,
  requestFilter,
  setRequestFilter,
  trigger,
  setTrigger,
  nextStep
}) => {
  const collectionTitles = Object.keys(collections);

  const tagDrag = (event, ElmLabel, field) => {
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
    const data = `@{${ElmLabel}|${field}}`;
    event.dataTransfer.setData('text', data);
  };

  // console.log('requestFilter', requestFilter);

  return (
    <>
      <Row>
        <Col span={12}>
          <Title level={5}>Sélectionner une collection</Title>
          <Item
            label="Action"
            name="action"
            rules={[{ required: true }]}
            initialValue={trigger}
          >
            <Select onChange={setTrigger} value={trigger}>
              <Option key="POST" value="POST">
                POST
              </Option>
              <Option key="DELETE" value="DELETE">
                DELETE
              </Option>
              <Option key="PATCH" value="PATCH">
                PATCH
              </Option>
              <Option key="GET" value="GET">
                GET
              </Option>
            </Select>
          </Item>

          <Item
            label="Collection"
            name="collection"
            rules={[{ required: true }]}
            initialValue={selectedCollection}
          >
            <Select
              showSearch
              value={selectedCollection}
              onChange={setSelectedCollection}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {collectionTitles !== [] &&
                collectionTitles.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>

        <Col span={11} offset={1}>
          {collections !== '' &&
            mapOutputs({ ...collections[selectedCollection] })}
        </Col>
      </Row>

      <Row
        style={{ marginBottom: 16 }}
        hidden={trigger === 'POST' || trigger === ''}
      >
        <Row>Filtre de la requête :</Row>
        <Row style={{ margin: '8px 0' }}>
          {mapInputs(sourceElements, true, tagDrag)}
        </Row>
      </Row>
      <Item
        label="Filtre"
        name="requestFilter"
        rules={[{ required: true }]}
        initialValue={requestFilter}
      >
        <Mentions
          style={{ width: '100%' }}
          value={requestFilter}
          onChange={setRequestFilter}
          required
          placeholder='Syntaxe: {"field": "value", "fieldWithFormula": "@{ElmLabel|field}", "fieldWithFunction": "@{ElmLabel|field".split(" ")[0]}'
          key={`formula-${'field.key'}`}
          className="input-code"
        >
          {displayTagInMention(sourceElements, true)}
        </Mentions>
      </Item>
      <Row style={{ paddingTop: 16 }}>
        <Button type="primary" block onClick={nextStep}>
          Etape suivante
          <ArrowRightOutlined />
        </Button>
      </Row>
    </>
  );
};

CRUDCollectionSelection.propTypes = {
  sourceElements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  collections: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  selectedCollection: PropTypes.string.isRequired,
  setSelectedCollection: PropTypes.func.isRequired,
  trigger: PropTypes.string.isRequired,
  setTrigger: PropTypes.func.isRequired,
  requestFilter: PropTypes.string.isRequired,
  setRequestFilter: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired
};

export default CRUDCollectionSelection;
