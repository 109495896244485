import { useState } from 'react';
import { Collapse, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import useFields from '../fields';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { DoubleAuthActivationModal } from './DoubleAuthActivationModal';

const { Panel } = Collapse;

/**
 * Component for managing double factor authentication settings for users.
 * @component
 *
 * @example
 * // Usage in a parent component:
 * <DoubleAuth />
 * @returns {JSX.Element} Returns the JSX element for the double factor authentication settings component.
 */
const DoubleAuth = () => {
  const { t } = useTranslation();
  const { user, setUser, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFields = useGenerateFormItem();
  const [modal, setModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const patch2FA = async (values) => {
    try {
      setIsLoading(true);

      const { data } = await dispatchAPI('PATCH', {
        url: '/users/patch2FA',
        body: values
      });

      if (data && data.double_factor_authentication_activation) {
        setModal(
          <DoubleAuthActivationModal
            key={Date.now()}
            setIsLoading={setIsLoading}
          />
        );
      } else {
        setUser((prev) => ({ ...prev, ...data }));
      }
    } catch (error) {
      message(error);
    } finally {
      setIsLoading(false);
    }
  };

  const { doubleFactorAuthFields } = useFields(user, patch2FA, isLoading);

  return (
    <Card style={{ marginBottom: 24 }}>
      {modal}
      <Collapse expandIconPosition="end" defaultActiveKey={['1']} ghost>
        <Panel header={t('settings.collapses.double_factor_auth')} key="1">
          {doubleFactorAuthFields.map((field) =>
            generateFields('users', field)
          )}
        </Panel>
      </Collapse>
    </Card>
  );
};

export default DoubleAuth;
