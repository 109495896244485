import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { getFullName } from '../../../utils/formatters';

/**
 * Custom hook that returns the columns configuration for the prescription authorization table.
 *
 * @hook
 * @returns {Object} The columns configuration.
 */
export const usePrescriptionAuthColumns = () => {
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const translationKey = 'users.form';
  const checkedSwitch = (tab) => tab.includes(user._id);

  const handleSubmit = async (id, checked) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/users/allow-nurse/${id}`,
        body: { checked }
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const columns = [
    {
      title: t(`${translationKey}.first_name_cap`),
      key: 'first_name',
      dataIndex: 'first_name',
      render: (first_name) => getFullName({ first_name }),
      sorter: true
    },
    {
      title: t(`${translationKey}.last_name_cap`),
      key: 'last_name',
      dataIndex: 'last_name',
      render: (last_name) => getFullName({ last_name }),
      sorter: true
    },
    {
      title: t(`${translationKey}.prescription`),
      key: 'action',
      valuePropName: 'checked',
      align: 'right',
      render: (record) => (
        <Switch
          defaultChecked={checkedSwitch(record.allowed_doctors)}
          checkedChildren={t('settings.collapses.allowed')}
          unCheckedChildren={t('settings.collapses.denied')}
          onChange={(checked) => handleSubmit(record._id, checked)}
        />
      )
    }
  ];

  return { columns };
};
