/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row, Image } from 'antd';
import { getFullName } from '../../utils/formatters';

/**
 * Component for displaying doctor's information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.showLabelInput - Flag indicating whether to show label input.
 * @param {Object} props.doctor - The doctor object.
 * @param {string} props.doctor._id - The ID of the doctor.
 * @param {string} props.doctor.specialization - The specialization of the doctor.
 * @param {number} props.doctor.cpx_number - The CPX number of the doctor.
 * @param {Object} props.doctor.cpx_barcode - The CPX barcode of the doctor.
 * @param {number} props.doctor.am_finess_number - The AM number of the doctor.
 * @param {string} props.translationKey - The translation key.
 * @returns {JSX.Element} The rendered component.
 */
export const DoctorsInformations = ({
  showLabelInput,
  doctor,
  translationKey
}) => {
  const { t } = useTranslation();
  const cpxBarCode = doctor?.cpx_barcode || null;

  return (
    <Row justify="end">
      <Col>
        <Row justify="end">
          {showLabelInput
            ? t(`${translationKey}.doctor.doctor_name`)
            : doctor
            ? `${t(`${translationKey}.doctor.title`)} ${getFullName(doctor)}`
            : ''}
        </Row>
        <Row justify="end">
          {showLabelInput
            ? t(`${translationKey}.doctor.specialization`)
            : doctor?.specialization || ''}
        </Row>
        <Row justify="end">
          {showLabelInput ? (
            t(`${translationKey}.doctor.cpx_barcode`)
          ) : cpxBarCode?.data ? (
            <Image
              alt={t(`${translationKey}.doctor.cpx_barcode`)}
              preview={false}
              src={cpxBarCode?.data}
            />
          ) : (
            ''
          )}
        </Row>
        <Row justify="end">
          {showLabelInput
            ? t(`${translationKey}.doctor.cpx_number`)
            : `RPPS : ${
                doctor?.cpx_number || t(`${translationKey}.not_specified`)
              }`}
        </Row>
        <Row style={{ marginBottom: 80 }} justify="end">
          {showLabelInput
            ? t(`${translationKey}.doctor.am_finess_number`)
            : `AM / Finess : ${
                doctor?.am_finess_number || t(`${translationKey}.not_specified`)
              }`}
        </Row>
      </Col>
    </Row>
  );
};

DoctorsInformations.propTypes = {
  showLabelInput: PropTypes.bool,
  doctor: PropTypes.shape({
    _id: PropTypes.string,
    specialization: PropTypes.string,
    cpx_number: PropTypes.number,
    cpx_barcode: PropTypes.shape({
      data: PropTypes.string
    }),
    am_finess_number: PropTypes.number
  }),
  translationKey: PropTypes.string.isRequired
};

DoctorsInformations.defaultProps = {
  showLabelInput: false,
  doctor: null
};
