import { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Card, Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { usePatientContext } from '../../../../contexts/PatientContext/PatientContext';
import { useDownloadDocument } from '../../../../utils/downloadDoc';
import { dateFormat } from '../../../../utils/formatters';

/**
 * Renders a component that displays the care consent card.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const CareConsentCard = () => {
  const { t } = useTranslation();
  const { viewDocument } = useDownloadDocument();
  const { patient } = usePatientContext();
  const [loading, setLoading] = useState(false);

  const displayCareConsent = async () => {
    setLoading(true);
    await viewDocument({ _id: patient.care_consent });
    setLoading(false);
  };

  return patient?._id ? (
    <Card title={t('patients.documents.care_consent')}>
      <p>
        {t('patients.documents.consent_signed_date', {
          date: dayjs(patient.care_consent_date).format(dateFormat)
        })}
      </p>
      <Button
        type="primary"
        onClick={() => displayCareConsent()}
        loading={loading}
      >
        <EyeOutlined />
        {t('buttons.view')}
      </Button>
    </Card>
  ) : null;
};
