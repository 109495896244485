import PropTypes from 'prop-types';
import { Row, Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ArrowRightOutlined, DownloadOutlined } from '@ant-design/icons';

const stellairObject = {
  insi: {
    type: 'MSG_STELLAIR',
    service: 'insi',
    payload: {
      patientId: 'e29a2fa2-aef4-4412-9d06-b4145d0e4114'
    }
  },
  aati: {
    type: 'MSG_STELLAIR',
    service: 'aati',
    payload: {
      patientId: 'e29a2fa2-aef4-4412-9d06-b4145d0e4114',
      nomAppli: 'Toola'
    }
  }
};

// TODO work in progress
export const TeleservicesButton = ({ service, justify }) => {
  const { t } = useTranslation();

  // const stellairUrl = 'https://tlsi.stellair.fr/apptlsi';
  const stellairUrl = 'https://tlsi.demo.stellair.fr/apptlsi';

  const stellair = new URL(stellairUrl);
  const messageHandler = (event) => {
    if (event.origin === stellair.origin) {
      switch (event.data.type) {
        case 'MSG_FROM_STELLAIR':
          break;
        case 'END_PROCESS_FROM_STELLAIR':
          break;
        case 'HEIGHT_FROM_STELLAIR':
          document.getElementById(
            'iFrameStellairId'
          ).style.width = `${event.data.payload.result}px`;
          break;
        case 'PDF_FROM_STELLAIR':
          break;
        default:
          break;
      }
    }
  };

  // const generateIframe = () => {
  //   tlsiIframe.style.position = 'absolute';
  //   tlsiIframe.style.zIndex = 1000;
  //   tlsiIframe.style.top = '50%';
  //   tlsiIframe.style.left = '50%';
  //   tlsiIframe.style.transform = 'translate(-50%, -50%)';
  //   tlsiIframe.style.height = height && height;
  //   tlsiIframe.src = `${stellairUrl}`;
  //   tlsiIframe.id = 'iFrameStellairId';
  //   document.body.appendChild(tlsiIframe);
  // };

  useEffect(() => {
    window.addEventListener('message', messageHandler, false);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  useEffect(() => {
    if (document.getElementById('iFrameStellairId')) {
      document
        .getElementById('iFrameStellairId')
        .contentWindow.postMessage(stellairObject[service], stellairUrl);
    }
  }, [messageHandler]);

  const icons = {
    aati: <DownloadOutlined />,
    insi: <ArrowRightOutlined />
  };

  return (
    <Row justify={justify}>
      <Tooltip title="En cours">
        <Button
          // onClick={generateIframe}
          icon={icons[service]}
          style={{
            borderRadius: '15px'
          }}
        >
          {t(`buttons.${service}_button`)}
        </Button>
      </Tooltip>
    </Row>
  );
};

TeleservicesButton.propTypes = {
  service: PropTypes.string.isRequired,
  justify: PropTypes.string.isRequired
};
