import { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spin, Flex, Col, Typography, Layout, message } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import Logo from '../../assets/images/logo_toola.svg';

/**
 * Component for handling the PSC success page.
 * This component is responsible for displaying a loading spinner and redirecting the user after a successful PSC login.
 *
 * @component
 *
 * @returns {React.Component} The PscSuccess component
 */
export const PscSuccess = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { dispatchAPI, setSession, token, setUser, isValid } = useAuthContext();
  const { from } = location?.state || { from: { pathname: '/' } };
  const searchParams = new URLSearchParams(location.search);
  const tokenFromUrl = searchParams.get('token');
  const refreshToken = searchParams.get('refresh_token');
  const userId = searchParams.get('user_id');

  const getUser = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/user/${id}`
      });
      setUser(data);
    } catch (error) {
      message.error(t('login.error'));
    }
  };

  useEffect(() => {
    (() => {
      setTimeout(() => {
        if (tokenFromUrl && refreshToken && userId) {
          setSession(tokenFromUrl);
        }
      }, 1000);
      if (token === tokenFromUrl && userId) {
        getUser(userId);
      }
    })();
  }, [token]);

  return (
    <Layout className="login-layout">
      {isValid && <Navigate to={from} />}
      <Flex justify="center" align="center" style={{ height: '100vh' }}>
        <Col>
          <Flex justify="center" align="center">
            <img
              style={{
                width: 240,
                marginBottom: 200
              }}
              alt="Logo"
              src={Logo}
            />
          </Flex>
          <Typography.Title level={2}>
            {t('login.psc_redirect_message')}
          </Typography.Title>
          <Spin size="large" />
        </Col>
      </Flex>
    </Layout>
  );
};
