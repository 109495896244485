import { MedicineBoxOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Space,
  Tooltip
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { displayInput } from '../utils/displayInput';
import { useHandleChange } from '../utils/handleChange';
import { useHandleConfirmTransmition } from '../utils/useHandleConfirmTransmition';

/**
 * Renders a care icon for a patient.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.consultation - The consultation object.
 * @param {Function} props.setRefresh - The function to refresh the component.
 * @returns {JSX.Element} - The rendered component.
 */
export const CareIcon = ({ consultation, setRefresh }) => {
  const [transmittedCares, setTransmittedCares] = useState([]);
  const [careList, setCareList] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation();

  const { handleConfirm } = useHandleConfirmTransmition(setRefresh);
  const { handleChange } = useHandleChange(
    transmittedCares,
    setTransmittedCares
  );

  const plainOptions = consultation?.prescribed_cares?.map((care) => ({
    label: care.label,
    value: care._id,
    checked: care.transmitted
  }));

  const checkAll = plainOptions.length === transmittedCares.length;
  const indeterminate =
    transmittedCares.length > 0 &&
    transmittedCares.length < plainOptions.length;

  useEffect(() => {
    if (plainOptions.length > 0) {
      const defaultCheckedList = plainOptions.flatMap((option) =>
        option.checked ? option.value : []
      );
      const newCareList = plainOptions.flatMap((option) => option.label);

      setTransmittedCares(defaultCheckedList);
      setCareList(newCareList);
    }
  }, []);

  const cares = consultation?.prescribed_cares || [];

  const prescribedCares = cares.map((care) => (
    <Checkbox
      defaultChecked={care.transmitted}
      onChange={handleChange}
      value={care._id}
      key={care._id}
    >
      {care.label}
    </Checkbox>
  ));

  return (
    consultation?.prescribed_cares?.length > 0 && (
      <>
        <Tooltip title={t('patients.icons.srn_care')}>
          <Popconfirm
            placement="bottomRight"
            title={t('patients.icons.srn_care')}
            description={
              <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                <Space direction="vertical">
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={(e) =>
                      setTransmittedCares(
                        e.target.checked
                          ? plainOptions.flatMap((option) => option.value)
                          : []
                      )
                    }
                    checked={checkAll}
                  >
                    {t('patients.icons.check_all')}
                  </Checkbox>
                  <Divider />
                  {prescribedCares}
                </Space>
              </div>
            }
            okText={t('buttons.validate')}
            cancelText={t('buttons.cancel')}
            icon={
              <MedicineBoxOutlined style={{ color: '#004a8f', fontSize: 18 }} />
            }
            onConfirm={() => {
              if (displayInput(careList)) {
                setOpenModal(true);
                return;
              }
              handleConfirm(consultation._id, { transmittedCares });
            }}
          >
            <MedicineBoxOutlined style={{ fontSize: 18 }} />
          </Popconfirm>
        </Tooltip>
        <Modal
          open={openModal}
          onCancel={() => setOpenModal(false)}
          onConfirm={handleConfirm}
        >
          <Input />
        </Modal>
      </>
    )
  );
};

CareIcon.propTypes = {
  consultation: PropTypes.shape({
    _id: PropTypes.string,
    prescribed_cares: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  setRefresh: PropTypes.func.isRequired
};

CareIcon.defaultProps = {
  consultation: {}
};
