import { BaseModel } from './BaseModel';

/**
 * Represents a Protocol model.
 * @class
 * @extends BaseModel
 */
export class Protocol extends BaseModel {
  /**
   * Creates an instance of the Protocol model.
   * @constructor
   * @param {Object} data - The data for the Protocol.
   */
  constructor(data) {
    super('protocols', undefined, data);
  }

  /**
   * The name of the model.
   * @static
   * @type {string}
   */
  static modelName = 'protocols';

  /**
   * Retrieves all protocols.
   * @static
   * @function getAll
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static getAll({ ...params } = {}) {
    return BaseModel.fetchAPI('GET', `${Protocol.modelName}`, {}, params);
  }

  /**
   * Retrieves favorite protocols.
   * @static
   * @function getFavoritesProtocols
   * @param {string} category - The category of the favorite protocols.
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static getFavoritesProtocols(category = '', { ...params } = {}) {
    return BaseModel.fetchAPI(
      'GET',
      `${Protocol.modelName}/favorites/${category}`,
      {},
      params
    );
  }

  /**
   * Retrieves a protocol by its ID.
   * @static
   * @function getOneById
   * @param {string} id - The ID of the protocol.
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static getOneById(id, { ...params } = {}) {
    return BaseModel.fetchAPI('GET', `${Protocol.modelName}/${id}`, {}, params);
  }

  /**
   * Creates a new protocol.
   * @static
   * @function create
   * @param {Object} data - The data for the new protocol.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static create(data) {
    return BaseModel.fetchAPI('POST', `${Protocol.modelName}`, data);
  }

  /**
   * Creates a new favorite protocol.
   * @static
   * @function createFavorite
   * @param {string} category - The category of the favorite protocol.
   * @param {Object} data - The data for the new favorite protocol.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static createFavorite(category, data) {
    return BaseModel.fetchAPI(
      'POST',
      `${Protocol.modelName}/favorites/${category}`,
      data
    );
  }

  /**
   * Patches a favorite protocol by its ID.
   * @static
   * @function patchFavoriteProtocol
   * @param {string} id - The ID of the favorite protocol to patch.
   * @param {Object} body - The updated data for the favorite protocol.
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static patchFavoriteProtocol(id, body = {}, { ...params } = {}) {
    return BaseModel.fetchAPI(
      'PATCH',
      `${Protocol.modelName}/favorites/${id}`,
      body,
      params
    );
  }

  /**
   * Deletes a favorite protocol by its ID.
   * @static
   * @function deleteFavoriteProtocol
   * @param {string} id - The ID of the favorite protocol to delete.
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static deleteFavoriteProtocol(id, { ...params } = {}) {
    return BaseModel.fetchAPI(
      'DELETE',
      `${Protocol.modelName}/favorites/${id}`,
      {},
      params
    );
  }

  /**
   * Updates a protocol by its ID.
   * @static
   * @function update
   * @param {string} id - The ID of the protocol to update.
   * @param {Object} data - The updated data for the protocol.
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  static update(id, data, { ...params } = {}) {
    return BaseModel.fetchAPI(
      'PATCH',
      `${Protocol.modelName}/${id}`,
      data,
      params
    );
  }
}
