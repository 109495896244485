import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useColumnsExitPrescriptions } from '../columns/columnsExitPrescriptions';
import { SorterDateReason } from '../../../Components/SorterDateReason';
import { useSwitchState } from '../../../Components/switchState';

/**
 * @component ExitTable
 * @description A custom table component for displaying exit prescriptions
 * @param {Array} data - array of exit prescriptions
 * @param {Function} onChange - function to handle table change
 * @returns {JSX} - JSX component
 */
export const ExitTable = ({ data, onChange }) => {
  const { isSwitched, handleSwitchChange } = useSwitchState();
  const columns = useColumnsExitPrescriptions(isSwitched);

  return (
    <>
      <SorterDateReason handleSwitchChange={handleSwitchChange} />
      <Table
        pagination={{
          pageSizeOptions: ['10', '20', '50', '100'],
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
        }}
        columns={columns}
        dataSource={data}
        onChange={onChange}
      />
    </>
  );
};

ExitTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired
};
