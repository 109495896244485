import PropTypes from 'prop-types';
import { Button, Card, Col, Flex } from 'antd';
import Icon from '@mdi/react';
import { mdiMedicalBag } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { FavoritesCard } from '../stay/cards/FavoritesCard';

/**
 * Renders a card component for free text prescriptions.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.refresh - Indicates whether the card should be refreshed.
 * @param {function} props.setRefresh - Callback function to set the refresh state.
 * @param {function} props.handleNewFreeTextPrescription - Callback function to handle new free text prescription.
 * @returns {JSX.Element} The rendered component.
 */
export const FreePrescriptionCard = ({
  refresh,
  setRefresh,
  handleNewFreeTextPrescription
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Col span={12}>
        <FavoritesCard
          className="card-prescription "
          category="FREE_TEXT"
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Col>
      <Col span={12}>
        <Card
          className="card-prescription "
          style={{ borderRadius: '0 8px 8px 0' }}
          title={
            <Flex gap={8}>
              <Icon path={mdiMedicalBag} size={1} />
              {t('prescription.enums.FREE_TEXT')}
            </Flex>
          }
        >
          <Button onClick={handleNewFreeTextPrescription}>
            +{' '}
            {t(
              'prescription.cards.prescriptions.free_prescription'
            ).toUpperCase()}
          </Button>
        </Card>
      </Col>
    </>
  );
};

FreePrescriptionCard.propTypes = {
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  handleNewFreeTextPrescription: PropTypes.func.isRequired
};
