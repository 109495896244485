import PropTypes from 'prop-types';
import { Flex } from 'antd';
import { useStellairContext } from '../../contexts/StellairContext';
import { ReadVitaleCardButton } from '../../components/Stellair/ReadVitaleCardButton';
import { DisplayVitaleCardDataButton } from './DisplayVitaleCardDataButton';

/**
 * Renders additional buttons for the patient page.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setIsModalVisible - Function to set the visibility of a modal.
 * @returns {JSX.Element} - The rendered component.
 */
export const ExtraButtons = ({ setIsModalVisible }) => {
  const { vitaleCardData } = useStellairContext();
  const props = { setIsModalVisible };

  return (
    <Flex gap={8}>
      {vitaleCardData && <DisplayVitaleCardDataButton {...props} />}
      <ReadVitaleCardButton {...props} />
    </Flex>
  );
};

ExtraButtons.propTypes = {
  setIsModalVisible: PropTypes.func.isRequired
};
