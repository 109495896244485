import React from 'react';
import { FormatedDatePicker } from '../components/Fields/FormatedDatePicker';
import { CardPicture } from '../components/Fields/CardPicture';
import { NoCardRadioButton } from '../components/CardsHistory/NoCardRadioButton';
import { CardUpload } from '../../../components/CardUpload/CardUpload';

/**
 * Returns an array of identity card fields for a form.
 * @hook
 *
 * @param {object} form - The form object.
 * @returns {Array} An array of identity card fields.
 */
export const useIdentityCardFields = (form) => {
  const identityCardFields = [
    {
      noLabel: true,
      name: ['card', 'identity_card_picture'],
      rules: [{ type: 'file' }],
      input: <CardPicture />
    },
    {
      noLabel: true,
      name: ['card', 'identity_card'],
      rules: [{ type: 'file' }],
      input: (
        <CardUpload
          dbKey="identity_card"
          resource="patients"
          antdKey="card"
          form={form}
        />
      )
    },
    {
      label: 'date',
      name: ['card', 'identity_card_exp_date'],
      rules: [{ type: 'date' }],
      input: (
        <FormatedDatePicker antdKey="card" dbKey="identity_card_exp_date" />
      )
    },
    {
      label: 'no_identity_card',
      name: ['card', 'no_identity_card'],

      input: <NoCardRadioButton dbKey="no_identity_card" />
    }
  ];
  return { identityCardFields };
};
