import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ResultsLaboratoriesTable } from '../PatientOverview/ResultsLaboratories/ResultsLaboratoriesTable';
import { StayHistoryTable } from '../PatientOverview/StayHistory/StayHistoryTable';

export const TitleSeeMoreButton = ({ id, title }) => {
  const resource = 'consultations';
  const [isModal, setModal] = useState(false);
  const { t } = useTranslation();
  const handleClick = () => {
    setModal(true);
  };
  return (
    <Row justify="space-between">
      {title && <h1>{t(`${resource}.form.${title}`)}</h1>}
      <Button type="Link" icon={<PlusOutlined />} onClick={handleClick}>
        {t('buttons.see_more')}
      </Button>
      <Modal
        open={isModal}
        onCancel={() => setModal(false)}
        okText={t('buttons.validate')}
        width="50%"
        footer={null}
      >
        {title === 'results_laboratories' ? (
          <ResultsLaboratoriesTable id={id} key={title} />
        ) : (
          <StayHistoryTable id={id} key={title} />
        )}
      </Modal>
    </Row>
  );
};
TitleSeeMoreButton.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
