import { Card, Checkbox, Col, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { renderTitle } from '../utils/renderTitle';
import { cardStyles } from '../utils/cardStyles';

/**
 * Renders a card component for selecting prescription types in a radiology prescription form.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Function} props.renderTitle - The function for rendering the card title.
 * @param {Object} props.cardStyles - The styles for the card component.
 * @param {Object} props.imagingData - The data for imaging.
 * @param {Function} props.setPrescriptionType - The function for setting the selected prescription type.
 * @param {string} props.prescriptionType - The currently selected prescription type.
 * @returns {JSX.Element} The rendered card component.
 */
export const CardPrescriptionType = ({
  imagingData,
  setPrescriptionType,
  prescriptionType
}) => {
  const { t } = useTranslation();

  return (
    <Col xs={24} md={24} lg={6} style={{ display: 'flex', height: '100%' }}>
      <Card
        title={renderTitle(
          t('prescription.radiology.form.card_titles.prescriptions_types')
        )}
        style={{
          border: 'none',
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
        styles={cardStyles}
      >
        <Form.Item
          name="prescriptions_types"
          style={{ flex: '1 1 auto', height: '100%' }}
        >
          <Checkbox.Group
            style={{
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {imagingData?.prescriptions_types?.map((prescription_type) => (
              <Checkbox
                key={prescription_type}
                onChange={(e) => {
                  if (e.target.checked) {
                    setPrescriptionType(e.target.value);
                  } else {
                    setPrescriptionType(null);
                  }
                }}
                disabled={
                  prescriptionType !== null &&
                  prescription_type !== prescriptionType
                }
                value={prescription_type}
              >
                {t(
                  `data_bases.stay_prescriptions.columns.prescription_types.${prescription_type}`
                )}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Card>
    </Col>
  );
};

CardPrescriptionType.propTypes = {
  imagingData: PropTypes.shape({
    prescriptions_types: PropTypes.arrayOf(PropTypes.string)
  }),
  setPrescriptionType: PropTypes.func.isRequired,
  prescriptionType: PropTypes.string
};

CardPrescriptionType.defaultProps = {
  imagingData: {
    prescriptions_types: []
  },
  prescriptionType: null
};
