import { Route, Routes } from 'react-router-dom';
import { CreateUpdateSurgicalHistory } from './CreateUpdateSurgicalHistory';
import { Exception } from '../../../components';

/**
 * Component for routing related to the surgical historic.
 *
 * @component
 * @returns {JSX.Element} The SurgicalHistoricRouter component.
 */
export const SurgicalHistoricRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateSurgicalHistory purpose="create" />}
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
