import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PreviousComments } from '../../../../components/PreviousComments/PreviousComments';

/**
 * Modal component for displaying medical comments.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.openModal - Flag indicating whether the modal is open.
 * @param {function} props.setOpenModal - Function to set the openModal flag.
 * @param {Array} props.medicalComments - Array of medical comments.
 * @param {function} props.deleteComment - Function to delete a comment.
 * @param {function} props.checkComment - Function to check a comment.
 * @returns {JSX.Element} The rendered modal component.
 */
export const ModalMedicalComments = ({
  openModal,
  setOpenModal,
  medicalComments,
  deleteComment,
  checkComment
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={openModal}
      footer={
        <Button type="primary" onClick={() => setOpenModal(false)}>
          {t('buttons.ok')}
        </Button>
      }
      closeIcon={null}
    >
      {medicalComments
        ? medicalComments.map((comment) => (
            <PreviousComments
              key={comment?._id}
              comment={comment}
              deleteComment={deleteComment}
              checkComment={checkComment}
            />
          ))
        : null}
    </Modal>
  );
};

ModalMedicalComments.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  medicalComments: PropTypes.arrayOf(PropTypes.shape({})),
  deleteComment: PropTypes.func,
  checkComment: PropTypes.func
};

ModalMedicalComments.defaultProps = {
  openModal: false,
  setOpenModal: () => {},
  medicalComments: [],
  deleteComment: () => {},
  checkComment: () => {}
};
