/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { getSynapseToken } from '../../utils/synapse';

/**
 * This component renders the Synapse Prescription Summarywidget.
 * @component SynapsePrescriptionSummaryWidget
 * @returns {JSX.Element} Synapse Prescription Summary widget
 */
export const SynapsePrescriptionSummaryWidget = () => {
  const { entities, patientInfos } = usePrescriptionContext();
  const [JWT, setJWT] = useState(null);
  const [prescriptionSummaryWidget, setPrescriptionSummaryWidget] =
    useState(null);

  const { dispatchAPI } = useAuthContext();

  const fetchNewToken = async () => {
    const token = await getSynapseToken(dispatchAPI);
    setJWT(token);
  };

  const createDataSummary = () => {
    const profile = {
      ...patientInfos?.infos,
      birthDate: patientInfos?.infos?.birth_date
    };
    delete profile.birth_date;

    return {
      entities,
      profile
    };
  };

  useEffect(() => {
    if (!prescriptionSummaryWidget) return;

    const dataSummary = createDataSummary();
    prescriptionSummaryWidget.update(dataSummary);
  }, [entities, prescriptionSummaryWidget]);

  useEffect(() => {
    if (!JWT) {
      fetchNewToken();
      return;
    }

    const dataSummary = createDataSummary();
    const newPrescriptionSummaryWidget =
      new window.Synapse.PrescriptionSummaryWidget(JWT);
    newPrescriptionSummaryWidget.init(
      document.getElementById('summary-container'),
      dataSummary
    );

    newPrescriptionSummaryWidget.setCallback('onOpenAnalysis', (module) => {});

    newPrescriptionSummaryWidget.setCallback(
      'onTokenExpiration',
      (err, data) => {
        fetchNewToken();
      }
    );

    setPrescriptionSummaryWidget(newPrescriptionSummaryWidget);
  }, [JWT]);

  return <Card id="summary-container" />;
};
