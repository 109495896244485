// The following commented code is an easteregg that plays an elevator music when the prescription is on generation
import PropTypes from 'prop-types';
// import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import quotes from './quotes';
// import audioFile from '../../../assets/audio/ascenseur.mp3';

/**
 * @component
 * @name QuotesModal
 * @description A component to display a random quote in a modal when the prescription is on generation and play an elevator music only in development mode
 * @param {boolean} visible - The visibility of the modal
 * @returns {JSX.Element} React component
 */
export const QuotesModal = ({ visible }) => {
  const { t } = useTranslation();
  // const audio = new Audio(audioFile);

  const generateRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
  };

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'development') {
  //     return null;
  //   }
  //   if (visible) {
  //     audio.play();
  //   } else {
  //     audio.pause();
  //   }
  //   return () => {
  //     audio.pause();
  //   };
  // }, [visible]);

  return (
    <Modal
      title={t('prescription.quotes.title')}
      open={visible}
      footer={null}
      closable={false}
    >
      {generateRandomQuote()}
    </Modal>
  );
};

QuotesModal.propTypes = {
  visible: PropTypes.bool
};

QuotesModal.defaultProps = {
  visible: false
};
