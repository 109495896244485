import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { Center } from '../../../../../models/Center';

/**
 * Hook to handle document submission.
 *
 * @hook useDocumentSubmit
 * @param {Object} params - The parameters passed to the hook.
 * @param {string} params.documentType - The type of the document to be submitted.
 * @param {string} params.documentWording - The wording of the document to be submitted.
 * @param {Function} params.setOpenModal - Function to set the open state of the modal.
 *
 * @returns {Object} An object containing the handleSubmit function.
 * @property {Function} handleSubmit - Function to handle the document submission.
 */
export const useDocumentSubmit = ({
  documentType = '',
  documentWording = '',
  setOpenModal = () => {},
  setLoading = () => {},
  setStatus = () => {},
  patientId = '',
  datePicking = ''
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { printDocument } = useDownloadDocument();

  const [centerRooms, setCenterRooms] = useState();

  const idCenter = user?.selected_center || user?.default_center;
  const { secretariat_phone_number: { number } = {} } = centerRooms || {};

  const population = ['first_visit_waiting_room', 'return_visit_waiting_room'];

  useEffect(() => {
    (async () => {
      const response = await Center.getCenter(idCenter, {
        populate: population.join(',')
      });
      setCenterRooms(response);
    })();
  }, []);

  const handleSubmit = async () => {
    if (patientId) {
      try {
        setLoading(true);
        setStatus('active');

        const { data } = await dispatchAPI('POST', {
          url: `${documentType}/${patientId}`,
          body: {
            sub: idCenter,
            center: {
              phone_number: {
                number
              }
            },
            // concat the string and remove "s" from documentType to get the right key
            [`${documentType.slice(0, -1)}_wording`]: documentWording,
            datePicking: datePicking || undefined
          }
        });

        setStatus('success');
        setTimeout(async () => {
          await printDocument(data);
        }, 1000);

        setLoading(false);
      } catch (error) {
        setStatus('exception');
        setLoading(false);
        message(error);
      }
    }
    setOpenModal(false);
  };

  return {
    handleSubmit
  };
};
