import {
  HomeOutlined,
  UserOutlined,
  BranchesOutlined,
  ApartmentOutlined,
  AppstoreOutlined,
  PhoneOutlined,
  IdcardOutlined,
  FileTextOutlined,
  ExperimentOutlined,
  DashboardOutlined,
  CalculatorOutlined,
  DatabaseOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  DASHBOARD: <DashboardOutlined />,
  USERS: <UserOutlined />,
  WORKFLOWS: <BranchesOutlined />,
  COMPANIES: <ApartmentOutlined />,
  INVOICES: <CalculatorOutlined />,
  DATA_BASE: <DatabaseOutlined />,
  ROOMS: <AppstoreOutlined />,
  VIEW: <IdcardOutlined />,
  DIRECTORY: <PhoneOutlined />,
  ADMINISTRATIVE: <FileTextOutlined />,
  LABORATORY: <ExperimentOutlined />,
  CENTERS: <ApartmentOutlined />
};

export default navMenuLogos;
