import { useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

export const usefetchPatientForSearch = (handleClose) => {
  const [searchResults, setSearchResults] = useState([]);
  const [isRequestComplete, setIsRequestComplete] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const resource = 'patients';

  const createResource = async (patient) => {
    try {
      const response = await dispatchAPI('POST', {
        url: `${resource}/search/selection`,
        body: patient
      });
      setSearchResults(response.data);
      setIsRequestComplete(true);
    } catch (e) {
      message(e);
      setIsRequestComplete(true);
    }
  };

  const handleSubmit = async (values) => {
    const { patient } = values;
    if (patient.birth_date) {
      patient.birth_date = patient.birth_date.toISOString();
    }
    setIsRequestComplete(false);
    await createResource(patient);
    handleClose();
  };

  return { handleSubmit, searchResults, isRequestComplete };
};
