import { mdiIvBag } from '@mdi/js';
import Icon from '@mdi/react';
import { Checkbox, Popconfirm, Space, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { MedicineBoxOutlined } from '@ant-design/icons';
import { useHandleChange } from '../utils/handleChange';
import { useHandleConfirmTransmition } from '../utils/useHandleConfirmTransmition';
import { useColorsIcons } from '../utils/useColorsIcons';

/**
 * Represents the Intravenous Icon component.
 * @param {Object} props - The component props.
 * @param {boolean} props.isAllergic - Indicates if the patient is allergic.
 * @param {string} props.consultationId - The ID of the consultation.
 * @param {function} props.setRefresh - The function to refresh the component.
 * @param {Object} props.consultation - The consultation object.
 * @returns {JSX.Element} The Intravenous Icon component.
 */
export const IntravenousIcon = ({
  isAllergic,
  consultationId,
  setRefresh,
  consultation
}) => {
  const { t } = useTranslation();
  const { handleConfirm } = useHandleConfirmTransmition(setRefresh);

  const [transmittedMedications, setTransmittedMedications] = useState([]);

  const { handleChange } = useHandleChange(
    transmittedMedications,
    setTransmittedMedications
  );

  const IVMedications = consultation.prescribed_medications?.filter(
    (medication) =>
      medication.administration_route === t('patients.icons.intravenous')
  );

  const allMedicationsPrescribed = IVMedications?.every(
    (medication) => medication.transmitted
  );

  const { colorsIcons } = useColorsIcons(
    allMedicationsPrescribed,
    'grey',
    'blue'
  );

  const prescribedMedications = IVMedications.map(
    (medication) =>
      !isAllergic(medication.ingredients) && (
        <Checkbox
          defaultChecked={medication.transmitted}
          onChange={handleChange}
          value={medication._id}
          key={medication._id}
        >
          {medication.label}
        </Checkbox>
      )
  );

  return (
    IVMedications.length > 0 && (
      <Tooltip title={t('patients.icons.intravenous_icon')}>
        <Popconfirm
          placement="bottomRight"
          title={t('patients.icons.medications')}
          description={
            <Space direction="vertical">{prescribedMedications}</Space>
          }
          okText={t('buttons.validate')}
          cancelText={t('buttons.cancel')}
          icon={<MedicineBoxOutlined />}
          onConfirm={() =>
            handleConfirm(consultationId, { transmittedMedications })
          }
        >
          <Icon path={mdiIvBag} color={colorsIcons} size="18px" />
        </Popconfirm>
      </Tooltip>
    )
  );
};

IntravenousIcon.propTypes = {
  isAllergic: PropTypes.func.isRequired,
  consultationId: PropTypes.string.isRequired,
  setRefresh: PropTypes.func.isRequired,
  consultation: PropTypes.shape({
    prescribed_medications: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};
