import { Route, Routes } from 'react-router-dom';
import { ListUsers } from './ListUsers';
import { CreateUpdateUser } from './CreateUpdateUser';
import { ShowUser } from './ShowUser';
import { Exception } from '../../components';

/**
 * UserRouter component renders the routes for user-related pages.
 *
 * /create/:companyId? - Create a new user with an optional company ID.
 * /edit/:id - Edit an existing user.
 * /show/:id - Show the details of a user.
 * / - List all users.
 * @returns {JSX.Element} The rendered UserRouter component.
 */
export const UserRouter = () => (
  <Routes>
    <Route
      path="/create/:companyId?"
      element={<CreateUpdateUser purpose="create" />}
    />
    <Route path="/edit/:id" element={<CreateUpdateUser purpose="edit" />} />
    <Route path="/show/:id" element={<ShowUser />} />
    <Route index element={<ListUsers />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
