import { useNavigate } from 'react-router-dom';
import { usePrescriptionContext } from '../../../../contexts/PrescriptionContext/PrescriptionContext';

/**
 * Custom hook that handles click events for favorite prescriptions and medications.
 * @hook
 * @param {Object} options - The options object.
 * @param {function} options.setCheckedKeys - The function to set the checked keys.
 * @param {string} options.category - The category of the prescription.
 * @param {object} options.form - The form object.
 * @param {function} options.setTreeKeys - The function to set the tree keys.
 * @returns {Object} - The object containing the click event handlers.
 */
export const useHandleClick = ({
  setCheckedKeys,
  category,
  form,
  setTreeKeys
}) => {
  const { setPosologies, setContent, setEntities, patientId } =
    usePrescriptionContext();
  const navigate = useNavigate();

  /**
   * Handles the click event for favorite prescriptions.
   * @function
   * @param {Object} options - The options object.
   * @param {Object} options.record - The record object.
   * @param {function} options.setSelectedKey - The function to set the selected key.
   * @param {function} options.isEditing - The function to check if the record is being edited.
   * @param {string} options.selectedKey - The currently selected key.
   * @param {function} options.setGlobalCheckedKeys - The function to set the global checked keys.
   * @return {void} - The function does not return anything.
   */
  const handleClickFavoritePrescription = ({
    record,
    setSelectedKey,
    isEditing,
    selectedKey,
    setGlobalCheckedKeys
  }) => {
    if (isEditing(record)) return;

    if (selectedKey === record._id) {
      setSelectedKey('');
      if (setGlobalCheckedKeys) setGlobalCheckedKeys([]);
      setCheckedKeys([]);
    } else if (category === 'GLOBAL') {
      setSelectedKey(record._id);
      setGlobalCheckedKeys(record.checked_keys);
      form.setFieldsValue(record.checked_keys);
    } else {
      setSelectedKey(record._id);
      setCheckedKeys(record.checked_keys);
      setTreeKeys(record.checked_keys[category]);
      form.setFieldValue(category, record.checked_keys[category]);
    }
  };

  /**
   * Handles the click event for favorite medications.
   * @function
   * @param {Object} options - The options object.
   * @param {Object} options.record - The record object.
   * @param {function} options.setSelectedKey - The function to set the selected key.
   * @param {function} options.isEditing - The function to check if the record is being edited.
   * @param {string} options.selectedKey - The currently selected key.
   * @return {void} - The function does not return anything.
   */
  const handleClickFavoriteMedication = ({
    record,
    setSelectedKey,
    isEditing,
    selectedKey
  }) => {
    if (isEditing(record)) return;

    if (selectedKey === record._id) {
      setSelectedKey('');
    } else {
      setSelectedKey(record._id);
      const { type, posologies } = record || {};
      setContent('');
      setPosologies(posologies);
      const entities = posologies?.map(({ entity }) => ({ ...entity }));
      setEntities(entities);
      navigate(`/prescription/create/medications/${type}/${patientId}`);
    }
  };

  return { handleClickFavoritePrescription, handleClickFavoriteMedication };
};
