import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import { Col, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * Renders the patient wording component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.patientName - The name of the patient.
 * @param {boolean} props.child - Indicates if the patient is a child.
 * @param {string} props.gender - The gender of the patient.
 * @param {string} props.convertedAge - The converted age of the patient.
 * @returns {JSX.Element} The rendered component.
 */
export const PatientWording = ({
  patientName,
  child,
  gender,
  convertedAge
}) => {
  const { t } = useTranslation();

  return (
    <Col span={12}>
      <Row align="middle" style={{ gap: 4 }}>
        <span className="kanban-patient-name">{patientName}</span>
        {child && <Tag color="yellow">{t('consultations.form.child')}</Tag>}
        {gender === 'WOMAN' ? (
          <WomanOutlined style={{ fontSize: 18 }} />
        ) : (
          <ManOutlined style={{ fontSize: 18 }} />
        )}
        <span className="kanban-patient-age">{convertedAge}</span>
      </Row>
    </Col>
  );
};

PatientWording.propTypes = {
  patientName: PropTypes.string.isRequired,
  child: PropTypes.bool.isRequired,
  gender: PropTypes.string.isRequired,
  convertedAge: PropTypes.string
};

PatientWording.defaultProps = {
  convertedAge: ''
};
