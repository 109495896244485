import { BaseModel } from './BaseModel';

/**
 * Represents a favorite medication.
 * @class
 * @extends BaseModel
 */
export class FavoriteMedication extends BaseModel {
  static modelName = 'favorite-medication-prescriptions';

  /**
   * Creates an instance of the FavoriteMedication model.
   * @constructor
   * @param {Object} data - The data for the FavoriteMedication.
   */
  constructor(data) {
    super('favorites-medications', undefined, data);
  }

  /**
   * Retrieves a favorite medication.
   * @static
   * @param {Object} params - The query parameters for the request.
   * @returns {Promise} A promise that resolves with the fetched favorite medication.
   */
  static getFavoriteMedication({ ...params } = {}) {
    return BaseModel.fetchAPI(
      'GET',
      `${FavoriteMedication.modelName}`,
      {},
      params
    );
  }

  /**
   * Patch a favorite medication by ID.
   * @static
   * @function
   *
   * @param {string} id - The ID of the favorite medication to patch.
   * @param {Object} [body={}] - The request body containing the updated data.
   * @param {Object} [params={}] - Additional parameters for the request.
   * @returns {Promise} A promise that resolves with the response from the API.
   */
  static patchFavoriteMedication(id, body = {}, { ...params } = {}) {
    return BaseModel.fetchAPI(
      'PATCH',
      `${FavoriteMedication.modelName}/${id}`,
      body,
      params
    );
  }

  /**
   * Deletes a favorite medication by ID.
   * @static
   * @function
   *
   * @param {string} id - The ID of the favorite medication to delete.
   * @param {Object} [params] - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the API response.
   */
  static deleteFavoriteMedication(id, { ...params } = {}) {
    return BaseModel.fetchAPI(
      'PATCH',
      `${FavoriteMedication.modelName}/${id}`,
      { archived: true },
      params
    );
  }
}
