import { useTranslation } from 'react-i18next';
import { message as antdMessage } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Custom hook for handling patient-related methods.
 *
 * @hook
 * @param {Object} datePicking - The date selected by the user.
 * @returns {Object} An object containing the `exitPatient` method.
 */
export const usePatientMethods = (datePicking) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  /**
   * Exit a patient by sending a PATCH request to the server.
   *
   * @function
   * @async
   * @param {string} id - The ID of the patient to exit. (ex: ObjectID)
   * @returns {Promise<void>} The promise object representing the exit patient method.
   */
  const exitPatient = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `consultations/exit/${id}`,
        body: {
          datePicking
        }
      });
      antdMessage.success(t('messages.patient_exit_successful'));
    } catch (error) {
      message(error);
    }
  };

  return { exitPatient };
};
