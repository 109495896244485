import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import { Flex, Col, Typography, Spin } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { Center } from '../../models/Center';
import { User } from '../../models/User';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { Header } from './Header';
import { CentersInformations } from './CentersInformations';
import { DoctorsInformations } from './DoctorsInformations';
import { PrescriptionsDate } from './PrescriptionsDate';
import { PatientsInformations } from './PatientsInformations';
import { FreeTextZone } from './FreeTextZone';
import { DoctorsSignature } from './DoctorsSignature';
import { Footer } from './Footer';

const { Text } = Typography;

const dateFormat = 'DD-MM-YYYY';
const styles = {
  container: {
    padding: 120,
    margin: '40px 80px',
    border: '1px solid black'
  },
  text: {
    textAlign: 'center'
  },
  reactQuill: {
    width: '100%',
    height: '100%'
  }
};

/**
 * PrescriptionForm
 * @component
 * @param {Object} props - Component properties.
 * @param {function} props.createBody - Function to create the body of the prescription.
 * @param {function} props.onSubmit - Function to handle form submission.
 * @param {Object} props.patientInfos - Patient information.
 * @param {string} props.content - Content of the prescription.
 * @param {function} props.setContent - Function to set the content of the prescription.
 * @param {string} props.label - Label of the prescription.
 * @param {function} props.setLabel - Function to set the label of the prescription.
 * @param {string} props.title - Title for the page header.
 * @param {boolean} props.showLabelInput - Flag to determine if the label input should be shown.
 * @param {string} props.prescriptionType - Type of the prescription. (STAY or EXIT)
 * @param {boolean} props.loading - Flag to determine if the form is loading.
 * @returns {React.Component} Prescription form.
 */
export const PrescriptionForm = ({
  createBody,
  onSubmit,
  patientInfos,
  content,
  setContent,
  label,
  setLabel,
  title,
  showLabelInput,
  prescriptionType,
  loading
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { message } = useErrorMessage();
  const [center, setCenter] = useState(null);
  const [doctors, setDoctors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { infos, headerTitle } = patientInfos;
  const doctor = infos?.current_consultation?.doctor;
  const translationKey = 'prescription.free_text.form';

  const getCenter = async () => {
    const data = await Center.getCenter(
      user?.selected_center || user?.default_center
    );
    setCenter(data);
  };

  const getDoctors = async () => {
    const data = await User.getDoctorsByCenter({
      center_id: user?.selected_center
    });
    setDoctors(data);
  };

  const postPrescription = async () => {
    const prescriptionBody = createBody();

    if (!prescriptionBody) {
      return null;
    }

    try {
      await onSubmit(prescriptionBody);
    } catch (error) {
      return message(error);
    }

    return null;
  };

  /**
   * Check if the content of the prescription is empty.
   *
   * @function
   * @returns {boolean} Returns true if the content is empty, otherwise returns false.
   * @description This function checks if the content of the prescription is empty.
   *              It removes all HTML tags from the content and checks if the length of the content is less than 1.
   *              If the content is empty and the label input is shown (based on the `showLabelInput` prop),
   *              it returns true only if both content and label are empty.
   *              Otherwise, it returns true if only the content is empty.
   *              This function is used to disable the submit button if the content is empty.
   *              It is also used to display a warning message if the content is empty.
   * @example
   * // Returns true
   * const emptyContent = isEmptyContent('<p></p>');
   * @example
   * // Returns false
   * const nonEmptyContent = isEmptyContent('<p>Some content</p>');
   */
  const isEmptyContent = () => {
    const formatContent = content.replace(/<[^>]*>/g, '').trim();
    if (showLabelInput) {
      return formatContent.length < 1 || label.length < 1;
    }
    return formatContent.length < 1;
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await Promise.all([getCenter(), getDoctors()]);
      setIsLoading(false);
    })();
  }, []);

  return (
    <ContentCustom>
      {isLoading ? (
        <Flex justify="center" align="center" style={{ height: '80vh' }}>
          <Col>
            <Spin />
            <Text style={{ fontSize: 20 }}>{t('loading')}</Text>
          </Col>
        </Flex>
      ) : (
        <>
          <Header
            showLabelInput={showLabelInput}
            headerTitle={headerTitle}
            title={title}
            translationKey={translationKey}
            setLabel={setLabel}
          />
          <div style={styles.container}>
            <Flex>
              <CentersInformations
                center={center}
                doctors={doctors}
                styles={styles}
                translationKey={translationKey}
              />
              <Col span={18}>
                <DoctorsInformations
                  showLabelInput={showLabelInput}
                  doctor={doctor}
                  translationKey={translationKey}
                />
                <PrescriptionsDate
                  showLabelInput={showLabelInput}
                  dateFormat={dateFormat}
                  infos={infos}
                  translationKey={translationKey}
                />
                <PatientsInformations
                  showLabelInput={showLabelInput}
                  infos={infos}
                  translationKey={translationKey}
                />
                <FreeTextZone
                  content={content}
                  setContent={setContent}
                  prescriptionType={prescriptionType}
                  styles={styles}
                  translationKey={translationKey}
                />
                <DoctorsSignature
                  showLabelInput={showLabelInput}
                  doctor={doctor}
                  translationKey={translationKey}
                />
              </Col>
            </Flex>
          </div>
          <Footer
            showLabelInput={showLabelInput}
            loading={loading}
            isEmptyContent={isEmptyContent}
            postPrescription={postPrescription}
          />
        </>
      )}
    </ContentCustom>
  );
};

PrescriptionForm.propTypes = {
  createBody: PropTypes.func,
  onSubmit: PropTypes.func,
  patientInfos: PropTypes.shape({
    infos: PropTypes.shape({
      current_consultation: PropTypes.shape({
        date: PropTypes.string,
        doctor: PropTypes.shape({
          _id: PropTypes.string,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          specialization: PropTypes.string,
          cpx_number: PropTypes.number,
          am_finess_number: PropTypes.number
        })
      }),
      patient: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        birth_date: PropTypes.string
      })
    }),
    headerTitle: PropTypes.string
  }),
  content: PropTypes.string,
  setContent: PropTypes.func,
  label: PropTypes.string,
  setLabel: PropTypes.func,
  title: PropTypes.string,
  showLabelInput: PropTypes.bool,
  prescriptionType: PropTypes.string,
  loading: PropTypes.bool
};

PrescriptionForm.defaultProps = {
  createBody: () => {},
  onSubmit: () => {},
  patientInfos: {
    infos: {
      current_consultation: {
        date: '',
        doctor: {
          _id: '',
          first_name: '',
          last_name: '',
          specialization: '',
          cpx_number: null,
          am_finess_number: null
        }
      },
      patient: {
        first_name: '',
        last_name: '',
        birth_date: ''
      }
    },
    headerTitle: ''
  },
  content: '',
  setContent: () => {},
  label: '',
  setLabel: () => {},
  title: '',
  showLabelInput: false,
  prescriptionType: '',
  loading: false
};
