import PropTypes from 'prop-types';
import { Form, Input, Select } from 'antd';

const { Option } = Select;
const { Group } = Input;
const { Item } = Form;

/**
 * PhoneNumberForm component
 *
 * @component
 * @param {object} props - The props of the PhoneNumberForm component
 * @param {string} props.dbKey - The dbKey of the PhoneNumberForm component (String, required)
 * @param {boolean} props.required - The required of the PhoneNumberForm component (Boolean, optional, default false)
 * @return {object} - The UI of the PhoneNumberForm component
 * @example
 * return <PhoneNumberForm dbKey="phone" required={true || false} />
 */
export const PhoneNumberForm = ({ dbKey, required }) => (
  <Group compact>
    <Item noStyle name={[`${dbKey}`, 'country_code']} initialValue="+33">
      <Select style={{ width: '25%' }}>
        <Option value="+33">+33</Option>
      </Select>
    </Item>
    <Item noStyle name={[`${dbKey}`, 'number']} rules={[{ required }]}>
      <Input style={{ width: '75%' }} maxLength={10} />
    </Item>
  </Group>
);

PhoneNumberForm.propTypes = {
  dbKey: PropTypes.string.isRequired,
  required: PropTypes.bool
};

PhoneNumberForm.defaultProps = {
  required: false
};
