import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Col, Button, Popover, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';
import { DrugsList } from '../prescription/MedicationPrescription/DrugsList';

// Synapse components
import { SynapseAutocompleteWidget } from '../../components/Synapse/SynapseAutoCompleteWidget';
import { SynapsePosologyCheckerWidget } from '../../components/Synapse/SynapsePosologyCheckerWidget';
import { SynapseAdverseEffectWidget } from '../../components/Synapse/SynapseAdverseEffectWidget';
import { SynapseInteractionWidget } from '../../components/Synapse/SynapseInteractionWidget';
import { SynapsePrescriptionSummaryWidget } from '../../components/Synapse/SynapsePrescriptionSummaryWidget';

/**
 * @component CreateUpdateFavoriteMedicationPrescription
 * @returns {React.Component} Page to create a favorite medication prescription
 */
export const CreateUpdateFavoriteMedicationPrescription = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { type } = useParams();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { posologies, entities } = usePrescriptionContext();

  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(null);
  const translationKey = 'prescription.favorites.form';

  const postPrescription = async () => {
    if (posologies.length > 0) {
      try {
        setLoading(true);

        const body = {
          purpose: 'MEDICATION',
          author: user._id,
          authorized: [user._id],
          type,
          label,
          posologies
        };

        await dispatchAPI('POST', {
          url: '/prescriptions/favorites',
          body
        });

        navigate(-1);
      } catch (error) {
        message(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ContentCustom>
      <PageHeaderCustom title={t(`${translationKey}.titles.${type}`)} />
      <div style={{ padding: '0 450px' }}>
        <Flex style={{ flexDirection: 'column', gap: 16 }}>
          <Input
            placeholder={t(`${translationKey}.label`)}
            onChange={(e) => setLabel(e.target.value)}
          />
          <Flex className="Synapse-Auto-complete-Widget" justify="start">
            <SynapseAutocompleteWidget />
          </Flex>
          <Flex
            className={entities.length > 0 && 'Drug-list-item'}
            justify="center"
          >
            <DrugsList />
          </Flex>
          <Flex
            className="Synapse-Prescription-Summary-Widget"
            justify="center"
            gutter={24}
          >
            <SynapsePrescriptionSummaryWidget />
          </Flex>
          <Flex
            className="Synapse-Posology-Checker-Widget"
            justify="center"
            gutter={24}
          >
            <Col span={24}>
              <SynapsePosologyCheckerWidget />
            </Col>
          </Flex>
          <Flex
            className="Synapse-Adverse-Effect-Widget"
            justify="center"
            gutter={24}
          >
            <SynapseAdverseEffectWidget />
          </Flex>
          <Flex
            className="Synapse-Interaction-Widget"
            justify="center"
            gutter={24}
          >
            <SynapseInteractionWidget />
          </Flex>
        </Flex>
        <Flex justify="end">
          <Popover
            content={t('warnings.message.empty_posology_or_label')}
            trigger={posologies.length < 1 || !label ? 'hover' : 'none'}
          >
            <Button
              loading={loading}
              disabled={posologies.length < 1 || !label || loading}
              type="primary"
              onClick={() => postPrescription()}
            >
              {t('buttons.validate')}
            </Button>
          </Popover>
        </Flex>
      </div>
    </ContentCustom>
  );
};
