import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';

const { Item } = Form;

export const SelectDiagnostic = ({ enums }) => {
  const { t } = useTranslation();

  const options = enums?.diagnostic?.map((item) => ({
    label: item.name,
    value: item.name
  }));

  return (
    <Item name={['patient', 'diagnostic']}>
      <Select
        mode="multiple"
        allowClear
        style={{
          width: '100%'
        }}
        placeholder={t('consultations.form.multiple_select_placeholder')}
        options={options}
      />
    </Item>
  );
};
SelectDiagnostic.propTypes = {
  enums: PropTypes.shape({
    diagnostic: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

SelectDiagnostic.defaultProps = {
  enums: { diagnostic: [] }
};
