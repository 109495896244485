import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Renders the extra buttons component.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Array} props.srnCareTreeKeys - The keys for the SRN care tree.
 * @param {Array} props.imagingTreeKeys - The keys for the imaging tree.
 * @param {Array} props.biologyTreeKeys - The keys for the biology tree.
 * @param {Array} props.globalCheckedKeys - The checked keys for the global tree.
 * @param {Function} props.postFavorite - The function to post a favorite.
 * @param {Function} props.handleValidateChoices - The function to handle validating choices.
 * @param {boolean} props.submiting - A flag indicating if the component is submitting.
 * @param {Function} props.setOpenModal - The function to set the open modal state.
 * @returns {JSX.Element} The rendered extra buttons component.
 */
export const ExtraButtons = ({
  srnCareTreeKeys,
  imagingTreeKeys,
  biologyTreeKeys,
  globalCheckedKeys,
  postFavorite,
  handleValidateChoices,
  submiting,
  setOpenModal
}) => {
  const { message } = useErrorMessage();
  const { user } = useAuthContext();
  const { t } = useTranslation();

  return (
    <Flex gap={8} justify="end">
      <Button onClick={() => postFavorite('GLOBAL')} loading={submiting}>
        {t('buttons.add_to_favorite')}
      </Button>
      <Button
        onClick={() => {
          if (
            !srnCareTreeKeys.length &&
            !imagingTreeKeys.length &&
            !biologyTreeKeys.length &&
            globalCheckedKeys.length === 0
          ) {
            return message('NO_CHOICES_SELECTED');
          }
          if (
            user?.position === 'DOCTOR' &&
            !imagingTreeKeys.some((item) => item.scanner)
          ) {
            return handleValidateChoices({
              doctor: user._id
            });
          }
          setOpenModal(true);
          return null;
        }}
      >
        {t('buttons.validate_prescription_choice')}
      </Button>
    </Flex>
  );
};

ExtraButtons.propTypes = {
  srnCareTreeKeys: PropTypes.shape([]),
  imagingTreeKeys: PropTypes.shape([]),
  biologyTreeKeys: PropTypes.shape([]),
  globalCheckedKeys: PropTypes.shape([]),
  postFavorite: PropTypes.func,
  handleValidateChoices: PropTypes.func,
  submiting: PropTypes.bool,
  setOpenModal: PropTypes.func
};

ExtraButtons.defaultProps = {
  srnCareTreeKeys: [],
  imagingTreeKeys: [],
  biologyTreeKeys: [],
  globalCheckedKeys: [],
  postFavorite: () => {},
  handleValidateChoices: () => {},
  submiting: false,
  setOpenModal: () => {}
};
