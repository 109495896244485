import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Col,
  Skeleton,
  Button,
  Popover,
  message as AntdMessage,
  Modal,
  Input,
  Row,
  Flex
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { DrugsList } from './DrugsList';
import { QuotesModal } from '../quotesModal/QuotesModal';

// Synapse components
import { SynapseAutocompleteWidget } from '../../../components/Synapse/SynapseAutoCompleteWidget';
import { SynapsePosologyCheckerWidget } from '../../../components/Synapse/SynapsePosologyCheckerWidget';
import { SynapseGroundsWidget } from '../../../components/Synapse/SynapseGroundsWidget';
import { SynapseAdverseEffectWidget } from '../../../components/Synapse/SynapseAdverseEffectWidget';
import { SynapseInteractionWidget } from '../../../components/Synapse/SynapseInteractionWidget';
import { SynapseStartStoppWidget } from '../../../components/Synapse/SynapseStartStoppWidget';
import { SynapsePrescriptionSummaryWidget } from '../../../components/Synapse/SynapsePrescriptionSummaryWidget';

/**
 * @component
 * @name CreateUpdateMedicationsPrescription
 * @description A component to create a medication prescription
 * @returns {JSX.Element} React component
 */
export const CreateUpdateMedicationsPrescription = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { printDocument } = useDownloadDocument();
  const { t } = useTranslation();
  const {
    setPatientId,
    patientId,
    patientInfos,
    posologies,
    isLoading,
    entities
  } = usePrescriptionContext();

  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const translationKey = 'prescription.favorites.form';

  const postPrescription = async (favorite) => {
    if (patientId && patientInfos && posologies.length > 0) {
      try {
        setLoading(true);

        let body;

        if (favorite) {
          body = {
            purpose: 'MEDICATION',
            author: user._id,
            authorized: [user._id],
            label,
            type,
            posologies
          };
        } else {
          body = {
            purpose: 'MEDICATION',
            doctor: user?._id,
            type,
            patient: patientId,
            consultation: patientInfos?.infos?.current_consultation,
            posologies
          };
        }

        const { data } = await dispatchAPI('POST', {
          url: `/prescriptions${favorite ? '/favorites' : ''}`,
          body
        });

        if (!favorite) {
          if (data && data._id) {
            setTimeout(async () => {
              await printDocument(data);
              AntdMessage.success(t('success.message.prescription.create'));
            }, 1000);
          } else {
            AntdMessage.error(t('warnings.message.bi-zones_not_supported'));
          }
          return navigate(`/consultations/show/${patientId}`);
        }

        return navigate(-1);
      } catch (error) {
        message(error);
      } finally {
        setLoading(false);
      }
    }

    return null;
  };

  useEffect(() => {
    setPatientId(id);
  }, [id]);

  return (
    <ContentCustom>
      <QuotesModal visible={loading} />
      <Skeleton loading={isLoading} active>
        {id && (
          <>
            <PageHeaderCustom
              title={patientInfos.headerTitle}
              extra={
                <Button
                  loading={loading}
                  disabled={posologies.length < 1 || loading}
                  type="primary"
                  onClick={() => setOpenModal(true)}
                >
                  {t('buttons.add_to_favorite')}
                </Button>
              }
            />
            <div style={{ padding: '0 450px' }}>
              <Row gutter={[0, 16]}>
                <Col span={24} className="Synapse-Auto-complete-Widget">
                  <SynapseAutocompleteWidget />
                </Col>
                <Col span={24} className="Synapse-Prescription-Summary-Widget">
                  <SynapsePrescriptionSummaryWidget />
                </Col>
                {entities.length > 0 && (
                  <Col span={24} className="Drug-list-item ">
                    <DrugsList />
                  </Col>
                )}
                <Col span={24} className="Synapse-Posology-Checker-Widget">
                  <SynapsePosologyCheckerWidget />
                </Col>
                <Col span={24} className="Synapse-Grounds-Widget">
                  <SynapseGroundsWidget />
                </Col>
                {patientInfos.patientAge >= 65 && (
                  <Col span={24} className="Synapse-Start-Stopp-Widget">
                    <SynapseStartStoppWidget />
                  </Col>
                )}
                <Col span={24} className="Synapse-Adverse-Effect-Widget">
                  <SynapseAdverseEffectWidget />
                </Col>
                <Col span={24} className="Synapse-Interaction-Widget">
                  <SynapseInteractionWidget />
                </Col>
                <Col span={24}>
                  <Flex justify="end">
                    <Popover
                      content={t('warnings.message.empty_posology')}
                      trigger={posologies.length < 1 ? 'hover' : 'none'}
                    >
                      <Button
                        loading={loading}
                        disabled={posologies.length < 1 || loading}
                        type="primary"
                        onClick={() => postPrescription(false)}
                      >
                        {t('buttons.validate')}
                      </Button>
                    </Popover>
                  </Flex>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Skeleton>
      <Modal open={openModal} onCancel={() => setOpenModal(false)}>
        <Input
          placeholder={t(`${translationKey}.label`)}
          onChange={(e) => setLabel(e.target.value)}
        />
        <Button onClick={() => postPrescription(true)}>
          {t('buttons.validate')}
        </Button>
      </Modal>
    </ContentCustom>
  );
};
