import { TreeSelect, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

/**
 * @component
 * @description A component that displays a tree select with all the visit reasons
 * @param {Object} treeData - The tree data to display
 * @returns {JSX.Element} A tree select with all the visit reasons
 */
export const ReasonsSelect = ({ treeData, form }) => {
  const { t } = useTranslation();

  const [values, setValues] = useState([]);
  const [formattedValues, setFormattedValues] = useState([]);

  useEffect(() => {
    if (values) {
      const newValue = values.map((item) => {
        const extractParentLabel = item.value.split(' ').slice(1).join(' ');
        return {
          label: `${extractParentLabel} ${item.label}`,
          value: item.value
        };
      });

      form.setFieldValue('visit_reasons', newValue);
      setFormattedValues(newValue);
    }
  }, [values, form]);

  return (
    <Form.Item name="visit_reasons" rules={[{ required: true }]}>
      <TreeSelect
        showSearch
        value={formattedValues}
        style={{
          width: '100%'
        }}
        dropdownStyle={{
          maxHeight: 400,
          overflow: 'auto'
        }}
        placeholder={t('consultations.form.visit_reasons_placeholder')}
        allowClear
        multiple
        treeDefaultExpandAll
        treeData={treeData}
        labelInValue
        onChange={(value) => setValues(value)}
      />
    </Form.Item>
  );
};

ReasonsSelect.propTypes = {
  treeData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired
  }).isRequired
};
