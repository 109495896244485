import { useTranslation } from 'react-i18next';
import { getVisitReasons } from '../../../../../utils/formatters';

/**
 * useReasonColumn
 *
 * @hook
 * @description A custom hook to return a column for reason
 * @returns {Object} - object containing title, key, dataIndex, sorter and render function
 */
export const useReasonColumn = () => {
  const { t } = useTranslation();

  return {
    title: t('consultations.reason'),
    key: 'consultation',
    dataIndex: 'consultation',
    sorter: (a, b) =>
      b.consultation.visit_reasons[0].reason_wording.length -
      a.consultation.visit_reasons[0].reason_wording.length,
    render: (consultation) =>
      consultation &&
      consultation.visit_reasons &&
      consultation.visit_reasons.length > 0 &&
      getVisitReasons(consultation.visit_reasons)
  };
};
