import PropTypes from 'prop-types';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

/**
 * Renders a toolbar component for logo preview with a delete button.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onDelete - The function to be called when the delete button is clicked.
 * @returns {JSX.Element} - The rendered component.
 */
export const LogoPreviewToolbar = ({ onDelete }) => (
  <Button type="link" onClick={onDelete}>
    <DeleteOutlined />
  </Button>
);

LogoPreviewToolbar.propTypes = {
  onDelete: PropTypes.func.isRequired
};
