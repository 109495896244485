import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateUpdateConsultation } from './CreateUpdateConsultation';
import { ShowConsultation } from './ShowConsultation';
import { ConsultationsContextProvider } from './ConsultationsContext';
import { Exception } from '../../components';

export const ConsultationsRouter = () => (
  <ConsultationsContextProvider>
    <Routes>
      <Route
        path="/create/:id"
        element={<CreateUpdateConsultation purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateConsultation purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowConsultation />} />
      <Route index element={<Exception />} />
    </Routes>
  </ConsultationsContextProvider>
);
