import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import { useConsultationsContext } from '../ConsultationsContext';

/**
 * HyperalgesticButton component. Renders a button to toggle the hyperalgesic status of the patient.
 * @component
 * @returns {JSX.Element} HyperalgesticButton component
 */
export const HyperalgesticButton = () => {
  const { t } = useTranslation();
  const { patient, updateHyperalgesic } = useConsultationsContext();
  const { current_consultation: { hyperalgesic } = {} } = patient || {};

  return (
    <Button type="link" onClick={() => updateHyperalgesic(!hyperalgesic)}>
      <Tooltip title={t('consultations.form.hyperalgesic_patient')}>
        <svg
          width="40"
          height="40"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20 0V20H0L20 0Z" fill={hyperalgesic ? '#EC3E40' : 'grey'} />
        </svg>
      </Tooltip>
    </Button>
  );
};
