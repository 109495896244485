import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import { useColumns } from './ColumnsStayHistoryModal';
import { SorterDateReason } from '../../Components/SorterDateReason';
import { useConsultationsContext } from '../../ConsultationsContext';
import { Datatable } from '../../../../components';
import { getFullName } from '../../../../utils/formatters';

// TODO : WIP
export const StayHistoryTable = () => {
  const resource = 'consultations';
  const [consulationsArray, setconsultationsArray] = useState([]);
  const [isSwitched, setIsSwitched] = useState(false);
  const columns = useColumns(isSwitched);
  const { patient } = useConsultationsContext();
  const { previous_consultations = [] } = patient || {};
  const { t } = useTranslation();

  useEffect(() => {
    setconsultationsArray(previous_consultations.slice().reverse());
  }, [patient]);

  const handleSwitchChange = (enabled) => {
    setIsSwitched(enabled);
  };

  return (
    <>
      <Row>
        {t(`${resource}.form.stay_history`)} {t(`${resource}.form.of`)}
        &nbsp;
        {getFullName(patient)}
      </Row>
      <SorterDateReason handleSwitchChange={handleSwitchChange} />
      <Datatable
        columns={columns}
        dataSource={consulationsArray}
        rowKey={(record) => record._id}
        showAction={false}
        editAction={false}
        archiveAction={false}
      />
    </>
  );
};
