import { useTranslation } from 'react-i18next';

/**
 * Custom hook to get the purpose of care based on the doctor and via.
 * @hook
 *
 * @param {Object} options - The options object.
 * @param {string} options.doctor - The name of the doctor.
 * @param {string} options.via - The method of care.
 * @returns {Object} - The purpose of care object.
 */
export const usePurposeOfCare = ({ doctor, via }) => {
  const { t } = useTranslation();

  const purposeOfCare = {
    FOLLOW_UP_CARE: t('patients.patients_cards.follow_up_doctor', { doctor }),
    VIA: t('patients.patients_cards.via', { via })
  };

  return { purposeOfCare };
};
