import dayjs from 'dayjs';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that returns an array of columns for the consultation rooms list.
 *
 * @hook
 * @returns {Array} Array of column objects
 */
export const useColumns = () => {
  const { t } = useTranslation();

  const getRandomHexColor = () => {
    const hex = Math.floor(Math.random() * 0xffffff).toString(16);
    return `#${'0'.repeat(6 - hex.length)}${hex}`;
  };

  const getWaitingTime = (date) => {
    const arrival = dayjs(date);
    const today = dayjs();
    const duration = dayjs.duration(today.diff(arrival));

    let timeString = '';
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (hours > 0) {
      timeString += `${hours} ${t('patients.form.hours')} `;
    }
    timeString += `${minutes} ${t('patients.form.minutes')}`;
    return timeString;
  };

  return [
    {
      key: 'hyperalgesic',
      dataIndex: ['current_consultation', 'hyperalgesic'],
      render: (hyperalgesic) => {
        if (hyperalgesic) {
          return (
            <Tag color="#f50" style={{ padding: '0 5px' }}>
              H
            </Tag>
          );
        }
        return null;
      }
    },
    {
      title: t('patients.form.index'),
      key: 'room',
      dataIndex: ['index'],
      sorter: true
    },
    {
      title: t('patients.form.presence_time'),
      key: 'presence_time',
      dataIndex: ['current_consultation', 'date'],
      sorter: true,
      render: (date) => getWaitingTime(date)
    },
    {
      key: 'initials',
      dataIndex: ['current_consultation', 'user_initials'],
      render: (initials) => {
        if (initials) {
          return (
            <Tag
              color={getRandomHexColor()}
              style={{
                borderRadius: '50%',
                width: '24px',
                height: '24px',
                padding: 0,
                lineHeight: '24px',
                textAlign: 'center'
              }}
            >
              {initials}
            </Tag>
          );
        }
        return null;
      }
    },
    {
      title: t('patients.form.visit_type'),
      key: 'type',
      dataIndex: ['current_consultation', 'visit_type'],
      sorter: true,
      render: (visit_type) => {
        switch (visit_type) {
          case 'FIRST_VISIT':
            return (
              <Tag color="blue" style={{ padding: '0 5px' }}>
                P
              </Tag>
            );
          case 'FOLLOW_UP_VISIT':
            return (
              <Tag color="purple" style={{ padding: '0 5px' }}>
                S
              </Tag>
            );
          case 'RETURN_VISIT':
            return (
              <Tag color="red" style={{ padding: '0 5px' }}>
                R
              </Tag>
            );
          default:
            return null;
        }
      }
    },
    {
      title: t('patients.form.last_name'),
      key: 'last_name',
      dataIndex: ['last_name'],
      sorter: true
    },
    {
      title: t('patients.form.first_name'),
      key: 'first_name',
      dataIndex: ['first_name'],
      sorter: true
    },
    {
      title: t('patients.form.visit_reasons'),
      key: 'visit_reasons',
      dataIndex: ['current_consultation', 'visit_reasons'],
      sorter: true
    }
  ];
};
