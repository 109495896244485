import { ListResource } from '../../../components';
import { CustomAction } from '../components/CustomAction';
import { useColumns } from './columns';

/**
 * Renders a list of surgical historic.
 *
 * @returns {JSX.Element} The ListSurgicalHistory component.
 */
export const ListSurgicalHistory = () => {
  const { columns } = useColumns();

  const resourceName = 'surgical-history';

  return (
    <>
      <CustomAction resource={resourceName} />
      <ListResource
        resourceName={resourceName}
        columns={columns}
        isPageHeaderCustom={false}
        isOriginalContentCustom={false}
        showAction={false}
        editAction={false}
        archiveAction
        withArchiveButton
      />
    </>
  );
};
