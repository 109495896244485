import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../utils/errorMessage';
import { Patient } from '../../models/Patient';
import { initialPatientState, patientReducer } from './reducer';
import { translateTreeData } from '../../utils/translateTreeData';
import { useAuthContext } from '../AuthContext';

export const PatientContext = createContext();

const axiosInstance = axios.create({
  baseURL: process.env.STELLAIR_API_URL,
  headers: { 'Content-Type': 'application/json' }
});

/**
 * Context provider for managing patient-related data and functionality.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The rendered component.
 */
export const PatientContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(patientReducer, initialPatientState);
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();

  const [treeData, setTreeData] = useState([]);

  const { message } = useErrorMessage();

  /**
   * Fetches the vitale card information for the authenticated patient.
   * @async
   * @function getVitaleCardInformations
   * @returns {Promise<void>} A Promise that resolves when the vitale card information is fetched.
   */
  const getVitaleCardInformations = async () => {
    try {
      const result = await axiosInstance({
        url: '/v1/patient/vitale',
        method: 'GET',
        responseType: 'json',
        cancelToken: undefined,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            'accessHealthcareToken'
          )}`
        }
      });

      dispatch({ type: 'SET_VITALE_CARD_INFORMATIONS', payload: result });
    } catch (error) {
      message(error.response.status);
    }
  };

  /**
   * Fetches the patient data for the specified patient ID.
   * @callback getPatient
   * @param {string} id - The ID of the patient.
   * @returns {Promise<void>} A Promise that resolves when the patient data is fetched.
   */
  const getPatient = async (id) => {
    try {
      const { data } = await Patient.getOneById(id, {
        populate:
          'doctors_letters.visit_reasons,documentsIds,previous_consultations,current_consultation,constants_survey,treatment_prescriptions.treatment_prescriptions_document,administrative_comments.written_by'
      });
      dispatch({ type: 'SET_PATIENT', payload: data });
      dispatch({
        type: 'SET_NO_HEALTH_INSURANCE_CARD',
        payload: data.no_health_mutual_code
      });
      dispatch({ type: 'SET_NO_ID_CARD', payload: data.no_indentity_card });
      dispatch({
        type: 'SET_DOCTORS_LETTER_FILES_LIST',
        payload: data.doctors_letters
      });
      dispatch({
        type: 'SET_TREATMENT_PRESCRIPTIONS',
        payload: data.treatment_prescriptions
      });
    } catch (e) {
      message(e);
    }
  };

  /**
   * Fetches the enums data for the patient context.
   * @async
   * @function getEnums
   * @returns {Promise<void>} A Promise that resolves when the enums data is fetched.
   */
  const getEnums = async () => {
    const data = await Patient.getEnums();
    dispatch({ type: 'SET_ENUMS', payload: data });
  };

  useEffect(() => {
    dispatch({ type: 'SET_NO_ID_CARD', payload: state.noIdCard });
    dispatch({
      type: 'SET_NO_HEALTH_INSURANCE_CARD',
      payload: state.noHealthInsuranceCard
    });
  }, [state.noIdCard, state.noHealthInsuranceCard]);

  useEffect(() => {
    dispatch({ type: 'SET_DATE_PICKING', payload: state.datePicking });
  }, [state.datePicking]);

  /**
   * Fetches tree data and translates it using the provided translation function.
   * Sets the translated tree data using the setTreeData function.
   * @returns {Promise<void>} A promise that resolves when the tree data is fetched and translated.
   */
  const fetchAndTranslateTreeData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/visit-reasons/tree-data`
      });

      const translatedTreeData = translateTreeData(
        data,
        'data_bases.visit-reasons.categories',
        t,
        1
      );

      setTreeData(translatedTreeData);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  /**
   * Deletes a comment for a patient.
   * @function
   * @async
   * @param {string} commentId - The ID of the comment to be deleted.
   * @returns {Promise<void>} - A promise that resolves when the comment is deleted.
   */
  const deleteComment = async (commentId) => {
    try {
      const { patient } = state || {};
      await dispatchAPI('DELETE', {
        url: `/patients/${patient?._id}/administrative-comments/${commentId}`
      });

      await getPatient(patient?._id);
    } catch (error) {
      message(error);
    }
  };

  /**
   * Checks a comment for a specific patient.
   * @function
   * @async
   * @param {string} commentId - The ID of the comment to check.
   * @returns {Promise<void>} - A promise that resolves when the comment is checked.
   */
  const checkComment = async (commentId) => {
    try {
      const { patient } = state || {};
      await dispatchAPI('PATCH', {
        url: `/patients/${patient?._id}/administrative-comments/${commentId}`
      });

      await getPatient(patient?._id);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([getEnums(), fetchAndTranslateTreeData()]);
    })();
  }, []);

  return (
    <PatientContext.Provider
      value={{
        ...state,
        setVitaleCardInformations: (value) =>
          dispatch({ type: 'SET_VITALE_CARD_INFORMATIONS', payload: value }),
        setEnums: (value) => dispatch({ type: 'SET_ENUMS', payload: value }),
        setNoIdCard: (value) =>
          dispatch({ type: 'SET_NO_ID_CARD', payload: value }),
        setNoHealthInsuranceCard: (value) =>
          dispatch({ type: 'SET_NO_HEALTH_INSURANCE_CARD', payload: value }),
        setForceRefresh: (value) =>
          dispatch({ type: 'SET_FORCE_REFRESH', payload: value }),
        setDatePicking: (value) =>
          dispatch({ type: 'SET_DATE_PICKING', payload: value }),
        setDoctorsLetterFilesList: (value) =>
          dispatch({ type: 'SET_DOCTORS_LETTER_FILES_LIST', payload: value }),
        setTreatmentPrescriptions: (value) =>
          dispatch({ type: 'SET_TREATMENT_PRESCRIPTIONS', payload: value }),
        setPatient: (value) =>
          dispatch({ type: 'SET_PATIENT', payload: value }),
        getPatient,
        getVitaleCardInformations,
        treeData,
        deleteComment,
        checkComment
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export const usePatientContext = () => useContext(PatientContext);
