import PropTypes from 'prop-types';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';

const resource = 'patients';

export const CollapseAmoAmcField = ({ fields }) => {
  const generateFields = useGenerateFormItem();

  return (
    <>
      {fields.map((field) =>
        field.label === 'social_security_number' ||
        field.label === 'general_practitioner'
          ? generateFields(resource, field)
          : null
      )}
    </>
  );
};

CollapseAmoAmcField.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
