import { useEffect, useState } from 'react';
import { Row, Collapse, Form, Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useConsultationsContext } from '../../../ConsultationsContext';
import { CardsDisplay } from '../../../../patients/components/CardsHistory/CardsDisplay';
import { useDoctorsLettersColumns } from '../columns/columnsDoctorsLetters.jsx';
import { useColumnsDateReasons } from '../columns/columnsDateReasons';
import useFields from '../../../fields';
import { useFields as useDoctorsLettersFields } from '../../../../patients/components/DoctorsLetter/fields';
import { useColumns } from '../../../../patients/components/TreatmentPrescriptions/columns';
import { getFullName } from '../../../../../utils/formatters';
import { useGenerateFormItem } from '../../../../../utils/generateFormItem';
import { useModalAdministrationCollapseItems } from '../utils/modalAdministrationCollapseItems';

/**
 * A component that displays a modal for administration.
 *
 * @returns {JSX.Element} The ModalAdministration component.
 */
export const ModalAdministaration = () => {
  const [isSwitched, setIsSwitched] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [submittable, setSubmittable] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { patient, treeData, getPatient } = useConsultationsContext();
  const { t } = useTranslation();
  const { identityCardFields, insuranceCardFields } = useFields({});
  const [form] = Form.useForm();
  const doctorsLettersColumns = useDoctorsLettersColumns(isSwitched);
  const { treatmentPrescriptionsColumns } = useColumns({
    fromConsultation: true
  });
  const columnsDateReasons = useColumnsDateReasons();
  const { doctorsLetterFields } = useDoctorsLettersFields({
    filesList,
    setFilesList,
    submittable,
    form,
    setLoading: () => {},
    treeData,
    getDataToRefresh: getPatient,
    setOpenModal
  });
  const generateFormItems = useGenerateFormItem();

  const resource = 'consultations';

  const handleSwitchChange = (enabled) => {
    setIsSwitched(enabled);
  };

  const { modalAdministrationCollapseItems } =
    useModalAdministrationCollapseItems({
      resource,
      setOpenModal,
      doctorsLettersColumns,
      patient,
      handleSwitchChange,
      treatmentPrescriptionsColumns,
      columnsDateReasons
    });

  const values = Form.useWatch([], form);
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true
      })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <>
      <Row>
        <h2 style={{ color: 'var(--primaryColor)', fontWeight: 'bold' }}>
          {t(`${resource}.documents.administration`)}&nbsp;
          {getFullName(patient)}
        </h2>
      </Row>
      <Form form={form}>
        <Row align-item="stretch" justify-content="space-betweenx">
          <CardsDisplay
            fields={identityCardFields}
            resource={resource}
            translationKey="identity_card"
          />
          <CardsDisplay
            fields={insuranceCardFields}
            resource={resource}
            translationKey="health_mutual_card"
          />
        </Row>
      </Form>
      <Collapse
        expandIconPosition="end"
        items={modalAdministrationCollapseItems}
      />
      <Modal
        open={openModal}
        footer={
          <Button onClick={() => setOpenModal(false)}>
            {t('buttons.cancel')}
          </Button>
        }
        onCancel={() => setOpenModal(false)}
      >
        <Form form={form}>
          {doctorsLetterFields.map((field) =>
            generateFormItems('consultations', field)
          )}
        </Form>
      </Modal>

      {/* En attente du client */}
      {/* <Collapse expandIconPosition="end">
        <Collapse.Panel
          header={t(`${resource}.documents.administrative_documents`)}
          key="4"
        >
          <Table columns={columnsDateReasons} />
        </Collapse.Panel>
      </Collapse> */}
    </>
  );
};
