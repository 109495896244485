import PropTypes from 'prop-types';
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Image, Spin, Row, Col } from 'antd';
import { PictureFilled } from '@ant-design/icons';
import { ConsultationsContext } from '../../../ConsultationsContext';

/**
 * A component that displays a compare button.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.consultationsWithPhotos - An array of consultations with photos.
 * @param {Object} props.record - The record to be compared.
 * @param {Array} props.record.diagnostic - The diagnostic of the record.
 *
 * @returns {JSX.Element} The CompareButton component.
 */
export const CompareButton = ({ consultationsWithPhotos, record }) => {
  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useContext(ConsultationsContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredConsultations, setFilteredConsultations] = useState([]);

  const handleCompare = () => {
    setIsLoading(true);
    setIsModalOpen(true);
    const diagnosticSearch = record.diagnostic.join(', ');

    const filtered = consultationsWithPhotos
      .filter((consultation) => {
        const consultationDiagnostic = consultation.diagnostic.join(', ');
        return consultationDiagnostic === diagnosticSearch;
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    setFilteredConsultations(filtered);
    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <Button
        style={{ borderRadius: '15px' }}
        type="primary"
        onClick={handleCompare}
        icon={<PictureFilled />}
      >
        {t('buttons.compare')}
      </Button>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        okText={t('buttons.validate')}
        width="100%"
        footer={null}
      >
        {filteredConsultations?.map((consultation) =>
          consultation.photo.map((photo) => (
            <Row key={photo._id} style={{ marginBottom: '2em' }}>
              <Col span={24}>
                <Row justify="center">
                  <Image src={photo.metadata.type} />
                </Row>
                <Row justify="center">
                  {t('consultations.date')}
                  {': '}
                  {new Date(consultation.date).toLocaleDateString('fr-FR')}
                </Row>
              </Col>
            </Row>
          ))
        )}
      </Modal>
    </Spin>
  );
};

CompareButton.propTypes = {
  consultationsWithPhotos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  record: PropTypes.shape({
    diagnostic: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};
