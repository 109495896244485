/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Upload, Collapse, Flex, Image, Spin, message } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { LogoPreviewToolbar } from './LogoPreviewToolbar';

const { Panel } = Collapse;
const { Dragger } = Upload;

/**
 * Logo component.
 * Renders a form for uploading a logo.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.logo - The logo image.
 * @param {function} props.setRefresh - Function to refresh the component.
 * @returns {JSX.Element} The Logo component
 */
export const Logo = ({ logo, setRefresh }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const [loading, setLoading] = useState(false);

  const handleSaveLogo = async (file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('logo', file);

      await dispatchAPI('PATCH', {
        url: `/centers/${id}`,
        body: formData
      });

      message.success(t('success.message.center.logo_updated_successfully'));
      setRefresh((prevState) => !prevState);
    } catch (error) {
      errorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteLogo = async () => {
    try {
      setLoading(true);
      await dispatchAPI('DELETE', {
        url: `/centers/delete-logo/${id}`
      });
      message.success(t('success.message.center.logo_deleted_successfully'));
      setRefresh((prevState) => !prevState);
    } catch (error) {
      errorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const draggerProps = {
    onRemove: false,
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        await handleSaveLogo(file);
        return false;
      }
      message.warning(t('errors.message.not_png_jpg_pdf_file'));
      return true;
    }
  };

  return (
    <Card style={{ marginBottom: 24 }}>
      <Collapse expandIconPosition="end" defaultActiveKey={['4']} ghost>
        <Panel
          header={
            <Flex gap={8}>
              <span>{t('centers.settings.logo')} - </span>
              <em>{t('centers.settings.logo_warning')}</em>
            </Flex>
          }
          key="4"
        >
          <Flex justify="center">
            {loading ? (
              <Spin />
            ) : logo ? (
              <Flex vertical>
                <Image
                  src={logo}
                  alt="logo"
                  preview={{
                    toolbarRender: () => (
                      <LogoPreviewToolbar onDelete={handleDeleteLogo} />
                    )
                  }}
                  style={{
                    maxWidth: '200px'
                  }}
                />
              </Flex>
            ) : (
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <CameraOutlined style={{ color: 'var(--textColor)' }} />
                </p>
                <p className="ant-upload-text">{t('files.create.action')}</p>
              </Dragger>
            )}
          </Flex>
        </Panel>
      </Collapse>
    </Card>
  );
};

Logo.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFileList: PropTypes.func.isRequired,
  logo: PropTypes.string,
  setRefresh: PropTypes.func.isRequired
};

Logo.defaultProps = {
  logo: null
};
