import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Row } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { ListDropDown } from './ListDropdown';
import { PrintButton } from '../../../consultations/PatientOverview/Documents/components/PrintButton';
import { useFields } from '../../../consultations/PatientOverview/Documents/fields';
import { usePatientContext } from '../../../../contexts/PatientContext/PatientContext';

const iconSize = 18;

/**
 * Custom hook that returns an array of action columns for the ListPatients component.
 * Each action column represents an action that can be performed on a patient record.
 *
 * @hook
 * @returns {Array} An array of action columns.
 */
export const useActionColumns = ({ setForceRefresh }) => {
  const { datePicking } = usePatientContext();
  const { t } = useTranslation();
  const { printFields, documentType, documentWording } = useFields();

  return [
    {
      title: t('patients.form.actions'),
      key: 'action',
      align: 'right',
      render: (record) => (
        <Row align="middle" style={{ flexWrap: 'nowrap' }}>
          <Link to={`/consultations/show/${record?.patient._id}`}>
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" />
          <Link
            to={`/patients/edit/${record?.patient._id}`}
            state={{ create_consultation: false }}
          >
            <EditOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" style={{ fontSize: iconSize }} />
          <PrintButton
            fields={printFields}
            documentType={documentType}
            documentWording={documentWording}
            patientId={record.patient._id}
            datePicking={datePicking}
          />
          <Divider type="vertical" />
          <ListDropDown record={record} setForceRefresh={setForceRefresh} />
        </Row>
      )
    }
  ];
};
