import { mdiAlert, mdiAlertRemove } from '@mdi/js';
import Icon from '@mdi/react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

/**
 * Renders an icon representing allergies.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.grounds - The grounds object containing allergy information.
 * @returns {JSX.Element} The rendered component.
 */
export const Allergies = ({ grounds, noAllergies }) => {
  const classNameIcon = () => {
    if (grounds?.allergies?.length > 0) {
      return 'uncrossed';
    }
    if (grounds?.allergies?.length <= 0 && noAllergies) {
      return 'crossed';
    }

    return '';
  };

  return (
    <Tooltip
      title={grounds?.allergies?.map((allergy) => allergy.label).join(', ')}
    >
      {!(grounds?.allergies?.length <= 0 && !noAllergies) && (
        <Icon
          path={classNameIcon() === 'crossed' ? mdiAlertRemove : mdiAlert}
          color={classNameIcon() === 'uncrossed' ? 'var(--errorColor)' : 'grey'}
          size="18px"
        />
      )}
    </Tooltip>
  );
};

Allergies.propTypes = {
  grounds: PropTypes.shape({
    allergies: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  noAllergies: PropTypes.bool.isRequired
};

Allergies.defaultProps = {
  grounds: {
    allergies: []
  }
};
