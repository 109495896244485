/**
 * Translate tree data
 * @function translateTreeData
 * @param {Array} data - The data to be translated
 * @param {String} translationKey - The translation key
 * @param {Function} t - The translation function
 * @param {Number} level - The level of the tree
 * @returns {Array} The translated data
 */
export const translateTreeData = (data, translationKey, t, level) =>
  data.map((element) => ({
    ...element,
    title: t(`${translationKey}.${element.title}`),
    children:
      element.children && level === 0
        ? translateTreeData(
            element.children,
            `data_bases.stay_prescriptions.form.prescription_types.${element.title}`,
            t,
            level + 1
          )
        : element.children
  }));
