import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Switch, Input, Button, Space, Select } from 'antd';
import {
  PlusOutlined,
  CloseOutlined,
  DownOutlined,
  UpOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { GetEnums } from '../../../../utils/getEnums';

export const useFields = () => {
  const { t } = useTranslation();
  const { getEnums } = GetEnums();
  const [options, setOptions] = useState([]);

  const getCategories = async () => {
    const data = await getEnums('visit-reasons');

    const categories = data?.categories.map((category) => ({
      label: t(`data_bases.visit-reasons.categories.${category}`),
      value: category
    }));

    setOptions(categories);
  };

  useEffect(() => {
    (async () => {
      await getCategories();
    })();
  }, []);

  const mainFields = [
    {
      name: 'reason_wording',
      label: 'reason_wording',
      rules: [
        {
          required: true
        }
      ]
    },
    {
      name: ['category'],
      label: 'category',
      rules: [
        {
          required: true
        }
      ],
      input: <Select options={options} />
    }
  ];

  /**
   * Array of extra fields configuration for visit reasons form.
   *
   * @type {object[]}
   */
  const arrayOfNameFields = [
    'lower_upper',
    'left_right_bilateral',
    'number_per_day',
    'duration'
  ];

  /**
   * Extra form fields configuration for visit reasons form.
   *
   * @type {object[]}
   */
  const extraFields = arrayOfNameFields.map((name) => ({
    name: ['extra_fields', name],
    label: name,
    input: <Switch />,
    valuePropName: 'checked'
  }));

  /**
   * Sub-options form fields configuration for visit reasons form.
   *
   * @returns {object[]} Array of sub-options form fields.
   */
  const subOptionsFields = {
    noLabel: true,
    input: (
      <Form.List name={['sub_options']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name, key }) => (
              <Input.Group compact key={key}>
                <Form.Item name={[name, 'label']} rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Space.Compact block>
                    <Button icon={<CheckOutlined />} />
                    <Button icon={<UpOutlined />} />
                    <Button icon={<DownOutlined />} />
                    <Button
                      onClick={() => remove(key)}
                      icon={<CloseOutlined />}
                    />
                  </Space.Compact>
                </Form.Item>
              </Input.Group>
            ))}
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              {t('data_bases.visit-reasons.form.add_sub_option')}
            </Button>
          </>
        )}
      </Form.List>
    )
  };

  return { mainFields, extraFields, subOptionsFields };
};
