import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { MedicalBackgroundForm } from './MedicalBackgroundForm';
import { useMedicalBackgroundFields } from '../../fields/medicalBackgroundFields';
import { getFullName } from '../../../../utils/formatters';

/**
 * Renders a component for displaying the medical background of a patient.
 *
 * @component
 * @param {string} id - The ID of the patient.
 * @param {object} patient - The patient object.
 * @returns {JSX.Element} The rendered component.
 */
export const TitleMedicalBackground = ({ id, patient }) => {
  const resource = 'consultations';
  const [isModal, setModal] = useState(false);
  const { fields } = useMedicalBackgroundFields(patient);
  const { t } = useTranslation();

  const handleClick = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <Row justify="space-between">
      <h1>{t(`${resource}.form.medical_history_breakdown`)}</h1>
      <Button type="Link" icon={<EditOutlined />} onClick={handleClick} />
      <Modal
        title={t(`${resource}.form.medical_history`, {
          name: getFullName(patient)
        })}
        open={isModal}
        onCancel={handleCloseModal}
        width="700px"
        footer={null}
      >
        <MedicalBackgroundForm
          fields={fields}
          id={id}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </Row>
  );
};

TitleMedicalBackground.propTypes = {
  id: PropTypes.string.isRequired,
  patient: PropTypes.shape({}).isRequired
};
