import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Upload, Form, Button, message, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Item } = Form;

/**
 * CardUpload component for uploading and displaying card images.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.dbKey - The database key for the card image.
 * @param {string} props.antdKey - The Ant Design key for the card image.
 * @param {Object} props.form - The form object from Ant Design.
 * @returns {JSX.Element} The CardUpload component.
 */
export const CardUpload = ({ dbKey, antdKey, form }) => {
  const itemName = antdKey !== '' ? [`${antdKey}`, `${dbKey}`] : `${dbKey}`;
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    fileList?.map(
      (file) =>
        file.type === dbKey.toUpperCase() &&
        form.setFieldValue(['card', `${dbKey}_picture`], file.base)
    );
  }, [fileList]);

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      form.resetFields([['card', `${dbKey}_picture`]]);
    },
    onPreview: async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['png', 'jpg', 'jpeg', 'bmp', 'heic', 'webp'];
      if (allowedExtensions.includes(fileExtension)) {
        const base = await fileToBase64(file);

        setFileList([
          ...fileList,
          {
            type: dbKey.toUpperCase(),
            name: file.name,
            filename: file.name,
            base
          }
        ]);
        return false;
      }
      message.warning(t('errors.message.not_an_allowed_file_format'));
      return true;
    },
    fileList
  };

  return (
    <>
      <Item name={itemName}>
        <Upload {...UploadProps}>
          <Button
            id="save-button"
            type="primary"
            style={{
              borderRadius: '15px'
            }}
            icon={<UploadOutlined />}
          >
            {t(`buttons.save_photo`)}
          </Button>
        </Upload>
      </Item>
      <Item
        label={t('consultations.form.photo_comment')}
        name={[dbKey, 'comment']}
      >
        <Input />
      </Item>
    </>
  );
};
CardUpload.propTypes = {
  dbKey: PropTypes.string.isRequired,
  antdKey: PropTypes.string,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    resetFields: PropTypes.func,
    getFieldsValue: PropTypes.func
  })
};
CardUpload.defaultProps = {
  antdKey: '',
  form: {
    setFieldValue: () => {},
    resetFields: () => {},
    getFieldsValue: () => {}
  }
};
