import { Protocol } from '../../../models/Protocol';

/**
 * Custom hook for posting favorites.
 * @hook
 *
 * @param {Object} options - The options for the hook.
 * @param {Object} options.form - The form object.
 * @param {Function} options.setRefresh - The function to set the refresh state.
 * @param {boolean} options.refresh - The refresh state.
 * @returns {Object} - The postFavorite function.
 */
export const usePostFavorite = ({ form, setRefresh, refresh }) => {
  /**
   * Posts a favorite to the server.
   *
   * @param {string} category - The category of the favorite.
   * @returns {Promise<void>} - A promise that resolves when the favorite is posted.
   */
  const postFavorite = async (category) => {
    let body;
    let categoryData;
    let categoryFormat;

    if (category !== 'SRN_CARE') {
      categoryData = form.getFieldValue(category);
      categoryFormat = category;
    } else {
      if (form.getFieldValue('SRN_CARE_WITH_COTATIONS')) {
        categoryData = form.getFieldValue('SRN_CARE_WITH_COTATIONS');
        categoryFormat = 'SRN_CARE_WITH_COTATIONS';
      }
      if (form.getFieldValue('SRN_CARE_WITHOUT_COTATIONS')) {
        categoryData = form.getFieldValue('SRN_CARE_WITHOUT_COTATIONS');
        categoryFormat = 'SRN_CARE_WITHOUT_COTATIONS';
      }
    }

    if (category === 'GLOBAL') {
      body = form.getFieldsValue();
    } else if (category === 'SRN_CARE') {
      const srnWithout = form.getFieldValue('SRN_CARE_WITHOUT_COTATIONS');
      const srnWith = form.getFieldValue('SRN_CARE_WITH_COTATIONS');
      body = {
        SRN_CARE_WITHOUT_COTATIONS: srnWithout || undefined,
        SRN_CARE_WITH_COTATIONS: srnWith || undefined
      };
    } else {
      body = { [categoryFormat]: categoryData };
    }

    await Protocol.createFavorite(category, body);

    setRefresh(!refresh);
  };

  return { postFavorite };
};
