import { useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { SecretaryUserRouter } from './SecretaryUserRouter';
import { UserRouter } from './UserRouter';
import { DoctorUserRouter } from './DoctorUserRouter';
import { SuperUserRouter } from './SuperUserRouter';
import { DevRouter } from './DeveloperRouter';
import { AdminRouter } from './AdminRouter';
import { useThemeContext } from '../contexts/ThemeContext';

export const RouterDispatch = () => {
  const { user } = useAuthContext();
  const { setTheme } = useThemeContext();

  useEffect(() => {
    const body = document.body;
    if (body && !user) {
      body.className = 'user';
      setTheme('user');
    }
  }, [user]);

  const dispatchRouter = () => {
    let rolePosition;
    if (user?.position === undefined || user?.position === null) {
      rolePosition = `${user?.role}`;
    } else if (
      user?.role === 'admins:DEV' ||
      user?.role === 'admins:SUPER-ADMIN'
    ) {
      rolePosition = user?.role;
    } else {
      rolePosition = `${user?.role}:${user?.position}`;
    }

    switch (rolePosition) {
      case 'admins:DEV':
        return <DevRouter />;
      case 'admins:SUPER-ADMIN':
        return <AdminRouter />;
      case 'users:SUPER-USER:DOCTOR':
        return <SuperUserRouter />;
      case 'users:USER:DOCTOR':
      case 'users:USER:NURSE':
        return <DoctorUserRouter />;
      case 'users:USER:SECRETARY':
        return <SecretaryUserRouter />;
      default:
        return <UserRouter />;
    }
  };
  return dispatchRouter();
};
