/**
 * Custom hook that returns an array of fields for a protocol.
 * Each field object contains a name, label, and rules.
 * @hook
 * @returns {Array} An array of field objects.
 */
export const useFields = () => [
  {
    name: 'protocol_wording',
    label: 'protocol_wording',
    rules: [{ required: true }]
  }
];
