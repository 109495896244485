import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ConsultationsContext } from '../../../ConsultationsContext';

/**
 * @hook
 * Hook to set options for the consultations dropdown.
 *
 * @param {Object} params - The parameters passed to the hook.
 * @param {Function} params.setSelectedDate - Function to set the selected date.
 * @param {Function} params.handleDateChange - Function to handle the date change.
 *
 * @returns {Array} An array containing the options for the consultations dropdown.
 */
export const useSetOptions = ({ setSelectedDate, handleDateChange }) => {
  const [options, setOptions] = useState();

  const { patient = {} } = useContext(ConsultationsContext);

  useEffect(() => {
    const consultations =
      patient?.consultations?.filter((c) => c && c._id && c.date) || [];

    const newOptions = consultations
      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
      .map((date) => ({
        value: date._id,
        label: dayjs(date.date).format('DD-MM-YYYY')
      }));

    setOptions(newOptions || []);
    if (newOptions?.length > 0) {
      setSelectedDate(newOptions[0].value);
      handleDateChange(newOptions[0].value);
    }
  }, [patient]);

  return {
    options
  };
};
