import { useState } from 'react';
import { Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';

const iconSize = 20;

/**
 * @hook useDownloadColumn
 * @description A custom hook to return a column for downloading documents
 * @returns {Object} - object containing render function
 */
export const useDownloadColumn = () => {
  const { viewDocument } = useDownloadDocument();
  const [loading, setLoading] = useState(false);

  const downloadDocument = async (record) => {
    setLoading(true);
    if (record && record.prescription_document) {
      await viewDocument({ _id: record.prescription_document });
    }
    setLoading(false);
  };

  return {
    render: (record) => (
      <Button
        loading={loading}
        disabled={loading}
        type="link"
        onClick={() => downloadDocument(record, setLoading)}
      >
        <EyeOutlined style={{ fontSize: iconSize }} />
      </Button>
    )
  };
};
