import { Card, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { renderTitle } from '../../utils/renderTitle';

const { TextArea } = Input;

/**
 * CardComment component.
 * @component
 *
 * @returns {JSX.Element} The CardComment component.
 */
export const CardComment = () => {
  const { t } = useTranslation();

  return (
    <Card
      title={renderTitle(t('prescription.radiology.form.comment'))}
      style={{ flex: '1 1 auto', height: '50%' }}
    >
      <Form.Item name="comment" style={{ height: '100%' }}>
        <TextArea
          rows={4}
          style={{ backgroundColor: '#F2F2F2', height: '100%' }}
        />
      </Form.Item>
    </Card>
  );
};
