import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Button, Col, Card, Flex } from 'antd';
import { PreviousComments } from '../../../../../../components/PreviousComments/PreviousComments';
import { useGenerateFormItem } from '../../../../../../utils/generateFormItem';
import { commentFields } from '../../../../fields/commentFields';
import { Patient } from '../../../../../../models/Patient';

/**
 * Component for adding administrative comments to a patient record.
 *
 * @component
 * @param {Object} record - The patient record.
 * @param {Function} setOpenModal - Function to open or close the modal.
 * @param {Array} comments - The comments to display.
 * @param {Function} setForceRefresh - Function to force a refresh of the patient record.
 * @returns {JSX.Element} The AddAdministrativeComments component.
 */
export const AddAdministrativeComments = ({
  record,
  setOpenModal,
  comments,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();

  const resource = 'patients';

  const handleSubmit = async (values) => {
    await Patient.patchPatient(record._id, values);
    setForceRefresh((prev) => !prev);
    setOpenModal(false);
  };

  return (
    <Col>
      {comments
        ? comments.map((data) => (
            <PreviousComments
              key={data.description + data.written_by}
              written_by={data.written_by}
              date={data.date}
              comment={data}
              withoutAction
            />
          ))
        : null}
      <Card>
        <Form form={form} onFinish={(values) => handleSubmit(values)}>
          {commentFields.map((field) => generateFields(resource, field))}
          <Flex justify="end">
            <Button htmlType="submit">{t('buttons.save')}</Button>
          </Flex>
        </Form>
      </Card>
    </Col>
  );
};

AddAdministrativeComments.propTypes = {
  record: PropTypes.shape({
    _id: PropTypes.string
  }),
  setOpenModal: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  setForceRefresh: PropTypes.func.isRequired
};
AddAdministrativeComments.defaultProps = {
  record: {
    _id: null
  },
  comments: []
};
