import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Radio } from 'antd';
import { GetEnums } from '../../utils/getEnums';

/**
 * Custom hook that returns an array of fields for urine analysis form.
 * @hook
 * @param {object} form - The form instance object.
 * @returns {Array} An array of fields for urine analysis form.
 */
const usefields = (form) => {
  const { t } = useTranslation();
  const { getEnums } = GetEnums();
  const [enums, setEnums] = useState([]);

  const onChange = () => {
    form.setFieldsValue({
      negative_urine_test_strip: null
    });
  };

  const UrinaryRadioButtons = (
    <Radio.Group buttonStyle="solid">
      {enums?.urine_analysis?.map((urine_analysis) => (
        <Radio.Button
          onChange={onChange}
          key={urine_analysis}
          value={urine_analysis}
        >
          {urine_analysis}
        </Radio.Button>
      ))}
    </Radio.Group>
  );

  const RadioButtons = (
    <Radio.Group buttonStyle="solid">
      {enums?.binary_results?.map((binary_result) => (
        <Radio.Button
          onChange={onChange}
          key={binary_result}
          value={binary_result}
        >
          {t(`constants_surveys.form.${binary_result}`)}
        </Radio.Button>
      ))}
    </Radio.Group>
  );

  useEffect(() => {
    (async () => {
      const data = await getEnums('constants');
      setEnums(data);
    })();
  }, []);

  const constantsAndOtherSurveyFields = [
    {
      name: 'PAS_PAD',
      label: 'PAS_PAD',
      input: <Input suffix="mmHg" />
    },
    {
      name: 'temperature',
      label: 'temperature',

      input: <Input suffix="°C" />
    },
    {
      name: 'heart_rate',
      label: 'heart_rate',

      input: <Input suffix="bpm" />
    },
    {
      name: 'OS',
      label: 'OS',
      input: <Input suffix="%" />
    },
    {
      name: 'respiratory_rate',
      label: 'respiratory_rate',

      input: <Input suffix="c/min" />
    },
    {
      name: 'oxygen_therapy',
      label: 'oxygen_therapy',

      input: <Input suffix="L/min" />
    },
    {
      name: 'peakflow',
      label: 'peakflow',
      input: <Input suffix="L/min" />
    },
    {
      name: 'height',
      label: 'height',

      input: <Input suffix="cm" />
    },
    {
      name: 'weight',
      label: 'weight',

      input: <Input suffix="kg" />
    },
    {
      name: 'capillary_blood_glucose',
      label: 'capillary_blood_glucose',
      input: <Input suffix="g/L ou mmol/L" />
    },
    {
      name: 'hemocue',
      label: 'hemocue',
      input: <Input suffix="g/L ou g/dL" />
    },
    {
      name: 'micro_INR',
      label: 'micro_INR'
    },
    {
      name: 'quick_tetanos_test',
      label: 'quick_tetanos_test',
      input: RadioButtons
    },
    {
      name: 'antigenic_test',
      label: 'antigenic_test',
      input: RadioButtons
    },
    {
      name: 'exacto_pro',
      label: 'exacto_pro',
      input: RadioButtons
    }
  ];

  const delocalizedBiologyFields = [
    {
      name: 'hemoglobin',
      label: 'hemoglobin',

      input: <Input suffix="g/L" />
    },
    {
      name: 'sodium',
      label: 'sodium'
    },
    {
      name: 'potassium',
      label: 'potassium'
    },
    {
      name: 'chlorine',
      label: 'chlorine'
    },
    {
      name: 'creatinine',
      label: 'creatinine'
    },
    {
      name: 'clearance',
      label: 'clearance'
    },
    {
      name: 'CRP',
      label: 'CRP'
    },
    {
      name: 'tropo',
      label: 'tropo'
    },
    {
      name: 'D_Dimer',
      label: 'D_Dimer'
    },
    {
      name: 'B_HCG',
      label: 'B_HCG'
    }
  ];

  const urineAnalysisFields = [
    {
      name: 'ket',
      label: 'ket',
      input: UrinaryRadioButtons
    },
    {
      name: 'glu',
      label: 'glu',
      input: UrinaryRadioButtons
    },
    {
      name: 'leu',
      label: 'leu',
      input: UrinaryRadioButtons
    },
    {
      name: 'nit',
      label: 'nit',
      input: (
        <Radio.Group buttonStyle="solid">
          <Radio.Button value="-">-</Radio.Button>
          <Radio.Button value="+">+</Radio.Button>
        </Radio.Group>
      )
    },
    {
      name: 'ery',
      label: 'ery',
      input: UrinaryRadioButtons
    },
    {
      name: 'pro',
      label: 'pro',
      input: UrinaryRadioButtons
    }
  ];

  return {
    constantsAndOtherSurveyFields,
    delocalizedBiologyFields,
    urineAnalysisFields
  };
};
export default usefields;
