import { BaseModel } from './BaseModel';

/**
 * Represents a Center model.
 * @class
 * @extends BaseModel
 */
export class Center extends BaseModel {
  constructor(data) {
    super('center', undefined, data);
  }

  /**
   * The name of the model.
   * @type {string}
   */
  static modelName = 'centers';

  /**
   * Retrieves a specific center by its ID.
   * @static
   * @async
   * @function
   * @param {string} id - The ID of the center to retrieve.
   * @param {Object} params - Additional parameters for the API request.
   * @returns {Promise} A promise that resolves with the center data.
   */
  static getCenter(id, { ...params } = {}) {
    return BaseModel.fetchAPI('GET', `${Center.modelName}/${id}`, {}, params);
  }

  /**
   * Archives a Center by sending a PATCH request to the API.
   * @static
   * @async
   * @function
   * @param {string} id - The ID of the Center to be archived.
   * @param {Object} archived - The data to be sent in the PATCH request.
   * @returns {Promise} A promise that resolves when the PATCH request is complete.
   */
  static archivingCenter(id, { ...archived }) {
    return BaseModel.fetchAPI('PATCH', `${Center.modelName}/${id}`, {
      ...archived
    });
  }
}
