import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListResource, ContentCustom } from '../../components';
import { useColumns } from './columns';
import { useActionColumns } from './actionColumns';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Renders a list of centers with custom actions and delete functionality.
 *
 * @component
 * @returns {JSX.Element} The rendered ListCenters component.
 */
export const ListCenters = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const columns = useColumns();
  const actionColumns = useActionColumns();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/centers/${id}` });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  return (
    <ContentCustom>
      <ListResource
        resourceName="centers"
        tradKey="centers"
        columns={[...columns, ...actionColumns]}
        customActionColumn
        forceRefresh={refresh}
        resourceModelName="Center"
        deleteResource={deleteResource}
        withCreateButton={false}
        onDoubleClickAction={{
          action: (record) => {
            navigate(`/centers/settings/${record._id}`);
          }
        }}
      />
    </ContentCustom>
  );
};
