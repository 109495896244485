import { Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiPill, mdiPillOff } from '@mdi/js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * Renders a pill icon based on the `notCurrentMedication` prop.
 * @param {Object} props - The component props.
 * @param {boolean} props.notCurrentMedication - Indicates whether the medication is current or not.
 * @returns {JSX.Element} - The rendered pill icon component.
 */
export const Pill = ({ patient }) => {
  const { t } = useTranslation();
  const {
    not_current_medications: notCurrentMedication,
    current_medications: currentMedications
  } = patient || {};

  const pillincons = {
    false: mdiPill,
    true: mdiPillOff
  };

  if (!currentMedications.length && !notCurrentMedication) {
    return null;
  }

  if (pillincons[notCurrentMedication]) {
    return (
      <Tooltip title={t('patients.icons.no_personnal_treatment')}>
        <Icon
          path={pillincons[notCurrentMedication]}
          size="18px"
          color="blue"
        />
      </Tooltip>
    );
  }

  return null;
};

Pill.propTypes = {
  patient: PropTypes.shape({
    not_current_medications: PropTypes.bool,
    current_medications: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};
