import { Button, Col, Radio, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalCCMU } from '../ModalCCMU';
import { Consultation } from '../../../../../models/Consultation';

const { Title } = Typography;

/**
 * Renders a row for the CCMU classification in the medical consultation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.ccmuClassificationsEnums - The array of CCMU classification options.
 * @param {string} props.ccmuClassification - The current CCMU classification value.
 * @param {string} props.currentConsultationId - The ID of the current consultation.
 * @param {Function} props.setRefresh - A function to refresh the component.
 * @returns {JSX.Element} The rendered component.
 */
export const RowCCMUClassification = ({
  ccmuClassificationsEnums,
  ccmuClassification,
  currentConsultationId,
  setRefresh
}) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  const ccmuOptions = ccmuClassificationsEnums?.map((item) => ({
    value: item,
    label: `CCMU ${item}`
  }));

  const onChange = async (value) => {
    const newValue = value?.type === 'click' ? null : value;
    await Consultation.patchMedicalConsultation(currentConsultationId, {
      ccmu_classification: newValue
    });
    setRefresh((prev) => !prev);
  };

  return (
    <Col span={24}>
      <Space direction="horizontal">
        <Title level={5}>
          {t('consultations.medical_consultation.ccmu_classifications.title')}
        </Title>
        <Button
          title={t(
            'consultations.medical_consultation.ccmu_classifications.title'
          )}
          icon={<QuestionCircleOutlined />}
          onClick={() => setOpenModal(true)}
        />
        <Button onClick={onChange} type="link">
          {t('buttons.reset')}
        </Button>
      </Space>
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        value={
          currentConsultationId && ccmuClassification
            ? ccmuClassification
            : null
        }
        options={ccmuOptions}
        onChange={({ target }) => onChange(target.value)}
      />
      <ModalCCMU
        openModal={openModal}
        setOpenModal={setOpenModal}
        ccmuClassificationsEnums={ccmuClassificationsEnums}
      />
    </Col>
  );
};

RowCCMUClassification.propTypes = {
  ccmuClassification: PropTypes.string,
  ccmuClassificationsEnums: PropTypes.arrayOf(PropTypes.string),
  currentConsultationId: PropTypes.string,
  setRefresh: PropTypes.func.isRequired
};

RowCCMUClassification.defaultProps = {
  ccmuClassification: null,
  ccmuClassificationsEnums: [],
  currentConsultationId: ''
};
