import { Button, Form, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { patchConsultation } from '../../../Components/patchConsultation';
import { useGenerateFormItem } from '../../../../../utils/generateFormItem';
import { formItemLayoutCustom } from '../../../../../utils/constants/formLayout';

/**
 * A component that displays a modal for uploading photos.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isModal - A boolean indicating whether the modal is open.
 * @param {Function} props.setIsModal - Function to set the open state of the modal.
 * @param {Array} props.fields - The fields to be uploaded.
 * @param {string} props.idConsultation - The id of the consultation.
 * @param {string} props.resource - The resource to be used for translation.
 *
 * @returns {JSX.Element} The UploadPhotoModal component.
 */
export const UploadPhotoModal = ({
  isModal,
  setIsModal,
  fields,
  idConsultation,
  resource
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const { handleSubmit } = patchConsultation(idConsultation, handleCloseModal);

  return (
    <Modal open={isModal} onCancel={() => setIsModal(false)} footer={null}>
      <Form form={form} onFinish={handleSubmit} {...formItemLayoutCustom}>
        {fields?.map((field) => generateFields(resource, field))}
        <Row justify="end">
          <Button htmlType="submit" type="primary">
            {t('buttons.validate')}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

UploadPhotoModal.propTypes = {
  isModal: PropTypes.bool.isRequired,
  setIsModal: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  idConsultation: PropTypes.string,
  resource: PropTypes.string.isRequired
};

UploadPhotoModal.defaultProps = {
  idConsultation: undefined
};
