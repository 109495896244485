import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Custom hook that handles the change event for paramedical transmissions.
 * @hook
 *
 * @param {Array} paramedicalsTransmissions - The array of paramedical transmissions.
 * @param {Function} setParamedicalTransmissions - The function to update the paramedical transmissions.
 * @returns {Object} - An object containing the handleChange function.
 */
export const useHandleChange = (
  paramedicalsTransmissions,
  setParamedicalTransmissions
) => {
  const { user } = useAuthContext();

  /**
   * Handles the change event for paramedical transmissions.
   * @function
   *
   * @param {Object} event - The event object.
   * @param {string} event.target.value - The value of the transmission.
   * @param {boolean} event.target.checked - The checked state of the transmission.
   * @returns {void}
   */
  const handleChange = ({
    target: { value: transmission, checked: transmitted }
  }) => {
    if (
      paramedicalsTransmissions.some(
        (care) => care.transmission === transmission
      )
    ) {
      const newParamedicalsTransmissions = paramedicalsTransmissions.filter(
        (care) => care.transmission !== transmission
      );
      setParamedicalTransmissions(newParamedicalsTransmissions);
      return;
    }
    setParamedicalTransmissions((prevCares) => [
      ...prevCares,
      { transmission, transmitted, nurse: user._id }
    ]);
  };

  return { handleChange };
};
