import { useState } from 'react';
import { Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';

const iconSize = 20;

/**
 * @hook usePrintColumn
 * @description A custom hook to return a column for printing documents
 * @returns {Object} - object containing render function
 */
export const usePrintColumn = () => {
  const { printDocument } = useDownloadDocument();
  const [loading, setLoading] = useState(false);

  const print = async (record) => {
    setLoading(true);
    if (record && record.prescription_document) {
      await printDocument({ _id: record.prescription_document });
    }
    setLoading(false);
  };

  return {
    render: (record) => (
      <Button
        loading={loading}
        disabled={loading}
        type="link"
        onClick={() => print(record, setLoading)}
      >
        <PrinterOutlined style={{ fontSize: iconSize }} />
      </Button>
    )
  };
};
