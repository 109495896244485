import { Link } from 'react-router-dom';
import { Divider, Row } from 'antd';
import { EditOutlined, EyeOutlined, PrinterOutlined } from '@ant-design/icons';

const iconSize = 18;

export const useActionColumns = () => [
  {
    key: 'action',
    align: 'right',
    render: (record) => (
      <Row align="middle" style={{ flexWrap: 'nowrap' }}>
        <Link to={`/patients/show/${record?.patientId}`}>
          <EyeOutlined style={{ fontSize: iconSize }} />
        </Link>
        <Divider type="vertical" />
        <Link to={`/patients/edit/${record?.patientId}`}>
          <EditOutlined style={{ fontSize: iconSize }} />
        </Link>
        <Divider type="vertical" style={{ fontSize: iconSize }} />
        <PrinterOutlined />
      </Row>
    )
  }
];
