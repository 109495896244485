import { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  doctorRoutes,
  doctorOutOfNavRoutes
} from '../utils/constants/userRoutes';
import { Login } from '../routes/login';
import { HomeDoctorKanban } from '../routes/home/HomeDoctorKanban';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { UserLayout } from '../components/layouts/UserLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import { PatientRouter } from '../routes/patients/PatientRouter';
import { SecretaryDoctorView } from '../routes/patients/SecretaryDoctorView';
import { ConsultationsRouter } from '../routes/consultations/ConsultationsRouter';
import { PrescriptionsRouter } from '../routes/prescription/PrescriptionsRouter';
import { FavoritesRouter } from '../routes/favorites/FavoritesRouter';
import { LaboratoryRouter } from '../routes/laboratory/LaboratoryRouter';
import { DefaultLanding } from '../components/layouts/DefaultLanding';

const RequireAuth = ({ isValid }) => {
  const location = useLocation();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: doctorOutOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <UserLayout />;
};

RequireAuth.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const DoctorUserRouter = () => {
  const { isValid, user: { landing_choice } = {} } = useAuthContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);

  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route
          path={`${doctorOutOfNavRoutes.LOGIN}/:token`}
          element={<Login />}
        />
        <Route path={doctorOutOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${doctorOutOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${doctorOutOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route
            path={`${doctorOutOfNavRoutes.FAVORITES}/*`}
            element={<FavoritesRouter />}
          />
          <Route path={doctorOutOfNavRoutes.SETTINGS} element={<Settings />} />

          <Route path={`${doctorRoutes.HOME}/*`} element={<DefaultLanding />} />
          <Route
            path={`${doctorRoutes.VIEW}/*`}
            element={<SecretaryDoctorView />}
          />
          {landing_choice === 'ROOMS' ? (
            <Route
              path={`${doctorRoutes.ADMINISTRATIVE}/*`}
              element={<PatientRouter />}
            />
          ) : (
            <Route
              path={`${doctorRoutes.ROOMS}/*`}
              element={<HomeDoctorKanban />}
            />
          )}
          <Route
            path={`${doctorRoutes.PATIENTS}/*`}
            element={<PatientRouter />}
          />
          <Route
            path={`${doctorOutOfNavRoutes.CONSULTATIONS}/*`}
            element={<ConsultationsRouter />}
          />
          <Route
            path={`${doctorRoutes.LABORATORY}/*`}
            element={<LaboratoryRouter />}
          />
          <Route
            path={`${doctorOutOfNavRoutes.PRESCRIPTION}/*`}
            element={<PrescriptionsRouter />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
