import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

/**
 * NegativeUrineTestButton component
 * @component
 * @param {object} form - The form object.
 * @returns {JSX.Element} The rendered component.
 */
export const NegativeUrineTestButton = ({ form }) => {
  const { t } = useTranslation();

  const onChange = (e) => {
    form.setFieldsValue({
      ket: e ? '-' : null,
      glu: e ? '-' : null,
      leu: e ? '-' : null,
      nit: e ? '-' : null,
      ery: e ? '-' : null,
      pro: e ? '-' : null
    });
  };

  return (
    <Form.Item
      name="negative_urine_test_strip"
      label={t('constants_surveys.form.negative_urine_test_strip')}
      valuePropName="checked"
    >
      <Switch
        onChange={(e) => onChange(e)}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
      />
    </Form.Item>
  );
};

NegativeUrineTestButton.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired
};
