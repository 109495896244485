import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDirectoryFields from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';

/**
 * Renders a component for creating or updating directory entries.
 * This component utilizes a CreateUpdateContainer for managing directory data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the directory operation (e.g., 'create' or 'update').
 * @returns {JSX.Element} The rendered component for creating or updating directory entries.
 */
export const CreateUpdateDirectory = ({ purpose }) => {
  const { fields, isFieldsLoading } = useDirectoryFields();
  const {
    user: { selected_center = '' }
  } = useAuthContext();
  const { t } = useTranslation();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        center: selected_center
      })
    }
  };

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('directories.form.create')} />
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="directories"
        resource="directories"
        config={config}
      />
    </ContentCustom>
  );
};

CreateUpdateDirectory.propTypes = {
  purpose: PropTypes.string.isRequired
};
