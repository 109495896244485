import { Card, Col, Row, Select, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useStellairContext } from '../../../contexts/StellairContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Text } = Typography;

/**
 * Component for displaying pricing information.
 * @component
 * @returns {JSX.Element} The ShowPricing component.
 */
export const ShowPricing = () => {
  const { t } = useTranslation();
  const resource = 'consultations';

  const [cotations, setCotations] = useState([]);
  const [selectedCotations, setSelectedCotations] = useState('');
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { redirectToOAuth } = useStellairContext();

  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const handleGetFavoritesCotations = async () => {
    try {
      setLoading(true);
      const response = await dispatchAPI('GET', {
        url: 'stellair/get-favorites-cotations'
      });
      setCotations(response.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        redirectToOAuth();
      }
    } finally {
      setLoading(false);
    }
  };

  const findDefaultCotationsLabel = () =>
    cotations?.list?.find(
      (cotation) => cotation.value === cotations?.defaultCotations
    )?.label;

  useEffect(() => {
    (async () => {
      await handleGetFavoritesCotations();
    })();
  }, [refresh]);

  const handleChange = async (value) => {
    try {
      setSelectedCotations(value);
      await dispatchAPI('PATCH', {
        url: `stellair/set-default-cotations/${value}`
      });
    } catch (error) {
      message(error);
    } finally {
      setRefresh(!refresh);
    }
  };

  return (
    <Card>
      <Row justify="start">
        <h1>{t(`${resource}.show_cotations.title`)}</h1>
      </Row>
      <Row>
        <Col style={{ display: 'flex', flexDirection: 'column' }} span={24}>
          <Text>{t(`${resource}.show_cotations.favorites_cotations`)}</Text>
          <Select
            options={cotations?.list?.map((cotation) => ({
              value: cotation.value,
              label: cotation.label
            }))}
            showSearch
            allowClear
            value={selectedCotations}
            defaultValue={cotations?.defaultCotations}
            onChange={handleChange}
            loading={loading}
          />
        </Col>
        <Col span={24}>
          <Text>{t(`${resource}.show_cotations.default_favorite`)}</Text>
          <Spin spinning={loading}>
            <Text>{findDefaultCotationsLabel()}</Text>
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};
