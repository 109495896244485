import { mdiNeedle } from '@mdi/js';
import Icon from '@mdi/react';
import { Checkbox, Popconfirm, Space, Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MedicineBoxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useHandleChange } from '../utils/handleChange';
import { useHandleConfirmTransmition } from '../utils/useHandleConfirmTransmition';
import { useColorsIcons } from '../utils/useColorsIcons';

/**
 * Component for Blood Sampling.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.consultation - The consultation object.
 * @param {Function} props.setRefresh - The function to refresh the component.
 * @param {Object} props.prescriptionTypesEnums - The enumeration of prescription types.
 * @returns {JSX.Element} The BloodSampling component.
 */
export const BloodSampling = ({
  consultation,
  setRefresh,
  prescriptionTypesEnums
}) => {
  const [transmittedBiologies, setTransmittedBiologies] = useState([]);

  const { t } = useTranslation();
  const { handleConfirm } = useHandleConfirmTransmition(setRefresh);
  const { handleChange } = useHandleChange(
    transmittedBiologies,
    setTransmittedBiologies
  );

  const { BIOLOGY: { bloodSampling } = {} } = prescriptionTypesEnums || {};

  const biologies = consultation?.prescribed_examinations?.filter(
    (prescription) => bloodSampling?.includes(prescription.type)
  );

  const allMedicationsPrescribed = biologies?.every(
    (medication) => medication.transmitted
  );

  const { colorsIcons } = useColorsIcons(
    allMedicationsPrescribed,
    'grey',
    'red'
  );

  const prescribedBiologies = biologies.map((prescription) => (
    <Checkbox
      defaultChecked={prescription.transmitted}
      onChange={handleChange}
      value={prescription._id}
      key={prescription._id}
    >
      {prescription.label}
    </Checkbox>
  ));

  return (
    biologies.length > 0 && (
      <Tooltip title={t('patients.icons.blood_sampling')}>
        <Popconfirm
          placement="bottomRight"
          title={t('patients.icons.sampling')}
          description={
            <Space direction="vertical">{prescribedBiologies}</Space>
          }
          okText={t('buttons.validate')}
          cancelText={t('buttons.cancel')}
          icon={<MedicineBoxOutlined />}
          onConfirm={() =>
            handleConfirm(consultation._id, { transmittedBiologies })
          }
        >
          <Icon path={mdiNeedle} size="18px" color={colorsIcons} />
        </Popconfirm>
      </Tooltip>
    )
  );
};

BloodSampling.propTypes = {
  setRefresh: PropTypes.func.isRequired,
  consultation: PropTypes.shape({
    _id: PropTypes.string,
    prescribed_examinations: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  prescriptionTypesEnums: PropTypes.shape({
    BIOLOGY: PropTypes.shape([])
  }).isRequired
};
