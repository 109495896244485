import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

/**
 * Button component for creating a new patient.
 *
 * @component
 * @param {object} props - The component props.
 * @param {object} props.form - The ant design form object.
 * @param {Function} props.onClick - The onClick event handler.
 * @param {string} props.resource - The resource string. (ex. '/patients')
 * @returns {JSX.Element} The JSX element representing the create patient button.
 */
export const CreatePatientButton = ({ form, onClick, resource }) => {
  const { t } = useTranslation();

  return (
    <Link
      to={`${resource}/create?createConsultation=true`}
      state={{ patient: form.getFieldsValue() }}
    >
      <Button key="validate" type="primary" onClick={onClick}>
        {t('buttons.create_patient')}
      </Button>
    </Link>
  );
};

CreatePatientButton.propTypes = {
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func
  }),
  onClick: PropTypes.func,
  resource: PropTypes.string
};

CreatePatientButton.defaultProps = {
  form: {
    getFieldsValue: () => ({})
  },
  onClick: () => {},
  resource: ''
};
