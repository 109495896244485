import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row } from 'antd';
import PropTypes from 'prop-types';
import { useConsultationsContext } from '../ConsultationsContext';
import { TitleMedicalBackground } from './MedicalBackground/TitleMedicalBackground';
import { MedicalBackgroundBottom } from './MedicalBackground/MedicalBackgroundBottom';

/**
 * ShowMedicalBackground component
 * @component
 * @param {string} id - The ID of the patient.
 * @returns {JSX.Element} The ShowMedicalBackground component.
 */
export const ShowMedicalBackground = ({ id }) => {
  const resource = 'consultations';
  const { t } = useTranslation();
  const { patient } = useConsultationsContext();
  const [modal, setModal] = useState(null);

  const pathologies = {
    heart_failure: patient?.grounds?.cardiacFailure,
    hepatic_insufficiency: patient?.grounds?.hepaticFailure,
    kidneyFailure: patient?.grounds?.kidneyFailure,
    grounds: patient?.grounds
  };

  const onOpenModal = () => {
    const modalKey = Date.now(); // Use a unique key, or current timestamp
    setModal(
      <TitleMedicalBackground key={modalKey} id={id} patient={patient} />
    );
  };

  useEffect(() => {
    onOpenModal(true);
  }, [patient]);

  return (
    <Card>
      {modal}
      <ul>
        {pathologies?.grounds?.cardiacFailure &&
          pathologies?.grounds?.cardiacFailure !== 'none' && (
            <li>
              {t(`${resource}.grounds.heart_failure`, {
                value: t(
                  `${resource}.grounds.${pathologies?.grounds?.cardiacFailure}`
                )
              })}
            </li>
          )}
        {pathologies?.grounds?.hepaticFailure &&
          pathologies?.grounds?.hepaticFailure !== 'none' && (
            <li>
              {t(`${resource}.grounds.hepatic_failure`, {
                value: t(
                  `${resource}.grounds.${pathologies?.grounds?.hepaticFailure}`
                )
              })}
            </li>
          )}
        {pathologies?.grounds?.kidneyFailure &&
          pathologies?.grounds?.kidneyFailure !== 'none' && (
            <li>
              {t(`${resource}.grounds.kidney_failure`, {
                value: t(
                  `${resource}.grounds.${pathologies?.grounds?.kidneyFailure}`
                )
              })}
            </li>
          )}
      </ul>
      {Object?.values(pathologies)?.filter(
        (value) => !['none', undefined].includes(value)
      )?.length > 0 && (
        <div style={{ marginTop: 16 }}>
          {pathologies?.grounds?.pathologies.length > 0 && (
            <>
              <Row>{t(`${resource}.form.pathologies`)}</Row>
              <Row>
                <ul>
                  {pathologies?.grounds?.pathologies.map((pathology) => (
                    <li key={pathology.identifier._id}>
                      {pathology.identifier.display}
                    </li>
                  ))}
                </ul>
              </Row>
            </>
          )}
          {/* I don't know the purpose of this code so I commented it out */}
          {/* <Row>
            <ul>
              {Object.entries(pathologies)
                .filter(([, value]) => value !== 'none')
                .map(([key, value]) => (
                  <li key={key}>
                    {t(`${resource}.form.${key}`)}{' '}
                    {t(`${resource}.form.${value}`)}
                  </li>
                ))}
            </ul>
          </Row> */}
        </div>
      )}
      {Array.isArray(patient?.surgical_history) &&
        patient.surgical_history.length > 0 && (
          <>
            <Row>{t(`${resource}.form.surgical_history`)}</Row>
            <Row>
              <ul>
                {patient.surgical_history.map((operation) => (
                  <li key={operation}>{operation}</li>
                ))}
              </ul>
            </Row>
          </>
        )}
      {Array.isArray(patient?.grounds?.allergies) &&
        patient.grounds.allergies.length > 0 && (
          <>
            <Row>{t(`${resource}.form.allergies`)}</Row>
            <Row>
              <ul>
                {patient.grounds.allergies.map((allergy) => (
                  <li key={allergy.label}>{allergy.label}</li>
                ))}
              </ul>
            </Row>
          </>
        )}
      <MedicalBackgroundBottom />
    </Card>
  );
};
ShowMedicalBackground.propTypes = {
  id: PropTypes.string.isRequired
};
