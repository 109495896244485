import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';
import { DataBaseMethods } from '../DataBaseMethods';

/**
 * Custom hook for defining columns used in the ListProtocol component.
 *
 * @returns {object} Object containing the columns configuration.
 * @property {array} columns - Array of column objects for rendering in a table.
 * @property {string} columns.title - The title of the column.
 * @property {string} columns.dataIndex - The key to access the data for this column.
 * @property {string} columns.key - The unique key for this column.
 * @property {Function} columns.render - Function to render the content of the column.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const { onChangeUserDisplay } = DataBaseMethods();

  const columns = [
    {
      title: t('data_bases.protocols.columns.protocol_wording'),
      dataIndex: 'protocol_wording',
      key: 'protocol_wording'
    },
    {
      title: t('data_bases.protocols.columns.visit_reason'),
      dataIndex: 'visit_reasons',
      key: 'visit_reasons',
      render: (visit_reasons) =>
        visit_reasons
          .flatMap((visitReason) => visitReason.reason_wording)
          .join(', ')
    },
    {
      title: t('data_bases.visit-reasons.columns.user_display'),
      key: 'actions',
      align: 'rigth',
      fixed: 'rigth',
      render: (display) => (
        <Switch
          checkedChildren={t('buttons.displayed')}
          unCheckedChildren={t('buttons.hidden')}
          defaultChecked={display.user_display}
          onChange={(checked) =>
            onChangeUserDisplay('protocols', checked, display._id)
          }
        />
      )
    }
  ];

  return { columns };
};
