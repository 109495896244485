import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table } from 'antd';
import { useColumnsSearch } from './columnsSearchList';

/**
 * SearchPatientResultModal component displays the search results of patients in a modal.
 *
 * @component
 * @param {Object[]} searchResults - The array of search results.
 * @param {Function} handleCancelResult - The function to handle canceling the search result.
 * @returns {JSX.Element} The rendered SearchPatientResultModal component.
 */
export const SearchPatientResultModal = ({
  searchResults,
  handleCancelResult
}) => {
  const { t } = useTranslation();
  const columnsSearch = useColumnsSearch(() => handleCancelResult());

  const searchResultsWithKey = searchResults.map((item) => ({
    ...item,
    key: item._id
  }));

  return (
    <Col span={24}>
      {searchResults.length > 0 ? (
        <>
          <Row className="modal-title">{t(`patients.form.patient_found`)}</Row>
          <Table
            dataSource={searchResultsWithKey}
            columns={columnsSearch}
            handleClose={handleCancelResult}
            scroll={{ x: 800 }}
          />
        </>
      ) : (
        <>
          <Row className="modal-title">
            {t('patients.form.no_patient_found')}
          </Row>
          <Row>{t('patients.form.redirect')}</Row>
        </>
      )}
    </Col>
  );
};

SearchPatientResultModal.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.shape({})),
  handleCancelResult: PropTypes.func
};
SearchPatientResultModal.defaultProps = {
  searchResults: undefined,
  handleCancelResult: undefined
};
