import { useEffect, useState } from 'react';
import { Select, Input, Form, Spin, Tag, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete from 'react-places-autocomplete';

import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { userRoles } from '../../utils/constants/tagColors';
import { getFullName } from '../../utils/formatters';

const { Option } = Select;

/**
 * Custom hook that provides fields configuration for company and user forms.
 *
 * @hook
 * @param {string} radioValue - The value of the radio button.
 * @param {string} address - The address of the company.
 * @param {Function} setAddress - The function to set the address of the company.
 * @param {boolean} companyCreation - The value to set the fields depending on whether we create a user from a company or not
 * @returns {object} - An object containing the fields configuration.
 */
const useFields = (radioValue, address, setAddress, companyCreation) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [enums, setEnums] = useState({});
  const [selectedPosition, setSelectedPosition] = useState('');

  const onChangePosition = (value) => {
    setSelectedPosition(value);
  };

  const getPhoneNumberGroup = (key) => (
    <Input.Group compact>
      <Form.Item
        noStyle
        name={['company', ...key, 'country_code']}
        initialValue="+33"
      >
        <Select style={{ width: '25%' }}>
          <Option value="+33">+33</Option>
        </Select>
      </Form.Item>
      <Form.Item noStyle name={['company', ...key, 'number']}>
        <Input style={{ width: '75%' }} maxLength={10} />
      </Form.Item>
    </Input.Group>
  );

  const companyFields = [
    {
      label: 'name',
      name: ['company', 'name'],
      rules: [{ required: true }]
    },
    {
      label: 'address',
      name: ['company', 'address'],
      rules: [{ required: true }],
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={(value) => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: t('placeholder.search_address'),
                  className: 'location-search-input'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <Spin />}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    },
    {
      label: 'secretariat_phone_number',
      name: ['company', 'secretariat_phone_number'],
      rules: [{ required: true }],
      input: getPhoneNumberGroup(['secretariat_phone_number'])
    },
    {
      label: 'provider',
      name: ['company', 'provider']
    },
    {
      label: 'partner_laboratory.name',
      name: ['company', 'partner_laboratory', 'name']
    },
    {
      label: 'partner_laboratory.phone_number',
      name: ['company', 'partner_laboratory', 'phone_number'],
      input: getPhoneNumberGroup(['partner_laboratory', 'phone_number'])
    }
  ];

  const userSelect = [
    {
      label: 'manager',
      name: ['user', '_id'],
      rules: [{ required: radioValue === 'select' }],
      input: (
        <Form.Item noStyle name={['user', '_id']}>
          <Select
            style={{
              width: 'calc(100% - 98px)',
              marginRight: '8px'
            }}
            showSearch
            allowClear
            placeholder={t('users.form.placeholders.select_a_user')}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={users
              .filter((user) => user.role === 'users:SUPER-USER')
              .map((user) => ({
                label: getFullName(user),
                value: user._id
              }))}
          />
        </Form.Item>
      )
    }
  ];

  const userFields = [
    {
      label: 'user.first_name',
      name: ['user', 'first_name'],
      rules: [{ required: radioValue === 'create' }]
    },
    {
      label: 'user.last_name',
      name: ['user', 'last_name'],
      rules: [{ required: radioValue === 'create' }]
    },
    {
      label: 'user.cpx_number',
      name: ['user', 'cpx_number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      label: 'user.email',
      name: ['user', 'email'],
      rules: [{ required: radioValue === 'create' }]
    },

    {
      name: ['user', 'phone_number'],
      label: 'user.phone_number',
      rules: [{ required: radioValue === 'create' }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['user', 'phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['user', 'phone_number', 'number']}>
            <Input style={{ width: '75%' }} maxLength={10} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  const roleFields = {
    label: 'user.role',
    name: ['user', 'role'],
    rules: [{ required: radioValue === 'create' }],
    input: (
      <Select loading={isFieldsLoading}>
        {enums.roles?.map((role) => (
          <Option key={role} value={role}>
            <Tag color={userRoles[role.split(':')[1]]}>
              {t(`users.tags.${role.split(':')[1]}`)}
            </Tag>
          </Option>
        ))}
      </Select>
    )
  };

  const positionFields = {
    label: 'user.position',
    name: ['user', 'position'],
    rules: [{ required: radioValue === 'create' }],
    input: (
      <Select loading={isFieldsLoading} allowClear onChange={onChangePosition}>
        {enums?.positions?.map((position) => (
          <Option key={position} value={position}>
            {t(`users.positions.${position}`)}
          </Option>
        ))}
      </Select>
    )
  };

  const statusField = {
    name: ['user', 'replacing'],
    label: 'status',
    rules: [{ required: true }],
    input: (
      <Radio.Group>
        <Radio value>{t('users.form.options.substitute')}</Radio>
        <Radio value={false}>{t('users.form.options.registered')}</Radio>
      </Radio.Group>
    )
  };

  switch (companyCreation) {
    case false:
      userFields.push(roleFields, positionFields);
      break;
    default:
      break;
  }

  switch (selectedPosition) {
    case 'DOCTOR':
      userFields.push(statusField);
      break;
    case 'NURSE':
      userFields.push(statusField);
      break;
    default:
      break;
  }
  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users?deleted=false'
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      await getUsers();
      await getEnums();
      setIsFieldsLoading(false);
    })();
  }, []);

  return {
    companyFields,
    userSelect,
    userFields
  };
};

export default useFields;
