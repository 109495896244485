import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';

// Components
import { PrescriptionChoiceExit } from './exit/PrescriptionChoiceExit';
import { PrescriptionChoiseStay } from './stay/PrescriptionChoiseStay';

/**
 * @component
 * @description Component that displays the prescription choices
 * @returns {JSX.Element} Prescription choices
 */
export const PrescriptionChoises = () => {
  const { type, id } = useParams();
  const { setPatientId } = usePrescriptionContext();
  const [component, setComponent] = useState(null);

  useEffect(() => {
    setPatientId(id);

    if (type === 'EXIT' || type === 'STAY') {
      const Component =
        type === 'EXIT' ? PrescriptionChoiceExit : PrescriptionChoiseStay;
      setComponent(<Component key={id} />);
    } else {
      setComponent(null);
      setPatientId(null);
    }
  }, [type, id]);

  return component;
};
