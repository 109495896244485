import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Button, Row, message } from 'antd';
import { ContentCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { Logo } from './components/Collapses/Logo';
import { TurnoverTargets } from './components/Collapses/TurnoverTargets';
import { WaitingRooms } from './components/Collapses/WaitingRooms';
import { ConsultingRooms } from './components/Collapses/ConsultingRooms';
import { KanbanColumns } from './components/Collapses/KanbanColumns';

/**
 * CenterSettings component.
 * Renders a form for editing center settings.
 *
 * @returns {JSX.Element} CenterSettings component.
 */
export const CenterSettings = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatchAPI, token } = useAuthContext();
  const [form] = Form.useForm();
  const [rooms, setRooms] = useState([]);
  const [center, setCenter] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const errorMessage = t('messages.error');

  const getResource = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/centers/${id}`
      });
      setCenter(data);

      form.setFieldsValue(data);
    } catch (error) {
      message.error(errorMessage);
    }
  };

  const getRooms = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rooms/WAITING_ROOM/${id}`
      });
      setRooms(data);
    } catch (error) {
      message.error(errorMessage);
    }
  };

  const patchCenter = async (body) => {
    try {
      const formData = new FormData();
      formData.append('values', JSON.stringify(body));

      await dispatchAPI('PATCH', {
        url: `/centers/${id}`,
        body: formData
      });
      setRefresh(!refresh);
      message.success(t('success.message.center.updated_successfully'));
    } catch (e) {
      message.error(errorMessage);
    }
  };

  const postRoom = async (type) => {
    try {
      await dispatchAPI('POST', {
        url: '/rooms',
        body: {
          type,
          language: navigator.language,
          center: id
        }
      });
      setRefresh(!refresh);
    } catch (error) {
      message.error(errorMessage);
    }
  };

  const props = {
    refresh,
    setRefresh,
    id,
    postRoom
  };

  useEffect(() => {
    (() => {
      getResource();
      getRooms();
    })();
  }, [refresh]);

  return (
    <ContentCustom>
      <Form form={form} onFinish={patchCenter}>
        <Logo
          logo={
            center?.logo &&
            `${process.env.REACT_APP_API_URL}/files/download-image/${center.logo}/${token}`
          }
          setRefresh={setRefresh}
        />
        <TurnoverTargets form={form} id={id} />
        <WaitingRooms rooms={rooms} {...props} />
        <ConsultingRooms {...props} />
        <KanbanColumns refresh={refresh} setRefresh={setRefresh} />
        <Row justify="end">
          <Button type="link" onClick={() => navigate(-1)}>
            {t('buttons.cancel')}
          </Button>
          <Button id="save-button" htmlType="submit" type="primary">
            {t('buttons.save_changes')}
          </Button>
        </Row>
      </Form>
    </ContentCustom>
  );
};
