import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select, Switch, Typography, notification } from 'antd';
import { useErrorMessage } from '../../../utils/errorMessage';
import { GetEnums } from '../../../utils/getEnums';
import { translatePrescriptionTypes } from './utils/translatePrescriptionTypes';

const { Text } = Typography;

/**
 * Custom hook for defining form fields for the CreateUpdatePrescriptions component.
 *
 * @param {Array} stayPrescriptions - The list of stay prescriptions to populate Select options.
 * @param {Function} setStayPrescriptions - The function to set the stay prescriptions.
 * @param {Function} setPrescriptionWording - The function to set the prescription wording.
 * @param {String} prescriptionWording - The prescription wording.
 * @param {Function} setCategory - The function to set the prescription category.
 * @param {String} category - The prescription category.
 * @param {Function} setPrescriptionType - The function to set the prescription type.
 * @param {String} prescriptionType - The prescription type.
 * @param {Function} setWithOptions - The function to set the prescription options.
 * @param {Boolean} withOptions - The prescription options.
 * @param {String} purpose - The purpose of the form.
 * @param {Object} form - The form object.
 *
 * @returns {Object} An object containing the `fields` array.
 */
export const useFields = ({
  prescriptionWording,
  setPrescriptionWording,
  category,
  setCategory,
  prescriptionType,
  setPrescriptionType,
  withOptions,
  setWithOptions,
  purpose,
  form
}) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { getEnums } = GetEnums();
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [prescriptionsTypesOptions, setPrescriptionsTypesOptions] = useState(
    []
  );

  const fetchEnums = async () => {
    try {
      const { categories, prescription_types } = await getEnums(
        'config-prescriptions'
      );

      const categoriesOptionsArray = (categories || []).map((item) => ({
        label: t(`data_bases.stay_prescriptions.categories.${item}`),
        value: item
      }));

      const prescriptionsTypesOptionsArray = translatePrescriptionTypes(
        category,
        prescription_types,
        t
      );

      setCategoriesOptions(categoriesOptionsArray);
      setPrescriptionsTypesOptions(prescriptionsTypesOptionsArray);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchEnums();
    })();
  }, [category]);

  const onChange = (checked) => {
    if (purpose === 'create') {
      setWithOptions(checked);
      if (checked) {
        notification.warning({
          message: t('notifications.with_options_warning')
        });
      }
    } else {
      notification.warning({
        message: t('notifications.edit_options_warning')
      });
    }
  };

  const fields = [
    {
      name: ['prescription_wording'],
      label: 'prescription_wording',
      rules: [{ required: true }],
      input: <Input onChange={(e) => setPrescriptionWording(e.target.value)} />,
      initialValue: prescriptionWording
    },
    {
      name: 'category',
      label: 'category',
      input: (
        <Select
          options={categoriesOptions}
          onChange={(e) => {
            setCategory(e);
            form.setFieldValue('prescription_type', undefined);
          }}
        />
      ),
      rules: [{ required: true }],
      initialValue: category
    },
    {
      name: 'prescription_type',
      label: 'prescription_type',
      input: (
        <Select
          options={prescriptionsTypesOptions}
          onChange={(e) => setPrescriptionType(e)}
          notFoundContent={
            <Text>
              {t(
                'data_bases.stay_prescriptions.form.prescription_types_placeholder'
              )}
            </Text>
          }
        />
      ),
      initialValue: prescriptionType
    },
    {
      label: 'option_wording',
      input: (
        <Switch
          checkedChildren={t('data_bases.stay_prescriptions.form.with_options')}
          unCheckedChildren={t(
            'data_bases.stay_prescriptions.form.without_options'
          )}
          checked={withOptions}
          onChange={onChange}
        />
      )
    },
    {
      name: 'left_right_bilateral',
      label: 'left_right_bilateral',
      input: <Switch />,
      valuePropName: 'checked'
    }
  ];

  return { fields };
};
